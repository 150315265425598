// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../libs/Date.res.js";
import * as Project from "./Project.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as ProjectProviderId from "./ProjectProviderId.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function toJson(reviewStatus) {
  if (reviewStatus === "RegistrationRequested") {
    return "registration_requested";
  } else if (reviewStatus === "RegistrationDenied") {
    return "registration_denied";
  } else if (reviewStatus === "Registered") {
    return "registered";
  } else if (reviewStatus === "Unresponsive") {
    return "unresponsive";
  } else if (reviewStatus === "Invited") {
    return "invited";
  } else {
    return "uninvited";
  }
}

function toString(value) {
  if (value === "RegistrationRequested") {
    return "Registration Requested";
  } else if (value === "RegistrationDenied") {
    return "Registration Denied";
  } else if (value === "Registered") {
    return "Registered";
  } else if (value === "Unresponsive") {
    return "Unresponsive";
  } else if (value === "Invited") {
    return "Invited";
  } else {
    return "Uninvited";
  }
}

function toApiString(value) {
  if (value === "RegistrationRequested") {
    return "registration_requested";
  } else if (value === "RegistrationDenied") {
    return "registration_denied";
  } else if (value === "Registered") {
    return "registered";
  } else if (value === "Unresponsive") {
    return "unresponsive";
  } else if (value === "Invited") {
    return "invited";
  } else {
    return "uninvited";
  }
}

function fromString(reviewStatus) {
  switch (reviewStatus) {
    case "invited" :
        return "Invited";
    case "registered" :
        return "Registered";
    case "registration_denied" :
        return "RegistrationDenied";
    case "registration_requested" :
        return "RegistrationRequested";
    case "uninvited" :
        return "Uninvited";
    case "unresponsive" :
        return "Unresponsive";
    default:
      return "Invited";
  }
}

function badgeClass(status) {
  if (status === "RegistrationRequested") {
    return "providerRegRequest";
  } else if (status === "RegistrationDenied") {
    return "providerDenied";
  } else if (status === "Registered") {
    return "providerRegistered";
  } else if (status === "Unresponsive") {
    return "providerUnresponsive";
  } else if (status === "Invited") {
    return "providerInvited";
  } else {
    return "providerUninvited";
  }
}

var Status = {
  toJson: toJson,
  toString: toString,
  toApiString: toApiString,
  fromString: fromString,
  badgeClass: badgeClass
};

function fromJs(js) {
  return {
          date: $$Date.Naive.fromString(js.date),
          event: js.event,
          user: js.user
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              date: field.required("date", $$Date.Naive.decoder),
              event: field.required("event", Json_Decode$JsonCombinators.string),
              user: field.required("user", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var $$Event = {
  fromJs: fromJs,
  decoder: decoder,
  fromJson: fromJson
};

function fromJs$1(js) {
  return {
          id: js.id,
          providerId: js.providerId,
          projectId: js.projectId,
          reviewStatus: js.reviewStatus,
          createdAt: $$Date.Naive.fromString(js.createdAt),
          updatedAt: $$Date.Naive.fromString(js.updatedAt),
          providerName: js.providerName,
          providerImage: js.providerImage,
          events: Belt_Array.map(js.events, fromJs)
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ProjectProviderId.decoder),
              providerId: field.required("providerId", ProjectProviderId.decoder),
              projectId: field.required("projectId", Project.Id.decoder),
              reviewStatus: field.required("reviewStatus", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.string),
              providerImage: field.required("providerImage", Json_Decode$JsonCombinators.string),
              events: field.required("events", Json_Decode$JsonCombinators.array(decoder))
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

export {
  Status ,
  $$Event ,
  fromJs$1 as fromJs,
  decoder$1 as decoder,
  fromJson$1 as fromJson,
}
/* decoder Not a pure module */
