// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as CartProductItem from "../../models/CartProductItem.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              products: field.required("products", Json_Decode$JsonCombinators.array(CartProductItem.decoder))
            };
    });

var Res = {
  decoder: decoder
};

function exec() {
  return Rest.$$fetch("/get_cart", "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Res ,
  exec ,
}
/* decoder Not a pure module */
