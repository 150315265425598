// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../../../../libs/Date.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as NonSecure from "nanoid/non-secure";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";
import * as CreateColocationRfpProjectConfiguration_Data_CrossConnect from "./CreateColocationRfpProjectConfiguration_Data_CrossConnect.res.js";
import * as CreateColocationRfpProjectConfiguration_Data_CloudDirectConnect from "./CreateColocationRfpProjectConfiguration_Data_CloudDirectConnect.res.js";
import * as CreateColocationRfpProjectConfiguration_Data_InternetConnectivity from "./CreateColocationRfpProjectConfiguration_Data_InternetConnectivity.res.js";
import * as CreateColocationRfpProjectConfiguration_Data_RackTypesAndPowerDensity from "./CreateColocationRfpProjectConfiguration_Data_RackTypesAndPowerDensity.res.js";

function empty() {
  return {
          key: NonSecure.nanoid(),
          startDate: undefined,
          contractLength: undefined,
          rackTypesAndPowerDensities: [CreateColocationRfpProjectConfiguration_Data_RackTypesAndPowerDensity.Input.empty],
          internetConnectivities: [CreateColocationRfpProjectConfiguration_Data_InternetConnectivity.Input.empty],
          crossConnects: [CreateColocationRfpProjectConfiguration_Data_CrossConnect.Input.empty],
          cloudDirectConnects: [CreateColocationRfpProjectConfiguration_Data_CloudDirectConnect.Input.empty],
          certificationRequirements: [],
          datacenterFeatures: [],
          additionalNotesOrRequirements: undefined
        };
}

function fromColocationConfiguration(colocationConfiguration) {
  return {
          key: NonSecure.nanoid(),
          startDate: colocationConfiguration.startDate,
          contractLength: colocationConfiguration.contractLength,
          rackTypesAndPowerDensities: Belt_Array.map(colocationConfiguration.rackTypesAndPowerDensities, CreateColocationRfpProjectConfiguration_Data_RackTypesAndPowerDensity.Input.fromRackTypesAndPowerOptions),
          internetConnectivities: Belt_Array.map(colocationConfiguration.internetConnectivities, CreateColocationRfpProjectConfiguration_Data_InternetConnectivity.Input.fromInternetConnectivity),
          crossConnects: Belt_Array.map(colocationConfiguration.crossConnects, CreateColocationRfpProjectConfiguration_Data_CrossConnect.Input.fromCrossConnect),
          cloudDirectConnects: Belt_Array.map(colocationConfiguration.cloudDirectConnects, CreateColocationRfpProjectConfiguration_Data_CloudDirectConnect.Input.fromCloudDirectConnect),
          certificationRequirements: colocationConfiguration.certificationRequirements,
          datacenterFeatures: colocationConfiguration.datacenterFeatures,
          additionalNotesOrRequirements: colocationConfiguration.additionalNotesOrRequirements
        };
}

function toJson(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "startDate",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(Belt_Option.map(input.startDate, $$Date.Naive.toString))
              ],
              [
                "contractLength",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.contractLength)
              ],
              [
                "rackTypesAndPowerDensities",
                Belt_Array.map(input.rackTypesAndPowerDensities, CreateColocationRfpProjectConfiguration_Data_RackTypesAndPowerDensity.Input.toJson)
              ],
              [
                "internetConnectivities",
                Belt_Array.map(input.internetConnectivities, CreateColocationRfpProjectConfiguration_Data_InternetConnectivity.Input.toJson)
              ],
              [
                "crossConnects",
                Belt_Array.map(input.crossConnects, CreateColocationRfpProjectConfiguration_Data_CrossConnect.Input.toJson)
              ],
              [
                "cloudDirectConnects",
                Belt_Array.map(input.cloudDirectConnects, CreateColocationRfpProjectConfiguration_Data_CloudDirectConnect.Input.toJson)
              ],
              [
                "certificationRequirements",
                Json_Encode$JsonCombinators.array(function (prim) {
                        return prim;
                      })(input.certificationRequirements)
              ],
              [
                "datacenterFeatures",
                Json_Encode$JsonCombinators.array(function (prim) {
                        return prim;
                      })(input.datacenterFeatures)
              ],
              [
                "additionalNotesOrRequirements",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.additionalNotesOrRequirements)
              ]
            ]);
}

function title(configuration) {
  return Js_array.joinWith(", ", Belt_Array.map(configuration.rackTypesAndPowerDensities, CreateColocationRfpProjectConfiguration_Data_RackTypesAndPowerDensity.Input.title));
}

var Input = {
  empty: empty,
  fromColocationConfiguration: fromColocationConfiguration,
  toJson: toJson,
  title: title
};

var ValidationResult = {};

function startDate(param) {
  if (param.startDate !== undefined) {
    return {
            TAG: "Ok",
            _0: undefined
          };
  } else {
    return {
            TAG: "Error",
            _0: "Start date is required"
          };
  }
}

function contractLength(param) {
  if (param.contractLength !== undefined) {
    return {
            TAG: "Ok",
            _0: undefined
          };
  } else {
    return {
            TAG: "Error",
            _0: "Contract length is required"
          };
  }
}

function all(state) {
  return {
          startDate: startDate(state),
          contractLength: contractLength(state),
          rackTypesAndPowerDensities: Belt_Array.map(state.rackTypesAndPowerDensities, (function (rackType) {
                  return {
                          quantity: CreateColocationRfpProjectConfiguration_Data_RackTypesAndPowerDensity.Validate.quantity(rackType),
                          colocationSpace: CreateColocationRfpProjectConfiguration_Data_RackTypesAndPowerDensity.Validate.colocationSpace(rackType),
                          powerValue: CreateColocationRfpProjectConfiguration_Data_RackTypesAndPowerDensity.Validate.powerValue(rackType),
                          powerUnits: CreateColocationRfpProjectConfiguration_Data_RackTypesAndPowerDensity.Validate.powerUnits(rackType)
                        };
                })),
          crossConnects: Belt_Array.map(state.crossConnects, (function (crossConnect) {
                  return {
                          quantity: CreateColocationRfpProjectConfiguration_Data_CrossConnect.Validate.quantity(crossConnect)
                        };
                }))
        };
}

function valid(result) {
  if (result.startDate.TAG === "Ok" && result.contractLength.TAG === "Ok" && Belt_Array.every(result.rackTypesAndPowerDensities, CreateColocationRfpProjectConfiguration_Data_RackTypesAndPowerDensity.Validate.valid)) {
    return Belt_Array.every(result.crossConnects, CreateColocationRfpProjectConfiguration_Data_CrossConnect.Validate.valid);
  } else {
    return false;
  }
}

var Validate = {
  startDate: startDate,
  contractLength: contractLength,
  all: all,
  valid: valid
};

export {
  Input ,
  ValidationResult ,
  Validate ,
}
/* Date Not a pure module */
