// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var DocumentId = ID.Int({});

function fromJs($$document) {
  return {
          id: $$document.id,
          filename: $$document.filename,
          byteSize: $$document.byteSize,
          createdAt: $$Date.Utc.fromString($$document.createdAt)
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", DocumentId.decoder),
              filename: field.required("filename", Json_Decode$JsonCombinators.string),
              byteSize: field.required("byteSize", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", $$Date.Utc.decoder)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function toKiloBytes(bytes) {
  return bytes / 1000.0;
}

function toMegaBytes(bytes) {
  return bytes / 1000000.0;
}

function sizeText(bytes) {
  if (bytes < 1000000) {
    return (bytes / 1000.0).toFixed(1) + " KB";
  } else {
    return (bytes / 1000000.0).toFixed(1) + " MB";
  }
}

var $$Document = {
  fromJs: fromJs,
  decoder: decoder,
  fromJson: fromJson,
  toKiloBytes: toKiloBytes,
  toMegaBytes: toMegaBytes,
  sizeText: sizeText
};

function fromJs$1(js) {
  return {
          id: js.id,
          title: js.title,
          resourceImage: js.resourceImage,
          slug: js.slug,
          resourceDetail: js.resourceDetail,
          resourceSummary: js.resourceSummary,
          popular: js.popular,
          categorySlug: js.categorySlug,
          pdfFile: js.pdfFile,
          privateDownload: js.privateDownload,
          createdAt: $$Date.Naive.fromString(js.createdAt),
          category: js.category,
          active: js.active,
          metaTitle: js.metaTitle,
          metaDescription: js.metaDescription,
          resourceCategoryId: js.resourceCategoryId,
          pdfResource: js.pdfResource
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              resourceImage: field.required("resourceImage", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              resourceDetail: field.required("resourceDetail", Json_Decode$JsonCombinators.string),
              resourceSummary: field.required("resourceSummary", Json_Decode$JsonCombinators.string),
              popular: field.required("popular", Json_Decode$JsonCombinators.bool),
              categorySlug: field.required("categorySlug", Json_Decode$JsonCombinators.string),
              pdfFile: field.required("pdfFile", Json_Decode$JsonCombinators.string),
              privateDownload: field.required("privateDownload", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              category: field.required("category", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string),
              resourceCategoryId: field.required("resourceCategoryId", ID.decoder),
              pdfResource: field.required("pdfResource", decoder)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

function fromJs$2(js) {
  return {
          id: js.id,
          title: js.title,
          slug: js.slug,
          category: js.category,
          categorySlug: js.categorySlug,
          resourceDownloadsCount: js.resourceDownloadsCount,
          ahoyEventsCount: js.ahoyEventsCount
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              category: field.required("category", Json_Decode$JsonCombinators.string),
              categorySlug: field.required("categorySlug", Json_Decode$JsonCombinators.string),
              resourceDownloadsCount: field.required("resourceDownloadsCount", Json_Decode$JsonCombinators.$$int),
              ahoyEventsCount: field.required("ahoyEventsCount", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

var Analytics = {
  fromJs: fromJs$2,
  decoder: decoder$2,
  fromJson: fromJson$2
};

function fromJs$3(js) {
  return {
          id: js.id,
          title: js.title,
          resourceImage: js.resourceImage,
          slug: js.slug,
          resourceDetail: js.resourceDetail,
          resourceSummary: js.resourceSummary,
          popular: js.popular,
          categorySlug: js.categorySlug,
          pdfFile: js.pdfFile,
          privateDownload: js.privateDownload,
          createdAt: $$Date.Naive.fromString(js.createdAt),
          category: js.category,
          active: js.active,
          metaTitle: js.metaTitle,
          metaDescription: js.metaDescription,
          resourceCategoryId: js.resourceCategoryId,
          pdfResource: fromJs(js.pdfResource),
          leads: js.leads
        };
}

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              resourceImage: field.required("resourceImage", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              resourceDetail: field.required("resourceDetail", Json_Decode$JsonCombinators.string),
              resourceSummary: field.required("resourceSummary", Json_Decode$JsonCombinators.string),
              popular: field.required("popular", Json_Decode$JsonCombinators.bool),
              categorySlug: field.required("categorySlug", Json_Decode$JsonCombinators.string),
              pdfFile: field.required("pdfFile", Json_Decode$JsonCombinators.string),
              privateDownload: field.required("privateDownload", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              category: field.required("category", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string),
              resourceCategoryId: field.required("resourceCategoryId", ID.decoder),
              pdfResource: field.required("pdfResource", decoder),
              leads: field.required("leads", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$3(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

var Dashboard = {
  fromJs: fromJs$3,
  decoder: decoder$3,
  fromJson: fromJson$3
};

function fromJs$4(js) {
  return {
          id: js.id,
          title: js.title,
          resourceImage: js.resourceImage,
          slug: js.slug,
          categorySlug: js.categorySlug
        };
}

var decoder$4 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              resourceImage: field.required("resourceImage", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              categorySlug: field.required("categorySlug", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$4(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$4));
}

var Sidebar = {
  fromJs: fromJs$4,
  decoder: decoder$4,
  fromJson: fromJson$4
};

export {
  DocumentId ,
  $$Document ,
  fromJs$1 as fromJs,
  decoder$1 as decoder,
  fromJson$1 as fromJson,
  Analytics ,
  Dashboard ,
  Sidebar ,
}
/* DocumentId Not a pure module */
