// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as User from "../../models/User.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as UserEditProfileChangePasswordData from "../../bundles/user-dashboard/edit-profile/UserEditProfileChangePasswordData.res.js";

var toJson = UserEditProfileChangePasswordData.Input.toJson;

var Req = {
  toJson: toJson
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return field.required("user", User.decoder);
    });

var Res = {
  decoder: decoder
};

function exec(body) {
  return Rest.$$fetch("/users/edit_profile", {
              NAME: "Patch",
              VAL: toJson(body)
            }, {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
