// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          firstName: js.firstName,
          lastName: js.lastName,
          company: js.company,
          email: js.email,
          phoneNumber: js.phoneNumber,
          newsletterSubscription: js.newsletterSubscription,
          agreedTermsAndConditions: js.agreedTermsAndConditions,
          confirmationNumber: js.confirmationNumber
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              firstName: field.required("firstName", Json_Decode$JsonCombinators.string),
              lastName: field.required("lastName", Json_Decode$JsonCombinators.string),
              company: field.required("company", Json_Decode$JsonCombinators.string),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              phoneNumber: field.required("phoneNumber", Json_Decode$JsonCombinators.string),
              newsletterSubscription: field.required("newsletterSubscription", Json_Decode$JsonCombinators.bool),
              agreedTermsAndConditions: field.required("agreedTermsAndConditions", Json_Decode$JsonCombinators.bool),
              confirmationNumber: field.required("confirmationNumber", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function fromJs$1(js) {
  return {
          firstName: js.firstName,
          lastName: js.lastName,
          company: js.company,
          email: js.email,
          phoneNumber: js.phoneNumber,
          newsletterSubscription: js.newsletterSubscription,
          agreedTermsAndConditions: js.agreedTermsAndConditions,
          dialCode: js.dialCode
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              firstName: field.required("firstName", Json_Decode$JsonCombinators.string),
              lastName: field.required("lastName", Json_Decode$JsonCombinators.string),
              company: field.required("company", Json_Decode$JsonCombinators.string),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              phoneNumber: field.required("phoneNumber", Json_Decode$JsonCombinators.string),
              newsletterSubscription: field.required("newsletterSubscription", Json_Decode$JsonCombinators.bool),
              agreedTermsAndConditions: field.required("agreedTermsAndConditions", Json_Decode$JsonCombinators.bool),
              dialCode: field.required("dialCode", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var Draft = {
  fromJs: fromJs$1,
  decoder: decoder$1,
  fromJson: fromJson$1
};

export {
  fromJs ,
  decoder ,
  fromJson ,
  Draft ,
}
/* decoder Not a pure module */
