// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as UserOrderData from "./UserOrderData.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          agreement: js.agreement,
          badge: js.badge,
          description: js.description,
          logo: js.logo,
          name: js.name,
          orders: Belt_Array.map(js.orders, UserOrderData.fromJs),
          totalLocations: js.totalLocations,
          totalProducts: js.totalProducts,
          url: js.url
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              agreement: field.required("agreement", Json_Decode$JsonCombinators.bool),
              badge: field.required("badge", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              logo: field.required("logo", Json_Decode$JsonCombinators.string),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              orders: field.required("orders", Json_Decode$JsonCombinators.array(UserOrderData.decoder)),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int),
              totalProducts: field.required("totalProducts", Json_Decode$JsonCombinators.$$int),
              url: field.required("url", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  fromJs ,
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
