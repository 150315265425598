// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromString(x) {
  switch (x) {
    case "cancelled" :
        return "Cancelled";
    case "finalized" :
        return "Finalized";
    case "in_progress" :
        return "InProgress";
    default:
      return PervasivesU.failwith("Unknown product status");
  }
}

function toString(x) {
  switch (x) {
    case "InProgress" :
        return "in_progress";
    case "Finalized" :
        return "finalized";
    case "Cancelled" :
        return "cancelled";
    
  }
}

var decoder = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.string, fromString);

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  fromString ,
  toString ,
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
