// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as Rest from "../Rest.res.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as OrderStatus from "../../models/OrderStatus.res.js";
import * as AdminOrderData from "../../models/AdminOrderData.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

var Req = {
  SortDirection: SortDirection,
  SortBy: SortBy
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              offset: field.required("offset", Json_Decode$JsonCombinators.$$int),
              orders: field.required("orders", Json_Decode$JsonCombinators.array(AdminOrderData.decoder)),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              totalReservations: field.required("totalReservations", Json_Decode$JsonCombinators.$$int)
            };
    });

var Res = {
  decoder: decoder
};

function exec(pageNum, name, sortBy, statuses, providers) {
  var page = "page=" + String(pageNum);
  var name$1 = Belt_Option.getWithDefault(Belt_Option.map(name, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var variant = sortBy.NAME;
  var sortBy$1 = variant === "Provider" ? "&sort_by=provider&sort_direction=" + toString(sortBy.VAL) : (
      variant === "ID" ? "&sort_by=id&sort_direction=" + toString(sortBy.VAL) : (
          variant === "User" ? "&sort_by=first_name&sort_direction=" + toString(sortBy.VAL) : (
              variant === "Status" ? "&sort_by=status&sort_direction=" + toString(sortBy.VAL) : (
                  variant === "Product" ? "&sort_by=product&sort_direction=" + toString(sortBy.VAL) : "&sort_by=company&sort_direction=" + toString(sortBy.VAL)
                )
            )
        )
    );
  var statuses$1 = Belt_List.reduce(statuses, "", (function (acc, status) {
          return acc + ("&status" + (encodeURIComponent("[]") + ("=" + OrderStatus.toString(status))));
        }));
  var providers$1 = Belt_List.reduce(providers, "", (function (acc, providerId) {
          return acc + ("&provider_ids" + (encodeURIComponent("[]") + ("=" + ID.toString(providerId))));
        }));
  return Rest.$$fetch("/dashboard/orders?" + (page + (name$1 + (sortBy$1 + (statuses$1 + providers$1)))), "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
