// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CityAndLocations from "../../models/CityAndLocations.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              cities: field.required("cities", Json_Decode$JsonCombinators.array(CityAndLocations.decoder))
            };
    });

var Res = {
  decoder: decoder
};

function exec(projectId) {
  var url = projectId !== undefined ? "/projects/" + (Caml_option.valFromOption(projectId) + "/locations_by_cities") : "/projects/locations_by_cities";
  return Rest.$$fetch(url, "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Res ,
  exec ,
}
/* decoder Not a pure module */
