// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as CityStateCountry from "./CityStateCountry.res.js";
import * as LocationForProject from "./LocationForProject.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          cityStateCountry: CityStateCountry.fromJs(js.cityStateCountry),
          totalLocations: js.totalLocations,
          totalProviders: js.totalProviders,
          locations: Belt_Array.map(js.locations, LocationForProject.fromJs)
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              cityStateCountry: field.required("cityStateCountry", CityStateCountry.decoder),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int),
              totalProviders: field.required("totalProviders", Json_Decode$JsonCombinators.$$int),
              locations: field.required("locations", Json_Decode$JsonCombinators.array(LocationForProject.decoder))
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  fromJs ,
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
