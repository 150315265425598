// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as User from "../../models/User.res.js";
import * as SignUpData from "../../bundles/client/sign-up/SignUpData.res.js";
import * as SentryLogger from "../../loggers/SentryLogger.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              invitationPending: field.optional("invitationPending", Json_Decode$JsonCombinators.bool),
              user: field.required("user", User.decoder)
            };
    });

var UserWithShowInvitation = {
  decoder: decoder
};

var toJson = SignUpData.Input.toJson;

var Req = {
  toJson: toJson
};

var decoder$1 = Json_Decode$JsonCombinators.map(decoder, (function (data) {
        return {
                TAG: "UserWithShowInvitation",
                _0: data
              };
      }));

var Res = {
  decoder: decoder$1
};

function exec(input, form) {
  SentryLogger.error({
        rootModule: "SignUpRequest",
        subModulePath: /* [] */0,
        value: "exec",
        fullPath: "SignUpRequest.exec"
      }, "here");
  return Rest.$$fetch("/users/sign_up", {
              NAME: "Post",
              VAL: toJson(input, form)
            }, {
              NAME: "Json",
              VAL: decoder$1
            }, undefined, undefined);
}

export {
  UserWithShowInvitation ,
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
