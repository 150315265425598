// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function fromCrossConnect(crossConnect) {
  return {
          quantity: crossConnect.quantity,
          interconnect: crossConnect.interconnect,
          provider: crossConnect.provider,
          specialNotesAndRequirements: crossConnect.specialNotesAndRequirements
        };
}

function toJson(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "quantity",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.quantity)
              ],
              [
                "interconnect",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.interconnect)
              ],
              [
                "provider",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.provider)
              ],
              [
                "specialNotesAndRequirements",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.specialNotesAndRequirements)
              ]
            ]);
}

var Input_empty = {
  quantity: undefined,
  interconnect: undefined,
  provider: undefined,
  specialNotesAndRequirements: undefined
};

var Input = {
  empty: Input_empty,
  fromCrossConnect: fromCrossConnect,
  toJson: toJson
};

var ValidationResult = {};

function quantity(param) {
  var quantity$1 = param.quantity;
  if (quantity$1 !== undefined && quantity$1 < 1) {
    return {
            TAG: "Error",
            _0: "Quantity should be positive"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function all(state) {
  return {
          quantity: quantity(state)
        };
}

function valid(result) {
  if (result.quantity.TAG === "Ok") {
    return true;
  } else {
    return false;
  }
}

var Validate = {
  quantity: quantity,
  all: all,
  valid: valid
};

export {
  Input ,
  ValidationResult ,
  Validate ,
}
/* No side effect */
