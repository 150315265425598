// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          interconnect: Caml_option.nullable_to_opt(js.interconnect),
          bandwidth: Caml_option.nullable_to_opt(js.bandwidth),
          cloudPlatform: Caml_option.nullable_to_opt(js.cloudPlatform),
          additionalOptions: js.additionalOptions,
          specialNotesAndRequirements: Caml_option.nullable_to_opt(js.specialNotesAndRequirements)
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              interconnect: field.required("interconnect", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              bandwidth: field.required("bandwidth", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              cloudPlatform: field.required("cloudPlatform", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              additionalOptions: field.required("additionalOptions", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              specialNotesAndRequirements: field.required("specialNotesAndRequirements", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var empty_additionalOptions = [];

var empty = {
  interconnect: undefined,
  bandwidth: undefined,
  cloudPlatform: undefined,
  additionalOptions: empty_additionalOptions,
  specialNotesAndRequirements: undefined
};

export {
  fromJs ,
  decoder ,
  fromJson ,
  empty ,
}
/* decoder Not a pure module */
