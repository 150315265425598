// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Http from "../Http.res.js";
import * as $$Promise from "../../bindings/Promise.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../loggers/SentryLogger.res.js";
import * as Globals from "Common/globals";

function unwrap(x) {
  return x.VAL;
}

var $$File = {
  $$File: undefined,
  unwrap: unwrap
};

function exec(file) {
  var formData = new FormData();
  formData.append("file", file.VAL);
  formData.append("api_key", Globals.CloudinaryAPIKey);
  formData.append("upload_preset", Globals.CloudinaryUploadPreset);
  formData.append("timestamp", Date.now() / 1000 | 0);
  return $$Promise.$$catch($$Promise.andThen(fetch(file.NAME === "Image" ? Globals.CloudinaryImageUploadUrl : Globals.CloudinaryVideoUploadUrl, {
                      method: Http.Method.toFetch({
                            NAME: "Post",
                            VAL: undefined
                          }),
                      body: Caml_option.some(formData),
                      headers: Caml_option.some(new Headers([[
                                  "X-Requested-With",
                                  "XMLHttpRequest"
                                ]]))
                    }), (function (response) {
                    if (response.ok) {
                      return $$Promise.map(response.json(), (function (json) {
                                    return {
                                            TAG: "Ok",
                                            _0: json
                                          };
                                  }));
                    }
                    var match = Http.ContentType.fromHeaders(response.headers);
                    if (match !== undefined && typeof match !== "object") {
                      switch (match) {
                        case "Text" :
                            return $$Promise.map(response.text(), (function (text) {
                                          SentryLogger.error4({
                                                rootModule: "UploadFilesToCloudinaryRequest",
                                                subModulePath: /* [] */0,
                                                value: "exec",
                                                fullPath: "UploadFilesToCloudinaryRequest.exec"
                                              }, "Fetch::CloudinaryUploadError", [
                                                "code",
                                                response.status
                                              ], [
                                                "status",
                                                response.statusText
                                              ], [
                                                "contentType",
                                                "text"
                                              ], [
                                                "responseBody",
                                                text
                                              ]);
                                          return {
                                                  TAG: "Error",
                                                  _0: undefined
                                                };
                                        }));
                        case "Json" :
                            return $$Promise.map(response.json(), (function (json) {
                                          SentryLogger.error4({
                                                rootModule: "UploadFilesToCloudinaryRequest",
                                                subModulePath: /* [] */0,
                                                value: "exec",
                                                fullPath: "UploadFilesToCloudinaryRequest.exec"
                                              }, "Fetch::CloudinaryUploadError", [
                                                "code",
                                                response.status
                                              ], [
                                                "status",
                                                response.statusText
                                              ], [
                                                "contentType",
                                                "json"
                                              ], [
                                                "responseBody",
                                                json
                                              ]);
                                          return {
                                                  TAG: "Error",
                                                  _0: undefined
                                                };
                                        }));
                        case "Blob" :
                            return $$Promise.map(response.blob(), (function (blob) {
                                          SentryLogger.error4({
                                                rootModule: "UploadFilesToCloudinaryRequest",
                                                subModulePath: /* [] */0,
                                                value: "exec",
                                                fullPath: "UploadFilesToCloudinaryRequest.exec"
                                              }, "Fetch::CloudinaryUploadError", [
                                                "code",
                                                response.status
                                              ], [
                                                "status",
                                                response.statusText
                                              ], [
                                                "contentType",
                                                "blob"
                                              ], [
                                                "responseBody",
                                                blob
                                              ]);
                                          return {
                                                  TAG: "Error",
                                                  _0: undefined
                                                };
                                        }));
                        
                      }
                    }
                    SentryLogger.error3({
                          rootModule: "UploadFilesToCloudinaryRequest",
                          subModulePath: /* [] */0,
                          value: "exec",
                          fullPath: "UploadFilesToCloudinaryRequest.exec"
                        }, "Fetch::CloudinaryUploadError", [
                          "code",
                          response.status
                        ], [
                          "status",
                          response.statusText
                        ], [
                          "contentType",
                          "N/A"
                        ]);
                    return $$Promise.resolve({
                                TAG: "Error",
                                _0: undefined
                              });
                  })), (function (error) {
                SentryLogger.error1({
                      rootModule: "UploadFilesToCloudinaryRequest",
                      subModulePath: /* [] */0,
                      value: "exec",
                      fullPath: "UploadFilesToCloudinaryRequest.exec"
                    }, "Fetch::CloudinaryUploadError", [
                      "error",
                      error
                    ]);
                return $$Promise.resolve({
                            TAG: "Error",
                            _0: undefined
                          });
              }));
}

var $$FormData$1;

export {
  $$File ,
  $$FormData$1 as $$FormData,
  exec ,
}
/* SentryLogger Not a pure module */
