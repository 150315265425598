// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as ContactUsData from "../../bundles/contact/ContactUsData.res.js";

var toJson = ContactUsData.Input.toJson;

var Req = {
  toJson: toJson
};

function exec(input) {
  return Rest.$$fetch("/leads/contact_message", {
              NAME: "Post",
              VAL: toJson(input)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* Rest Not a pure module */
