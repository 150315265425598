// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as Label from "../../../styleguide/forms/Label/Label.res.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Textarea from "../../../styleguide/forms/Textarea/Textarea.res.js";
import * as TextField from "../../../styleguide/forms/TextField/TextField.res.js";
import * as IconDelete from "../../../styleguide/icons/IconDelete.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ErrorMessage from "../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as ProductFeature from "../../../models/ProductFeature.res.js";
import * as FieldWithCounter from "../../../styleguide/forms/FieldWithCounter/FieldWithCounter.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";
import * as DashboardProductEditorScss from "./DashboardProductEditor.scss";

var css = DashboardProductEditorScss;

function fromFeature(feature) {
  return {
          id: Caml_option.some(feature.id),
          name: feature.name,
          description: feature.description
        };
}

function toJson(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "id",
                ID.toJsonOpt(input.id)
              ],
              [
                "name",
                input.name
              ],
              [
                "description",
                input.description
              ]
            ]);
}

var Input_empty = {
  id: undefined,
  name: "",
  description: ""
};

var Input = {
  empty: Input_empty,
  fromFeature: fromFeature,
  toJson: toJson
};

function name(param) {
  var name$1 = param.name;
  if (name$1 === "") {
    return {
            TAG: "Error",
            _0: "Name is required"
          };
  } else if (name$1.length > ProductFeature.nameMaxLength) {
    return {
            TAG: "Error",
            _0: "Name must be " + (String(ProductFeature.nameMaxLength) + " characters or less")
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function description(param) {
  var description$1 = param.description;
  if (description$1 === "") {
    return {
            TAG: "Error",
            _0: "Description is required"
          };
  } else if (description$1.length > ProductFeature.descriptionMaxLength) {
    return {
            TAG: "Error",
            _0: "Description must be " + (String(ProductFeature.descriptionMaxLength) + " characters or less")
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

var Validate = {
  name: name,
  description: description
};

var ValidationResult = {};

function DashboardProductFeatureEditor(props) {
  var remove = props.remove;
  var update = props.update;
  var results = props.results;
  var input = props.input;
  var i = props.i;
  var tmp;
  if (results !== undefined) {
    var message = results.name;
    tmp = message.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message._0
          });
  } else {
    tmp = null;
  }
  var tmp$1;
  if (results !== undefined) {
    var message$1 = results.description;
    tmp$1 = message$1.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$1._0
          });
  } else {
    tmp$1 = null;
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: String(i + 1 | 0) + ".",
                      className: css.featureCounter
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(FieldWithCounter.make, {
                                            value: input.name,
                                            limit: ProductFeature.nameMaxLength,
                                            label: JsxRuntime.jsx(Label.make, {
                                                  forId: "product-form--feature--" + (String(i) + "--name"),
                                                  status: Belt_Option.map(results, (function (x) {
                                                          return x.name;
                                                        })),
                                                  children: "Title"
                                                }),
                                            field: JsxRuntime.jsx(TextField.make, {
                                                  id: "product-form--feature--" + (String(i) + "--name"),
                                                  value: input.name,
                                                  status: Belt_Option.map(results, (function (x) {
                                                          if (x.name.TAG === "Ok") {
                                                            return "Valid";
                                                          } else {
                                                            return "Error";
                                                          }
                                                        })),
                                                  onChange: (function ($$event) {
                                                      update(i, {
                                                            id: input.id,
                                                            name: $$event.target.value,
                                                            description: input.description
                                                          });
                                                    })
                                                })
                                          }),
                                      tmp
                                    ],
                                    className: css.field
                                  }),
                              className: Cx.cx([
                                    css.fieldGroup_md_lg,
                                    css.fieldGroup1_md_lg
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(FieldWithCounter.make, {
                                            value: input.description,
                                            limit: ProductFeature.descriptionMaxLength,
                                            label: JsxRuntime.jsx(Label.make, {
                                                  forId: "product-form--feature--" + (String(i) + "--description"),
                                                  status: Belt_Option.map(results, (function (x) {
                                                          return x.description;
                                                        })),
                                                  children: "Description"
                                                }),
                                            field: JsxRuntime.jsx(Textarea.make, {
                                                  id: "product-form--feature--" + (String(i) + "--description"),
                                                  value: input.description,
                                                  rows: 3,
                                                  status: Belt_Option.map(results, (function (x) {
                                                          if (x.description.TAG === "Ok") {
                                                            return "Valid";
                                                          } else {
                                                            return "Error";
                                                          }
                                                        })),
                                                  onChange: (function ($$event) {
                                                      update(i, {
                                                            id: input.id,
                                                            name: input.name,
                                                            description: $$event.target.value
                                                          });
                                                    })
                                                })
                                          }),
                                      tmp$1
                                    ],
                                    className: css.field
                                  }),
                              className: Cx.cx([
                                    css.fieldGroup_md_lg,
                                    css.fieldGroup1_md_lg
                                  ])
                            })
                      ],
                      className: css.featureFields
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Control.make, {
                            className: css.featureDeleteIconControl,
                            onClick: (function (param) {
                                remove(i);
                              }),
                            children: JsxRuntime.jsx(IconDelete.make, {
                                  size: "LG",
                                  color: "LightGray"
                                })
                          }),
                      className: css.featureDeleteIcon
                    })
              ],
              className: css.featureWrapper
            });
}

var make = DashboardProductFeatureEditor;

export {
  css ,
  Input ,
  Validate ,
  ValidationResult ,
  make ,
}
/* css Not a pure module */
