// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as SortingPopover from "../../bundles/product-index/sorting-popover/SortingPopover.res.js";
import * as ColocationProduct from "../../models/ColocationProduct.res.js";
import * as MarketplaceSeoText from "../../models/MarketplaceSeoText.res.js";
import * as MarketplaceFilterData from "../../bundles/product-index/filters/MarketplaceFilterData.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              products: field.required("products", Json_Decode$JsonCombinators.array(ColocationProduct.decoder)),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              totalProducts: field.required("totalProducts", Json_Decode$JsonCombinators.$$int),
              seoTexts: field.required("seoTexts", Json_Decode$JsonCombinators.array(MarketplaceSeoText.decoder))
            };
    });

var Res = {
  decoder: decoder
};

function exec(pageNum, sortBy, filters, param) {
  var url = "/marketplace?page=" + (String(pageNum) + ("&" + (SortingPopover.SortBy.toQueryString(sortBy) + ("&" + MarketplaceFilterData.Input.toQueryString(filters)))));
  return Rest.$$fetch(url, "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Res ,
  exec ,
}
/* decoder Not a pure module */
