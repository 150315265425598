// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Csrf from "../Csrf.res.js";
import * as $$Date from "../../libs/Date.res.js";
import * as Http from "../Http.res.js";
import * as Project from "../../models/Project.res.js";
import * as $$Promise from "../../bindings/Promise.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../loggers/SentryLogger.res.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";

function exec(projectId, data) {
  var formData = new FormData();
  Belt_Array.forEach(data.configurations, (function (configuration) {
          formData.append("[network_sd_wan_configurations][]start_date", Belt_Option.map(configuration.startDate, $$Date.Naive.toJson));
          formData.append("[network_sd_wan_configurations][]contract_length", Belt_Option.mapWithDefault(configuration.contractLength, "", (function (cl) {
                      return String(cl.value);
                    })));
          formData.append("[network_sd_wan_configurations][]street_address", configuration.streetAddress);
          formData.append("[network_sd_wan_configurations][]building_or_floor", configuration.buildingOrFloor);
          formData.append("[network_sd_wan_configurations][]suite", configuration.suite);
          formData.append("[network_sd_wan_configurations][]city_or_town", configuration.cityOrTown);
          formData.append("[network_sd_wan_configurations][]sub_locality", configuration.subLocality);
          formData.append("[network_sd_wan_configurations][]state_or_province", configuration.stateOrProvince);
          formData.append("[network_sd_wan_configurations][]country", configuration.country);
          formData.append("[network_sd_wan_configurations][]postal_code", configuration.postalCode);
          formData.append("[network_sd_wan_configurations][]prefix", configuration.prefix);
          formData.append("[network_sd_wan_configurations][]additional_location_details", configuration.additionalLocationDetails);
          formData.append("[network_sd_wan_configurations][]primary_network_type", configuration.primaryNetworkType);
          formData.append("[network_sd_wan_configurations][]primary_bandwidth_value", configuration.primaryBandwidthValue);
          formData.append("[network_sd_wan_configurations][]primary_bandwidth_unit", configuration.primaryBandwidthUnit);
          formData.append("[network_sd_wan_configurations][]primary_class_of_service", configuration.primaryClassOfService);
          if (Belt_SetString.isEmpty(configuration.primaryServices)) {
            formData.append("[network_sd_wan_configurations][]primary_services", "");
          }
          Belt_SetString.forEach(configuration.primaryServices, (function (service) {
                  formData.append("[network_sd_wan_configurations][][primary_services][]", service);
                }));
          formData.append("[network_sd_wan_configurations][]diverse_network_option", configuration.diverseNetworkOption);
          formData.append("[network_sd_wan_configurations][]diverse_bandwidth_value", configuration.diverseBandwidthValue);
          formData.append("[network_sd_wan_configurations][]diverse_bandwidth_unit", configuration.diverseBandwidthUnit);
          formData.append("[network_sd_wan_configurations][]diverse_class_of_service", configuration.diverseClassOfService);
          if (Belt_SetString.isEmpty(configuration.diverseServices)) {
            formData.append("[network_sd_wan_configurations][]diverse_services", "");
          }
          Belt_SetString.forEach(configuration.diverseServices, (function (service) {
                  formData.append("[network_sd_wan_configurations][][diverse_services][]", service);
                }));
          if (Belt_SetString.isEmpty(configuration.sdWanFeatures)) {
            formData.append("[network_sd_wan_configurations][]sd_wan_features", "");
          }
          Belt_SetString.forEach(configuration.sdWanFeatures, (function (service) {
                  formData.append("[network_sd_wan_configurations][][sd_wan_features][]", service);
                }));
          formData.append("[network_sd_wan_configurations][]additional_requirements", configuration.additionalRequirements);
        }));
  Belt_Array.forEach(data.documents, (function ($$document) {
          formData.append("[documents][]", $$document);
        }));
  Belt_Array.forEach(data.deletedDocumentIds, (function (id) {
          formData.append("[deleted_document_ids][]", Project.DocumentId.toString(id));
        }));
  formData.append("[geographical_region]", data.geographicalRegion);
  return $$Promise.andThen(fetch("/api/v1/projects/" + (projectId + "/save_network_sd_wan_configurations"), {
                  method: Http.Method.toFetch({
                        NAME: "Put",
                        VAL: undefined
                      }),
                  body: Caml_option.some(formData),
                  headers: Caml_option.some(new Headers([[
                              "X-CSRF-Token",
                              Belt_Option.getExn(Csrf.token())
                            ]])),
                  credentials: "same-origin"
                }), (function (response) {
                if (response.ok) {
                  return $$Promise.resolve({
                              TAG: "Ok",
                              _0: undefined
                            });
                }
                var match = Http.ContentType.fromHeaders(response.headers);
                if (match !== undefined && typeof match !== "object") {
                  switch (match) {
                    case "Text" :
                        return $$Promise.map(response.text(), (function (text) {
                                      SentryLogger.error4({
                                            rootModule: "UpdateNetworkSdWanDraftProjectAndContinueLaterRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "UpdateNetworkSdWanDraftProjectAndContinueLaterRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "text"
                                          ], [
                                            "responseBody",
                                            text
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: "Opaque"
                                            };
                                    }));
                    case "Json" :
                        return $$Promise.map(response.json(), (function (json) {
                                      SentryLogger.error4({
                                            rootModule: "UpdateNetworkSdWanDraftProjectAndContinueLaterRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "UpdateNetworkSdWanDraftProjectAndContinueLaterRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "json"
                                          ], [
                                            "responseBody",
                                            json
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: "Opaque"
                                            };
                                    }));
                    case "Blob" :
                        return $$Promise.map(response.blob(), (function (blob) {
                                      SentryLogger.error4({
                                            rootModule: "UpdateNetworkSdWanDraftProjectAndContinueLaterRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "UpdateNetworkSdWanDraftProjectAndContinueLaterRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "blob"
                                          ], [
                                            "responseBody",
                                            blob
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: "Opaque"
                                            };
                                    }));
                    
                  }
                }
                SentryLogger.error3({
                      rootModule: "UpdateNetworkSdWanDraftProjectAndContinueLaterRequest",
                      subModulePath: /* [] */0,
                      value: "exec",
                      fullPath: "UpdateNetworkSdWanDraftProjectAndContinueLaterRequest.exec"
                    }, "Fetch::RequestError", [
                      "code",
                      response.status
                    ], [
                      "status",
                      response.statusText
                    ], [
                      "contentType",
                      "N/A"
                    ]);
                return $$Promise.resolve({
                            TAG: "Error",
                            _0: "Opaque"
                          });
              }));
}

export {
  exec ,
}
/* Csrf Not a pure module */
