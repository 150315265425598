// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as LocationForProject from "./LocationForProject.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          name: js.name,
          providerImage: js.providerImage,
          locations: Belt_Array.map(js.locations, (function ($$location) {
                  return LocationForProject.fromJs($$location);
                }))
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              providerImage: field.required("providerImage", Json_Decode$JsonCombinators.string),
              locations: field.required("locations", Json_Decode$JsonCombinators.array(LocationForProject.decoder))
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  fromJs ,
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
