// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function empty(user) {
  return {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phoneNumber,
          description: user.description,
          jobTitle: user.jobTitle,
          profilePic: user.profilePic,
          dialCountryCode: user.dialCodeCountry,
          country: user.dialCode,
          password: "",
          providerNameRequested: user.providerNameRequested,
          agency: user.agency
        };
}

function getProfileImage(image, cloudName) {
  return "https://res.cloudinary.com/" + cloudName + "/image/upload/c_scale,w_500/c_crop,g_face,h_400,r_max,w_400/c_scale,w_205/" + image;
}

function toJson(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "first_name",
                input.firstName
              ],
              [
                "last_name",
                input.lastName
              ],
              [
                "email",
                input.email
              ],
              [
                "phone",
                Js_string.replace(input.dialCountryCode, "", input.phone).trim()
              ],
              [
                "description",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.description)
              ],
              [
                "job_title",
                input.jobTitle
              ],
              [
                "current_password",
                input.password
              ],
              [
                "profile_pic",
                input.profilePic
              ],
              [
                "dial_code",
                input.dialCountryCode
              ],
              [
                "dial_code_country",
                input.country
              ],
              [
                "provider_name_requested",
                input.providerNameRequested
              ],
              [
                "agency",
                input.agency
              ]
            ]);
}

var Input = {
  empty: empty,
  getProfileImage: getProfileImage,
  toJson: toJson
};

function toJson$1(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "url",
                input.url
              ],
              [
                "public_id",
                input.public_id
              ]
            ]);
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              url: field.required("url", Json_Decode$JsonCombinators.string),
              public_id: field.required("public_id", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var ImageResponse = {
  toJson: toJson$1,
  decoder: decoder,
  fromJson: fromJson
};

var ValidationResult = {};

function firstName(param) {
  if (param.firstName === "") {
    return {
            TAG: "Error",
            _0: "First Name is required"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function lastName(param) {
  if (param.lastName === "") {
    return {
            TAG: "Error",
            _0: "Last Name is required"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function email(param) {
  if (param.email === "") {
    return {
            TAG: "Error",
            _0: "Email is required"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function phone(param) {
  if (param.phone === "+") {
    return {
            TAG: "Error",
            _0: "Phone is required"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function password(param) {
  if (param.password === "") {
    return {
            TAG: "Error",
            _0: "Password is required"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function all(state) {
  return {
          firstName: firstName(state),
          lastName: lastName(state),
          email: email(state),
          phone: phone(state),
          password: password(state)
        };
}

function valid(result) {
  if (result.firstName.TAG === "Ok" && result.lastName.TAG === "Ok" && result.email.TAG === "Ok" && result.phone.TAG === "Ok" && result.password.TAG === "Ok") {
    return true;
  } else {
    return false;
  }
}

var Validate = {
  firstName: firstName,
  lastName: lastName,
  email: email,
  phone: phone,
  password: password,
  all: all,
  valid: valid
};

export {
  Input ,
  ImageResponse ,
  ValidationResult ,
  Validate ,
}
/* decoder Not a pure module */
