// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as Rest from "../Rest.res.js";
import * as ColocationProduct from "../../models/ColocationProduct.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(days) {
  return Json_Encode$JsonCombinators.object([[
                "days",
                days
              ]]);
}

var Req = {
  toJson: toJson
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return field.required("product", ColocationProduct.Draft.decoder);
    });

var Res = {
  decoder: decoder
};

function exec(id, days) {
  return Rest.$$fetch("/products/" + (ID.toString(id) + "/extend_end_date"), {
              NAME: "Patch",
              VAL: toJson(days)
            }, {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
