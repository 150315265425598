// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

var empty_additionalOptions = [];

var empty = {
  quantity: undefined,
  colocationSpace: undefined,
  powerCircuit: undefined,
  powerValue: undefined,
  powerUnits: undefined,
  additionalOptions: empty_additionalOptions
};

function fromRackTypesAndPowerOptions(rackTypesAndPowerOptions) {
  return {
          quantity: rackTypesAndPowerOptions.quantity,
          colocationSpace: rackTypesAndPowerOptions.colocationSpace,
          powerCircuit: rackTypesAndPowerOptions.powerCircuit,
          powerValue: rackTypesAndPowerOptions.powerValue,
          powerUnits: rackTypesAndPowerOptions.powerUnits,
          additionalOptions: rackTypesAndPowerOptions.additionalOptions
        };
}

function toJson(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "quantity",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.quantity)
              ],
              [
                "colocationSpace",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.colocationSpace)
              ],
              [
                "powerCircuit",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.powerCircuit)
              ],
              [
                "powerValue",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.powerValue)
              ],
              [
                "powerUnits",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.powerUnits)
              ],
              [
                "additionalOptions",
                Json_Encode$JsonCombinators.array(function (prim) {
                        return prim;
                      })(input.additionalOptions)
              ]
            ]);
}

function title(rack) {
  var quantity = rack.quantity;
  var colocationSpace = rack.colocationSpace;
  var rackQuantity = (
    quantity !== undefined ? String(quantity) : ""
  ) + (
    colocationSpace !== undefined ? " x " + colocationSpace : ""
  );
  var match = rack.powerValue;
  var match$1 = rack.powerUnits;
  var powerDetail = match !== undefined && match$1 !== undefined ? String(match) + match$1 : undefined;
  var powerCircuit = rack.powerCircuit;
  return rackQuantity + ((
            powerCircuit !== undefined ? ", " + powerCircuit : ""
          ) + (
            powerDetail !== undefined ? ", " + powerDetail : ""
          ));
}

var Input = {
  empty: empty,
  fromRackTypesAndPowerOptions: fromRackTypesAndPowerOptions,
  toJson: toJson,
  title: title
};

var ValidationResult = {};

function quantity(param) {
  var quantity$1 = param.quantity;
  if (quantity$1 !== undefined) {
    if (quantity$1 < 1) {
      return {
              TAG: "Error",
              _0: "Quantity should be positive"
            };
    } else {
      return {
              TAG: "Ok",
              _0: undefined
            };
    }
  } else {
    return {
            TAG: "Error",
            _0: "Quantity is required"
          };
  }
}

function colocationSpace(param) {
  if (param.colocationSpace !== undefined) {
    return {
            TAG: "Ok",
            _0: undefined
          };
  } else {
    return {
            TAG: "Error",
            _0: "Colocation space is required"
          };
  }
}

function powerValue(param) {
  var powerUnits = param.powerUnits;
  var powerValue$1 = param.powerValue;
  if (powerValue$1 !== undefined) {
    if (powerValue$1 <= 0) {
      return {
              TAG: "Error",
              _0: "Power value should be positive"
            };
    } else {
      return {
              TAG: "Ok",
              _0: undefined
            };
    }
  } else if (powerUnits !== undefined && powerUnits !== "") {
    return {
            TAG: "Error",
            _0: "Power value is required"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function powerUnits(param) {
  var powerUnits$1 = param.powerUnits;
  if (param.powerValue !== undefined && !(powerUnits$1 !== undefined && powerUnits$1 !== "")) {
    return {
            TAG: "Error",
            _0: "Power units are required"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function all(state) {
  return {
          quantity: quantity(state),
          colocationSpace: colocationSpace(state),
          powerValue: powerValue(state),
          powerUnits: powerUnits(state)
        };
}

function valid(result) {
  if (result.quantity.TAG === "Ok" && result.colocationSpace.TAG === "Ok" && result.powerValue.TAG === "Ok" && result.powerUnits.TAG === "Ok") {
    return true;
  } else {
    return false;
  }
}

var Validate = {
  quantity: quantity,
  colocationSpace: colocationSpace,
  powerValue: powerValue,
  powerUnits: powerUnits,
  all: all,
  valid: valid
};

export {
  Input ,
  ValidationResult ,
  Validate ,
}
/* No side effect */
