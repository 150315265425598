// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../../../../libs/Date.res.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as NonSecure from "nanoid/non-secure";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function errorIfNotPresent(field, message) {
  if (field !== undefined) {
    return {
            TAG: "Ok",
            _0: Caml_option.valFromOption(field)
          };
  } else {
    return {
            TAG: "Error",
            _0: message
          };
  }
}

var validators = {
  configurations: {
    collection: (function (input) {
        var match = input.configurations;
        if (match.length !== 0) {
          return {
                  TAG: "Ok",
                  _0: undefined
                };
        } else {
          return {
                  TAG: "Error",
                  _0: "There must be at least one configuration"
                };
        }
      }),
    fields: {
      key: undefined,
      startDate: {
        strategy: "OnFirstSuccessOrFirstBlur",
        validate: (function (input, at) {
            return errorIfNotPresent(input.configurations[at].startDate, "Start Date must be specified");
          })
      },
      contractLength: {
        strategy: "OnFirstSuccessOrFirstBlur",
        validate: (function (param, at) {
            var cl = param.configurations[at].contractLength;
            if (cl !== undefined) {
              return {
                      TAG: "Ok",
                      _0: cl.value
                    };
            } else {
              return {
                      TAG: "Error",
                      _0: "Contract length is required"
                    };
            }
          })
      },
      rawStorageCapacity: {
        strategy: "OnFirstSuccessOrFirstBlur",
        validate: (function (param, at) {
            var x = param.configurations[at].rawStorageCapacity;
            if (x === "") {
              return {
                      TAG: "Error",
                      _0: "Raw Storage Capacity must be specified"
                    };
            } else {
              return {
                      TAG: "Ok",
                      _0: x
                    };
            }
          })
      },
      diskType: undefined,
      connectivityMethod: undefined,
      getRequests: {
        strategy: "OnFirstBlur",
        validate: (function (input, at) {
            var configuration = input.configurations[at];
            var match = configuration.getRequests;
            var match$1 = configuration.getRequestsUnit;
            if (match !== undefined) {
              if (match < 0) {
                return {
                        TAG: "Error",
                        _0: "Get requests must be zero or greater"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: match
                      };
              }
            } else if (match$1 === "") {
              return {
                      TAG: "Ok",
                      _0: undefined
                    };
            } else {
              return {
                      TAG: "Error",
                      _0: "You provided get requests unit but forgot to specify its value"
                    };
            }
          })
      },
      getRequestsUnit: {
        strategy: "OnFirstBlur",
        validate: (function (input, at) {
            var configuration = input.configurations[at];
            var match = configuration.getRequests;
            var match$1 = configuration.getRequestsUnit;
            if (match !== undefined) {
              if (match$1 === "") {
                return {
                        TAG: "Error",
                        _0: "You provided get requests value but forgot to specify its unit"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: match$1
                      };
              }
            } else if (match$1 === "") {
              return {
                      TAG: "Ok",
                      _0: undefined
                    };
            } else {
              return {
                      TAG: "Ok",
                      _0: match$1
                    };
            }
          })
      },
      putRequests: {
        strategy: "OnFirstBlur",
        validate: (function (input, at) {
            var configuration = input.configurations[at];
            var match = configuration.putRequests;
            var match$1 = configuration.putRequestsUnit;
            if (match !== undefined) {
              if (match < 0) {
                return {
                        TAG: "Error",
                        _0: "Put requests must be zero or greater"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: match
                      };
              }
            } else if (match$1 === "") {
              return {
                      TAG: "Ok",
                      _0: undefined
                    };
            } else {
              return {
                      TAG: "Error",
                      _0: "You provided put requests unit but forgot to specify its value"
                    };
            }
          })
      },
      putRequestsUnit: {
        strategy: "OnFirstBlur",
        validate: (function (input, at) {
            var configuration = input.configurations[at];
            var match = configuration.putRequests;
            var match$1 = configuration.putRequestsUnit;
            if (match !== undefined) {
              if (match$1 === "") {
                return {
                        TAG: "Error",
                        _0: "You provided put requests value but forgot to specify its unit"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: match$1
                      };
              }
            } else if (match$1 === "") {
              return {
                      TAG: "Ok",
                      _0: undefined
                    };
            } else {
              return {
                      TAG: "Ok",
                      _0: match$1
                    };
            }
          })
      },
      transferOut: {
        strategy: "OnFirstBlur",
        validate: (function (input, at) {
            var configuration = input.configurations[at];
            var match = configuration.transferOut;
            var match$1 = configuration.transferOutUnit;
            if (match !== undefined) {
              if (match < 0) {
                return {
                        TAG: "Error",
                        _0: "Transfer out must be zero or greater"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: match
                      };
              }
            } else if (match$1 === "") {
              return {
                      TAG: "Ok",
                      _0: undefined
                    };
            } else {
              return {
                      TAG: "Error",
                      _0: "You provided transfer out unit but forgot to specify its value"
                    };
            }
          })
      },
      transferOutUnit: {
        strategy: "OnFirstBlur",
        validate: (function (input, at) {
            var configuration = input.configurations[at];
            var match = configuration.transferOut;
            var match$1 = configuration.transferOutUnit;
            if (match !== undefined) {
              if (match$1 === "") {
                return {
                        TAG: "Error",
                        _0: "You provided transfer out value but forgot to specify its unit"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: match$1
                      };
              }
            } else if (match$1 === "") {
              return {
                      TAG: "Ok",
                      _0: undefined
                    };
            } else {
              return {
                      TAG: "Ok",
                      _0: match$1
                    };
            }
          })
      },
      region: undefined,
      cloudType: {
        strategy: "OnFirstSuccessOrFirstBlur",
        validate: (function (input, at) {
            return errorIfNotPresent(input.configurations[at].cloudType, "Type must be specified");
          })
      },
      description: undefined,
      features: {
        strategy: "OnFirstChange",
        validate: (function (param, at) {
            return {
                    TAG: "Ok",
                    _0: Belt_SetString.toArray(param.configurations[at].features)
                  };
          })
      },
      accessTypes: {
        strategy: "OnFirstChange",
        validate: (function (param, at) {
            return {
                    TAG: "Ok",
                    _0: Belt_SetString.toArray(param.configurations[at].accessTypes)
                  };
          })
      },
      certifications: {
        strategy: "OnFirstChange",
        validate: (function (param, at) {
            return {
                    TAG: "Ok",
                    _0: Belt_SetString.toArray(param.configurations[at].certifications)
                  };
          })
      },
      additionalRequirements: undefined
    }
  }
};

function initialFieldsStatuses(input) {
  return {
          configurations: Belt_Array.make(input.configurations.length, {
                key: "Pristine",
                startDate: "Pristine",
                contractLength: "Pristine",
                rawStorageCapacity: "Pristine",
                diskType: "Pristine",
                connectivityMethod: "Pristine",
                getRequests: "Pristine",
                getRequestsUnit: "Pristine",
                putRequests: "Pristine",
                putRequestsUnit: "Pristine",
                transferOut: "Pristine",
                transferOutUnit: "Pristine",
                region: "Pristine",
                cloudType: "Pristine",
                description: "Pristine",
                features: "Pristine",
                accessTypes: "Pristine",
                certifications: "Pristine",
                additionalRequirements: "Pristine"
              })
        };
}

var initialCollectionsStatuses = {
  configurations: undefined
};

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: initialCollectionsStatuses,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = validators.configurations.collection(input);
  var match$1 = Belt_Array.reduceWithIndex(fieldsStatuses.configurations, [
        {
          TAG: "Ok",
          _0: []
        },
        []
      ], (function (param, fieldStatus, index) {
          var statuses = param[1];
          var output = param[0];
          var match_0 = {
            TAG: "Ok",
            _0: input.configurations[index].key
          };
          var match = fieldStatus.startDate;
          var tmp;
          tmp = typeof match !== "object" ? validators.configurations.fields.startDate.validate(input, index) : match._0;
          var match$1 = fieldStatus.contractLength;
          var tmp$1;
          tmp$1 = typeof match$1 !== "object" ? validators.configurations.fields.contractLength.validate(input, index) : match$1._0;
          var match$2 = fieldStatus.rawStorageCapacity;
          var tmp$2;
          tmp$2 = typeof match$2 !== "object" ? validators.configurations.fields.rawStorageCapacity.validate(input, index) : match$2._0;
          var match_0$1 = {
            TAG: "Ok",
            _0: input.configurations[index].diskType
          };
          var match_0$2 = {
            TAG: "Ok",
            _0: input.configurations[index].connectivityMethod
          };
          var match$3 = fieldStatus.getRequests;
          var tmp$3;
          tmp$3 = typeof match$3 !== "object" ? validators.configurations.fields.getRequests.validate(input, index) : match$3._0;
          var match$4 = fieldStatus.getRequestsUnit;
          var tmp$4;
          tmp$4 = typeof match$4 !== "object" ? validators.configurations.fields.getRequestsUnit.validate(input, index) : match$4._0;
          var match$5 = fieldStatus.putRequests;
          var tmp$5;
          tmp$5 = typeof match$5 !== "object" ? validators.configurations.fields.putRequests.validate(input, index) : match$5._0;
          var match$6 = fieldStatus.putRequestsUnit;
          var tmp$6;
          tmp$6 = typeof match$6 !== "object" ? validators.configurations.fields.putRequestsUnit.validate(input, index) : match$6._0;
          var match$7 = fieldStatus.transferOut;
          var tmp$7;
          tmp$7 = typeof match$7 !== "object" ? validators.configurations.fields.transferOut.validate(input, index) : match$7._0;
          var match$8 = fieldStatus.transferOutUnit;
          var tmp$8;
          tmp$8 = typeof match$8 !== "object" ? validators.configurations.fields.transferOutUnit.validate(input, index) : match$8._0;
          var match_0$3 = {
            TAG: "Ok",
            _0: input.configurations[index].region
          };
          var match$9 = fieldStatus.cloudType;
          var tmp$9;
          tmp$9 = typeof match$9 !== "object" ? validators.configurations.fields.cloudType.validate(input, index) : match$9._0;
          var match_0$4 = {
            TAG: "Ok",
            _0: input.configurations[index].description
          };
          var match$10 = fieldStatus.features;
          var tmp$10;
          tmp$10 = typeof match$10 !== "object" ? validators.configurations.fields.features.validate(input, index) : match$10._0;
          var match$11 = fieldStatus.accessTypes;
          var tmp$11;
          tmp$11 = typeof match$11 !== "object" ? validators.configurations.fields.accessTypes.validate(input, index) : match$11._0;
          var match$12 = fieldStatus.certifications;
          var tmp$12;
          tmp$12 = typeof match$12 !== "object" ? validators.configurations.fields.certifications.validate(input, index) : match$12._0;
          var match_0$5 = {
            TAG: "Ok",
            _0: input.configurations[index].additionalRequirements
          };
          if (output.TAG === "Ok") {
            var keyResult = match_0;
            var output$1 = output._0;
            if (keyResult.TAG === "Ok") {
              var startDateResult = tmp;
              if (startDateResult.TAG === "Ok") {
                var contractLengthResult = tmp$1;
                if (contractLengthResult.TAG === "Ok") {
                  var rawStorageCapacityResult = tmp$2;
                  if (rawStorageCapacityResult.TAG === "Ok") {
                    var diskTypeResult = match_0$1;
                    if (diskTypeResult.TAG === "Ok") {
                      var connectivityMethodResult = match_0$2;
                      if (connectivityMethodResult.TAG === "Ok") {
                        var getRequestsResult = tmp$3;
                        if (getRequestsResult.TAG === "Ok") {
                          var getRequestsUnitResult = tmp$4;
                          if (getRequestsUnitResult.TAG === "Ok") {
                            var putRequestsResult = tmp$5;
                            if (putRequestsResult.TAG === "Ok") {
                              var putRequestsUnitResult = tmp$6;
                              if (putRequestsUnitResult.TAG === "Ok") {
                                var transferOutResult = tmp$7;
                                if (transferOutResult.TAG === "Ok") {
                                  var transferOutUnitResult = tmp$8;
                                  if (transferOutUnitResult.TAG === "Ok") {
                                    var regionResult = match_0$3;
                                    if (regionResult.TAG === "Ok") {
                                      var cloudTypeResult = tmp$9;
                                      if (cloudTypeResult.TAG === "Ok") {
                                        var descriptionResult = match_0$4;
                                        if (descriptionResult.TAG === "Ok") {
                                          var featuresResult = tmp$10;
                                          if (featuresResult.TAG === "Ok") {
                                            var accessTypesResult = tmp$11;
                                            if (accessTypesResult.TAG === "Ok") {
                                              var certificationsResult = tmp$12;
                                              if (certificationsResult.TAG === "Ok") {
                                                var additionalRequirementsResult = match_0$5;
                                                if (additionalRequirementsResult.TAG === "Ok") {
                                                  output$1.push({
                                                        key: keyResult._0,
                                                        startDate: startDateResult._0,
                                                        contractLength: contractLengthResult._0,
                                                        rawStorageCapacity: rawStorageCapacityResult._0,
                                                        diskType: diskTypeResult._0,
                                                        connectivityMethod: connectivityMethodResult._0,
                                                        getRequests: getRequestsResult._0,
                                                        getRequestsUnit: getRequestsUnitResult._0,
                                                        putRequests: putRequestsResult._0,
                                                        putRequestsUnit: putRequestsUnitResult._0,
                                                        transferOut: transferOutResult._0,
                                                        transferOutUnit: transferOutUnitResult._0,
                                                        region: regionResult._0,
                                                        cloudType: cloudTypeResult._0,
                                                        description: descriptionResult._0,
                                                        features: featuresResult._0,
                                                        accessTypes: accessTypesResult._0,
                                                        certifications: certificationsResult._0,
                                                        additionalRequirements: additionalRequirementsResult._0
                                                      });
                                                  statuses.push({
                                                        key: {
                                                          TAG: "Dirty",
                                                          _0: keyResult,
                                                          _1: "Hidden"
                                                        },
                                                        startDate: {
                                                          TAG: "Dirty",
                                                          _0: startDateResult,
                                                          _1: "Shown"
                                                        },
                                                        contractLength: {
                                                          TAG: "Dirty",
                                                          _0: contractLengthResult,
                                                          _1: "Shown"
                                                        },
                                                        rawStorageCapacity: {
                                                          TAG: "Dirty",
                                                          _0: rawStorageCapacityResult,
                                                          _1: "Shown"
                                                        },
                                                        diskType: {
                                                          TAG: "Dirty",
                                                          _0: diskTypeResult,
                                                          _1: "Hidden"
                                                        },
                                                        connectivityMethod: {
                                                          TAG: "Dirty",
                                                          _0: connectivityMethodResult,
                                                          _1: "Hidden"
                                                        },
                                                        getRequests: {
                                                          TAG: "Dirty",
                                                          _0: getRequestsResult,
                                                          _1: "Shown"
                                                        },
                                                        getRequestsUnit: {
                                                          TAG: "Dirty",
                                                          _0: getRequestsUnitResult,
                                                          _1: "Shown"
                                                        },
                                                        putRequests: {
                                                          TAG: "Dirty",
                                                          _0: putRequestsResult,
                                                          _1: "Shown"
                                                        },
                                                        putRequestsUnit: {
                                                          TAG: "Dirty",
                                                          _0: putRequestsUnitResult,
                                                          _1: "Shown"
                                                        },
                                                        transferOut: {
                                                          TAG: "Dirty",
                                                          _0: transferOutResult,
                                                          _1: "Shown"
                                                        },
                                                        transferOutUnit: {
                                                          TAG: "Dirty",
                                                          _0: transferOutUnitResult,
                                                          _1: "Shown"
                                                        },
                                                        region: {
                                                          TAG: "Dirty",
                                                          _0: regionResult,
                                                          _1: "Hidden"
                                                        },
                                                        cloudType: {
                                                          TAG: "Dirty",
                                                          _0: cloudTypeResult,
                                                          _1: "Shown"
                                                        },
                                                        description: {
                                                          TAG: "Dirty",
                                                          _0: descriptionResult,
                                                          _1: "Hidden"
                                                        },
                                                        features: {
                                                          TAG: "Dirty",
                                                          _0: featuresResult,
                                                          _1: "Shown"
                                                        },
                                                        accessTypes: {
                                                          TAG: "Dirty",
                                                          _0: accessTypesResult,
                                                          _1: "Shown"
                                                        },
                                                        certifications: {
                                                          TAG: "Dirty",
                                                          _0: certificationsResult,
                                                          _1: "Shown"
                                                        },
                                                        additionalRequirements: {
                                                          TAG: "Dirty",
                                                          _0: additionalRequirementsResult,
                                                          _1: "Hidden"
                                                        }
                                                      });
                                                  return [
                                                          {
                                                            TAG: "Ok",
                                                            _0: output$1
                                                          },
                                                          statuses
                                                        ];
                                                }
                                                
                                              }
                                              
                                            }
                                            
                                          }
                                          
                                        }
                                        
                                      }
                                      
                                    }
                                    
                                  }
                                  
                                }
                                
                              }
                              
                            }
                            
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }
            
          }
          statuses.push({
                key: {
                  TAG: "Dirty",
                  _0: match_0,
                  _1: "Hidden"
                },
                startDate: {
                  TAG: "Dirty",
                  _0: tmp,
                  _1: "Shown"
                },
                contractLength: {
                  TAG: "Dirty",
                  _0: tmp$1,
                  _1: "Shown"
                },
                rawStorageCapacity: {
                  TAG: "Dirty",
                  _0: tmp$2,
                  _1: "Shown"
                },
                diskType: {
                  TAG: "Dirty",
                  _0: match_0$1,
                  _1: "Hidden"
                },
                connectivityMethod: {
                  TAG: "Dirty",
                  _0: match_0$2,
                  _1: "Hidden"
                },
                getRequests: {
                  TAG: "Dirty",
                  _0: tmp$3,
                  _1: "Shown"
                },
                getRequestsUnit: {
                  TAG: "Dirty",
                  _0: tmp$4,
                  _1: "Shown"
                },
                putRequests: {
                  TAG: "Dirty",
                  _0: tmp$5,
                  _1: "Shown"
                },
                putRequestsUnit: {
                  TAG: "Dirty",
                  _0: tmp$6,
                  _1: "Shown"
                },
                transferOut: {
                  TAG: "Dirty",
                  _0: tmp$7,
                  _1: "Shown"
                },
                transferOutUnit: {
                  TAG: "Dirty",
                  _0: tmp$8,
                  _1: "Shown"
                },
                region: {
                  TAG: "Dirty",
                  _0: match_0$3,
                  _1: "Hidden"
                },
                cloudType: {
                  TAG: "Dirty",
                  _0: tmp$9,
                  _1: "Shown"
                },
                description: {
                  TAG: "Dirty",
                  _0: match_0$4,
                  _1: "Hidden"
                },
                features: {
                  TAG: "Dirty",
                  _0: tmp$10,
                  _1: "Shown"
                },
                accessTypes: {
                  TAG: "Dirty",
                  _0: tmp$11,
                  _1: "Shown"
                },
                certifications: {
                  TAG: "Dirty",
                  _0: tmp$12,
                  _1: "Shown"
                },
                additionalRequirements: {
                  TAG: "Dirty",
                  _0: match_0$5,
                  _1: "Hidden"
                }
              });
          return [
                  {
                    TAG: "Error",
                    _0: undefined
                  },
                  statuses
                ];
        }));
  if (match.TAG === "Ok") {
    var configurations = match$1[0];
    if (configurations.TAG === "Ok") {
      return {
              TAG: "Valid",
              output: {
                configurations: configurations._0
              },
              fieldsStatuses: {
                configurations: match$1[1]
              },
              collectionsStatuses: {
                configurations: match
              }
            };
    }
    
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            configurations: match$1[1]
          },
          collectionsStatuses: {
            configurations: match
          }
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateConfigurationKeyField" :
                  var index = action._1;
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput.configurations[index].key, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index) {
                                                      return {
                                                              key: status,
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: item.diskType,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: item.region,
                                                              cloudType: item.cloudType,
                                                              description: item.description,
                                                              features: item.features,
                                                              accessTypes: item.accessTypes,
                                                              certifications: item.certifications,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationStartDateField" :
                  var index$1 = action._1;
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$1, index$1, state.fieldsStatuses.configurations[index$1].startDate, state.submissionStatus, validators.configurations.fields.startDate, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$1) {
                                                      return {
                                                              key: item.key,
                                                              startDate: status,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: item.diskType,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: item.region,
                                                              cloudType: item.cloudType,
                                                              description: item.description,
                                                              features: item.features,
                                                              accessTypes: item.accessTypes,
                                                              certifications: item.certifications,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationContractLengthField" :
                  var index$2 = action._1;
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$2, index$2, state.fieldsStatuses.configurations[index$2].contractLength, state.submissionStatus, validators.configurations.fields.contractLength, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$2) {
                                                      return {
                                                              key: item.key,
                                                              startDate: item.startDate,
                                                              contractLength: status,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: item.diskType,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: item.region,
                                                              cloudType: item.cloudType,
                                                              description: item.description,
                                                              features: item.features,
                                                              accessTypes: item.accessTypes,
                                                              certifications: item.certifications,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationRawStorageCapacityField" :
                  var index$3 = action._1;
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$3, index$3, state.fieldsStatuses.configurations[index$3].rawStorageCapacity, state.submissionStatus, validators.configurations.fields.rawStorageCapacity, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$3) {
                                                      return {
                                                              key: item.key,
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: status,
                                                              diskType: item.diskType,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: item.region,
                                                              cloudType: item.cloudType,
                                                              description: item.description,
                                                              features: item.features,
                                                              accessTypes: item.accessTypes,
                                                              certifications: item.certifications,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationDiskTypeField" :
                  var index$4 = action._1;
                  var nextInput$4 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$4.configurations[index$4].diskType, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$4) {
                                                      return {
                                                              key: item.key,
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: status,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: item.region,
                                                              cloudType: item.cloudType,
                                                              description: item.description,
                                                              features: item.features,
                                                              accessTypes: item.accessTypes,
                                                              certifications: item.certifications,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationConnectivityMethodField" :
                  var index$5 = action._1;
                  var nextInput$5 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$5.configurations[index$5].connectivityMethod, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$5) {
                                                      return {
                                                              key: item.key,
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: item.diskType,
                                                              connectivityMethod: status,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: item.region,
                                                              cloudType: item.cloudType,
                                                              description: item.description,
                                                              features: item.features,
                                                              accessTypes: item.accessTypes,
                                                              certifications: item.certifications,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationGetRequestsField" :
                  var index$6 = action._1;
                  var nextInput$6 = action._0(state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$6, index$p, item.getRequestsUnit, validators.configurations.fields.getRequestsUnit, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: item.getRequests,
                                                            getRequestsUnit: status,
                                                            putRequests: item.putRequests,
                                                            putRequestsUnit: item.putRequestsUnit,
                                                            transferOut: item.transferOut,
                                                            transferOutUnit: item.transferOutUnit,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$6, index$6, nextFieldsStatuses.contents.configurations[index$6].getRequests, state.submissionStatus, validators.configurations.fields.getRequests, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.configurations, (function (idx_, item) {
                                                    if (idx_ === index$6) {
                                                      return {
                                                              key: item.key,
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: item.diskType,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: status,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: item.region,
                                                              cloudType: item.cloudType,
                                                              description: item.description,
                                                              features: item.features,
                                                              accessTypes: item.accessTypes,
                                                              certifications: item.certifications,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationGetRequestsUnitField" :
                  var index$7 = action._1;
                  var nextInput$7 = action._0(state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$1.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$7, index$p, item.getRequests, validators.configurations.fields.getRequests, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$1.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: status,
                                                            getRequestsUnit: item.getRequestsUnit,
                                                            putRequests: item.putRequests,
                                                            putRequestsUnit: item.putRequestsUnit,
                                                            transferOut: item.transferOut,
                                                            transferOutUnit: item.transferOutUnit,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$1.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$7, index$7, nextFieldsStatuses$1.contents.configurations[index$7].getRequestsUnit, state.submissionStatus, validators.configurations.fields.getRequestsUnit, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$1.contents.configurations, (function (idx_, item) {
                                                    if (idx_ === index$7) {
                                                      return {
                                                              key: item.key,
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: item.diskType,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: status,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: item.region,
                                                              cloudType: item.cloudType,
                                                              description: item.description,
                                                              features: item.features,
                                                              accessTypes: item.accessTypes,
                                                              certifications: item.certifications,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationPutRequestsField" :
                  var index$8 = action._1;
                  var nextInput$8 = action._0(state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$2.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$8, index$p, item.putRequestsUnit, validators.configurations.fields.putRequestsUnit, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$2.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: item.getRequests,
                                                            getRequestsUnit: item.getRequestsUnit,
                                                            putRequests: item.putRequests,
                                                            putRequestsUnit: status,
                                                            transferOut: item.transferOut,
                                                            transferOutUnit: item.transferOutUnit,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$2.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$8, index$8, nextFieldsStatuses$2.contents.configurations[index$8].putRequests, state.submissionStatus, validators.configurations.fields.putRequests, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$2.contents.configurations, (function (idx_, item) {
                                                    if (idx_ === index$8) {
                                                      return {
                                                              key: item.key,
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: item.diskType,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: status,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: item.region,
                                                              cloudType: item.cloudType,
                                                              description: item.description,
                                                              features: item.features,
                                                              accessTypes: item.accessTypes,
                                                              certifications: item.certifications,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationPutRequestsUnitField" :
                  var index$9 = action._1;
                  var nextInput$9 = action._0(state.input);
                  var nextFieldsStatuses$3 = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$3.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$9, index$p, item.putRequests, validators.configurations.fields.putRequests, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$3.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: item.getRequests,
                                                            getRequestsUnit: item.getRequestsUnit,
                                                            putRequests: status,
                                                            putRequestsUnit: item.putRequestsUnit,
                                                            transferOut: item.transferOut,
                                                            transferOutUnit: item.transferOutUnit,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$3.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$9, index$9, nextFieldsStatuses$3.contents.configurations[index$9].putRequestsUnit, state.submissionStatus, validators.configurations.fields.putRequestsUnit, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$3.contents.configurations, (function (idx_, item) {
                                                    if (idx_ === index$9) {
                                                      return {
                                                              key: item.key,
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: item.diskType,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: status,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: item.region,
                                                              cloudType: item.cloudType,
                                                              description: item.description,
                                                              features: item.features,
                                                              accessTypes: item.accessTypes,
                                                              certifications: item.certifications,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationTransferOutField" :
                  var index$10 = action._1;
                  var nextInput$10 = action._0(state.input);
                  var nextFieldsStatuses$4 = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$4.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$10, index$p, item.transferOutUnit, validators.configurations.fields.transferOutUnit, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$4.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: item.getRequests,
                                                            getRequestsUnit: item.getRequestsUnit,
                                                            putRequests: item.putRequests,
                                                            putRequestsUnit: item.putRequestsUnit,
                                                            transferOut: item.transferOut,
                                                            transferOutUnit: status,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$4.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$10, index$10, nextFieldsStatuses$4.contents.configurations[index$10].transferOut, state.submissionStatus, validators.configurations.fields.transferOut, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$4.contents.configurations, (function (idx_, item) {
                                                    if (idx_ === index$10) {
                                                      return {
                                                              key: item.key,
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: item.diskType,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: status,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: item.region,
                                                              cloudType: item.cloudType,
                                                              description: item.description,
                                                              features: item.features,
                                                              accessTypes: item.accessTypes,
                                                              certifications: item.certifications,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationTransferOutUnitField" :
                  var index$11 = action._1;
                  var nextInput$11 = action._0(state.input);
                  var nextFieldsStatuses$5 = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$5.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$11, index$p, item.transferOut, validators.configurations.fields.transferOut, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$5.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: item.getRequests,
                                                            getRequestsUnit: item.getRequestsUnit,
                                                            putRequests: item.putRequests,
                                                            putRequestsUnit: item.putRequestsUnit,
                                                            transferOut: status,
                                                            transferOutUnit: item.transferOutUnit,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$5.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$11, index$11, nextFieldsStatuses$5.contents.configurations[index$11].transferOutUnit, state.submissionStatus, validators.configurations.fields.transferOutUnit, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$5.contents.configurations, (function (idx_, item) {
                                                    if (idx_ === index$11) {
                                                      return {
                                                              key: item.key,
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: item.diskType,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: status,
                                                              region: item.region,
                                                              cloudType: item.cloudType,
                                                              description: item.description,
                                                              features: item.features,
                                                              accessTypes: item.accessTypes,
                                                              certifications: item.certifications,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationRegionField" :
                  var index$12 = action._1;
                  var nextInput$12 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$12.configurations[index$12].region, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$12) {
                                                      return {
                                                              key: item.key,
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: item.diskType,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: status,
                                                              cloudType: item.cloudType,
                                                              description: item.description,
                                                              features: item.features,
                                                              accessTypes: item.accessTypes,
                                                              certifications: item.certifications,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationCloudTypeField" :
                  var index$13 = action._1;
                  var nextInput$13 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$13, index$13, state.fieldsStatuses.configurations[index$13].cloudType, state.submissionStatus, validators.configurations.fields.cloudType, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$13) {
                                                      return {
                                                              key: item.key,
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: item.diskType,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: item.region,
                                                              cloudType: status,
                                                              description: item.description,
                                                              features: item.features,
                                                              accessTypes: item.accessTypes,
                                                              certifications: item.certifications,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationDescriptionField" :
                  var index$14 = action._1;
                  var nextInput$14 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$14.configurations[index$14].description, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$14) {
                                                      return {
                                                              key: item.key,
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: item.diskType,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: item.region,
                                                              cloudType: item.cloudType,
                                                              description: status,
                                                              features: item.features,
                                                              accessTypes: item.accessTypes,
                                                              certifications: item.certifications,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationFeaturesField" :
                  var index$15 = action._1;
                  var nextInput$15 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$15, index$15, state.fieldsStatuses.configurations[index$15].features, state.submissionStatus, validators.configurations.fields.features, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$15) {
                                                      return {
                                                              key: item.key,
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: item.diskType,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: item.region,
                                                              cloudType: item.cloudType,
                                                              description: item.description,
                                                              features: status,
                                                              accessTypes: item.accessTypes,
                                                              certifications: item.certifications,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationAccessTypesField" :
                  var index$16 = action._1;
                  var nextInput$16 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$16,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$16, index$16, state.fieldsStatuses.configurations[index$16].accessTypes, state.submissionStatus, validators.configurations.fields.accessTypes, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$16) {
                                                      return {
                                                              key: item.key,
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: item.diskType,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: item.region,
                                                              cloudType: item.cloudType,
                                                              description: item.description,
                                                              features: item.features,
                                                              accessTypes: status,
                                                              certifications: item.certifications,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationCertificationsField" :
                  var index$17 = action._1;
                  var nextInput$17 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$17,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$17, index$17, state.fieldsStatuses.configurations[index$17].certifications, state.submissionStatus, validators.configurations.fields.certifications, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$17) {
                                                      return {
                                                              key: item.key,
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: item.diskType,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: item.region,
                                                              cloudType: item.cloudType,
                                                              description: item.description,
                                                              features: item.features,
                                                              accessTypes: item.accessTypes,
                                                              certifications: status,
                                                              additionalRequirements: item.additionalRequirements
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationAdditionalRequirementsField" :
                  var index$18 = action._1;
                  var nextInput$18 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$18,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$18.configurations[index$18].additionalRequirements, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$18) {
                                                      return {
                                                              key: item.key,
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              rawStorageCapacity: item.rawStorageCapacity,
                                                              diskType: item.diskType,
                                                              connectivityMethod: item.connectivityMethod,
                                                              getRequests: item.getRequests,
                                                              getRequestsUnit: item.getRequestsUnit,
                                                              putRequests: item.putRequests,
                                                              putRequestsUnit: item.putRequestsUnit,
                                                              transferOut: item.transferOut,
                                                              transferOutUnit: item.transferOutUnit,
                                                              region: item.region,
                                                              cloudType: item.cloudType,
                                                              description: item.description,
                                                              features: item.features,
                                                              accessTypes: item.accessTypes,
                                                              certifications: item.certifications,
                                                              additionalRequirements: status
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "BlurConfigurationKeyField" :
                  var index$19 = action._0;
                  var result = Formality.validateFieldOnBlurWithoutValidator(state.input.configurations[index$19].key, state.fieldsStatuses.configurations[index$19].key, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$19) {
                                            return {
                                                    key: status,
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: item.diskType,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: item.region,
                                                    cloudType: item.cloudType,
                                                    description: item.description,
                                                    features: item.features,
                                                    accessTypes: item.accessTypes,
                                                    certifications: item.certifications,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationStartDateField" :
                  var index$20 = action._0;
                  var result$1 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$20, state.fieldsStatuses.configurations[index$20].startDate, validators.configurations.fields.startDate, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$20) {
                                            return {
                                                    key: item.key,
                                                    startDate: status,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: item.diskType,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: item.region,
                                                    cloudType: item.cloudType,
                                                    description: item.description,
                                                    features: item.features,
                                                    accessTypes: item.accessTypes,
                                                    certifications: item.certifications,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationContractLengthField" :
                  var index$21 = action._0;
                  var result$2 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$21, state.fieldsStatuses.configurations[index$21].contractLength, validators.configurations.fields.contractLength, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$21) {
                                            return {
                                                    key: item.key,
                                                    startDate: item.startDate,
                                                    contractLength: status,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: item.diskType,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: item.region,
                                                    cloudType: item.cloudType,
                                                    description: item.description,
                                                    features: item.features,
                                                    accessTypes: item.accessTypes,
                                                    certifications: item.certifications,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationRawStorageCapacityField" :
                  var index$22 = action._0;
                  var result$3 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$22, state.fieldsStatuses.configurations[index$22].rawStorageCapacity, validators.configurations.fields.rawStorageCapacity, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$22) {
                                            return {
                                                    key: item.key,
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: status,
                                                    diskType: item.diskType,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: item.region,
                                                    cloudType: item.cloudType,
                                                    description: item.description,
                                                    features: item.features,
                                                    accessTypes: item.accessTypes,
                                                    certifications: item.certifications,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationDiskTypeField" :
                  var index$23 = action._0;
                  var result$4 = Formality.validateFieldOnBlurWithoutValidator(state.input.configurations[index$23].diskType, state.fieldsStatuses.configurations[index$23].diskType, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$23) {
                                            return {
                                                    key: item.key,
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: status,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: item.region,
                                                    cloudType: item.cloudType,
                                                    description: item.description,
                                                    features: item.features,
                                                    accessTypes: item.accessTypes,
                                                    certifications: item.certifications,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationConnectivityMethodField" :
                  var index$24 = action._0;
                  var result$5 = Formality.validateFieldOnBlurWithoutValidator(state.input.configurations[index$24].connectivityMethod, state.fieldsStatuses.configurations[index$24].connectivityMethod, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$24) {
                                            return {
                                                    key: item.key,
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: item.diskType,
                                                    connectivityMethod: status,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: item.region,
                                                    cloudType: item.cloudType,
                                                    description: item.description,
                                                    features: item.features,
                                                    accessTypes: item.accessTypes,
                                                    certifications: item.certifications,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationGetRequestsField" :
                  var index$25 = action._0;
                  var result$6 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$25, state.fieldsStatuses.configurations[index$25].getRequests, validators.configurations.fields.getRequests, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$25) {
                                            return {
                                                    key: item.key,
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: item.diskType,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: status,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: item.region,
                                                    cloudType: item.cloudType,
                                                    description: item.description,
                                                    features: item.features,
                                                    accessTypes: item.accessTypes,
                                                    certifications: item.certifications,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationGetRequestsUnitField" :
                  var index$26 = action._0;
                  var result$7 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$26, state.fieldsStatuses.configurations[index$26].getRequestsUnit, validators.configurations.fields.getRequestsUnit, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$26) {
                                            return {
                                                    key: item.key,
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: item.diskType,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: status,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: item.region,
                                                    cloudType: item.cloudType,
                                                    description: item.description,
                                                    features: item.features,
                                                    accessTypes: item.accessTypes,
                                                    certifications: item.certifications,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationPutRequestsField" :
                  var index$27 = action._0;
                  var result$8 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$27, state.fieldsStatuses.configurations[index$27].putRequests, validators.configurations.fields.putRequests, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$27) {
                                            return {
                                                    key: item.key,
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: item.diskType,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: status,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: item.region,
                                                    cloudType: item.cloudType,
                                                    description: item.description,
                                                    features: item.features,
                                                    accessTypes: item.accessTypes,
                                                    certifications: item.certifications,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationPutRequestsUnitField" :
                  var index$28 = action._0;
                  var result$9 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$28, state.fieldsStatuses.configurations[index$28].putRequestsUnit, validators.configurations.fields.putRequestsUnit, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$28) {
                                            return {
                                                    key: item.key,
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: item.diskType,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: status,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: item.region,
                                                    cloudType: item.cloudType,
                                                    description: item.description,
                                                    features: item.features,
                                                    accessTypes: item.accessTypes,
                                                    certifications: item.certifications,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationTransferOutField" :
                  var index$29 = action._0;
                  var result$10 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$29, state.fieldsStatuses.configurations[index$29].transferOut, validators.configurations.fields.transferOut, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$29) {
                                            return {
                                                    key: item.key,
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: item.diskType,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: status,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: item.region,
                                                    cloudType: item.cloudType,
                                                    description: item.description,
                                                    features: item.features,
                                                    accessTypes: item.accessTypes,
                                                    certifications: item.certifications,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationTransferOutUnitField" :
                  var index$30 = action._0;
                  var result$11 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$30, state.fieldsStatuses.configurations[index$30].transferOutUnit, validators.configurations.fields.transferOutUnit, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$30) {
                                            return {
                                                    key: item.key,
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: item.diskType,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: status,
                                                    region: item.region,
                                                    cloudType: item.cloudType,
                                                    description: item.description,
                                                    features: item.features,
                                                    accessTypes: item.accessTypes,
                                                    certifications: item.certifications,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationRegionField" :
                  var index$31 = action._0;
                  var result$12 = Formality.validateFieldOnBlurWithoutValidator(state.input.configurations[index$31].region, state.fieldsStatuses.configurations[index$31].region, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$31) {
                                            return {
                                                    key: item.key,
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: item.diskType,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: status,
                                                    cloudType: item.cloudType,
                                                    description: item.description,
                                                    features: item.features,
                                                    accessTypes: item.accessTypes,
                                                    certifications: item.certifications,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationCloudTypeField" :
                  var index$32 = action._0;
                  var result$13 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$32, state.fieldsStatuses.configurations[index$32].cloudType, validators.configurations.fields.cloudType, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$32) {
                                            return {
                                                    key: item.key,
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: item.diskType,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: item.region,
                                                    cloudType: status,
                                                    description: item.description,
                                                    features: item.features,
                                                    accessTypes: item.accessTypes,
                                                    certifications: item.certifications,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationDescriptionField" :
                  var index$33 = action._0;
                  var result$14 = Formality.validateFieldOnBlurWithoutValidator(state.input.configurations[index$33].description, state.fieldsStatuses.configurations[index$33].description, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$33) {
                                            return {
                                                    key: item.key,
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: item.diskType,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: item.region,
                                                    cloudType: item.cloudType,
                                                    description: status,
                                                    features: item.features,
                                                    accessTypes: item.accessTypes,
                                                    certifications: item.certifications,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationFeaturesField" :
                  var index$34 = action._0;
                  var result$15 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$34, state.fieldsStatuses.configurations[index$34].features, validators.configurations.fields.features, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$34) {
                                            return {
                                                    key: item.key,
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: item.diskType,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: item.region,
                                                    cloudType: item.cloudType,
                                                    description: item.description,
                                                    features: status,
                                                    accessTypes: item.accessTypes,
                                                    certifications: item.certifications,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$15 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationAccessTypesField" :
                  var index$35 = action._0;
                  var result$16 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$35, state.fieldsStatuses.configurations[index$35].accessTypes, validators.configurations.fields.accessTypes, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$35) {
                                            return {
                                                    key: item.key,
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: item.diskType,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: item.region,
                                                    cloudType: item.cloudType,
                                                    description: item.description,
                                                    features: item.features,
                                                    accessTypes: status,
                                                    certifications: item.certifications,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$16 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$16,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationCertificationsField" :
                  var index$36 = action._0;
                  var result$17 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$36, state.fieldsStatuses.configurations[index$36].certifications, validators.configurations.fields.certifications, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$36) {
                                            return {
                                                    key: item.key,
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: item.diskType,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: item.region,
                                                    cloudType: item.cloudType,
                                                    description: item.description,
                                                    features: item.features,
                                                    accessTypes: item.accessTypes,
                                                    certifications: status,
                                                    additionalRequirements: item.additionalRequirements
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$17 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$17,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationAdditionalRequirementsField" :
                  var index$37 = action._0;
                  var result$18 = Formality.validateFieldOnBlurWithoutValidator(state.input.configurations[index$37].additionalRequirements, state.fieldsStatuses.configurations[index$37].additionalRequirements, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$37) {
                                            return {
                                                    key: item.key,
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    rawStorageCapacity: item.rawStorageCapacity,
                                                    diskType: item.diskType,
                                                    connectivityMethod: item.connectivityMethod,
                                                    getRequests: item.getRequests,
                                                    getRequestsUnit: item.getRequestsUnit,
                                                    putRequests: item.putRequests,
                                                    putRequestsUnit: item.putRequestsUnit,
                                                    transferOut: item.transferOut,
                                                    transferOutUnit: item.transferOutUnit,
                                                    region: item.region,
                                                    cloudType: item.cloudType,
                                                    description: item.description,
                                                    features: item.features,
                                                    accessTypes: item.accessTypes,
                                                    certifications: item.certifications,
                                                    additionalRequirements: status
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$18 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$18,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "AddConfigurationEntry" :
                  var nextInput$19 = {
                    configurations: Belt_Array.concat(state.input.configurations, [action._0])
                  };
                  var nextFieldsStatuses$6 = {
                    contents: {
                      configurations: Belt_Array.concat(state.fieldsStatuses.configurations, [{
                              key: "Pristine",
                              startDate: "Pristine",
                              contractLength: "Pristine",
                              rawStorageCapacity: "Pristine",
                              diskType: "Pristine",
                              connectivityMethod: "Pristine",
                              getRequests: "Pristine",
                              getRequestsUnit: "Pristine",
                              putRequests: "Pristine",
                              putRequestsUnit: "Pristine",
                              transferOut: "Pristine",
                              transferOutUnit: "Pristine",
                              region: "Pristine",
                              cloudType: "Pristine",
                              description: "Pristine",
                              features: "Pristine",
                              accessTypes: "Pristine",
                              certifications: "Pristine",
                              additionalRequirements: "Pristine"
                            }])
                    }
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$6.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$19, index$p, item.transferOut, validators.configurations.fields.transferOut, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$6.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: item.getRequests,
                                                            getRequestsUnit: item.getRequestsUnit,
                                                            putRequests: item.putRequests,
                                                            putRequestsUnit: item.putRequestsUnit,
                                                            transferOut: status,
                                                            transferOutUnit: item.transferOutUnit,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$6.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$6.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$19, index$p, item.transferOutUnit, validators.configurations.fields.transferOutUnit, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$6.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: item.getRequests,
                                                            getRequestsUnit: item.getRequestsUnit,
                                                            putRequests: item.putRequests,
                                                            putRequestsUnit: item.putRequestsUnit,
                                                            transferOut: item.transferOut,
                                                            transferOutUnit: status,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$6.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$6.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$19, index$p, item.putRequests, validators.configurations.fields.putRequests, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$6.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: item.getRequests,
                                                            getRequestsUnit: item.getRequestsUnit,
                                                            putRequests: status,
                                                            putRequestsUnit: item.putRequestsUnit,
                                                            transferOut: item.transferOut,
                                                            transferOutUnit: item.transferOutUnit,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$6.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$6.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$19, index$p, item.putRequestsUnit, validators.configurations.fields.putRequestsUnit, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$6.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: item.getRequests,
                                                            getRequestsUnit: item.getRequestsUnit,
                                                            putRequests: item.putRequests,
                                                            putRequestsUnit: status,
                                                            transferOut: item.transferOut,
                                                            transferOutUnit: item.transferOutUnit,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$6.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$6.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$19, index$p, item.getRequests, validators.configurations.fields.getRequests, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$6.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: status,
                                                            getRequestsUnit: item.getRequestsUnit,
                                                            putRequests: item.putRequests,
                                                            putRequestsUnit: item.putRequestsUnit,
                                                            transferOut: item.transferOut,
                                                            transferOutUnit: item.transferOutUnit,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$6.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$6.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$19, index$p, item.getRequestsUnit, validators.configurations.fields.getRequestsUnit, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$6.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: item.getRequests,
                                                            getRequestsUnit: status,
                                                            putRequests: item.putRequests,
                                                            putRequestsUnit: item.putRequestsUnit,
                                                            transferOut: item.transferOut,
                                                            transferOutUnit: item.transferOutUnit,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$6.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$19,
                            fieldsStatuses: nextFieldsStatuses$6.contents,
                            collectionsStatuses: {
                              configurations: validators.configurations.collection(nextInput$19)
                            },
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "RemoveConfigurationEntry" :
                  var index$38 = action._0;
                  var nextInput$20 = {
                    configurations: Belt_Array.keepWithIndex(state.input.configurations, (function (param, i) {
                            return i !== index$38;
                          }))
                  };
                  var nextFieldsStatuses$7 = {
                    contents: {
                      configurations: Belt_Array.keepWithIndex(state.fieldsStatuses.configurations, (function (param, i) {
                              return i !== index$38;
                            }))
                    }
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$7.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$20, index$p, item.transferOut, validators.configurations.fields.transferOut, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$7.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: item.getRequests,
                                                            getRequestsUnit: item.getRequestsUnit,
                                                            putRequests: item.putRequests,
                                                            putRequestsUnit: item.putRequestsUnit,
                                                            transferOut: status,
                                                            transferOutUnit: item.transferOutUnit,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$7.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$7.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$20, index$p, item.transferOutUnit, validators.configurations.fields.transferOutUnit, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$7.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: item.getRequests,
                                                            getRequestsUnit: item.getRequestsUnit,
                                                            putRequests: item.putRequests,
                                                            putRequestsUnit: item.putRequestsUnit,
                                                            transferOut: item.transferOut,
                                                            transferOutUnit: status,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$7.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$7.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$20, index$p, item.putRequests, validators.configurations.fields.putRequests, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$7.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: item.getRequests,
                                                            getRequestsUnit: item.getRequestsUnit,
                                                            putRequests: status,
                                                            putRequestsUnit: item.putRequestsUnit,
                                                            transferOut: item.transferOut,
                                                            transferOutUnit: item.transferOutUnit,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$7.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$7.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$20, index$p, item.putRequestsUnit, validators.configurations.fields.putRequestsUnit, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$7.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: item.getRequests,
                                                            getRequestsUnit: item.getRequestsUnit,
                                                            putRequests: item.putRequests,
                                                            putRequestsUnit: status,
                                                            transferOut: item.transferOut,
                                                            transferOutUnit: item.transferOutUnit,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$7.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$7.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$20, index$p, item.getRequests, validators.configurations.fields.getRequests, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$7.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: status,
                                                            getRequestsUnit: item.getRequestsUnit,
                                                            putRequests: item.putRequests,
                                                            putRequestsUnit: item.putRequestsUnit,
                                                            transferOut: item.transferOut,
                                                            transferOutUnit: item.transferOutUnit,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$7.contents = result;
                            return ;
                          }
                          
                        }));
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$7.contents.configurations, (function (index$p, item) {
                          var result = Formality.validateDependentFieldOfCollectionOnChange(nextInput$20, index$p, item.getRequestsUnit, validators.configurations.fields.getRequestsUnit, (function (status) {
                                  return {
                                          configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$7.contents.configurations, (function (idx_, item) {
                                                  if (idx_ === index$p) {
                                                    return {
                                                            key: item.key,
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            rawStorageCapacity: item.rawStorageCapacity,
                                                            diskType: item.diskType,
                                                            connectivityMethod: item.connectivityMethod,
                                                            getRequests: item.getRequests,
                                                            getRequestsUnit: status,
                                                            putRequests: item.putRequests,
                                                            putRequestsUnit: item.putRequestsUnit,
                                                            transferOut: item.transferOut,
                                                            transferOutUnit: item.transferOutUnit,
                                                            region: item.region,
                                                            cloudType: item.cloudType,
                                                            description: item.description,
                                                            features: item.features,
                                                            accessTypes: item.accessTypes,
                                                            certifications: item.certifications,
                                                            additionalRequirements: item.additionalRequirements
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (result !== undefined) {
                            nextFieldsStatuses$7.contents = result;
                            return ;
                          }
                          
                        }));
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$20,
                            fieldsStatuses: nextFieldsStatuses$7.contents,
                            collectionsStatuses: {
                              configurations: validators.configurations.collection(nextInput$20)
                            },
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateConfigurationKey: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationKeyField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationStartDate: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationStartDateField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationContractLength: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationContractLengthField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationRawStorageCapacity: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationRawStorageCapacityField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationDiskType: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationDiskTypeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationConnectivityMethod: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationConnectivityMethodField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationGetRequests: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationGetRequestsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationGetRequestsUnit: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationGetRequestsUnitField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationPutRequests: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationPutRequestsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationPutRequestsUnit: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationPutRequestsUnitField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationTransferOut: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationTransferOutField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationTransferOutUnit: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationTransferOutUnitField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationRegion: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationRegionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationCloudType: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationCloudTypeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationDescription: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationDescriptionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationFeatures: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationFeaturesField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationAccessTypes: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationAccessTypesField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationCertifications: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationCertificationsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationAdditionalRequirements: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationAdditionalRequirementsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          blurConfigurationKey: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationKeyField",
                    _0: index
                  });
            }),
          blurConfigurationStartDate: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationStartDateField",
                    _0: index
                  });
            }),
          blurConfigurationContractLength: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationContractLengthField",
                    _0: index
                  });
            }),
          blurConfigurationRawStorageCapacity: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationRawStorageCapacityField",
                    _0: index
                  });
            }),
          blurConfigurationDiskType: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationDiskTypeField",
                    _0: index
                  });
            }),
          blurConfigurationConnectivityMethod: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationConnectivityMethodField",
                    _0: index
                  });
            }),
          blurConfigurationGetRequests: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationGetRequestsField",
                    _0: index
                  });
            }),
          blurConfigurationGetRequestsUnit: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationGetRequestsUnitField",
                    _0: index
                  });
            }),
          blurConfigurationPutRequests: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationPutRequestsField",
                    _0: index
                  });
            }),
          blurConfigurationPutRequestsUnit: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationPutRequestsUnitField",
                    _0: index
                  });
            }),
          blurConfigurationTransferOut: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationTransferOutField",
                    _0: index
                  });
            }),
          blurConfigurationTransferOutUnit: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationTransferOutUnitField",
                    _0: index
                  });
            }),
          blurConfigurationRegion: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationRegionField",
                    _0: index
                  });
            }),
          blurConfigurationCloudType: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationCloudTypeField",
                    _0: index
                  });
            }),
          blurConfigurationDescription: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationDescriptionField",
                    _0: index
                  });
            }),
          blurConfigurationFeatures: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationFeaturesField",
                    _0: index
                  });
            }),
          blurConfigurationAccessTypes: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationAccessTypesField",
                    _0: index
                  });
            }),
          blurConfigurationCertifications: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationCertificationsField",
                    _0: index
                  });
            }),
          blurConfigurationAdditionalRequirements: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationAdditionalRequirementsField",
                    _0: index
                  });
            }),
          configurationKeyResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].key);
            }),
          configurationStartDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].startDate);
            }),
          configurationContractLengthResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].contractLength);
            }),
          configurationRawStorageCapacityResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].rawStorageCapacity);
            }),
          configurationDiskTypeResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].diskType);
            }),
          configurationConnectivityMethodResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].connectivityMethod);
            }),
          configurationGetRequestsResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].getRequests);
            }),
          configurationGetRequestsUnitResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].getRequestsUnit);
            }),
          configurationPutRequestsResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].putRequests);
            }),
          configurationPutRequestsUnitResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].putRequestsUnit);
            }),
          configurationTransferOutResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].transferOut);
            }),
          configurationTransferOutUnitResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].transferOutUnit);
            }),
          configurationRegionResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].region);
            }),
          configurationCloudTypeResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].cloudType);
            }),
          configurationDescriptionResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].description);
            }),
          configurationFeaturesResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].features);
            }),
          configurationAccessTypesResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].accessTypes);
            }),
          configurationCertificationsResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].certifications);
            }),
          configurationAdditionalRequirementsResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].additionalRequirements);
            }),
          addConfiguration: (function (entry) {
              dispatch({
                    TAG: "AddConfigurationEntry",
                    _0: entry
                  });
            }),
          removeConfiguration: (function (index) {
              dispatch({
                    TAG: "RemoveConfigurationEntry",
                    _0: index
                  });
            }),
          configurationsResult: state.collectionsStatuses.configurations,
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              return Belt_Array.every(state.fieldsStatuses.configurations, (function (item) {
                            var exit = 0;
                            var tmp = item.key;
                            if (typeof tmp !== "object") {
                              var tmp$1 = item.startDate;
                              if (typeof tmp$1 !== "object") {
                                var tmp$2 = item.contractLength;
                                if (typeof tmp$2 !== "object") {
                                  var tmp$3 = item.rawStorageCapacity;
                                  if (typeof tmp$3 !== "object") {
                                    var tmp$4 = item.diskType;
                                    if (typeof tmp$4 !== "object") {
                                      var tmp$5 = item.connectivityMethod;
                                      if (typeof tmp$5 !== "object") {
                                        var tmp$6 = item.getRequests;
                                        if (typeof tmp$6 !== "object") {
                                          var tmp$7 = item.getRequestsUnit;
                                          if (typeof tmp$7 !== "object") {
                                            var tmp$8 = item.putRequests;
                                            if (typeof tmp$8 !== "object") {
                                              var tmp$9 = item.putRequestsUnit;
                                              if (typeof tmp$9 !== "object") {
                                                var tmp$10 = item.transferOut;
                                                if (typeof tmp$10 !== "object") {
                                                  var tmp$11 = item.transferOutUnit;
                                                  if (typeof tmp$11 !== "object") {
                                                    var tmp$12 = item.region;
                                                    if (typeof tmp$12 !== "object") {
                                                      var tmp$13 = item.cloudType;
                                                      if (typeof tmp$13 !== "object") {
                                                        var tmp$14 = item.description;
                                                        if (typeof tmp$14 !== "object") {
                                                          var tmp$15 = item.features;
                                                          if (typeof tmp$15 !== "object") {
                                                            var tmp$16 = item.accessTypes;
                                                            if (typeof tmp$16 !== "object") {
                                                              var tmp$17 = item.certifications;
                                                              if (typeof tmp$17 !== "object") {
                                                                var tmp$18 = item.additionalRequirements;
                                                                if (typeof tmp$18 !== "object") {
                                                                  return false;
                                                                }
                                                                exit = 1;
                                                              } else {
                                                                exit = 1;
                                                              }
                                                            } else {
                                                              exit = 1;
                                                            }
                                                          } else {
                                                            exit = 1;
                                                          }
                                                        } else {
                                                          exit = 1;
                                                        }
                                                      } else {
                                                        exit = 1;
                                                      }
                                                    } else {
                                                      exit = 1;
                                                    }
                                                  } else {
                                                    exit = 1;
                                                  }
                                                } else {
                                                  exit = 1;
                                                }
                                              } else {
                                                exit = 1;
                                              }
                                            } else {
                                              exit = 1;
                                            }
                                          } else {
                                            exit = 1;
                                          }
                                        } else {
                                          exit = 1;
                                        }
                                      } else {
                                        exit = 1;
                                      }
                                    } else {
                                      exit = 1;
                                    }
                                  } else {
                                    exit = 1;
                                  }
                                } else {
                                  exit = 1;
                                }
                              } else {
                                exit = 1;
                              }
                            } else {
                              exit = 1;
                            }
                            if (exit === 1) {
                              var exit$1 = 0;
                              exit$1 = 2;
                              if (exit$1 === 2) {
                                var exit$2 = 0;
                                exit$2 = 3;
                                if (exit$2 === 3) {
                                  var exit$3 = 0;
                                  exit$3 = 4;
                                  if (exit$3 === 4) {
                                    var exit$4 = 0;
                                    exit$4 = 5;
                                    if (exit$4 === 5) {
                                      var exit$5 = 0;
                                      exit$5 = 6;
                                      if (exit$5 === 6) {
                                        var exit$6 = 0;
                                        exit$6 = 7;
                                        if (exit$6 === 7) {
                                          var exit$7 = 0;
                                          exit$7 = 8;
                                          if (exit$7 === 8) {
                                            var exit$8 = 0;
                                            exit$8 = 9;
                                            if (exit$8 === 9) {
                                              return true;
                                            }
                                            
                                          }
                                          
                                        }
                                        
                                      }
                                      
                                    }
                                    
                                  }
                                  
                                }
                                
                              }
                              
                            }
                            
                          }));
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

function empty() {
  return {
          key: NonSecure.nanoid(),
          startDate: undefined,
          contractLength: undefined,
          rawStorageCapacity: "",
          diskType: undefined,
          connectivityMethod: undefined,
          getRequests: undefined,
          getRequestsUnit: "",
          putRequests: undefined,
          putRequestsUnit: "",
          transferOut: undefined,
          transferOutUnit: "",
          region: undefined,
          cloudType: undefined,
          description: undefined,
          features: undefined,
          accessTypes: undefined,
          certifications: undefined,
          additionalRequirements: undefined
        };
}

function update(input, index, updater) {
  return {
          configurations: Belt_Array.mapWithIndex(input.configurations, (function (idx, configuration) {
                  if (idx !== index) {
                    return configuration;
                  } else {
                    return updater(configuration);
                  }
                }))
        };
}

function remove(input, index) {
  return {
          configurations: Belt_Array.keepWithIndex(input.configurations, (function (param, idx) {
                  return idx !== index;
                }))
        };
}

function inputDecoder(contractLengths) {
  return Json_Decode$JsonCombinators.object(function (field) {
              var cloudStorage = field.required("cloudStorage", Json_Decode$JsonCombinators.object(function (field) {
                        return {
                                rawStorageCapacity: field.required("rawStorageCapacity", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
                                diskType: field.required("diskType", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
                                connectivityMethod: field.required("connectivityMethod", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
                                getRequestsUnit: field.required("getRequestsUnit", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
                                putRequestsUnit: field.required("putRequestsUnit", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
                                transferOutUnit: field.required("transferOutUnit", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
                                putRequestsCount: field.required("putRequestsCount", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
                                getRequestsCount: field.required("getRequestsCount", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
                                transferOut: field.required("transferOut", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
                                region: field.required("region", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
                                type: field.required("type", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
                                description: field.required("description", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))
                              };
                      }));
              return {
                      key: NonSecure.nanoid(),
                      startDate: field.required("startDate", Json_Decode$JsonCombinators.option($$Date.Naive.decoder)),
                      contractLength: Belt_Option.flatMap(field.required("contractLength", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)), (function (x) {
                              return Belt_Array.getBy(contractLengths, (function (cl) {
                                            return cl.value === x;
                                          }));
                            })),
                      rawStorageCapacity: Belt_Option.getWithDefault(cloudStorage.rawStorageCapacity, ""),
                      diskType: cloudStorage.diskType,
                      connectivityMethod: cloudStorage.connectivityMethod,
                      getRequests: cloudStorage.getRequestsCount,
                      getRequestsUnit: Belt_Option.getWithDefault(cloudStorage.getRequestsUnit, ""),
                      putRequests: cloudStorage.putRequestsCount,
                      putRequestsUnit: Belt_Option.getWithDefault(cloudStorage.putRequestsUnit, ""),
                      transferOut: cloudStorage.transferOut,
                      transferOutUnit: Belt_Option.getWithDefault(cloudStorage.transferOutUnit, ""),
                      region: cloudStorage.region,
                      cloudType: cloudStorage.type,
                      description: cloudStorage.description,
                      features: Belt_SetString.fromArray(field.required("features", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))),
                      accessTypes: Belt_SetString.fromArray(field.required("accessTypes", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))),
                      certifications: Belt_SetString.fromArray(field.required("certifications", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))),
                      additionalRequirements: field.required("additionalRequirements", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))
                    };
            });
}

function inputFromJson(json, contractLengths) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, inputDecoder(contractLengths)));
}

function inputToJson(configuration) {
  var x = configuration.additionalRequirements;
  var tmp;
  tmp = x !== undefined && x !== "" ? x : null;
  var x$1 = configuration.contractLength;
  var x$2 = configuration.rawStorageCapacity;
  var tmp$1 = x$2 === "" ? null : x$2;
  var x$3 = configuration.transferOutUnit;
  var tmp$2 = x$3 === "" ? null : x$3;
  var x$4 = configuration.getRequestsUnit;
  var tmp$3 = x$4 === "" ? null : x$4;
  var x$5 = configuration.putRequestsUnit;
  var tmp$4 = x$5 === "" ? null : x$5;
  var x$6 = configuration.description;
  var tmp$5;
  tmp$5 = x$6 !== undefined && x$6 !== "" ? x$6 : null;
  return Json_Encode$JsonCombinators.object([
              [
                "features",
                Json_Encode$JsonCombinators.array(function (prim) {
                        return prim;
                      })(Belt_SetString.toArray(configuration.features))
              ],
              [
                "accessTypes",
                Json_Encode$JsonCombinators.array(function (prim) {
                        return prim;
                      })(Belt_SetString.toArray(configuration.accessTypes))
              ],
              [
                "certifications",
                Json_Encode$JsonCombinators.array(function (prim) {
                        return prim;
                      })(Belt_SetString.toArray(configuration.certifications))
              ],
              [
                "additionalRequirements",
                tmp
              ],
              [
                "startDate",
                $$Date.Naive.toJsonOpt(configuration.startDate)
              ],
              [
                "contractLength",
                x$1 !== undefined ? x$1.value : null
              ],
              [
                "cloudStorage",
                Json_Encode$JsonCombinators.object([
                      [
                        "rawStorageCapacity",
                        tmp$1
                      ],
                      [
                        "diskType",
                        Json_Encode$JsonCombinators.option(function (prim) {
                                return prim;
                              })(configuration.diskType)
                      ],
                      [
                        "connectivityMethod",
                        Json_Encode$JsonCombinators.option(function (prim) {
                                return prim;
                              })(configuration.connectivityMethod)
                      ],
                      [
                        "transferOutUnit",
                        tmp$2
                      ],
                      [
                        "transferOut",
                        Json_Encode$JsonCombinators.option(function (prim) {
                                return prim;
                              })(configuration.transferOut)
                      ],
                      [
                        "getRequestsUnit",
                        tmp$3
                      ],
                      [
                        "getRequestsCount",
                        Json_Encode$JsonCombinators.option(function (prim) {
                                return prim;
                              })(configuration.getRequests)
                      ],
                      [
                        "putRequestsUnit",
                        tmp$4
                      ],
                      [
                        "putRequestsCount",
                        Json_Encode$JsonCombinators.option(function (prim) {
                                return prim;
                              })(configuration.putRequests)
                      ],
                      [
                        "region",
                        Json_Encode$JsonCombinators.option(function (prim) {
                                return prim;
                              })(configuration.region)
                      ],
                      [
                        "type",
                        Json_Encode$JsonCombinators.option(function (prim) {
                                return prim;
                              })(configuration.cloudType)
                      ],
                      [
                        "description",
                        tmp$5
                      ]
                    ])
              ]
            ]);
}

function outputToJson(configuration) {
  return Json_Encode$JsonCombinators.object([
              [
                "startDate",
                $$Date.Naive.toJson(configuration.startDate)
              ],
              [
                "contractLength",
                configuration.contractLength
              ],
              [
                "features",
                Json_Encode$JsonCombinators.array(function (prim) {
                        return prim;
                      })(configuration.features)
              ],
              [
                "accessTypes",
                Json_Encode$JsonCombinators.array(function (prim) {
                        return prim;
                      })(configuration.accessTypes)
              ],
              [
                "certifications",
                Json_Encode$JsonCombinators.array(function (prim) {
                        return prim;
                      })(configuration.certifications)
              ],
              [
                "additionalRequirements",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(configuration.additionalRequirements)
              ],
              [
                "cloudStorage",
                Json_Encode$JsonCombinators.object([
                      [
                        "rawStorageCapacity",
                        configuration.rawStorageCapacity
                      ],
                      [
                        "diskType",
                        Json_Encode$JsonCombinators.option(function (prim) {
                                return prim;
                              })(configuration.diskType)
                      ],
                      [
                        "connectivityMethod",
                        Json_Encode$JsonCombinators.option(function (prim) {
                                return prim;
                              })(configuration.connectivityMethod)
                      ],
                      [
                        "transferOutUnit",
                        Json_Encode$JsonCombinators.option(function (prim) {
                                return prim;
                              })(configuration.transferOutUnit)
                      ],
                      [
                        "transferOut",
                        Json_Encode$JsonCombinators.option(function (prim) {
                                return prim;
                              })(configuration.transferOut)
                      ],
                      [
                        "getRequestsUnit",
                        Json_Encode$JsonCombinators.option(function (prim) {
                                return prim;
                              })(configuration.getRequestsUnit)
                      ],
                      [
                        "getRequestsCount",
                        Json_Encode$JsonCombinators.option(function (prim) {
                                return prim;
                              })(configuration.getRequests)
                      ],
                      [
                        "putRequestsUnit",
                        Json_Encode$JsonCombinators.option(function (prim) {
                                return prim;
                              })(configuration.putRequestsUnit)
                      ],
                      [
                        "putRequestsCount",
                        Json_Encode$JsonCombinators.option(function (prim) {
                                return prim;
                              })(configuration.putRequests)
                      ],
                      [
                        "region",
                        Json_Encode$JsonCombinators.option(function (prim) {
                                return prim;
                              })(configuration.region)
                      ],
                      [
                        "type",
                        configuration.cloudType
                      ],
                      [
                        "description",
                        Json_Encode$JsonCombinators.option(function (prim) {
                                return prim;
                              })(configuration.description)
                      ]
                    ])
              ]
            ]);
}

var Configuration = {
  empty: empty,
  update: update,
  remove: remove,
  inputDecoder: inputDecoder,
  inputFromJson: inputFromJson,
  inputToJson: inputToJson,
  outputToJson: outputToJson
};

function toJson(input) {
  return Json_Encode$JsonCombinators.object([[
                "cloudIaasStorageConfigurations",
                Belt_Array.map(input.configurations, inputToJson)
              ]]);
}

var Input = {
  toJson: toJson
};

function toJson$1(output) {
  return Json_Encode$JsonCombinators.object([[
                "cloudIaasStorageConfigurations",
                Belt_Array.map(output.configurations, outputToJson)
              ]]);
}

var Output = {
  toJson: toJson$1
};

export {
  errorIfNotPresent ,
  validators ,
  initialFieldsStatuses ,
  initialCollectionsStatuses ,
  initialState ,
  validateForm ,
  useForm ,
  Configuration ,
  Input ,
  Output ,
}
/* Date Not a pure module */
