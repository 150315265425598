// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../libs/Date.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CrossConnect from "./CrossConnect.res.js";
import * as CloudDirectConnect from "./CloudDirectConnect.res.js";
import * as InternetConnectivity from "./InternetConnectivity.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as RackTypesAndPowerDensity from "./RackTypesAndPowerDensity.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          startDate: Belt_Option.map(Caml_option.nullable_to_opt(js.startDate), $$Date.Naive.fromString),
          contractLength: Caml_option.nullable_to_opt(js.contractLength),
          rackTypesAndPowerDensities: Belt_Array.map(js.rackTypesAndPowerDensities, (function (rackTypesAndPowerDensity) {
                  return RackTypesAndPowerDensity.fromJs(rackTypesAndPowerDensity);
                })),
          internetConnectivities: Belt_Array.map(js.internetConnectivities, (function (internetConnectivity) {
                  return InternetConnectivity.fromJs(internetConnectivity);
                })),
          crossConnects: Belt_Array.map(js.crossConnects, (function (crossConnect) {
                  return CrossConnect.fromJs(crossConnect);
                })),
          cloudDirectConnects: Belt_Array.map(js.cloudDirectConnects, (function (cloudDirectConnect) {
                  return CloudDirectConnect.fromJs(cloudDirectConnect);
                })),
          certificationRequirements: js.certificationRequirements,
          datacenterFeatures: js.datacenterFeatures,
          additionalNotesOrRequirements: Caml_option.nullable_to_opt(js.additionalNotesOrRequirements)
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              startDate: field.required("startDate", Json_Decode$JsonCombinators.option($$Date.Naive.decoder)),
              contractLength: field.required("contractLength", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              rackTypesAndPowerDensities: field.required("rackTypesAndPowerDensities", Json_Decode$JsonCombinators.array(RackTypesAndPowerDensity.decoder)),
              internetConnectivities: field.required("internetConnectivities", Json_Decode$JsonCombinators.array(InternetConnectivity.decoder)),
              crossConnects: field.required("crossConnects", Json_Decode$JsonCombinators.array(CrossConnect.decoder)),
              cloudDirectConnects: field.required("cloudDirectConnects", Json_Decode$JsonCombinators.array(CloudDirectConnect.decoder)),
              certificationRequirements: field.required("certificationRequirements", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              datacenterFeatures: field.required("datacenterFeatures", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              additionalNotesOrRequirements: field.required("additionalNotesOrRequirements", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  fromJs ,
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
