// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as Rest from "../Rest.res.js";
import * as ColocationProduct from "../../models/ColocationProduct.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as DashboardProductEditorGeneralTabData from "../../bundles/common/dashboard/DashboardProductEditorGeneralTabData.res.js";

var toJson = DashboardProductEditorGeneralTabData.Input.toJson;

var Req = {
  toJson: toJson
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return field.required("product", ColocationProduct.Draft.decoder);
    });

var Res = {
  decoder: decoder
};

function exec(productId, data) {
  return Rest.$$fetch("/products/" + ID.toString(productId), {
              NAME: "Patch",
              VAL: toJson(data)
            }, {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
