// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          name: js.name,
          category: js.category,
          subcategory: js.subcategory,
          pricePerMonth: js.pricePerMonth,
          providerName: js.providerName,
          providerLogo: js.providerLogo,
          qty: js.qty,
          startDate: $$Date.Naive.fromString(js.startDate),
          endDate: $$Date.Naive.fromString(js.endDate),
          isExpired: js.isExpired
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              category: field.required("category", Json_Decode$JsonCombinators.string),
              subcategory: field.required("subcategory", Json_Decode$JsonCombinators.string),
              pricePerMonth: field.required("pricePerMonth", Json_Decode$JsonCombinators.$$float),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.string),
              providerLogo: field.required("providerLogo", Json_Decode$JsonCombinators.string),
              qty: field.required("qty", Json_Decode$JsonCombinators.$$int),
              startDate: field.required("startDate", $$Date.Naive.decoder),
              endDate: field.required("endDate", $$Date.Naive.decoder),
              isExpired: field.required("isExpired", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  fromJs ,
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
