// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as Provider from "../../models/Provider.res.js";
import * as ProjectProposalId from "../../models/ProjectProposalId.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var toJson = Provider.Simple.NoBidInput.toJson;

var Req = {
  toJson: toJson
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ProjectProposalId.decoder)
            };
    });

var Res = {
  decoder: decoder
};

function exec(projectId, input) {
  var url = "/projects/" + (projectId + "/proposals/no_bid");
  return Rest.$$fetch(url, {
              NAME: "Post",
              VAL: toJson(input)
            }, {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
