// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../libs/Date.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as OrderStatus from "./OrderStatus.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          category: js.category,
          name: js.name,
          orderedAt: $$Date.Naive.fromString(js.orderedAt),
          pricePerMonth: js.pricePerMonth,
          subcategory: js.subcategory,
          quantity: js.quantity,
          status: OrderStatus.fromString(js.status),
          url: js.url
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              category: field.required("category", Json_Decode$JsonCombinators.string),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              orderedAt: field.required("orderedAt", $$Date.Naive.decoder),
              pricePerMonth: field.required("pricePerMonth", Json_Decode$JsonCombinators.$$float),
              subcategory: field.required("subcategory", Json_Decode$JsonCombinators.string),
              quantity: field.required("quantity", Json_Decode$JsonCombinators.$$int),
              status: field.required("status", OrderStatus.decoder),
              url: field.required("url", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  fromJs ,
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
