// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as ProjectProposal from "../../models/ProjectProposal.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as UserProjectProposals_Filter_Data from "../../bundles/user-dashboard/projects/user-project-proposals/UserProjectProposals_Filter_Data.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              proposals: field.required("proposals", Json_Decode$JsonCombinators.array(ProjectProposal.decoder)),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              totalProposals: field.required("totalProposals", Json_Decode$JsonCombinators.$$int)
            };
    });

var Res = {
  decoder: decoder
};

function queryString(pageNum, filters) {
  return "page=" + (String(pageNum) + ("&" + UserProjectProposals_Filter_Data.Input.toQueryString(filters)));
}

function exec(projectId, pageNum, filters) {
  var url = "/projects/" + (projectId + ("/proposals?" + queryString(pageNum, filters)));
  return Rest.$$fetch(url, "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Res ,
  queryString ,
  exec ,
}
/* decoder Not a pure module */
