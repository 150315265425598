// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as Qs from "qs";
import * as Rest from "../Rest.res.js";
import * as Provider from "../../models/Provider.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

function toString$1(x) {
  return "name";
}

var SortBy = {
  toString: toString$1
};

function toJson(req) {
  var match = req.sortBy;
  return Json_Encode$JsonCombinators.object([
              [
                "page",
                req.page
              ],
              [
                "name",
                req.name
              ],
              [
                "sort_by",
                "name"
              ],
              [
                "sort_direction",
                toString(match.VAL)
              ],
              [
                "directory_services",
                ID.toJsonArray(req.directoryServices)
              ],
              [
                "permission_type_ids",
                ID.toJsonArray(req.permissionTypes)
              ],
              [
                "status_type_ids",
                ID.toJsonArray(req.statusTypes)
              ],
              [
                "view_all",
                req.viewAll
              ],
              [
                "service_category",
                req.serviceCategory
              ]
            ]);
}

function toQueryString(req) {
  return Qs.stringify(toJson(req), {
              arrayFormat: "brackets"
            });
}

var Req = {
  SortDirection: SortDirection,
  SortBy: SortBy,
  toJson: toJson,
  toQueryString: toQueryString
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              providers: field.required("providers", Json_Decode$JsonCombinators.array(Provider.decoder)),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              offset: field.required("offset", Json_Decode$JsonCombinators.$$int),
              totalProviders: field.required("totalProviders", Json_Decode$JsonCombinators.$$int),
              serviceCategory: field.required("serviceCategory", Json_Decode$JsonCombinators.string)
            };
    });

var Res = {
  decoder: decoder
};

function exec(pageNum, name, sortBy, directoryServices, permissionTypes, statusTypes, viewAll, serviceCategory) {
  var req = {
    page: pageNum,
    name: name,
    sortBy: sortBy,
    directoryServices: directoryServices,
    permissionTypes: permissionTypes,
    statusTypes: statusTypes,
    viewAll: viewAll,
    serviceCategory: serviceCategory
  };
  var url = "/providers?" + toQueryString(req);
  return Rest.$$fetch(url, "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
