import { isAfter } from 'date-fns';
import * as errorTracker from 'Utils/errorTracker';

const isEmpty = value => !value || value === '' || value === undefined || value === null;

const checkEmpty = value => value && value.trim() === '';

const validEmail = email => email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i.test(email);

const isNumeric = value => Number.isNaN(Number(value));

const isPhoneNumber = value => value && !/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/i.test(value);

export const isValidYear = value => value && isAfter(new Date(parseInt(value, 10), 0, 1), new Date());

const minLength = (value, strLength) => value && value.length < strLength;

const yearLength = value => value && !/^\d{4}$/i.test(value);

const matchPassword = (confirmPassword, passwordVal) => confirmPassword !== passwordVal;

const isGreaterThan = (valueOne, valueTwo) => Number(valueOne) > Number(valueTwo);

export const formErrorsValidations = (values, requiredFields) => {
  const errors = {};
  requiredFields.forEach(field => {
    const validationArray = field.validation;
    Object.keys(validationArray).forEach(key => {
      const fieldValue =
        values[field.id] && (field.type === 'text' || field.type === 'number')
          ? values[field.id].trim()
          : values[field.id];
      const { message, rule } = validationArray[key];

      switch (rule) {
        case 'required': {
          if (isEmpty(fieldValue)) {
            errors[field.id] = message;
          }
          break;
        }

        case 'empty': {
          if (checkEmpty(fieldValue)) {
            errors[field.id] = message;
          }
          break;
        }

        case 'email': {
          if (validEmail(fieldValue)) {
            errors[field.id] = message;
          }
          break;
        }

        case 'number': {
          if (isNumeric(fieldValue)) {
            errors[field.id] = message;
          }
          break;
        }

        case 'phone': {
          if (isPhoneNumber(fieldValue)) {
            errors[field.id] = message;
          }
          break;
        }

        case 'year': {
          if (isValidYear(fieldValue)) {
            errors[field.id] = message;
          }
          break;
        }

        case 'minlength': {
          if (minLength(fieldValue, validationArray[key].length)) {
            errors[field.id] = message;
          }
          break;
        }

        case 'yearLength': {
          if (yearLength(fieldValue)) {
            errors[field.id] = message;
          }
          break;
        }

        case 'matchPassword': {
          if (
            values &&
            values[validationArray[key].matchWithField] &&
            matchPassword(fieldValue, values[validationArray[key].matchWithField])
          ) {
            errors[field.id] = message;
          }
          break;
        }

        case 'isGreaterThan': {
          if (
            values &&
            values[validationArray[key].comparisonField] &&
            isGreaterThan(fieldValue, values[validationArray[key].comparisonField])
          ) {
            errors[field.id] = message;
          }
          break;
        }

        default: {
          errorTracker.captureMessage(`Unknown validation rule: ${rule}`);
          break;
        }
      }
    });
  });
  return errors;
};

export const getProperDialCode = code => {
  if (code !== null && code !== 'null') {
    return code;
  }
  return '+1';
};
