// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../../../../libs/Date.res.js";
import * as React from "react";
import * as Project from "../../../../models/Project.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import IsPast from "date-fns/isPast";
import IsToday from "date-fns/isToday";
import * as NonSecure from "nanoid/non-secure";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function validateFieldOfCollection2OnChangeWithValidator(input, index, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || fieldStatus._1 !== "Shown") {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 2;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 1;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        return setStatus({
                    TAG: "Dirty",
                    _0: validator.validate(input, index),
                    _1: "Hidden"
                  });
    case 2 :
        var result = validator.validate(input, index);
        if (result.TAG === "Ok") {
          return setStatus({
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return setStatus({
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
    case 3 :
        return setStatus({
                    TAG: "Dirty",
                    _0: validator.validate(input, index),
                    _1: "Shown"
                  });
    
  }
}

function validateFieldOfCollection2OnBlurWithValidator(input, index, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(setStatus({
                        TAG: "Dirty",
                        _0: validator.validate(input, index),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      return Caml_option.some(setStatus({
                      TAG: "Dirty",
                      _0: validator.validate(input, index),
                      _1: "Shown"
                    }));
    }
    
  }
  
}

var validators_validateRegion = {
  strategy: "OnFirstChange",
  validate: (function (param) {
      return {
              TAG: "Ok",
              _0: param.validateRegion
            };
    })
};

var validators_geographicalRegion = {
  strategy: "OnFirstBlur",
  validate: (function (param) {
      var geographicalRegion = param.geographicalRegion;
      var documents = param.documents;
      if (param.validateRegion) {
        if (documents.length === 0 && geographicalRegion === "") {
          return {
                  TAG: "Ok",
                  _0: geographicalRegion
                };
        }
        if (geographicalRegion === "") {
          return {
                  TAG: "Error",
                  _0: "Geographical Region is required"
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: geographicalRegion
                };
        }
      }
      if (documents.length === 0 && geographicalRegion === "") {
        return {
                TAG: "Error",
                _0: "Geographical Region is required"
              };
      }
      if (geographicalRegion === "") {
        return {
                TAG: "Error",
                _0: "Geographical Region is required"
              };
      } else {
        return {
                TAG: "Ok",
                _0: geographicalRegion
              };
      }
    })
};

var validators_deletedDocumentIds = {
  strategy: "OnFirstBlur",
  validate: (function (param) {
      return {
              TAG: "Ok",
              _0: param.deletedDocumentIds
            };
    })
};

var validators_documents = {
  strategy: "OnFirstBlur",
  validate: (function (param) {
      return {
              TAG: "Ok",
              _0: param.documents
            };
    })
};

var validators_configurations = {
  collection: (function (param) {
      if (param.configurations.length !== 0) {
        return {
                TAG: "Ok",
                _0: undefined
              };
      } else {
        return {
                TAG: "Error",
                _0: "At least one configuration is required"
              };
      }
    }),
  fields: {
    startDate: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var date = param.configurations[at].startDate;
          if (date === undefined) {
            return {
                    TAG: "Error",
                    _0: "Start Date is required"
                  };
          }
          var date$1 = Caml_option.valFromOption(date);
          if (IsPast(date$1) || IsToday(date$1)) {
            return {
                    TAG: "Error",
                    _0: "Start Date can't be in the past"
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: date$1
                  };
          }
        })
    },
    contractLength: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var cl = param.configurations[at].contractLength;
          if (cl !== undefined) {
            return {
                    TAG: "Ok",
                    _0: cl.value
                  };
          } else {
            return {
                    TAG: "Error",
                    _0: "Contract length is required"
                  };
          }
        })
    },
    streetAddress: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].streetAddress;
          if (x === "") {
            return {
                    TAG: "Error",
                    _0: "Street Address is required"
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    buildingOrFloor: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].buildingOrFloor;
          if (x === "") {
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    suite: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].suite;
          if (x === "") {
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    cityOrTown: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].cityOrTown;
          if (x === "") {
            return {
                    TAG: "Error",
                    _0: "City/Town is required"
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    subLocality: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].subLocality;
          if (x === "") {
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    stateOrProvince: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].stateOrProvince;
          if (x === "") {
            return {
                    TAG: "Error",
                    _0: "State/Province is required"
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    country: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].country;
          if (x === "") {
            return {
                    TAG: "Error",
                    _0: "Country is required"
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    postalCode: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].postalCode;
          if (x === "") {
            return {
                    TAG: "Error",
                    _0: "Postal Code is required"
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    prefix: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].prefix;
          if (x === "") {
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    additionalLocationDetails: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].additionalLocationDetails;
          if (x === "") {
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    privateLineFeatures: {
      strategy: "OnFirstChange",
      validate: (function (param, at) {
          return {
                  TAG: "Ok",
                  _0: Belt_SetString.toArray(param.configurations[at].privateLineFeatures)
                };
        })
    },
    accessType: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].accessType;
          if (x === "") {
            return {
                    TAG: "Error",
                    _0: "Access Type is required"
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    portSpeed: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].portSpeed;
          if (x === "") {
            return {
                    TAG: "Error",
                    _0: "Port Speed is required"
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    locations: {
      collection: (function (param, at) {
          var match = param.configurations[at].locations;
          if (match.length !== 0) {
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          } else {
            return {
                    TAG: "Error",
                    _0: "At least one location is required"
                  };
          }
        }),
      fields: {
        streetAddress: {
          strategy: "OnFirstBlur",
          validate: (function (param, param$1) {
              var x = param.configurations[param$1[0]].locations[param$1[1]].streetAddress;
              if (x === "") {
                return {
                        TAG: "Error",
                        _0: "Street Address is required"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        buildingOrFloor: {
          strategy: "OnFirstBlur",
          validate: (function (param, param$1) {
              var x = param.configurations[param$1[0]].locations[param$1[1]].buildingOrFloor;
              if (x === "") {
                return {
                        TAG: "Ok",
                        _0: undefined
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        suite: {
          strategy: "OnFirstBlur",
          validate: (function (param, param$1) {
              var x = param.configurations[param$1[0]].locations[param$1[1]].suite;
              if (x === "") {
                return {
                        TAG: "Ok",
                        _0: undefined
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        cityOrTown: {
          strategy: "OnFirstBlur",
          validate: (function (param, param$1) {
              var x = param.configurations[param$1[0]].locations[param$1[1]].cityOrTown;
              if (x === "") {
                return {
                        TAG: "Error",
                        _0: "City/Town is required"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        subLocality: {
          strategy: "OnFirstBlur",
          validate: (function (param, param$1) {
              var x = param.configurations[param$1[0]].locations[param$1[1]].subLocality;
              if (x === "") {
                return {
                        TAG: "Ok",
                        _0: undefined
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        stateOrProvince: {
          strategy: "OnFirstBlur",
          validate: (function (param, param$1) {
              var x = param.configurations[param$1[0]].locations[param$1[1]].stateOrProvince;
              if (x === "") {
                return {
                        TAG: "Error",
                        _0: "State/Province is required"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        country: {
          strategy: "OnFirstBlur",
          validate: (function (param, param$1) {
              var x = param.configurations[param$1[0]].locations[param$1[1]].country;
              if (x === "") {
                return {
                        TAG: "Error",
                        _0: "Country is required"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        postalCode: {
          strategy: "OnFirstBlur",
          validate: (function (param, param$1) {
              var x = param.configurations[param$1[0]].locations[param$1[1]].postalCode;
              if (x === "") {
                return {
                        TAG: "Error",
                        _0: "Postal Code is required"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        prefix: {
          strategy: "OnFirstBlur",
          validate: (function (param, param$1) {
              var x = param.configurations[param$1[0]].locations[param$1[1]].prefix;
              if (x === "") {
                return {
                        TAG: "Ok",
                        _0: undefined
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        additionalLocationDetails: {
          strategy: "OnFirstBlur",
          validate: (function (param, param$1) {
              var x = param.configurations[param$1[0]].locations[param$1[1]].additionalLocationDetails;
              if (x === "") {
                return {
                        TAG: "Ok",
                        _0: undefined
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        privateLineFeatures: {
          strategy: "OnFirstChange",
          validate: (function (param, param$1) {
              return {
                      TAG: "Ok",
                      _0: Belt_SetString.toArray(param.configurations[param$1[0]].locations[param$1[1]].privateLineFeatures)
                    };
            })
        },
        accessType: {
          strategy: "OnFirstBlur",
          validate: (function (param, param$1) {
              var x = param.configurations[param$1[0]].locations[param$1[1]].accessType;
              if (x === "") {
                return {
                        TAG: "Error",
                        _0: "Access Type is required"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        portSpeed: {
          strategy: "OnFirstBlur",
          validate: (function (param, param$1) {
              var x = param.configurations[param$1[0]].locations[param$1[1]].portSpeed;
              if (x === "") {
                return {
                        TAG: "Error",
                        _0: "Port Speed is required"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        }
      }
    }
  }
};

var validators = {
  validateRegion: validators_validateRegion,
  geographicalRegion: validators_geographicalRegion,
  deletedDocumentIds: validators_deletedDocumentIds,
  documents: validators_documents,
  configurations: validators_configurations
};

function initialFieldsStatuses(input) {
  return {
          validateRegion: "Pristine",
          geographicalRegion: "Pristine",
          deletedDocumentIds: "Pristine",
          documents: "Pristine",
          configurations: Belt_Array.map(input.configurations, (function (configuration) {
                  return {
                          startDate: "Pristine",
                          contractLength: "Pristine",
                          streetAddress: "Pristine",
                          buildingOrFloor: "Pristine",
                          suite: "Pristine",
                          cityOrTown: "Pristine",
                          subLocality: "Pristine",
                          stateOrProvince: "Pristine",
                          country: "Pristine",
                          postalCode: "Pristine",
                          prefix: "Pristine",
                          additionalLocationDetails: "Pristine",
                          privateLineFeatures: "Pristine",
                          accessType: "Pristine",
                          portSpeed: "Pristine",
                          locations: Belt_Array.map(configuration.locations, (function (_location) {
                                  return {
                                          streetAddress: "Pristine",
                                          buildingOrFloor: "Pristine",
                                          suite: "Pristine",
                                          cityOrTown: "Pristine",
                                          subLocality: "Pristine",
                                          stateOrProvince: "Pristine",
                                          country: "Pristine",
                                          postalCode: "Pristine",
                                          prefix: "Pristine",
                                          additionalLocationDetails: "Pristine",
                                          privateLineFeatures: "Pristine",
                                          accessType: "Pristine",
                                          portSpeed: "Pristine"
                                        };
                                }))
                        };
                }))
        };
}

function initialCollectionsStatuses(input) {
  return {
          configurations: {
            self: undefined,
            locations: Belt_Array.map(input.configurations, (function (param) {
                    
                  }))
          }
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: initialCollectionsStatuses(input),
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.validateRegion;
  var tmp;
  tmp = typeof match !== "object" ? validators.validateRegion.validate(input) : match._0;
  var match$1 = fieldsStatuses.geographicalRegion;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.geographicalRegion.validate(input) : match$1._0;
  var match$2 = fieldsStatuses.deletedDocumentIds;
  var tmp$2;
  tmp$2 = typeof match$2 !== "object" ? validators.deletedDocumentIds.validate(input) : match$2._0;
  var match$3 = fieldsStatuses.documents;
  var tmp$3;
  tmp$3 = typeof match$3 !== "object" ? validators.documents.validate(input) : match$3._0;
  var match_0 = validators.configurations.collection(input);
  var match_1 = Belt_Array.reduceWithIndex(fieldsStatuses.configurations, [
        {
          TAG: "Ok",
          _0: []
        },
        [],
        []
      ], (function (param, fieldStatus, configurationIndex) {
          var configurationsLocationCollectionsStatuses = param[2];
          var fieldStatuses = param[1];
          var output = param[0];
          var match = fieldStatus.startDate;
          var tmp;
          tmp = typeof match !== "object" ? validators.configurations.fields.startDate.validate(input, configurationIndex) : match._0;
          var match$1 = fieldStatus.contractLength;
          var tmp$1;
          tmp$1 = typeof match$1 !== "object" ? validators.configurations.fields.contractLength.validate(input, configurationIndex) : match$1._0;
          var match$2 = fieldStatus.streetAddress;
          var tmp$2;
          tmp$2 = typeof match$2 !== "object" ? validators.configurations.fields.streetAddress.validate(input, configurationIndex) : match$2._0;
          var match$3 = fieldStatus.buildingOrFloor;
          var tmp$3;
          tmp$3 = typeof match$3 !== "object" ? validators.configurations.fields.buildingOrFloor.validate(input, configurationIndex) : match$3._0;
          var match$4 = fieldStatus.suite;
          var tmp$4;
          tmp$4 = typeof match$4 !== "object" ? validators.configurations.fields.suite.validate(input, configurationIndex) : match$4._0;
          var match$5 = fieldStatus.cityOrTown;
          var tmp$5;
          tmp$5 = typeof match$5 !== "object" ? validators.configurations.fields.cityOrTown.validate(input, configurationIndex) : match$5._0;
          var match$6 = fieldStatus.subLocality;
          var tmp$6;
          tmp$6 = typeof match$6 !== "object" ? validators.configurations.fields.subLocality.validate(input, configurationIndex) : match$6._0;
          var match$7 = fieldStatus.stateOrProvince;
          var tmp$7;
          tmp$7 = typeof match$7 !== "object" ? validators.configurations.fields.stateOrProvince.validate(input, configurationIndex) : match$7._0;
          var match$8 = fieldStatus.country;
          var tmp$8;
          tmp$8 = typeof match$8 !== "object" ? validators.configurations.fields.country.validate(input, configurationIndex) : match$8._0;
          var match$9 = fieldStatus.postalCode;
          var tmp$9;
          tmp$9 = typeof match$9 !== "object" ? validators.configurations.fields.postalCode.validate(input, configurationIndex) : match$9._0;
          var match$10 = fieldStatus.prefix;
          var tmp$10;
          tmp$10 = typeof match$10 !== "object" ? validators.configurations.fields.prefix.validate(input, configurationIndex) : match$10._0;
          var match$11 = fieldStatus.additionalLocationDetails;
          var tmp$11;
          tmp$11 = typeof match$11 !== "object" ? validators.configurations.fields.additionalLocationDetails.validate(input, configurationIndex) : match$11._0;
          var match$12 = fieldStatus.privateLineFeatures;
          var tmp$12;
          tmp$12 = typeof match$12 !== "object" ? validators.configurations.fields.privateLineFeatures.validate(input, configurationIndex) : match$12._0;
          var match$13 = fieldStatus.accessType;
          var tmp$13;
          tmp$13 = typeof match$13 !== "object" ? validators.configurations.fields.accessType.validate(input, configurationIndex) : match$13._0;
          var match$14 = fieldStatus.portSpeed;
          var tmp$14;
          tmp$14 = typeof match$14 !== "object" ? validators.configurations.fields.portSpeed.validate(input, configurationIndex) : match$14._0;
          var match_0 = validators.configurations.fields.locations.collection(input, configurationIndex);
          var match_1 = Belt_Array.reduceWithIndex(fieldStatus.locations, [
                {
                  TAG: "Ok",
                  _0: []
                },
                []
              ], (function (param, fieldStatus, locationIndex) {
                  var statuses = param[1];
                  var output = param[0];
                  var match = fieldStatus.streetAddress;
                  var tmp;
                  tmp = typeof match !== "object" ? validators.configurations.fields.locations.fields.streetAddress.validate(input, [
                          configurationIndex,
                          locationIndex
                        ]) : match._0;
                  var match$1 = fieldStatus.buildingOrFloor;
                  var tmp$1;
                  tmp$1 = typeof match$1 !== "object" ? validators.configurations.fields.locations.fields.buildingOrFloor.validate(input, [
                          configurationIndex,
                          locationIndex
                        ]) : match$1._0;
                  var match$2 = fieldStatus.suite;
                  var tmp$2;
                  tmp$2 = typeof match$2 !== "object" ? validators.configurations.fields.locations.fields.suite.validate(input, [
                          configurationIndex,
                          locationIndex
                        ]) : match$2._0;
                  var match$3 = fieldStatus.cityOrTown;
                  var tmp$3;
                  tmp$3 = typeof match$3 !== "object" ? validators.configurations.fields.locations.fields.cityOrTown.validate(input, [
                          configurationIndex,
                          locationIndex
                        ]) : match$3._0;
                  var match$4 = fieldStatus.subLocality;
                  var tmp$4;
                  tmp$4 = typeof match$4 !== "object" ? validators.configurations.fields.locations.fields.subLocality.validate(input, [
                          configurationIndex,
                          locationIndex
                        ]) : match$4._0;
                  var match$5 = fieldStatus.stateOrProvince;
                  var tmp$5;
                  tmp$5 = typeof match$5 !== "object" ? validators.configurations.fields.locations.fields.stateOrProvince.validate(input, [
                          configurationIndex,
                          locationIndex
                        ]) : match$5._0;
                  var match$6 = fieldStatus.country;
                  var tmp$6;
                  tmp$6 = typeof match$6 !== "object" ? validators.configurations.fields.locations.fields.country.validate(input, [
                          configurationIndex,
                          locationIndex
                        ]) : match$6._0;
                  var match$7 = fieldStatus.postalCode;
                  var tmp$7;
                  tmp$7 = typeof match$7 !== "object" ? validators.configurations.fields.locations.fields.postalCode.validate(input, [
                          configurationIndex,
                          locationIndex
                        ]) : match$7._0;
                  var match$8 = fieldStatus.prefix;
                  var tmp$8;
                  tmp$8 = typeof match$8 !== "object" ? validators.configurations.fields.locations.fields.prefix.validate(input, [
                          configurationIndex,
                          locationIndex
                        ]) : match$8._0;
                  var match$9 = fieldStatus.additionalLocationDetails;
                  var tmp$9;
                  tmp$9 = typeof match$9 !== "object" ? validators.configurations.fields.locations.fields.additionalLocationDetails.validate(input, [
                          configurationIndex,
                          locationIndex
                        ]) : match$9._0;
                  var match$10 = fieldStatus.privateLineFeatures;
                  var tmp$10;
                  tmp$10 = typeof match$10 !== "object" ? validators.configurations.fields.locations.fields.privateLineFeatures.validate(input, [
                          configurationIndex,
                          locationIndex
                        ]) : match$10._0;
                  var match$11 = fieldStatus.accessType;
                  var tmp$11;
                  tmp$11 = typeof match$11 !== "object" ? validators.configurations.fields.locations.fields.accessType.validate(input, [
                          configurationIndex,
                          locationIndex
                        ]) : match$11._0;
                  var match$12 = fieldStatus.portSpeed;
                  var tmp$12;
                  tmp$12 = typeof match$12 !== "object" ? validators.configurations.fields.locations.fields.portSpeed.validate(input, [
                          configurationIndex,
                          locationIndex
                        ]) : match$12._0;
                  if (output.TAG === "Ok") {
                    var streetAddressResult = tmp;
                    var output$1 = output._0;
                    if (streetAddressResult.TAG === "Ok") {
                      var buildingOrFloorResult = tmp$1;
                      if (buildingOrFloorResult.TAG === "Ok") {
                        var suiteResult = tmp$2;
                        if (suiteResult.TAG === "Ok") {
                          var cityOrTownResult = tmp$3;
                          if (cityOrTownResult.TAG === "Ok") {
                            var subLocalityResult = tmp$4;
                            if (subLocalityResult.TAG === "Ok") {
                              var stateOrProvinceResult = tmp$5;
                              if (stateOrProvinceResult.TAG === "Ok") {
                                var countryResult = tmp$6;
                                if (countryResult.TAG === "Ok") {
                                  var postalCodeResult = tmp$7;
                                  if (postalCodeResult.TAG === "Ok") {
                                    var prefixResult = tmp$8;
                                    if (prefixResult.TAG === "Ok") {
                                      var additionalLocationDetailsResult = tmp$9;
                                      if (additionalLocationDetailsResult.TAG === "Ok") {
                                        var privateLineFeaturesResult = tmp$10;
                                        if (privateLineFeaturesResult.TAG === "Ok") {
                                          var accessTypeResult = tmp$11;
                                          if (accessTypeResult.TAG === "Ok") {
                                            var portSpeedResult = tmp$12;
                                            if (portSpeedResult.TAG === "Ok") {
                                              output$1.push({
                                                    streetAddress: streetAddressResult._0,
                                                    buildingOrFloor: buildingOrFloorResult._0,
                                                    suite: suiteResult._0,
                                                    cityOrTown: cityOrTownResult._0,
                                                    subLocality: subLocalityResult._0,
                                                    stateOrProvince: stateOrProvinceResult._0,
                                                    country: countryResult._0,
                                                    postalCode: postalCodeResult._0,
                                                    prefix: prefixResult._0,
                                                    additionalLocationDetails: additionalLocationDetailsResult._0,
                                                    privateLineFeatures: privateLineFeaturesResult._0,
                                                    accessType: accessTypeResult._0,
                                                    portSpeed: portSpeedResult._0
                                                  });
                                              statuses.push({
                                                    streetAddress: {
                                                      TAG: "Dirty",
                                                      _0: streetAddressResult,
                                                      _1: "Shown"
                                                    },
                                                    buildingOrFloor: {
                                                      TAG: "Dirty",
                                                      _0: buildingOrFloorResult,
                                                      _1: "Shown"
                                                    },
                                                    suite: {
                                                      TAG: "Dirty",
                                                      _0: suiteResult,
                                                      _1: "Shown"
                                                    },
                                                    cityOrTown: {
                                                      TAG: "Dirty",
                                                      _0: cityOrTownResult,
                                                      _1: "Shown"
                                                    },
                                                    subLocality: {
                                                      TAG: "Dirty",
                                                      _0: subLocalityResult,
                                                      _1: "Shown"
                                                    },
                                                    stateOrProvince: {
                                                      TAG: "Dirty",
                                                      _0: stateOrProvinceResult,
                                                      _1: "Shown"
                                                    },
                                                    country: {
                                                      TAG: "Dirty",
                                                      _0: countryResult,
                                                      _1: "Shown"
                                                    },
                                                    postalCode: {
                                                      TAG: "Dirty",
                                                      _0: postalCodeResult,
                                                      _1: "Shown"
                                                    },
                                                    prefix: {
                                                      TAG: "Dirty",
                                                      _0: prefixResult,
                                                      _1: "Shown"
                                                    },
                                                    additionalLocationDetails: {
                                                      TAG: "Dirty",
                                                      _0: additionalLocationDetailsResult,
                                                      _1: "Shown"
                                                    },
                                                    privateLineFeatures: {
                                                      TAG: "Dirty",
                                                      _0: privateLineFeaturesResult,
                                                      _1: "Shown"
                                                    },
                                                    accessType: {
                                                      TAG: "Dirty",
                                                      _0: accessTypeResult,
                                                      _1: "Shown"
                                                    },
                                                    portSpeed: {
                                                      TAG: "Dirty",
                                                      _0: portSpeedResult,
                                                      _1: "Shown"
                                                    }
                                                  });
                                              return [
                                                      {
                                                        TAG: "Ok",
                                                        _0: output$1
                                                      },
                                                      statuses
                                                    ];
                                            }
                                            
                                          }
                                          
                                        }
                                        
                                      }
                                      
                                    }
                                    
                                  }
                                  
                                }
                                
                              }
                              
                            }
                            
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  statuses.push({
                        streetAddress: {
                          TAG: "Dirty",
                          _0: tmp,
                          _1: "Shown"
                        },
                        buildingOrFloor: {
                          TAG: "Dirty",
                          _0: tmp$1,
                          _1: "Shown"
                        },
                        suite: {
                          TAG: "Dirty",
                          _0: tmp$2,
                          _1: "Shown"
                        },
                        cityOrTown: {
                          TAG: "Dirty",
                          _0: tmp$3,
                          _1: "Shown"
                        },
                        subLocality: {
                          TAG: "Dirty",
                          _0: tmp$4,
                          _1: "Shown"
                        },
                        stateOrProvince: {
                          TAG: "Dirty",
                          _0: tmp$5,
                          _1: "Shown"
                        },
                        country: {
                          TAG: "Dirty",
                          _0: tmp$6,
                          _1: "Shown"
                        },
                        postalCode: {
                          TAG: "Dirty",
                          _0: tmp$7,
                          _1: "Shown"
                        },
                        prefix: {
                          TAG: "Dirty",
                          _0: tmp$8,
                          _1: "Shown"
                        },
                        additionalLocationDetails: {
                          TAG: "Dirty",
                          _0: tmp$9,
                          _1: "Shown"
                        },
                        privateLineFeatures: {
                          TAG: "Dirty",
                          _0: tmp$10,
                          _1: "Shown"
                        },
                        accessType: {
                          TAG: "Dirty",
                          _0: tmp$11,
                          _1: "Shown"
                        },
                        portSpeed: {
                          TAG: "Dirty",
                          _0: tmp$12,
                          _1: "Shown"
                        }
                      });
                  return [
                          {
                            TAG: "Error",
                            _0: undefined
                          },
                          statuses
                        ];
                }));
          if (output.TAG === "Ok") {
            var startDateResult = tmp;
            var output$1 = output._0;
            if (startDateResult.TAG === "Ok") {
              var contractLengthResult = tmp$1;
              if (contractLengthResult.TAG === "Ok") {
                var streetAddressResult = tmp$2;
                if (streetAddressResult.TAG === "Ok") {
                  var buildingOrFloorResult = tmp$3;
                  if (buildingOrFloorResult.TAG === "Ok") {
                    var suiteResult = tmp$4;
                    if (suiteResult.TAG === "Ok") {
                      var cityOrTownResult = tmp$5;
                      if (cityOrTownResult.TAG === "Ok") {
                        var subLocalityResult = tmp$6;
                        if (subLocalityResult.TAG === "Ok") {
                          var stateOrProvinceResult = tmp$7;
                          if (stateOrProvinceResult.TAG === "Ok") {
                            var countryResult = tmp$8;
                            if (countryResult.TAG === "Ok") {
                              var postalCodeResult = tmp$9;
                              if (postalCodeResult.TAG === "Ok") {
                                var prefixResult = tmp$10;
                                if (prefixResult.TAG === "Ok") {
                                  var additionalLocationDetailsResult = tmp$11;
                                  if (additionalLocationDetailsResult.TAG === "Ok") {
                                    var privateLineFeaturesResult = tmp$12;
                                    if (privateLineFeaturesResult.TAG === "Ok") {
                                      var accessTypeResult = tmp$13;
                                      if (accessTypeResult.TAG === "Ok") {
                                        var portSpeedResult = tmp$14;
                                        if (portSpeedResult.TAG === "Ok") {
                                          var configurationLocationsCollectionResult = match_0;
                                          if (configurationLocationsCollectionResult.TAG === "Ok") {
                                            var match$15 = match_1;
                                            var locations = match$15[0];
                                            if (locations.TAG === "Ok") {
                                              output$1.push({
                                                    startDate: startDateResult._0,
                                                    contractLength: contractLengthResult._0,
                                                    streetAddress: streetAddressResult._0,
                                                    buildingOrFloor: buildingOrFloorResult._0,
                                                    suite: suiteResult._0,
                                                    cityOrTown: cityOrTownResult._0,
                                                    subLocality: subLocalityResult._0,
                                                    stateOrProvince: stateOrProvinceResult._0,
                                                    country: countryResult._0,
                                                    postalCode: postalCodeResult._0,
                                                    prefix: prefixResult._0,
                                                    additionalLocationDetails: additionalLocationDetailsResult._0,
                                                    privateLineFeatures: privateLineFeaturesResult._0,
                                                    accessType: accessTypeResult._0,
                                                    portSpeed: portSpeedResult._0,
                                                    locations: locations._0
                                                  });
                                              fieldStatuses.push({
                                                    startDate: {
                                                      TAG: "Dirty",
                                                      _0: startDateResult,
                                                      _1: "Shown"
                                                    },
                                                    contractLength: {
                                                      TAG: "Dirty",
                                                      _0: contractLengthResult,
                                                      _1: "Shown"
                                                    },
                                                    streetAddress: {
                                                      TAG: "Dirty",
                                                      _0: streetAddressResult,
                                                      _1: "Shown"
                                                    },
                                                    buildingOrFloor: {
                                                      TAG: "Dirty",
                                                      _0: buildingOrFloorResult,
                                                      _1: "Shown"
                                                    },
                                                    suite: {
                                                      TAG: "Dirty",
                                                      _0: suiteResult,
                                                      _1: "Shown"
                                                    },
                                                    cityOrTown: {
                                                      TAG: "Dirty",
                                                      _0: cityOrTownResult,
                                                      _1: "Shown"
                                                    },
                                                    subLocality: {
                                                      TAG: "Dirty",
                                                      _0: subLocalityResult,
                                                      _1: "Shown"
                                                    },
                                                    stateOrProvince: {
                                                      TAG: "Dirty",
                                                      _0: stateOrProvinceResult,
                                                      _1: "Shown"
                                                    },
                                                    country: {
                                                      TAG: "Dirty",
                                                      _0: countryResult,
                                                      _1: "Shown"
                                                    },
                                                    postalCode: {
                                                      TAG: "Dirty",
                                                      _0: postalCodeResult,
                                                      _1: "Shown"
                                                    },
                                                    prefix: {
                                                      TAG: "Dirty",
                                                      _0: prefixResult,
                                                      _1: "Shown"
                                                    },
                                                    additionalLocationDetails: {
                                                      TAG: "Dirty",
                                                      _0: additionalLocationDetailsResult,
                                                      _1: "Shown"
                                                    },
                                                    privateLineFeatures: {
                                                      TAG: "Dirty",
                                                      _0: privateLineFeaturesResult,
                                                      _1: "Shown"
                                                    },
                                                    accessType: {
                                                      TAG: "Dirty",
                                                      _0: accessTypeResult,
                                                      _1: "Shown"
                                                    },
                                                    portSpeed: {
                                                      TAG: "Dirty",
                                                      _0: portSpeedResult,
                                                      _1: "Shown"
                                                    },
                                                    locations: match$15[1]
                                                  });
                                              configurationsLocationCollectionsStatuses.push(configurationLocationsCollectionResult);
                                              return [
                                                      {
                                                        TAG: "Ok",
                                                        _0: output$1
                                                      },
                                                      fieldStatuses,
                                                      configurationsLocationCollectionsStatuses
                                                    ];
                                            }
                                            
                                          }
                                          
                                        }
                                        
                                      }
                                      
                                    }
                                    
                                  }
                                  
                                }
                                
                              }
                              
                            }
                            
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }
            
          }
          fieldStatuses.push({
                startDate: {
                  TAG: "Dirty",
                  _0: tmp,
                  _1: "Shown"
                },
                contractLength: {
                  TAG: "Dirty",
                  _0: tmp$1,
                  _1: "Shown"
                },
                streetAddress: {
                  TAG: "Dirty",
                  _0: tmp$2,
                  _1: "Shown"
                },
                buildingOrFloor: {
                  TAG: "Dirty",
                  _0: tmp$3,
                  _1: "Shown"
                },
                suite: {
                  TAG: "Dirty",
                  _0: tmp$4,
                  _1: "Shown"
                },
                cityOrTown: {
                  TAG: "Dirty",
                  _0: tmp$5,
                  _1: "Shown"
                },
                subLocality: {
                  TAG: "Dirty",
                  _0: tmp$6,
                  _1: "Shown"
                },
                stateOrProvince: {
                  TAG: "Dirty",
                  _0: tmp$7,
                  _1: "Shown"
                },
                country: {
                  TAG: "Dirty",
                  _0: tmp$8,
                  _1: "Shown"
                },
                postalCode: {
                  TAG: "Dirty",
                  _0: tmp$9,
                  _1: "Shown"
                },
                prefix: {
                  TAG: "Dirty",
                  _0: tmp$10,
                  _1: "Shown"
                },
                additionalLocationDetails: {
                  TAG: "Dirty",
                  _0: tmp$11,
                  _1: "Shown"
                },
                privateLineFeatures: {
                  TAG: "Dirty",
                  _0: tmp$12,
                  _1: "Shown"
                },
                accessType: {
                  TAG: "Dirty",
                  _0: tmp$13,
                  _1: "Shown"
                },
                portSpeed: {
                  TAG: "Dirty",
                  _0: tmp$14,
                  _1: "Shown"
                },
                locations: match_1[1]
              });
          configurationsLocationCollectionsStatuses.push(match_0);
          return [
                  {
                    TAG: "Error",
                    _0: undefined
                  },
                  fieldStatuses,
                  configurationsLocationCollectionsStatuses
                ];
        }));
  var validateRegionResult = tmp;
  var validateRegionResult$1;
  if (validateRegionResult.TAG === "Ok") {
    var geographicalRegionResult = tmp$1;
    if (geographicalRegionResult.TAG === "Ok") {
      var deletedDocumentIdsResult = tmp$2;
      if (deletedDocumentIdsResult.TAG === "Ok") {
        var documentsResult = tmp$3;
        if (documentsResult.TAG === "Ok") {
          var configurationsCollectionResult = match_0;
          if (configurationsCollectionResult.TAG === "Ok") {
            var match$4 = match_1;
            var configurations = match$4[0];
            if (configurations.TAG === "Ok") {
              return {
                      TAG: "Valid",
                      output: {
                        configurations: configurations._0,
                        documents: documentsResult._0,
                        deletedDocumentIds: deletedDocumentIdsResult._0,
                        geographicalRegion: geographicalRegionResult._0,
                        validateRegion: validateRegionResult._0
                      },
                      fieldsStatuses: {
                        validateRegion: {
                          TAG: "Dirty",
                          _0: validateRegionResult,
                          _1: "Shown"
                        },
                        geographicalRegion: {
                          TAG: "Dirty",
                          _0: geographicalRegionResult,
                          _1: "Shown"
                        },
                        deletedDocumentIds: {
                          TAG: "Dirty",
                          _0: deletedDocumentIdsResult,
                          _1: "Shown"
                        },
                        documents: {
                          TAG: "Dirty",
                          _0: documentsResult,
                          _1: "Shown"
                        },
                        configurations: match$4[1]
                      },
                      collectionsStatuses: {
                        configurations: {
                          self: configurationsCollectionResult,
                          locations: match$4[2]
                        }
                      }
                    };
            }
            validateRegionResult$1 = validateRegionResult;
          } else {
            validateRegionResult$1 = validateRegionResult;
          }
        } else {
          validateRegionResult$1 = validateRegionResult;
        }
      } else {
        validateRegionResult$1 = validateRegionResult;
      }
    } else {
      validateRegionResult$1 = validateRegionResult;
    }
  } else {
    validateRegionResult$1 = validateRegionResult;
  }
  var match$5 = match_1;
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            validateRegion: {
              TAG: "Dirty",
              _0: validateRegionResult$1,
              _1: "Shown"
            },
            geographicalRegion: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            },
            deletedDocumentIds: {
              TAG: "Dirty",
              _0: tmp$2,
              _1: "Shown"
            },
            documents: {
              TAG: "Dirty",
              _0: tmp$3,
              _1: "Shown"
            },
            configurations: match$5[1]
          },
          collectionsStatuses: {
            configurations: {
              self: match_0,
              locations: match$5[2]
            }
          }
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurValidateRegionField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.validateRegion, validators_validateRegion, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: status,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: init.configurations
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurGeographicalRegionField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.geographicalRegion, validators_geographicalRegion, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: status,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: init.configurations
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurDeletedDocumentIdsField" :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.deletedDocumentIds, validators_deletedDocumentIds, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: status,
                                  documents: init.documents,
                                  configurations: init.configurations
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurDocumentsField" :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.documents, validators_documents, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: status,
                                  configurations: init.configurations
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateValidateRegionField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.validateRegion, state.submissionStatus, validators_validateRegion, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: status,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: init.configurations
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateGeographicalRegionField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.geographicalRegion, state.submissionStatus, validators_geographicalRegion, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: status,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: init.configurations
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateDeletedDocumentIdsField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.deletedDocumentIds, state.submissionStatus, validators_deletedDocumentIds, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: status,
                                            documents: init.documents,
                                            configurations: init.configurations
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateDocumentsField" :
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.documents, state.submissionStatus, validators_documents, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: status,
                                            configurations: init.configurations
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationStartDateField" :
                  var index = action._1;
                  var nextInput$4 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$4, index, state.fieldsStatuses.configurations[index].startDate, state.submissionStatus, validators_configurations.fields.startDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index) {
                                                      return {
                                                              startDate: status,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: item.locations
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationContractLengthField" :
                  var index$1 = action._1;
                  var nextInput$5 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$5, index$1, state.fieldsStatuses.configurations[index$1].contractLength, state.submissionStatus, validators_configurations.fields.contractLength, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$1) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: status,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: item.locations
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationStreetAddressField" :
                  var index$2 = action._1;
                  var nextInput$6 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$6, index$2, state.fieldsStatuses.configurations[index$2].streetAddress, state.submissionStatus, validators_configurations.fields.streetAddress, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$2) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: status,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: item.locations
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationBuildingOrFloorField" :
                  var index$3 = action._1;
                  var nextInput$7 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$7, index$3, state.fieldsStatuses.configurations[index$3].buildingOrFloor, state.submissionStatus, validators_configurations.fields.buildingOrFloor, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$3) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: status,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: item.locations
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationSuiteField" :
                  var index$4 = action._1;
                  var nextInput$8 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$8, index$4, state.fieldsStatuses.configurations[index$4].suite, state.submissionStatus, validators_configurations.fields.suite, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$4) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: status,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: item.locations
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationCityOrTownField" :
                  var index$5 = action._1;
                  var nextInput$9 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$9, index$5, state.fieldsStatuses.configurations[index$5].cityOrTown, state.submissionStatus, validators_configurations.fields.cityOrTown, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$5) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: status,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: item.locations
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationSubLocalityField" :
                  var index$6 = action._1;
                  var nextInput$10 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$10, index$6, state.fieldsStatuses.configurations[index$6].subLocality, state.submissionStatus, validators_configurations.fields.subLocality, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$6) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: status,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: item.locations
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationStateOrProvinceField" :
                  var index$7 = action._1;
                  var nextInput$11 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$11, index$7, state.fieldsStatuses.configurations[index$7].stateOrProvince, state.submissionStatus, validators_configurations.fields.stateOrProvince, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$7) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: status,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: item.locations
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationCountryField" :
                  var index$8 = action._1;
                  var nextInput$12 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$12, index$8, state.fieldsStatuses.configurations[index$8].country, state.submissionStatus, validators_configurations.fields.country, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$8) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: status,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: item.locations
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationPostalCodeField" :
                  var index$9 = action._1;
                  var nextInput$13 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$13, index$9, state.fieldsStatuses.configurations[index$9].postalCode, state.submissionStatus, validators_configurations.fields.postalCode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$9) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: status,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: item.locations
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationPrefixField" :
                  var index$10 = action._1;
                  var nextInput$14 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$14, index$10, state.fieldsStatuses.configurations[index$10].prefix, state.submissionStatus, validators_configurations.fields.prefix, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$10) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: status,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: item.locations
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationAdditionalLocationDetailsField" :
                  var index$11 = action._1;
                  var nextInput$15 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$15, index$11, state.fieldsStatuses.configurations[index$11].additionalLocationDetails, state.submissionStatus, validators_configurations.fields.additionalLocationDetails, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$11) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: status,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: item.locations
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationPrivateLineFeaturesField" :
                  var index$12 = action._1;
                  var nextInput$16 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$16,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$16, index$12, state.fieldsStatuses.configurations[index$12].privateLineFeatures, state.submissionStatus, validators_configurations.fields.privateLineFeatures, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$12) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: status,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: item.locations
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationAccessTypeField" :
                  var index$13 = action._1;
                  var nextInput$17 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$17,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$17, index$13, state.fieldsStatuses.configurations[index$13].accessType, state.submissionStatus, validators_configurations.fields.accessType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$13) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: status,
                                                              portSpeed: item.portSpeed,
                                                              locations: item.locations
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationPortSpeedField" :
                  var index$14 = action._1;
                  var nextInput$18 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$18,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$18, index$14, state.fieldsStatuses.configurations[index$14].portSpeed, state.submissionStatus, validators_configurations.fields.portSpeed, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$14) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: status,
                                                              locations: item.locations
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationLocationStreetAddressField" :
                  var index$15 = action._1;
                  var locationIndex = index$15[1];
                  var configurationIndex = index$15[0];
                  var nextInput$19 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$19,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$19, index$15, state.fieldsStatuses.configurations[configurationIndex].locations[locationIndex].streetAddress, state.submissionStatus, validators_configurations.fields.locations.fields.streetAddress, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                                      if (index$p === locationIndex) {
                                                                        return {
                                                                                streetAddress: status,
                                                                                buildingOrFloor: item.buildingOrFloor,
                                                                                suite: item.suite,
                                                                                cityOrTown: item.cityOrTown,
                                                                                subLocality: item.subLocality,
                                                                                stateOrProvince: item.stateOrProvince,
                                                                                country: item.country,
                                                                                postalCode: item.postalCode,
                                                                                prefix: item.prefix,
                                                                                additionalLocationDetails: item.additionalLocationDetails,
                                                                                privateLineFeatures: item.privateLineFeatures,
                                                                                accessType: item.accessType,
                                                                                portSpeed: item.portSpeed
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationLocationBuildingOrFloorField" :
                  var index$16 = action._1;
                  var locationIndex$1 = index$16[1];
                  var configurationIndex$1 = index$16[0];
                  var nextInput$20 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$20,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$20, index$16, state.fieldsStatuses.configurations[configurationIndex$1].locations[locationIndex$1].buildingOrFloor, state.submissionStatus, validators_configurations.fields.locations.fields.buildingOrFloor, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex$1) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                                      if (index$p === locationIndex$1) {
                                                                        return {
                                                                                streetAddress: item.streetAddress,
                                                                                buildingOrFloor: status,
                                                                                suite: item.suite,
                                                                                cityOrTown: item.cityOrTown,
                                                                                subLocality: item.subLocality,
                                                                                stateOrProvince: item.stateOrProvince,
                                                                                country: item.country,
                                                                                postalCode: item.postalCode,
                                                                                prefix: item.prefix,
                                                                                additionalLocationDetails: item.additionalLocationDetails,
                                                                                privateLineFeatures: item.privateLineFeatures,
                                                                                accessType: item.accessType,
                                                                                portSpeed: item.portSpeed
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationLocationSuiteField" :
                  var index$17 = action._1;
                  var locationIndex$2 = index$17[1];
                  var configurationIndex$2 = index$17[0];
                  var nextInput$21 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$21,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$21, index$17, state.fieldsStatuses.configurations[configurationIndex$2].locations[locationIndex$2].suite, state.submissionStatus, validators_configurations.fields.locations.fields.suite, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex$2) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                                      if (index$p === locationIndex$2) {
                                                                        return {
                                                                                streetAddress: item.streetAddress,
                                                                                buildingOrFloor: item.buildingOrFloor,
                                                                                suite: status,
                                                                                cityOrTown: item.cityOrTown,
                                                                                subLocality: item.subLocality,
                                                                                stateOrProvince: item.stateOrProvince,
                                                                                country: item.country,
                                                                                postalCode: item.postalCode,
                                                                                prefix: item.prefix,
                                                                                additionalLocationDetails: item.additionalLocationDetails,
                                                                                privateLineFeatures: item.privateLineFeatures,
                                                                                accessType: item.accessType,
                                                                                portSpeed: item.portSpeed
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationLocationCityOrTownField" :
                  var index$18 = action._1;
                  var locationIndex$3 = index$18[1];
                  var configurationIndex$3 = index$18[0];
                  var nextInput$22 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$22,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$22, index$18, state.fieldsStatuses.configurations[configurationIndex$3].locations[locationIndex$3].cityOrTown, state.submissionStatus, validators_configurations.fields.locations.fields.cityOrTown, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex$3) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                                      if (index$p === locationIndex$3) {
                                                                        return {
                                                                                streetAddress: item.streetAddress,
                                                                                buildingOrFloor: item.buildingOrFloor,
                                                                                suite: item.suite,
                                                                                cityOrTown: status,
                                                                                subLocality: item.subLocality,
                                                                                stateOrProvince: item.stateOrProvince,
                                                                                country: item.country,
                                                                                postalCode: item.postalCode,
                                                                                prefix: item.prefix,
                                                                                additionalLocationDetails: item.additionalLocationDetails,
                                                                                privateLineFeatures: item.privateLineFeatures,
                                                                                accessType: item.accessType,
                                                                                portSpeed: item.portSpeed
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationLocationSubLocalityField" :
                  var index$19 = action._1;
                  var locationIndex$4 = index$19[1];
                  var configurationIndex$4 = index$19[0];
                  var nextInput$23 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$23,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$23, index$19, state.fieldsStatuses.configurations[configurationIndex$4].locations[locationIndex$4].subLocality, state.submissionStatus, validators_configurations.fields.locations.fields.subLocality, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex$4) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                                      if (index$p === locationIndex$4) {
                                                                        return {
                                                                                streetAddress: item.streetAddress,
                                                                                buildingOrFloor: item.buildingOrFloor,
                                                                                suite: item.suite,
                                                                                cityOrTown: item.cityOrTown,
                                                                                subLocality: status,
                                                                                stateOrProvince: item.stateOrProvince,
                                                                                country: item.country,
                                                                                postalCode: item.postalCode,
                                                                                prefix: item.prefix,
                                                                                additionalLocationDetails: item.additionalLocationDetails,
                                                                                privateLineFeatures: item.privateLineFeatures,
                                                                                accessType: item.accessType,
                                                                                portSpeed: item.portSpeed
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationLocationStateOrProvinceField" :
                  var index$20 = action._1;
                  var locationIndex$5 = index$20[1];
                  var configurationIndex$5 = index$20[0];
                  var nextInput$24 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$24,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$24, index$20, state.fieldsStatuses.configurations[configurationIndex$5].locations[locationIndex$5].stateOrProvince, state.submissionStatus, validators_configurations.fields.locations.fields.stateOrProvince, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex$5) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                                      if (index$p === locationIndex$5) {
                                                                        return {
                                                                                streetAddress: item.streetAddress,
                                                                                buildingOrFloor: item.buildingOrFloor,
                                                                                suite: item.suite,
                                                                                cityOrTown: item.cityOrTown,
                                                                                subLocality: item.subLocality,
                                                                                stateOrProvince: status,
                                                                                country: item.country,
                                                                                postalCode: item.postalCode,
                                                                                prefix: item.prefix,
                                                                                additionalLocationDetails: item.additionalLocationDetails,
                                                                                privateLineFeatures: item.privateLineFeatures,
                                                                                accessType: item.accessType,
                                                                                portSpeed: item.portSpeed
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationLocationCountryField" :
                  var index$21 = action._1;
                  var locationIndex$6 = index$21[1];
                  var configurationIndex$6 = index$21[0];
                  var nextInput$25 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$25,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$25, index$21, state.fieldsStatuses.configurations[configurationIndex$6].locations[locationIndex$6].country, state.submissionStatus, validators_configurations.fields.locations.fields.country, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex$6) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                                      if (index$p === locationIndex$6) {
                                                                        return {
                                                                                streetAddress: item.streetAddress,
                                                                                buildingOrFloor: item.buildingOrFloor,
                                                                                suite: item.suite,
                                                                                cityOrTown: item.cityOrTown,
                                                                                subLocality: item.subLocality,
                                                                                stateOrProvince: item.stateOrProvince,
                                                                                country: status,
                                                                                postalCode: item.postalCode,
                                                                                prefix: item.prefix,
                                                                                additionalLocationDetails: item.additionalLocationDetails,
                                                                                privateLineFeatures: item.privateLineFeatures,
                                                                                accessType: item.accessType,
                                                                                portSpeed: item.portSpeed
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationLocationPostalCodeField" :
                  var index$22 = action._1;
                  var locationIndex$7 = index$22[1];
                  var configurationIndex$7 = index$22[0];
                  var nextInput$26 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$26,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$26, index$22, state.fieldsStatuses.configurations[configurationIndex$7].locations[locationIndex$7].postalCode, state.submissionStatus, validators_configurations.fields.locations.fields.postalCode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex$7) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                                      if (index$p === locationIndex$7) {
                                                                        return {
                                                                                streetAddress: item.streetAddress,
                                                                                buildingOrFloor: item.buildingOrFloor,
                                                                                suite: item.suite,
                                                                                cityOrTown: item.cityOrTown,
                                                                                subLocality: item.subLocality,
                                                                                stateOrProvince: item.stateOrProvince,
                                                                                country: item.country,
                                                                                postalCode: status,
                                                                                prefix: item.prefix,
                                                                                additionalLocationDetails: item.additionalLocationDetails,
                                                                                privateLineFeatures: item.privateLineFeatures,
                                                                                accessType: item.accessType,
                                                                                portSpeed: item.portSpeed
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationLocationPrefixField" :
                  var index$23 = action._1;
                  var locationIndex$8 = index$23[1];
                  var configurationIndex$8 = index$23[0];
                  var nextInput$27 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$27,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$27, index$23, state.fieldsStatuses.configurations[configurationIndex$8].locations[locationIndex$8].prefix, state.submissionStatus, validators_configurations.fields.locations.fields.prefix, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex$8) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                                      if (index$p === locationIndex$8) {
                                                                        return {
                                                                                streetAddress: item.streetAddress,
                                                                                buildingOrFloor: item.buildingOrFloor,
                                                                                suite: item.suite,
                                                                                cityOrTown: item.cityOrTown,
                                                                                subLocality: item.subLocality,
                                                                                stateOrProvince: item.stateOrProvince,
                                                                                country: item.country,
                                                                                postalCode: item.postalCode,
                                                                                prefix: status,
                                                                                additionalLocationDetails: item.additionalLocationDetails,
                                                                                privateLineFeatures: item.privateLineFeatures,
                                                                                accessType: item.accessType,
                                                                                portSpeed: item.portSpeed
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationLocationAdditionalLocationDetailsField" :
                  var index$24 = action._1;
                  var locationIndex$9 = index$24[1];
                  var configurationIndex$9 = index$24[0];
                  var nextInput$28 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$28,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$28, index$24, state.fieldsStatuses.configurations[configurationIndex$9].locations[locationIndex$9].additionalLocationDetails, state.submissionStatus, validators_configurations.fields.locations.fields.additionalLocationDetails, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex$9) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                                      if (index$p === locationIndex$9) {
                                                                        return {
                                                                                streetAddress: item.streetAddress,
                                                                                buildingOrFloor: item.buildingOrFloor,
                                                                                suite: item.suite,
                                                                                cityOrTown: item.cityOrTown,
                                                                                subLocality: item.subLocality,
                                                                                stateOrProvince: item.stateOrProvince,
                                                                                country: item.country,
                                                                                postalCode: item.postalCode,
                                                                                prefix: item.prefix,
                                                                                additionalLocationDetails: status,
                                                                                privateLineFeatures: item.privateLineFeatures,
                                                                                accessType: item.accessType,
                                                                                portSpeed: item.portSpeed
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationLocationPrivateLineFeaturesField" :
                  var index$25 = action._1;
                  var locationIndex$10 = index$25[1];
                  var configurationIndex$10 = index$25[0];
                  var nextInput$29 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$29,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$29, index$25, state.fieldsStatuses.configurations[configurationIndex$10].locations[locationIndex$10].privateLineFeatures, state.submissionStatus, validators_configurations.fields.locations.fields.privateLineFeatures, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex$10) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                                      if (index$p === locationIndex$10) {
                                                                        return {
                                                                                streetAddress: item.streetAddress,
                                                                                buildingOrFloor: item.buildingOrFloor,
                                                                                suite: item.suite,
                                                                                cityOrTown: item.cityOrTown,
                                                                                subLocality: item.subLocality,
                                                                                stateOrProvince: item.stateOrProvince,
                                                                                country: item.country,
                                                                                postalCode: item.postalCode,
                                                                                prefix: item.prefix,
                                                                                additionalLocationDetails: item.additionalLocationDetails,
                                                                                privateLineFeatures: status,
                                                                                accessType: item.accessType,
                                                                                portSpeed: item.portSpeed
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationLocationAccessTypeField" :
                  var index$26 = action._1;
                  var locationIndex$11 = index$26[1];
                  var configurationIndex$11 = index$26[0];
                  var nextInput$30 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$30,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$30, index$26, state.fieldsStatuses.configurations[configurationIndex$11].locations[locationIndex$11].accessType, state.submissionStatus, validators_configurations.fields.locations.fields.accessType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex$11) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                                      if (index$p === locationIndex$11) {
                                                                        return {
                                                                                streetAddress: item.streetAddress,
                                                                                buildingOrFloor: item.buildingOrFloor,
                                                                                suite: item.suite,
                                                                                cityOrTown: item.cityOrTown,
                                                                                subLocality: item.subLocality,
                                                                                stateOrProvince: item.stateOrProvince,
                                                                                country: item.country,
                                                                                postalCode: item.postalCode,
                                                                                prefix: item.prefix,
                                                                                additionalLocationDetails: item.additionalLocationDetails,
                                                                                privateLineFeatures: item.privateLineFeatures,
                                                                                accessType: status,
                                                                                portSpeed: item.portSpeed
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationLocationPortSpeedField" :
                  var index$27 = action._1;
                  var locationIndex$12 = index$27[1];
                  var configurationIndex$12 = index$27[0];
                  var nextInput$31 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$31,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$31, index$27, state.fieldsStatuses.configurations[configurationIndex$12].locations[locationIndex$12].portSpeed, state.submissionStatus, validators_configurations.fields.locations.fields.portSpeed, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex$12) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              streetAddress: item.streetAddress,
                                                              buildingOrFloor: item.buildingOrFloor,
                                                              suite: item.suite,
                                                              cityOrTown: item.cityOrTown,
                                                              subLocality: item.subLocality,
                                                              stateOrProvince: item.stateOrProvince,
                                                              country: item.country,
                                                              postalCode: item.postalCode,
                                                              prefix: item.prefix,
                                                              additionalLocationDetails: item.additionalLocationDetails,
                                                              privateLineFeatures: item.privateLineFeatures,
                                                              accessType: item.accessType,
                                                              portSpeed: item.portSpeed,
                                                              locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                                      if (index$p === locationIndex$12) {
                                                                        return {
                                                                                streetAddress: item.streetAddress,
                                                                                buildingOrFloor: item.buildingOrFloor,
                                                                                suite: item.suite,
                                                                                cityOrTown: item.cityOrTown,
                                                                                subLocality: item.subLocality,
                                                                                stateOrProvince: item.stateOrProvince,
                                                                                country: item.country,
                                                                                postalCode: item.postalCode,
                                                                                prefix: item.prefix,
                                                                                additionalLocationDetails: item.additionalLocationDetails,
                                                                                privateLineFeatures: item.privateLineFeatures,
                                                                                accessType: item.accessType,
                                                                                portSpeed: status
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "AddConfigurationLocationEntry" :
                  var at = action._1;
                  var entry = action._0;
                  var init = state.input;
                  var nextInput_configurations = Belt_Array.mapWithIndex(state.input.configurations, (function (index, configuration) {
                          if (index === at) {
                            return {
                                    key: configuration.key,
                                    startDate: configuration.startDate,
                                    contractLength: configuration.contractLength,
                                    streetAddress: configuration.streetAddress,
                                    buildingOrFloor: configuration.buildingOrFloor,
                                    suite: configuration.suite,
                                    cityOrTown: configuration.cityOrTown,
                                    subLocality: configuration.subLocality,
                                    stateOrProvince: configuration.stateOrProvince,
                                    country: configuration.country,
                                    postalCode: configuration.postalCode,
                                    prefix: configuration.prefix,
                                    additionalLocationDetails: configuration.additionalLocationDetails,
                                    privateLineFeatures: configuration.privateLineFeatures,
                                    accessType: configuration.accessType,
                                    portSpeed: configuration.portSpeed,
                                    locations: Belt_Array.concat(configuration.locations, [entry])
                                  };
                          } else {
                            return configuration;
                          }
                        }));
                  var nextInput_documents = init.documents;
                  var nextInput_deletedDocumentIds = init.deletedDocumentIds;
                  var nextInput_geographicalRegion = init.geographicalRegion;
                  var nextInput_validateRegion = init.validateRegion;
                  var nextInput$32 = {
                    configurations: nextInput_configurations,
                    documents: nextInput_documents,
                    deletedDocumentIds: nextInput_deletedDocumentIds,
                    geographicalRegion: nextInput_geographicalRegion,
                    validateRegion: nextInput_validateRegion
                  };
                  var init$1 = state.fieldsStatuses;
                  var nextFieldsStatuses_validateRegion = init$1.validateRegion;
                  var nextFieldsStatuses_geographicalRegion = init$1.geographicalRegion;
                  var nextFieldsStatuses_deletedDocumentIds = init$1.deletedDocumentIds;
                  var nextFieldsStatuses_documents = init$1.documents;
                  var nextFieldsStatuses_configurations = Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index, configuration) {
                          if (index === at) {
                            return {
                                    startDate: configuration.startDate,
                                    contractLength: configuration.contractLength,
                                    streetAddress: configuration.streetAddress,
                                    buildingOrFloor: configuration.buildingOrFloor,
                                    suite: configuration.suite,
                                    cityOrTown: configuration.cityOrTown,
                                    subLocality: configuration.subLocality,
                                    stateOrProvince: configuration.stateOrProvince,
                                    country: configuration.country,
                                    postalCode: configuration.postalCode,
                                    prefix: configuration.prefix,
                                    additionalLocationDetails: configuration.additionalLocationDetails,
                                    privateLineFeatures: configuration.privateLineFeatures,
                                    accessType: configuration.accessType,
                                    portSpeed: configuration.portSpeed,
                                    locations: Belt_Array.concat(configuration.locations, [{
                                            streetAddress: "Pristine",
                                            buildingOrFloor: "Pristine",
                                            suite: "Pristine",
                                            cityOrTown: "Pristine",
                                            subLocality: "Pristine",
                                            stateOrProvince: "Pristine",
                                            country: "Pristine",
                                            postalCode: "Pristine",
                                            prefix: "Pristine",
                                            additionalLocationDetails: "Pristine",
                                            privateLineFeatures: "Pristine",
                                            accessType: "Pristine",
                                            portSpeed: "Pristine"
                                          }])
                                  };
                          } else {
                            return configuration;
                          }
                        }));
                  var nextFieldsStatuses = {
                    validateRegion: nextFieldsStatuses_validateRegion,
                    geographicalRegion: nextFieldsStatuses_geographicalRegion,
                    deletedDocumentIds: nextFieldsStatuses_deletedDocumentIds,
                    documents: nextFieldsStatuses_documents,
                    configurations: nextFieldsStatuses_configurations
                  };
                  var nextCollectionsStatuses = {
                    configurations: {
                      self: validators_configurations.collection(nextInput$32),
                      locations: Belt_Array.concat(state.collectionsStatuses.configurations.locations, [validators_configurations.fields.locations.collection(nextInput$32, at)])
                    }
                  };
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$32,
                            fieldsStatuses: nextFieldsStatuses,
                            collectionsStatuses: nextCollectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "RemoveConfigurationLocationEntry" :
                  var match$4 = action._0;
                  var locationIndex$13 = match$4[1];
                  var configurationIndex$13 = match$4[0];
                  var init$2 = state.input;
                  var nextInput_configurations$1 = Belt_Array.mapWithIndex(state.input.configurations, (function (index, configuration) {
                          if (index === configurationIndex$13) {
                            return {
                                    key: configuration.key,
                                    startDate: configuration.startDate,
                                    contractLength: configuration.contractLength,
                                    streetAddress: configuration.streetAddress,
                                    buildingOrFloor: configuration.buildingOrFloor,
                                    suite: configuration.suite,
                                    cityOrTown: configuration.cityOrTown,
                                    subLocality: configuration.subLocality,
                                    stateOrProvince: configuration.stateOrProvince,
                                    country: configuration.country,
                                    postalCode: configuration.postalCode,
                                    prefix: configuration.prefix,
                                    additionalLocationDetails: configuration.additionalLocationDetails,
                                    privateLineFeatures: configuration.privateLineFeatures,
                                    accessType: configuration.accessType,
                                    portSpeed: configuration.portSpeed,
                                    locations: Belt_Array.keepWithIndex(configuration.locations, (function (param, index) {
                                            return index !== locationIndex$13;
                                          }))
                                  };
                          } else {
                            return configuration;
                          }
                        }));
                  var nextInput_documents$1 = init$2.documents;
                  var nextInput_deletedDocumentIds$1 = init$2.deletedDocumentIds;
                  var nextInput_geographicalRegion$1 = init$2.geographicalRegion;
                  var nextInput_validateRegion$1 = init$2.validateRegion;
                  var nextInput$33 = {
                    configurations: nextInput_configurations$1,
                    documents: nextInput_documents$1,
                    deletedDocumentIds: nextInput_deletedDocumentIds$1,
                    geographicalRegion: nextInput_geographicalRegion$1,
                    validateRegion: nextInput_validateRegion$1
                  };
                  var init$3 = state.fieldsStatuses;
                  var nextFieldsStatuses_validateRegion$1 = init$3.validateRegion;
                  var nextFieldsStatuses_geographicalRegion$1 = init$3.geographicalRegion;
                  var nextFieldsStatuses_deletedDocumentIds$1 = init$3.deletedDocumentIds;
                  var nextFieldsStatuses_documents$1 = init$3.documents;
                  var nextFieldsStatuses_configurations$1 = Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index, configuration) {
                          if (index === configurationIndex$13) {
                            return {
                                    startDate: configuration.startDate,
                                    contractLength: configuration.contractLength,
                                    streetAddress: configuration.streetAddress,
                                    buildingOrFloor: configuration.buildingOrFloor,
                                    suite: configuration.suite,
                                    cityOrTown: configuration.cityOrTown,
                                    subLocality: configuration.subLocality,
                                    stateOrProvince: configuration.stateOrProvince,
                                    country: configuration.country,
                                    postalCode: configuration.postalCode,
                                    prefix: configuration.prefix,
                                    additionalLocationDetails: configuration.additionalLocationDetails,
                                    privateLineFeatures: configuration.privateLineFeatures,
                                    accessType: configuration.accessType,
                                    portSpeed: configuration.portSpeed,
                                    locations: Belt_Array.keepWithIndex(configuration.locations, (function (param, index) {
                                            return index !== locationIndex$13;
                                          }))
                                  };
                          } else {
                            return configuration;
                          }
                        }));
                  var nextFieldsStatuses$1 = {
                    validateRegion: nextFieldsStatuses_validateRegion$1,
                    geographicalRegion: nextFieldsStatuses_geographicalRegion$1,
                    deletedDocumentIds: nextFieldsStatuses_deletedDocumentIds$1,
                    documents: nextFieldsStatuses_documents$1,
                    configurations: nextFieldsStatuses_configurations$1
                  };
                  var nextCollectionsStatuses$1 = {
                    configurations: {
                      self: validators_configurations.collection(nextInput$33),
                      locations: Belt_Array.mapWithIndex(state.collectionsStatuses.configurations.locations, (function (index, item) {
                              if (index === configurationIndex$13) {
                                return validators_configurations.fields.locations.collection(nextInput$33, configurationIndex$13);
                              } else {
                                return item;
                              }
                            }))
                    }
                  };
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$33,
                            fieldsStatuses: nextFieldsStatuses$1,
                            collectionsStatuses: nextCollectionsStatuses$1,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "BlurConfigurationStartDateField" :
                  var index$28 = action._0;
                  var result$4 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$28, state.fieldsStatuses.configurations[index$28].startDate, validators_configurations.fields.startDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$28) {
                                            return {
                                                    startDate: status,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: item.locations
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationContractLengthField" :
                  var index$29 = action._0;
                  var result$5 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$29, state.fieldsStatuses.configurations[index$29].contractLength, validators_configurations.fields.contractLength, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$29) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: status,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: item.locations
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationStreetAddressField" :
                  var index$30 = action._0;
                  var result$6 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$30, state.fieldsStatuses.configurations[index$30].streetAddress, validators_configurations.fields.streetAddress, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$30) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: status,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: item.locations
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationBuildingOrFloorField" :
                  var index$31 = action._0;
                  var result$7 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$31, state.fieldsStatuses.configurations[index$31].buildingOrFloor, validators_configurations.fields.buildingOrFloor, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$31) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: status,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: item.locations
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationSuiteField" :
                  var index$32 = action._0;
                  var result$8 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$32, state.fieldsStatuses.configurations[index$32].suite, validators_configurations.fields.suite, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$32) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: status,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: item.locations
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationCityOrTownField" :
                  var index$33 = action._0;
                  var result$9 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$33, state.fieldsStatuses.configurations[index$33].cityOrTown, validators_configurations.fields.cityOrTown, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$33) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: status,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: item.locations
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationSubLocalityField" :
                  var index$34 = action._0;
                  var result$10 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$34, state.fieldsStatuses.configurations[index$34].subLocality, validators_configurations.fields.subLocality, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$34) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: status,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: item.locations
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationStateOrProvinceField" :
                  var index$35 = action._0;
                  var result$11 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$35, state.fieldsStatuses.configurations[index$35].stateOrProvince, validators_configurations.fields.stateOrProvince, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$35) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: status,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: item.locations
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationCountryField" :
                  var index$36 = action._0;
                  var result$12 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$36, state.fieldsStatuses.configurations[index$36].country, validators_configurations.fields.country, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$36) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: status,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: item.locations
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationPostalCodeField" :
                  var index$37 = action._0;
                  var result$13 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$37, state.fieldsStatuses.configurations[index$37].postalCode, validators_configurations.fields.postalCode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$37) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: status,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: item.locations
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationPrefixField" :
                  var index$38 = action._0;
                  var result$14 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$38, state.fieldsStatuses.configurations[index$38].prefix, validators_configurations.fields.prefix, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$38) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: status,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: item.locations
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationAdditionalLocationDetailsField" :
                  var index$39 = action._0;
                  var result$15 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$39, state.fieldsStatuses.configurations[index$39].additionalLocationDetails, validators_configurations.fields.additionalLocationDetails, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$39) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: status,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: item.locations
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$15 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationPrivateLineFeaturesField" :
                  var index$40 = action._0;
                  var result$16 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$40, state.fieldsStatuses.configurations[index$40].privateLineFeatures, validators_configurations.fields.privateLineFeatures, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$40) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: status,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: item.locations
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$16 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$16,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationAccessTypeField" :
                  var index$41 = action._0;
                  var result$17 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$41, state.fieldsStatuses.configurations[index$41].accessType, validators_configurations.fields.accessType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$41) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: status,
                                                    portSpeed: item.portSpeed,
                                                    locations: item.locations
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$17 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$17,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationPortSpeedField" :
                  var index$42 = action._0;
                  var result$18 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$42, state.fieldsStatuses.configurations[index$42].portSpeed, validators_configurations.fields.portSpeed, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$42) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: status,
                                                    locations: item.locations
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$18 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$18,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationLocationStreetAddressField" :
                  var index$43 = action._0;
                  var locationIndex$14 = index$43[1];
                  var configurationIndex$14 = index$43[0];
                  var result$19 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$43, state.fieldsStatuses.configurations[configurationIndex$14].locations[locationIndex$14].streetAddress, validators_configurations.fields.locations.fields.streetAddress, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$14) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                            if (index$p === locationIndex$14) {
                                                              return {
                                                                      streetAddress: status,
                                                                      buildingOrFloor: item.buildingOrFloor,
                                                                      suite: item.suite,
                                                                      cityOrTown: item.cityOrTown,
                                                                      subLocality: item.subLocality,
                                                                      stateOrProvince: item.stateOrProvince,
                                                                      country: item.country,
                                                                      postalCode: item.postalCode,
                                                                      prefix: item.prefix,
                                                                      additionalLocationDetails: item.additionalLocationDetails,
                                                                      privateLineFeatures: item.privateLineFeatures,
                                                                      accessType: item.accessType,
                                                                      portSpeed: item.portSpeed
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$19 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$19,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationLocationBuildingOrFloorField" :
                  var index$44 = action._0;
                  var locationIndex$15 = index$44[1];
                  var configurationIndex$15 = index$44[0];
                  var result$20 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$44, state.fieldsStatuses.configurations[configurationIndex$15].locations[locationIndex$15].buildingOrFloor, validators_configurations.fields.locations.fields.buildingOrFloor, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$15) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                            if (index$p === locationIndex$15) {
                                                              return {
                                                                      streetAddress: item.streetAddress,
                                                                      buildingOrFloor: status,
                                                                      suite: item.suite,
                                                                      cityOrTown: item.cityOrTown,
                                                                      subLocality: item.subLocality,
                                                                      stateOrProvince: item.stateOrProvince,
                                                                      country: item.country,
                                                                      postalCode: item.postalCode,
                                                                      prefix: item.prefix,
                                                                      additionalLocationDetails: item.additionalLocationDetails,
                                                                      privateLineFeatures: item.privateLineFeatures,
                                                                      accessType: item.accessType,
                                                                      portSpeed: item.portSpeed
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$20 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$20,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationLocationSuiteField" :
                  var index$45 = action._0;
                  var locationIndex$16 = index$45[1];
                  var configurationIndex$16 = index$45[0];
                  var result$21 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$45, state.fieldsStatuses.configurations[configurationIndex$16].locations[locationIndex$16].suite, validators_configurations.fields.locations.fields.suite, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$16) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                            if (index$p === locationIndex$16) {
                                                              return {
                                                                      streetAddress: item.streetAddress,
                                                                      buildingOrFloor: item.buildingOrFloor,
                                                                      suite: status,
                                                                      cityOrTown: item.cityOrTown,
                                                                      subLocality: item.subLocality,
                                                                      stateOrProvince: item.stateOrProvince,
                                                                      country: item.country,
                                                                      postalCode: item.postalCode,
                                                                      prefix: item.prefix,
                                                                      additionalLocationDetails: item.additionalLocationDetails,
                                                                      privateLineFeatures: item.privateLineFeatures,
                                                                      accessType: item.accessType,
                                                                      portSpeed: item.portSpeed
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$21 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$21,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationLocationCityOrTownField" :
                  var index$46 = action._0;
                  var locationIndex$17 = index$46[1];
                  var configurationIndex$17 = index$46[0];
                  var result$22 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$46, state.fieldsStatuses.configurations[configurationIndex$17].locations[locationIndex$17].cityOrTown, validators_configurations.fields.locations.fields.cityOrTown, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$17) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                            if (index$p === locationIndex$17) {
                                                              return {
                                                                      streetAddress: item.streetAddress,
                                                                      buildingOrFloor: item.buildingOrFloor,
                                                                      suite: item.suite,
                                                                      cityOrTown: status,
                                                                      subLocality: item.subLocality,
                                                                      stateOrProvince: item.stateOrProvince,
                                                                      country: item.country,
                                                                      postalCode: item.postalCode,
                                                                      prefix: item.prefix,
                                                                      additionalLocationDetails: item.additionalLocationDetails,
                                                                      privateLineFeatures: item.privateLineFeatures,
                                                                      accessType: item.accessType,
                                                                      portSpeed: item.portSpeed
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$22 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$22,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationLocationSubLocalityField" :
                  var index$47 = action._0;
                  var locationIndex$18 = index$47[1];
                  var configurationIndex$18 = index$47[0];
                  var result$23 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$47, state.fieldsStatuses.configurations[configurationIndex$18].locations[locationIndex$18].subLocality, validators_configurations.fields.locations.fields.subLocality, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$18) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                            if (index$p === locationIndex$18) {
                                                              return {
                                                                      streetAddress: item.streetAddress,
                                                                      buildingOrFloor: item.buildingOrFloor,
                                                                      suite: item.suite,
                                                                      cityOrTown: item.cityOrTown,
                                                                      subLocality: status,
                                                                      stateOrProvince: item.stateOrProvince,
                                                                      country: item.country,
                                                                      postalCode: item.postalCode,
                                                                      prefix: item.prefix,
                                                                      additionalLocationDetails: item.additionalLocationDetails,
                                                                      privateLineFeatures: item.privateLineFeatures,
                                                                      accessType: item.accessType,
                                                                      portSpeed: item.portSpeed
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$23 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$23,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationLocationStateOrProvinceField" :
                  var index$48 = action._0;
                  var locationIndex$19 = index$48[1];
                  var configurationIndex$19 = index$48[0];
                  var result$24 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$48, state.fieldsStatuses.configurations[configurationIndex$19].locations[locationIndex$19].stateOrProvince, validators_configurations.fields.locations.fields.stateOrProvince, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$19) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                            if (index$p === locationIndex$19) {
                                                              return {
                                                                      streetAddress: item.streetAddress,
                                                                      buildingOrFloor: item.buildingOrFloor,
                                                                      suite: item.suite,
                                                                      cityOrTown: item.cityOrTown,
                                                                      subLocality: item.subLocality,
                                                                      stateOrProvince: status,
                                                                      country: item.country,
                                                                      postalCode: item.postalCode,
                                                                      prefix: item.prefix,
                                                                      additionalLocationDetails: item.additionalLocationDetails,
                                                                      privateLineFeatures: item.privateLineFeatures,
                                                                      accessType: item.accessType,
                                                                      portSpeed: item.portSpeed
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$24 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$24,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationLocationCountryField" :
                  var index$49 = action._0;
                  var locationIndex$20 = index$49[1];
                  var configurationIndex$20 = index$49[0];
                  var result$25 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$49, state.fieldsStatuses.configurations[configurationIndex$20].locations[locationIndex$20].country, validators_configurations.fields.locations.fields.country, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$20) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                            if (index$p === locationIndex$20) {
                                                              return {
                                                                      streetAddress: item.streetAddress,
                                                                      buildingOrFloor: item.buildingOrFloor,
                                                                      suite: item.suite,
                                                                      cityOrTown: item.cityOrTown,
                                                                      subLocality: item.subLocality,
                                                                      stateOrProvince: item.stateOrProvince,
                                                                      country: status,
                                                                      postalCode: item.postalCode,
                                                                      prefix: item.prefix,
                                                                      additionalLocationDetails: item.additionalLocationDetails,
                                                                      privateLineFeatures: item.privateLineFeatures,
                                                                      accessType: item.accessType,
                                                                      portSpeed: item.portSpeed
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$25 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$25,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationLocationPostalCodeField" :
                  var index$50 = action._0;
                  var locationIndex$21 = index$50[1];
                  var configurationIndex$21 = index$50[0];
                  var result$26 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$50, state.fieldsStatuses.configurations[configurationIndex$21].locations[locationIndex$21].postalCode, validators_configurations.fields.locations.fields.postalCode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$21) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                            if (index$p === locationIndex$21) {
                                                              return {
                                                                      streetAddress: item.streetAddress,
                                                                      buildingOrFloor: item.buildingOrFloor,
                                                                      suite: item.suite,
                                                                      cityOrTown: item.cityOrTown,
                                                                      subLocality: item.subLocality,
                                                                      stateOrProvince: item.stateOrProvince,
                                                                      country: item.country,
                                                                      postalCode: status,
                                                                      prefix: item.prefix,
                                                                      additionalLocationDetails: item.additionalLocationDetails,
                                                                      privateLineFeatures: item.privateLineFeatures,
                                                                      accessType: item.accessType,
                                                                      portSpeed: item.portSpeed
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$26 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$26,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationLocationPrefixField" :
                  var index$51 = action._0;
                  var locationIndex$22 = index$51[1];
                  var configurationIndex$22 = index$51[0];
                  var result$27 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$51, state.fieldsStatuses.configurations[configurationIndex$22].locations[locationIndex$22].prefix, validators_configurations.fields.locations.fields.prefix, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$22) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                            if (index$p === locationIndex$22) {
                                                              return {
                                                                      streetAddress: item.streetAddress,
                                                                      buildingOrFloor: item.buildingOrFloor,
                                                                      suite: item.suite,
                                                                      cityOrTown: item.cityOrTown,
                                                                      subLocality: item.subLocality,
                                                                      stateOrProvince: item.stateOrProvince,
                                                                      country: item.country,
                                                                      postalCode: item.postalCode,
                                                                      prefix: status,
                                                                      additionalLocationDetails: item.additionalLocationDetails,
                                                                      privateLineFeatures: item.privateLineFeatures,
                                                                      accessType: item.accessType,
                                                                      portSpeed: item.portSpeed
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$27 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$27,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationLocationAdditionalLocationDetailsField" :
                  var index$52 = action._0;
                  var locationIndex$23 = index$52[1];
                  var configurationIndex$23 = index$52[0];
                  var result$28 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$52, state.fieldsStatuses.configurations[configurationIndex$23].locations[locationIndex$23].additionalLocationDetails, validators_configurations.fields.locations.fields.additionalLocationDetails, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$23) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                            if (index$p === locationIndex$23) {
                                                              return {
                                                                      streetAddress: item.streetAddress,
                                                                      buildingOrFloor: item.buildingOrFloor,
                                                                      suite: item.suite,
                                                                      cityOrTown: item.cityOrTown,
                                                                      subLocality: item.subLocality,
                                                                      stateOrProvince: item.stateOrProvince,
                                                                      country: item.country,
                                                                      postalCode: item.postalCode,
                                                                      prefix: item.prefix,
                                                                      additionalLocationDetails: status,
                                                                      privateLineFeatures: item.privateLineFeatures,
                                                                      accessType: item.accessType,
                                                                      portSpeed: item.portSpeed
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$28 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$28,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationLocationPrivateLineFeaturesField" :
                  var index$53 = action._0;
                  var locationIndex$24 = index$53[1];
                  var configurationIndex$24 = index$53[0];
                  var result$29 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$53, state.fieldsStatuses.configurations[configurationIndex$24].locations[locationIndex$24].privateLineFeatures, validators_configurations.fields.locations.fields.privateLineFeatures, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$24) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                            if (index$p === locationIndex$24) {
                                                              return {
                                                                      streetAddress: item.streetAddress,
                                                                      buildingOrFloor: item.buildingOrFloor,
                                                                      suite: item.suite,
                                                                      cityOrTown: item.cityOrTown,
                                                                      subLocality: item.subLocality,
                                                                      stateOrProvince: item.stateOrProvince,
                                                                      country: item.country,
                                                                      postalCode: item.postalCode,
                                                                      prefix: item.prefix,
                                                                      additionalLocationDetails: item.additionalLocationDetails,
                                                                      privateLineFeatures: status,
                                                                      accessType: item.accessType,
                                                                      portSpeed: item.portSpeed
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$29 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$29,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationLocationAccessTypeField" :
                  var index$54 = action._0;
                  var locationIndex$25 = index$54[1];
                  var configurationIndex$25 = index$54[0];
                  var result$30 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$54, state.fieldsStatuses.configurations[configurationIndex$25].locations[locationIndex$25].accessType, validators_configurations.fields.locations.fields.accessType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$25) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                            if (index$p === locationIndex$25) {
                                                              return {
                                                                      streetAddress: item.streetAddress,
                                                                      buildingOrFloor: item.buildingOrFloor,
                                                                      suite: item.suite,
                                                                      cityOrTown: item.cityOrTown,
                                                                      subLocality: item.subLocality,
                                                                      stateOrProvince: item.stateOrProvince,
                                                                      country: item.country,
                                                                      postalCode: item.postalCode,
                                                                      prefix: item.prefix,
                                                                      additionalLocationDetails: item.additionalLocationDetails,
                                                                      privateLineFeatures: item.privateLineFeatures,
                                                                      accessType: status,
                                                                      portSpeed: item.portSpeed
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$30 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$30,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationLocationPortSpeedField" :
                  var index$55 = action._0;
                  var locationIndex$26 = index$55[1];
                  var configurationIndex$26 = index$55[0];
                  var result$31 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$55, state.fieldsStatuses.configurations[configurationIndex$26].locations[locationIndex$26].portSpeed, validators_configurations.fields.locations.fields.portSpeed, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$26) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    streetAddress: item.streetAddress,
                                                    buildingOrFloor: item.buildingOrFloor,
                                                    suite: item.suite,
                                                    cityOrTown: item.cityOrTown,
                                                    subLocality: item.subLocality,
                                                    stateOrProvince: item.stateOrProvince,
                                                    country: item.country,
                                                    postalCode: item.postalCode,
                                                    prefix: item.prefix,
                                                    additionalLocationDetails: item.additionalLocationDetails,
                                                    privateLineFeatures: item.privateLineFeatures,
                                                    accessType: item.accessType,
                                                    portSpeed: item.portSpeed,
                                                    locations: Belt_Array.mapWithIndex(item.locations, (function (index$p, item) {
                                                            if (index$p === locationIndex$26) {
                                                              return {
                                                                      streetAddress: item.streetAddress,
                                                                      buildingOrFloor: item.buildingOrFloor,
                                                                      suite: item.suite,
                                                                      cityOrTown: item.cityOrTown,
                                                                      subLocality: item.subLocality,
                                                                      stateOrProvince: item.stateOrProvince,
                                                                      country: item.country,
                                                                      postalCode: item.postalCode,
                                                                      prefix: item.prefix,
                                                                      additionalLocationDetails: item.additionalLocationDetails,
                                                                      privateLineFeatures: item.privateLineFeatures,
                                                                      accessType: item.accessType,
                                                                      portSpeed: status
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$31 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$31,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "AddConfigurationEntry" :
                  var entry$1 = action._0;
                  var init$4 = state.input;
                  var nextInput_configurations$2 = Belt_Array.concat(state.input.configurations, [entry$1]);
                  var nextInput_documents$2 = init$4.documents;
                  var nextInput_deletedDocumentIds$2 = init$4.deletedDocumentIds;
                  var nextInput_geographicalRegion$2 = init$4.geographicalRegion;
                  var nextInput_validateRegion$2 = init$4.validateRegion;
                  var nextInput$34 = {
                    configurations: nextInput_configurations$2,
                    documents: nextInput_documents$2,
                    deletedDocumentIds: nextInput_deletedDocumentIds$2,
                    geographicalRegion: nextInput_geographicalRegion$2,
                    validateRegion: nextInput_validateRegion$2
                  };
                  var init$5 = state.fieldsStatuses;
                  var nextFieldsStatuses_validateRegion$2 = init$5.validateRegion;
                  var nextFieldsStatuses_geographicalRegion$2 = init$5.geographicalRegion;
                  var nextFieldsStatuses_deletedDocumentIds$2 = init$5.deletedDocumentIds;
                  var nextFieldsStatuses_documents$2 = init$5.documents;
                  var nextFieldsStatuses_configurations$2 = Belt_Array.concat(state.fieldsStatuses.configurations, [{
                          startDate: "Pristine",
                          contractLength: "Pristine",
                          streetAddress: "Pristine",
                          buildingOrFloor: "Pristine",
                          suite: "Pristine",
                          cityOrTown: "Pristine",
                          subLocality: "Pristine",
                          stateOrProvince: "Pristine",
                          country: "Pristine",
                          postalCode: "Pristine",
                          prefix: "Pristine",
                          additionalLocationDetails: "Pristine",
                          privateLineFeatures: "Pristine",
                          accessType: "Pristine",
                          portSpeed: "Pristine",
                          locations: Belt_Array.map(entry$1.locations, (function (param) {
                                  return {
                                          streetAddress: "Pristine",
                                          buildingOrFloor: "Pristine",
                                          suite: "Pristine",
                                          cityOrTown: "Pristine",
                                          subLocality: "Pristine",
                                          stateOrProvince: "Pristine",
                                          country: "Pristine",
                                          postalCode: "Pristine",
                                          prefix: "Pristine",
                                          additionalLocationDetails: "Pristine",
                                          privateLineFeatures: "Pristine",
                                          accessType: "Pristine",
                                          portSpeed: "Pristine"
                                        };
                                }))
                        }]);
                  var nextFieldsStatuses$2 = {
                    validateRegion: nextFieldsStatuses_validateRegion$2,
                    geographicalRegion: nextFieldsStatuses_geographicalRegion$2,
                    deletedDocumentIds: nextFieldsStatuses_deletedDocumentIds$2,
                    documents: nextFieldsStatuses_documents$2,
                    configurations: nextFieldsStatuses_configurations$2
                  };
                  var nextCollectionsStatuses$2 = {
                    configurations: {
                      self: validators_configurations.collection(nextInput$34),
                      locations: Belt_Array.concat(state.collectionsStatuses.configurations.locations, [validators_configurations.fields.locations.collection(nextInput$34, state.input.configurations.length)])
                    }
                  };
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$34,
                            fieldsStatuses: nextFieldsStatuses$2,
                            collectionsStatuses: nextCollectionsStatuses$2,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "RemoveConfigurationEntry" :
                  var index$56 = action._0;
                  var init$6 = state.input;
                  var nextInput_configurations$3 = Belt_Array.keepWithIndex(state.input.configurations, (function (param, i) {
                          return i !== index$56;
                        }));
                  var nextInput_documents$3 = init$6.documents;
                  var nextInput_deletedDocumentIds$3 = init$6.deletedDocumentIds;
                  var nextInput_geographicalRegion$3 = init$6.geographicalRegion;
                  var nextInput_validateRegion$3 = init$6.validateRegion;
                  var nextInput$35 = {
                    configurations: nextInput_configurations$3,
                    documents: nextInput_documents$3,
                    deletedDocumentIds: nextInput_deletedDocumentIds$3,
                    geographicalRegion: nextInput_geographicalRegion$3,
                    validateRegion: nextInput_validateRegion$3
                  };
                  var init$7 = state.fieldsStatuses;
                  var nextFieldsStatuses_validateRegion$3 = init$7.validateRegion;
                  var nextFieldsStatuses_geographicalRegion$3 = init$7.geographicalRegion;
                  var nextFieldsStatuses_deletedDocumentIds$3 = init$7.deletedDocumentIds;
                  var nextFieldsStatuses_documents$3 = init$7.documents;
                  var nextFieldsStatuses_configurations$3 = Belt_Array.keepWithIndex(state.fieldsStatuses.configurations, (function (param, i) {
                          return i !== index$56;
                        }));
                  var nextFieldsStatuses$3 = {
                    validateRegion: nextFieldsStatuses_validateRegion$3,
                    geographicalRegion: nextFieldsStatuses_geographicalRegion$3,
                    deletedDocumentIds: nextFieldsStatuses_deletedDocumentIds$3,
                    documents: nextFieldsStatuses_documents$3,
                    configurations: nextFieldsStatuses_configurations$3
                  };
                  var nextCollectionsStatuses$3 = {
                    configurations: {
                      self: validators_configurations.collection(nextInput$35),
                      locations: Belt_Array.keepWithIndex(state.collectionsStatuses.configurations.locations, (function (param, i) {
                              return i !== (state.collectionsStatuses.configurations.locations.length - 1 | 0);
                            }))
                    }
                  };
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$35,
                            fieldsStatuses: nextFieldsStatuses$3,
                            collectionsStatuses: nextCollectionsStatuses$3,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateValidateRegion: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateValidateRegionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateGeographicalRegion: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateGeographicalRegionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateDeletedDocumentIds: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateDeletedDocumentIdsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateDocuments: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateDocumentsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateConfigurationStartDate: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationStartDateField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationContractLength: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationContractLengthField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationStreetAddress: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationStreetAddressField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationBuildingOrFloor: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationBuildingOrFloorField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationSuite: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationSuiteField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationCityOrTown: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationCityOrTownField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationSubLocality: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationSubLocalityField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationStateOrProvince: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationStateOrProvinceField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationCountry: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationCountryField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationPostalCode: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationPostalCodeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationPrefix: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationPrefixField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationAdditionalLocationDetails: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationAdditionalLocationDetailsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationPrivateLineFeatures: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationPrivateLineFeaturesField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationAccessType: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationAccessTypeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationPortSpeed: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationPortSpeedField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationLocationStreetAddress: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationLocationStreetAddressField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationLocationBuildingOrFloor: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationLocationBuildingOrFloorField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationLocationSuite: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationLocationSuiteField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationLocationCityOrTown: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationLocationCityOrTownField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationLocationSubLocality: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationLocationSubLocalityField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationLocationStateOrProvince: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationLocationStateOrProvinceField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationLocationCountry: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationLocationCountryField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationLocationPostalCode: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationLocationPostalCodeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationLocationPrefix: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationLocationPrefixField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationLocationAdditionalLocationDetails: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationLocationAdditionalLocationDetailsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationLocationPrivateLineFeatures: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationLocationPrivateLineFeaturesField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationLocationAccessType: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationLocationAccessTypeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationLocationPortSpeed: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationLocationPortSpeedField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          blurValidateRegion: (function () {
              dispatch("BlurValidateRegionField");
            }),
          blurGeographicalRegion: (function () {
              dispatch("BlurGeographicalRegionField");
            }),
          blurDeletedDocumentIds: (function () {
              dispatch("BlurDeletedDocumentIdsField");
            }),
          blurDocuments: (function () {
              dispatch("BlurDocumentsField");
            }),
          blurConfigurationStartDate: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationStartDateField",
                    _0: index
                  });
            }),
          blurConfigurationContractLength: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationContractLengthField",
                    _0: index
                  });
            }),
          blurConfigurationStreetAddress: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationStreetAddressField",
                    _0: index
                  });
            }),
          blurConfigurationBuildingOrFloor: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationBuildingOrFloorField",
                    _0: index
                  });
            }),
          blurConfigurationSuite: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationSuiteField",
                    _0: index
                  });
            }),
          blurConfigurationCityOrTown: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationCityOrTownField",
                    _0: index
                  });
            }),
          blurConfigurationSubLocality: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationSubLocalityField",
                    _0: index
                  });
            }),
          blurConfigurationStateOrProvince: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationStateOrProvinceField",
                    _0: index
                  });
            }),
          blurConfigurationCountry: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationCountryField",
                    _0: index
                  });
            }),
          blurConfigurationPostalCode: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationPostalCodeField",
                    _0: index
                  });
            }),
          blurConfigurationPrefix: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationPrefixField",
                    _0: index
                  });
            }),
          blurConfigurationAdditionalLocationDetails: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationAdditionalLocationDetailsField",
                    _0: index
                  });
            }),
          blurConfigurationPrivateLineFeatures: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationPrivateLineFeaturesField",
                    _0: index
                  });
            }),
          blurConfigurationAccessType: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationAccessTypeField",
                    _0: index
                  });
            }),
          blurConfigurationPortSpeed: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationPortSpeedField",
                    _0: index
                  });
            }),
          blurConfigurationLocationStreetAddress: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationLocationStreetAddressField",
                    _0: index
                  });
            }),
          blurConfigurationLocationBuildingOrFloor: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationLocationBuildingOrFloorField",
                    _0: index
                  });
            }),
          blurConfigurationLocationSuite: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationLocationSuiteField",
                    _0: index
                  });
            }),
          blurConfigurationLocationCityOrTown: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationLocationCityOrTownField",
                    _0: index
                  });
            }),
          blurConfigurationLocationSubLocality: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationLocationSubLocalityField",
                    _0: index
                  });
            }),
          blurConfigurationLocationStateOrProvince: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationLocationStateOrProvinceField",
                    _0: index
                  });
            }),
          blurConfigurationLocationCountry: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationLocationCountryField",
                    _0: index
                  });
            }),
          blurConfigurationLocationPostalCode: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationLocationPostalCodeField",
                    _0: index
                  });
            }),
          blurConfigurationLocationPrefix: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationLocationPrefixField",
                    _0: index
                  });
            }),
          blurConfigurationLocationAdditionalLocationDetails: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationLocationAdditionalLocationDetailsField",
                    _0: index
                  });
            }),
          blurConfigurationLocationPrivateLineFeatures: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationLocationPrivateLineFeaturesField",
                    _0: index
                  });
            }),
          blurConfigurationLocationAccessType: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationLocationAccessTypeField",
                    _0: index
                  });
            }),
          blurConfigurationLocationPortSpeed: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationLocationPortSpeedField",
                    _0: index
                  });
            }),
          validateRegionResult: Formality.exposeFieldResult(state.fieldsStatuses.validateRegion),
          geographicalRegionResult: Formality.exposeFieldResult(state.fieldsStatuses.geographicalRegion),
          deletedDocumentIdsResult: Formality.exposeFieldResult(state.fieldsStatuses.deletedDocumentIds),
          documentsResult: Formality.exposeFieldResult(state.fieldsStatuses.documents),
          configurationStartDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].startDate);
            }),
          configurationContractLengthResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].contractLength);
            }),
          configurationStreetAddressResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].streetAddress);
            }),
          configurationBuildingOrFloorResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].buildingOrFloor);
            }),
          configurationSuiteResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].suite);
            }),
          configurationCityOrTownResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].cityOrTown);
            }),
          configurationSubLocalityResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].subLocality);
            }),
          configurationStateOrProvinceResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].stateOrProvince);
            }),
          configurationCountryResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].country);
            }),
          configurationPostalCodeResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].postalCode);
            }),
          configurationPrefixResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].prefix);
            }),
          configurationAdditionalLocationDetailsResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].additionalLocationDetails);
            }),
          configurationPrivateLineFeaturesResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].privateLineFeatures);
            }),
          configurationAccessTypeResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].accessType);
            }),
          configurationPortSpeedResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].portSpeed);
            }),
          configurationLocationStreetAddressResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].locations[param[1]].streetAddress);
            }),
          configurationLocationBuildingOrFloorResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].locations[param[1]].buildingOrFloor);
            }),
          configurationLocationSuiteResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].locations[param[1]].suite);
            }),
          configurationLocationCityOrTownResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].locations[param[1]].cityOrTown);
            }),
          configurationLocationSubLocalityResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].locations[param[1]].subLocality);
            }),
          configurationLocationStateOrProvinceResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].locations[param[1]].stateOrProvince);
            }),
          configurationLocationCountryResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].locations[param[1]].country);
            }),
          configurationLocationPostalCodeResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].locations[param[1]].postalCode);
            }),
          configurationLocationPrefixResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].locations[param[1]].prefix);
            }),
          configurationLocationAdditionalLocationDetailsResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].locations[param[1]].additionalLocationDetails);
            }),
          configurationLocationPrivateLineFeaturesResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].locations[param[1]].privateLineFeatures);
            }),
          configurationLocationAccessTypeResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].locations[param[1]].accessType);
            }),
          configurationLocationPortSpeedResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].locations[param[1]].portSpeed);
            }),
          addConfiguration: (function (entry) {
              dispatch({
                    TAG: "AddConfigurationEntry",
                    _0: entry
                  });
            }),
          removeConfiguration: (function (index) {
              dispatch({
                    TAG: "RemoveConfigurationEntry",
                    _0: index
                  });
            }),
          addConfigurationLocation: (function (entry, index) {
              dispatch({
                    TAG: "AddConfigurationLocationEntry",
                    _0: entry,
                    _1: index
                  });
            }),
          removeConfigurationLocation: (function (index) {
              dispatch({
                    TAG: "RemoveConfigurationLocationEntry",
                    _0: index
                  });
            }),
          configurationsResult: state.collectionsStatuses.configurations.self,
          configurationLocationsResult: (function (index) {
              return state.collectionsStatuses.configurations.locations[index];
            }),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              if (Belt_Array.every(state.fieldsStatuses.configurations, (function (item) {
                        var exit = 0;
                        var tmp = item.startDate;
                        if (typeof tmp !== "object") {
                          var tmp$1 = item.contractLength;
                          if (typeof tmp$1 !== "object") {
                            var tmp$2 = item.streetAddress;
                            if (typeof tmp$2 !== "object") {
                              var tmp$3 = item.buildingOrFloor;
                              if (typeof tmp$3 !== "object") {
                                var tmp$4 = item.suite;
                                if (typeof tmp$4 !== "object") {
                                  var tmp$5 = item.cityOrTown;
                                  if (typeof tmp$5 !== "object") {
                                    var tmp$6 = item.subLocality;
                                    if (typeof tmp$6 !== "object") {
                                      var tmp$7 = item.stateOrProvince;
                                      if (typeof tmp$7 !== "object") {
                                        var tmp$8 = item.country;
                                        if (typeof tmp$8 !== "object") {
                                          var tmp$9 = item.postalCode;
                                          if (typeof tmp$9 !== "object") {
                                            var tmp$10 = item.prefix;
                                            if (typeof tmp$10 !== "object") {
                                              var tmp$11 = item.additionalLocationDetails;
                                              if (typeof tmp$11 !== "object") {
                                                var tmp$12 = item.privateLineFeatures;
                                                if (typeof tmp$12 !== "object") {
                                                  var tmp$13 = item.accessType;
                                                  if (typeof tmp$13 !== "object") {
                                                    var tmp$14 = item.portSpeed;
                                                    if (typeof tmp$14 !== "object") {
                                                      if (Belt_Array.every(item.locations, (function (item) {
                                                                var exit = 0;
                                                                var tmp = item.streetAddress;
                                                                if (typeof tmp !== "object") {
                                                                  var tmp$1 = item.buildingOrFloor;
                                                                  if (typeof tmp$1 !== "object") {
                                                                    var tmp$2 = item.suite;
                                                                    if (typeof tmp$2 !== "object") {
                                                                      var tmp$3 = item.cityOrTown;
                                                                      if (typeof tmp$3 !== "object") {
                                                                        var tmp$4 = item.subLocality;
                                                                        if (typeof tmp$4 !== "object") {
                                                                          var tmp$5 = item.stateOrProvince;
                                                                          if (typeof tmp$5 !== "object") {
                                                                            var tmp$6 = item.country;
                                                                            if (typeof tmp$6 !== "object") {
                                                                              var tmp$7 = item.postalCode;
                                                                              if (typeof tmp$7 !== "object") {
                                                                                var tmp$8 = item.prefix;
                                                                                if (typeof tmp$8 !== "object") {
                                                                                  var tmp$9 = item.additionalLocationDetails;
                                                                                  if (typeof tmp$9 !== "object") {
                                                                                    var tmp$10 = item.privateLineFeatures;
                                                                                    if (typeof tmp$10 !== "object") {
                                                                                      var tmp$11 = item.accessType;
                                                                                      if (typeof tmp$11 !== "object") {
                                                                                        var tmp$12 = item.portSpeed;
                                                                                        if (typeof tmp$12 !== "object") {
                                                                                          return false;
                                                                                        }
                                                                                        exit = 1;
                                                                                      } else {
                                                                                        exit = 1;
                                                                                      }
                                                                                    } else {
                                                                                      exit = 1;
                                                                                    }
                                                                                  } else {
                                                                                    exit = 1;
                                                                                  }
                                                                                } else {
                                                                                  exit = 1;
                                                                                }
                                                                              } else {
                                                                                exit = 1;
                                                                              }
                                                                            } else {
                                                                              exit = 1;
                                                                            }
                                                                          } else {
                                                                            exit = 1;
                                                                          }
                                                                        } else {
                                                                          exit = 1;
                                                                        }
                                                                      } else {
                                                                        exit = 1;
                                                                      }
                                                                    } else {
                                                                      exit = 1;
                                                                    }
                                                                  } else {
                                                                    exit = 1;
                                                                  }
                                                                } else {
                                                                  exit = 1;
                                                                }
                                                                if (exit === 1) {
                                                                  var exit$1 = 0;
                                                                  exit$1 = 2;
                                                                  if (exit$1 === 2) {
                                                                    var exit$2 = 0;
                                                                    exit$2 = 3;
                                                                    if (exit$2 === 3) {
                                                                      return true;
                                                                    }
                                                                    
                                                                  }
                                                                  
                                                                }
                                                                
                                                              }))) {
                                                        return false;
                                                      }
                                                      exit = 1;
                                                    } else {
                                                      exit = 1;
                                                    }
                                                  } else {
                                                    exit = 1;
                                                  }
                                                } else {
                                                  exit = 1;
                                                }
                                              } else {
                                                exit = 1;
                                              }
                                            } else {
                                              exit = 1;
                                            }
                                          } else {
                                            exit = 1;
                                          }
                                        } else {
                                          exit = 1;
                                        }
                                      } else {
                                        exit = 1;
                                      }
                                    } else {
                                      exit = 1;
                                    }
                                  } else {
                                    exit = 1;
                                  }
                                } else {
                                  exit = 1;
                                }
                              } else {
                                exit = 1;
                              }
                            } else {
                              exit = 1;
                            }
                          } else {
                            exit = 1;
                          }
                        } else {
                          exit = 1;
                        }
                        if (exit === 1) {
                          var exit$1 = 0;
                          exit$1 = 2;
                          if (exit$1 === 2) {
                            var exit$2 = 0;
                            exit$2 = 3;
                            if (exit$2 === 3) {
                              var exit$3 = 0;
                              exit$3 = 4;
                              if (exit$3 === 4) {
                                var exit$4 = 0;
                                exit$4 = 5;
                                if (exit$4 === 5) {
                                  var exit$5 = 0;
                                  exit$5 = 6;
                                  if (exit$5 === 6) {
                                    return true;
                                  }
                                  
                                }
                                
                              }
                              
                            }
                            
                          }
                          
                        }
                        
                      }))) {
                return true;
              }
              var match = state.fieldsStatuses;
              var tmp = match.validateRegion;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.geographicalRegion;
              if (typeof tmp$1 === "object") {
                return true;
              }
              var tmp$2 = match.deletedDocumentIds;
              if (typeof tmp$2 === "object") {
                return true;
              }
              var tmp$3 = match.documents;
              if (typeof tmp$3 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

function empty() {
  return {
          key: NonSecure.nanoid(),
          streetAddress: "",
          buildingOrFloor: "",
          suite: "",
          cityOrTown: "",
          subLocality: "",
          stateOrProvince: "",
          country: "",
          postalCode: "",
          prefix: "",
          additionalLocationDetails: "",
          privateLineFeatures: undefined,
          accessType: "",
          portSpeed: ""
        };
}

var inputDecoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              key: NonSecure.nanoid(),
              streetAddress: Belt_Option.getWithDefault(field.required("streetAddress", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              buildingOrFloor: Belt_Option.getWithDefault(field.required("buildingOrFloor", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              suite: Belt_Option.getWithDefault(field.required("suite", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              cityOrTown: Belt_Option.getWithDefault(field.required("cityOrTown", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              subLocality: Belt_Option.getWithDefault(field.required("subLocality", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              stateOrProvince: Belt_Option.getWithDefault(field.required("stateOrProvince", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              country: Belt_Option.getWithDefault(field.required("country", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              postalCode: Belt_Option.getWithDefault(field.required("postalCode", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              prefix: Belt_Option.getWithDefault(field.required("prefix", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              additionalLocationDetails: Belt_Option.getWithDefault(field.required("additionalLocationDetails", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              privateLineFeatures: Belt_SetString.fromArray(field.required("privateLineFeatures", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))),
              accessType: Belt_Option.getWithDefault(field.required("accessType", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              portSpeed: Belt_Option.getWithDefault(field.required("portSpeed", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), "")
            };
    });

function inputFromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, inputDecoder));
}

function inputToJson($$location) {
  var x = $$location.streetAddress;
  var tmp = x === "" ? null : x;
  var x$1 = $$location.buildingOrFloor;
  var tmp$1 = x$1 === "" ? null : x$1;
  var x$2 = $$location.suite;
  var tmp$2 = x$2 === "" ? null : x$2;
  var x$3 = $$location.cityOrTown;
  var tmp$3 = x$3 === "" ? null : x$3;
  var x$4 = $$location.subLocality;
  var tmp$4 = x$4 === "" ? null : x$4;
  var x$5 = $$location.stateOrProvince;
  var tmp$5 = x$5 === "" ? null : x$5;
  var x$6 = $$location.country;
  var tmp$6 = x$6 === "" ? null : x$6;
  var x$7 = $$location.postalCode;
  var tmp$7 = x$7 === "" ? null : x$7;
  var x$8 = $$location.prefix;
  var tmp$8 = x$8 === "" ? null : x$8;
  var x$9 = $$location.additionalLocationDetails;
  var tmp$9 = x$9 === "" ? null : x$9;
  var x$10 = $$location.accessType;
  var tmp$10 = x$10 === "" ? null : x$10;
  var x$11 = $$location.portSpeed;
  var tmp$11 = x$11 === "" ? null : x$11;
  return Json_Encode$JsonCombinators.object([
              [
                "streetAddress",
                tmp
              ],
              [
                "buildingOrFloor",
                tmp$1
              ],
              [
                "suite",
                tmp$2
              ],
              [
                "cityOrTown",
                tmp$3
              ],
              [
                "subLocality",
                tmp$4
              ],
              [
                "stateOrProvince",
                tmp$5
              ],
              [
                "country",
                tmp$6
              ],
              [
                "postalCode",
                tmp$7
              ],
              [
                "prefix",
                tmp$8
              ],
              [
                "additionalLocationDetails",
                tmp$9
              ],
              [
                "privateLineFeatures",
                Json_Encode$JsonCombinators.array(function (prim) {
                        return prim;
                      })(Belt_SetString.toArray($$location.privateLineFeatures))
              ],
              [
                "accessType",
                tmp$10
              ],
              [
                "portSpeed",
                tmp$11
              ]
            ]);
}

function update(input, param, updater) {
  var locationIndex = param[1];
  var configurationIndex = param[0];
  return {
          configurations: Belt_Array.mapWithIndex(input.configurations, (function (idx, configuration) {
                  if (idx !== configurationIndex) {
                    return configuration;
                  } else {
                    return {
                            key: configuration.key,
                            startDate: configuration.startDate,
                            contractLength: configuration.contractLength,
                            streetAddress: configuration.streetAddress,
                            buildingOrFloor: configuration.buildingOrFloor,
                            suite: configuration.suite,
                            cityOrTown: configuration.cityOrTown,
                            subLocality: configuration.subLocality,
                            stateOrProvince: configuration.stateOrProvince,
                            country: configuration.country,
                            postalCode: configuration.postalCode,
                            prefix: configuration.prefix,
                            additionalLocationDetails: configuration.additionalLocationDetails,
                            privateLineFeatures: configuration.privateLineFeatures,
                            accessType: configuration.accessType,
                            portSpeed: configuration.portSpeed,
                            locations: Belt_Array.mapWithIndex(configuration.locations, (function (idx, $$location) {
                                    if (idx !== locationIndex) {
                                      return $$location;
                                    } else {
                                      return updater($$location);
                                    }
                                  }))
                          };
                  }
                })),
          documents: input.documents,
          deletedDocumentIds: input.deletedDocumentIds,
          geographicalRegion: input.geographicalRegion,
          validateRegion: input.validateRegion
        };
}

function remove(input, param) {
  var locationIndex = param[1];
  var configurationIndex = param[0];
  return {
          configurations: Belt_Array.mapWithIndex(input.configurations, (function (idx, configuration) {
                  if (idx !== configurationIndex) {
                    return configuration;
                  } else {
                    return {
                            key: configuration.key,
                            startDate: configuration.startDate,
                            contractLength: configuration.contractLength,
                            streetAddress: configuration.streetAddress,
                            buildingOrFloor: configuration.buildingOrFloor,
                            suite: configuration.suite,
                            cityOrTown: configuration.cityOrTown,
                            subLocality: configuration.subLocality,
                            stateOrProvince: configuration.stateOrProvince,
                            country: configuration.country,
                            postalCode: configuration.postalCode,
                            prefix: configuration.prefix,
                            additionalLocationDetails: configuration.additionalLocationDetails,
                            privateLineFeatures: configuration.privateLineFeatures,
                            accessType: configuration.accessType,
                            portSpeed: configuration.portSpeed,
                            locations: Belt_Array.keepWithIndex(configuration.locations, (function (param, idx) {
                                    return idx !== locationIndex;
                                  }))
                          };
                  }
                })),
          documents: input.documents,
          deletedDocumentIds: input.deletedDocumentIds,
          geographicalRegion: input.geographicalRegion,
          validateRegion: input.validateRegion
        };
}

var $$Location = {
  empty: empty,
  inputDecoder: inputDecoder,
  inputFromJson: inputFromJson,
  inputToJson: inputToJson,
  update: update,
  remove: remove
};

function empty$1() {
  return {
          key: NonSecure.nanoid(),
          startDate: undefined,
          contractLength: undefined,
          streetAddress: "",
          buildingOrFloor: "",
          suite: "",
          cityOrTown: "",
          subLocality: "",
          stateOrProvince: "",
          country: "",
          postalCode: "",
          prefix: "",
          additionalLocationDetails: "",
          privateLineFeatures: undefined,
          accessType: "",
          portSpeed: "",
          locations: [empty()]
        };
}

function inputDecoder$1(contractLengths) {
  return Json_Decode$JsonCombinators.object(function (field) {
              return {
                      key: NonSecure.nanoid(),
                      startDate: field.required("startDate", Json_Decode$JsonCombinators.option($$Date.Naive.decoder)),
                      contractLength: Belt_Option.flatMap(field.required("contractLength", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)), (function (x) {
                              return Belt_Array.getBy(contractLengths, (function (cl) {
                                            return cl.value === x;
                                          }));
                            })),
                      streetAddress: Belt_Option.getWithDefault(field.required("streetAddress", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      buildingOrFloor: Belt_Option.getWithDefault(field.required("buildingOrFloor", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      suite: Belt_Option.getWithDefault(field.required("suite", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      cityOrTown: Belt_Option.getWithDefault(field.required("cityOrTown", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      subLocality: Belt_Option.getWithDefault(field.required("subLocality", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      stateOrProvince: Belt_Option.getWithDefault(field.required("stateOrProvince", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      country: Belt_Option.getWithDefault(field.required("country", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      postalCode: Belt_Option.getWithDefault(field.required("postalCode", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      prefix: Belt_Option.getWithDefault(field.required("prefix", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      additionalLocationDetails: Belt_Option.getWithDefault(field.required("additionalLocationDetails", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      privateLineFeatures: Belt_SetString.fromArray(field.required("privateLineFeatures", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))),
                      accessType: Belt_Option.getWithDefault(field.required("accessType", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      portSpeed: Belt_Option.getWithDefault(field.required("portSpeed", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      locations: field.required("locationZs", Json_Decode$JsonCombinators.array(inputDecoder))
                    };
            });
}

function inputFromJson$1(json, contractLengths) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, inputDecoder$1(contractLengths)));
}

function inputToJson$1(configuration) {
  var x = configuration.contractLength;
  var x$1 = configuration.streetAddress;
  var tmp = x$1 === "" ? null : x$1;
  var x$2 = configuration.buildingOrFloor;
  var tmp$1 = x$2 === "" ? null : x$2;
  var x$3 = configuration.suite;
  var tmp$2 = x$3 === "" ? null : x$3;
  var x$4 = configuration.cityOrTown;
  var tmp$3 = x$4 === "" ? null : x$4;
  var x$5 = configuration.subLocality;
  var tmp$4 = x$5 === "" ? null : x$5;
  var x$6 = configuration.stateOrProvince;
  var tmp$5 = x$6 === "" ? null : x$6;
  var x$7 = configuration.country;
  var tmp$6 = x$7 === "" ? null : x$7;
  var x$8 = configuration.postalCode;
  var tmp$7 = x$8 === "" ? null : x$8;
  var x$9 = configuration.prefix;
  var tmp$8 = x$9 === "" ? null : x$9;
  var x$10 = configuration.additionalLocationDetails;
  var tmp$9 = x$10 === "" ? null : x$10;
  var x$11 = configuration.accessType;
  var tmp$10 = x$11 === "" ? null : x$11;
  var x$12 = configuration.portSpeed;
  var tmp$11 = x$12 === "" ? null : x$12;
  return Json_Encode$JsonCombinators.object([
              [
                "startDate",
                $$Date.Naive.toJsonOpt(configuration.startDate)
              ],
              [
                "contractLength",
                x !== undefined ? x.value : null
              ],
              [
                "streetAddress",
                tmp
              ],
              [
                "buildingOrFloor",
                tmp$1
              ],
              [
                "suite",
                tmp$2
              ],
              [
                "cityOrTown",
                tmp$3
              ],
              [
                "subLocality",
                tmp$4
              ],
              [
                "stateOrProvince",
                tmp$5
              ],
              [
                "country",
                tmp$6
              ],
              [
                "postalCode",
                tmp$7
              ],
              [
                "prefix",
                tmp$8
              ],
              [
                "additionalLocationDetails",
                tmp$9
              ],
              [
                "accessType",
                tmp$10
              ],
              [
                "portSpeed",
                tmp$11
              ],
              [
                "privateLineFeatures",
                Json_Encode$JsonCombinators.array(function (prim) {
                        return prim;
                      })(Belt_SetString.toArray(configuration.privateLineFeatures))
              ],
              [
                "locationZs",
                Belt_Array.map(configuration.locations, inputToJson)
              ]
            ]);
}

function outputToFormData(data) {
  var formData = new FormData();
  Belt_Array.forEach(data.configurations, (function (configuration) {
          formData.append("[network_private_line_configurations][]start_date", $$Date.Naive.toJson(configuration.startDate));
          formData.append("[network_private_line_configurations][]contract_length", configuration.contractLength);
          formData.append("[network_private_line_configurations][]street_address", configuration.streetAddress);
          formData.append("[network_private_line_configurations][]building_or_floor", Belt_Option.getWithDefault(configuration.buildingOrFloor, ""));
          formData.append("[network_private_line_configurations][]suite", Belt_Option.getWithDefault(configuration.suite, ""));
          formData.append("[network_private_line_configurations][]city_or_town", configuration.cityOrTown);
          formData.append("[network_private_line_configurations][]sub_locality", Belt_Option.getWithDefault(configuration.subLocality, ""));
          formData.append("[network_private_line_configurations][]state_or_province", configuration.stateOrProvince);
          formData.append("[network_private_line_configurations][]country", configuration.country);
          formData.append("[network_private_line_configurations][]postal_code", configuration.postalCode);
          formData.append("[network_private_line_configurations][]prefix", Belt_Option.getWithDefault(configuration.prefix, ""));
          formData.append("[network_private_line_configurations][]additional_location_details", Belt_Option.getWithDefault(configuration.additionalLocationDetails, ""));
          if (configuration.privateLineFeatures.length === 0) {
            formData.append("[network_private_line_configurations][]private_line_features", "");
          }
          Belt_Array.forEach(configuration.privateLineFeatures, (function (service) {
                  formData.append("[network_private_line_configurations][][private_line_features][]", service);
                }));
          formData.append("[network_private_line_configurations][]access_type", configuration.accessType);
          formData.append("[network_private_line_configurations][]port_speed", configuration.portSpeed);
          Belt_Array.forEach(configuration.locations, (function ($$location) {
                  formData.append("[network_private_line_configurations][][location_zs][]street_address", $$location.streetAddress);
                  formData.append("[network_private_line_configurations][][location_zs][]building_or_floor", Belt_Option.getWithDefault($$location.buildingOrFloor, ""));
                  formData.append("[network_private_line_configurations][][location_zs][]suite", Belt_Option.getWithDefault($$location.suite, ""));
                  formData.append("[network_private_line_configurations][][location_zs][]city_or_town", $$location.cityOrTown);
                  formData.append("[network_private_line_configurations][][location_zs][]sub_locality", Belt_Option.getWithDefault($$location.subLocality, ""));
                  formData.append("[network_private_line_configurations][][location_zs][]state_or_province", $$location.stateOrProvince);
                  formData.append("[network_private_line_configurations][][location_zs][]country", $$location.country);
                  formData.append("[network_private_line_configurations][][location_zs][]postal_code", $$location.postalCode);
                  formData.append("[network_private_line_configurations][][location_zs][]prefix", Belt_Option.getWithDefault($$location.prefix, ""));
                  formData.append("[network_private_line_configurations][][location_zs][]additional_location_details", Belt_Option.getWithDefault($$location.additionalLocationDetails, ""));
                  if ($$location.privateLineFeatures.length === 0) {
                    formData.append("[network_private_line_configurations][][location_zs][]private_line_features", "");
                  }
                  Belt_Array.forEach($$location.privateLineFeatures, (function (service) {
                          formData.append("[network_private_line_configurations][][location_zs][][private_line_features][]", service);
                        }));
                  formData.append("[network_private_line_configurations][][location_zs][]access_type", $$location.accessType);
                  formData.append("[network_private_line_configurations][][location_zs][]port_speed", $$location.portSpeed);
                }));
        }));
  Belt_Array.forEach(data.documents, (function ($$document) {
          formData.append("[documents][]", $$document);
        }));
  Belt_Array.forEach(data.deletedDocumentIds, (function (id) {
          formData.append("[deleted_document_ids][]", Project.DocumentId.toString(id));
        }));
  formData.append("[geographical_region]", Belt_Option.getWithDefault(data.geographicalRegion, ""));
  return formData;
}

function update$1(input, index, updater) {
  return {
          configurations: Belt_Array.mapWithIndex(input.configurations, (function (idx, configuration) {
                  if (idx !== index) {
                    return configuration;
                  } else {
                    return updater(configuration);
                  }
                })),
          documents: input.documents,
          deletedDocumentIds: input.deletedDocumentIds,
          geographicalRegion: input.geographicalRegion,
          validateRegion: input.validateRegion
        };
}

function remove$1(input, index) {
  return {
          configurations: Belt_Array.keepWithIndex(input.configurations, (function (param, idx) {
                  return idx !== index;
                })),
          documents: input.documents,
          deletedDocumentIds: input.deletedDocumentIds,
          geographicalRegion: input.geographicalRegion,
          validateRegion: input.validateRegion
        };
}

var Configuration = {
  empty: empty$1,
  inputDecoder: inputDecoder$1,
  inputFromJson: inputFromJson$1,
  inputToJson: inputToJson$1,
  outputToFormData: outputToFormData,
  update: update$1,
  remove: remove$1
};

function toJson(input) {
  return Json_Encode$JsonCombinators.object([[
                "networkPrivateLineConfigurations",
                Belt_Array.map(input.configurations, inputToJson$1)
              ]]);
}

var Input = {
  toJson: toJson
};

function toFormData(data) {
  return outputToFormData(data);
}

var Output = {
  toFormData: toFormData
};

export {
  validateFieldOfCollection2OnChangeWithValidator ,
  validateFieldOfCollection2OnBlurWithValidator ,
  validators ,
  initialFieldsStatuses ,
  initialCollectionsStatuses ,
  initialState ,
  validateForm ,
  useForm ,
  $$Location ,
  Configuration ,
  Input ,
  Output ,
}
/* inputDecoder Not a pure module */
