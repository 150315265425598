// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as Rest from "../Rest.res.js";
import * as ColocationProduct from "../../models/ColocationProduct.res.js";

function exec(productId, body) {
  return Rest.$$fetch("/dashboard/products/" + (ID.toString(productId) + "/videos"), {
              NAME: "Post",
              VAL: body
            }, {
              NAME: "Json",
              VAL: ColocationProduct.Draft.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
