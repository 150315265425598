// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function toString(x) {
  switch (x) {
    case "Admin" :
        return "admin";
    case "Provider" :
        return "provider";
    case "User" :
        return "user";
    case "Visitor" :
        return "visitor";
    case "Concierge" :
        return "concierge";
    case "Agent" :
        return "agent";
    
  }
}

function fromString(x) {
  switch (x) {
    case "admin" :
        return "Admin";
    case "agent" :
        return "Agent";
    case "concierge" :
        return "Concierge";
    case "provider" :
        return "Provider";
    case "user" :
        return "User";
    case "visitor" :
        return "Visitor";
    default:
      return "Visitor";
  }
}

var decoder = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.string, fromString);

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function toCapital(x) {
  switch (x) {
    case "Admin" :
        return "Admin";
    case "Provider" :
        return "Provider";
    case "User" :
        return "User";
    case "Visitor" :
        return "";
    case "Concierge" :
        return "Concierge";
    case "Agent" :
        return "Agent";
    
  }
}

function toJson(role) {
  switch (role) {
    case "Admin" :
        return "admin";
    case "Provider" :
        return "provider";
    case "User" :
        return "user";
    case "Visitor" :
        return "visitor";
    case "Concierge" :
        return "concierge";
    case "Agent" :
        return "agent";
    
  }
}

var filterFields = [
  "Admin",
  "Provider",
  "User",
  "Agent",
  "Concierge",
  "Visitor"
];

export {
  toString ,
  fromString ,
  decoder ,
  fromJson ,
  toCapital ,
  toJson ,
  filterFields ,
}
/* decoder Not a pure module */
