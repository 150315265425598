// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as ProjectProposal from "../../models/ProjectProposal.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(proposal, message) {
  return Json_Encode$JsonCombinators.object([
              [
                "status",
                ProjectProposal.Status.toJson(proposal.status)
              ],
              [
                "message",
                message !== undefined ? message : ""
              ]
            ]);
}

var Req = {
  toJson: toJson
};

var decoder = Json_Decode$JsonCombinators.map(ProjectProposal.decoder, (function (proposal) {
        return {
                proposal: proposal
              };
      }));

var Res = {
  decoder: decoder
};

function exec(projectId, proposal, message, param) {
  return Rest.$$fetch("/projects/" + (projectId + ("/proposals/" + (proposal.id + ("/" + ProjectProposal.Status.toApiString(proposal.status))))), {
              NAME: "Patch",
              VAL: toJson(proposal, message)
            }, {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
