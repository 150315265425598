// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function empty() {
  return {
          companyName: "",
          addressLine1: "",
          addressLine2: "",
          country: "",
          state: "",
          city: "",
          zip: ""
        };
}

function toJson(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "company_name",
                input.companyName
              ],
              [
                "address_line_1",
                input.addressLine1
              ],
              [
                "address_line_2",
                input.addressLine2
              ],
              [
                "country",
                input.country
              ],
              [
                "state",
                input.state
              ],
              [
                "city",
                input.city
              ],
              [
                "zip",
                input.zip
              ]
            ]);
}

var Input = {
  empty: empty,
  toJson: toJson
};

var ValidationResult = {};

function checkNotEmpty(fieldValue) {
  var match = fieldValue.trim();
  if (match === "") {
    return {
            TAG: "Error",
            _0: "field should not be empty"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function all(state) {
  return {
          companyName: checkNotEmpty(state.companyName),
          addressLine1: checkNotEmpty(state.addressLine1),
          addressLine2: {
            TAG: "Ok",
            _0: undefined
          },
          country: checkNotEmpty(state.country),
          state: {
            TAG: "Ok",
            _0: undefined
          },
          city: checkNotEmpty(state.city),
          zip: checkNotEmpty(state.zip)
        };
}

function valid(result) {
  if (result.companyName.TAG === "Ok" && result.addressLine1.TAG === "Ok" && result.addressLine2.TAG === "Ok" && result.country.TAG === "Ok" && result.state.TAG === "Ok" && result.city.TAG === "Ok" && result.zip.TAG === "Ok") {
    return true;
  } else {
    return false;
  }
}

var Validate = {
  checkNotEmpty: checkNotEmpty,
  all: all,
  valid: valid
};

export {
  Input ,
  ValidationResult ,
  Validate ,
}
/* No side effect */
