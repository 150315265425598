// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          locationId: js.locationId,
          name: js.name,
          fullAddress: js.fullAddress,
          longitude: js.longitude,
          latitude: js.latitude,
          url: js.url,
          image: js.image,
          providerId: js.providerId,
          providerName: js.providerName,
          providerLogo: js.providerLogo,
          selected: js.selected
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              locationId: field.required("locationId", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              fullAddress: field.required("fullAddress", Json_Decode$JsonCombinators.string),
              longitude: field.required("longitude", Json_Decode$JsonCombinators.$$float),
              latitude: field.required("latitude", Json_Decode$JsonCombinators.$$float),
              url: field.required("url", Json_Decode$JsonCombinators.string),
              image: field.required("image", Json_Decode$JsonCombinators.string),
              providerId: field.required("providerId", ID.decoder),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.string),
              providerLogo: field.required("providerLogo", Json_Decode$JsonCombinators.string),
              selected: field.required("selected", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  fromJs ,
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
