// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Csrf from "../Csrf.res.js";
import * as Http from "../Http.res.js";
import * as $$Promise from "../../bindings/Promise.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../loggers/SentryLogger.res.js";
import * as CreateNetworkProjectMplsNetworkConfigurationForm from "../../bundles/user-dashboard/projects/create-network-project-mpls-network-configuration/CreateNetworkProjectMplsNetworkConfigurationForm.res.js";

var toFormData = CreateNetworkProjectMplsNetworkConfigurationForm.Output.toFormData;

var Req = {
  toFormData: toFormData
};

function exec(data) {
  return $$Promise.andThen(fetch("/api/v1/projects/save_network_mpls_configurations", {
                  method: Http.Method.toFetch({
                        NAME: "Put",
                        VAL: undefined
                      }),
                  body: Caml_option.some(toFormData(data)),
                  headers: Caml_option.some(new Headers([[
                              "X-CSRF-Token",
                              Belt_Option.getExn(Csrf.token())
                            ]])),
                  credentials: "same-origin"
                }), (function (response) {
                if (response.ok) {
                  return $$Promise.resolve({
                              TAG: "Ok",
                              _0: undefined
                            });
                }
                var match = Http.ContentType.fromHeaders(response.headers);
                if (match !== undefined && typeof match !== "object") {
                  switch (match) {
                    case "Text" :
                        return $$Promise.map(response.text(), (function (text) {
                                      SentryLogger.error4({
                                            rootModule: "SaveNetworkProjectMplsNetworkConfigurationRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "SaveNetworkProjectMplsNetworkConfigurationRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "text"
                                          ], [
                                            "responseBody",
                                            text
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: undefined
                                            };
                                    }));
                    case "Json" :
                        return $$Promise.map(response.json(), (function (json) {
                                      SentryLogger.error4({
                                            rootModule: "SaveNetworkProjectMplsNetworkConfigurationRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "SaveNetworkProjectMplsNetworkConfigurationRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "json"
                                          ], [
                                            "responseBody",
                                            json
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: undefined
                                            };
                                    }));
                    case "Blob" :
                        return $$Promise.map(response.blob(), (function (blob) {
                                      SentryLogger.error4({
                                            rootModule: "SaveNetworkProjectMplsNetworkConfigurationRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "SaveNetworkProjectMplsNetworkConfigurationRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "blob"
                                          ], [
                                            "responseBody",
                                            blob
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: undefined
                                            };
                                    }));
                    
                  }
                }
                SentryLogger.error3({
                      rootModule: "SaveNetworkProjectMplsNetworkConfigurationRequest",
                      subModulePath: /* [] */0,
                      value: "exec",
                      fullPath: "SaveNetworkProjectMplsNetworkConfigurationRequest.exec"
                    }, "Fetch::RequestError", [
                      "code",
                      response.status
                    ], [
                      "status",
                      response.statusText
                    ], [
                      "contentType",
                      "N/A"
                    ]);
                return $$Promise.resolve({
                            TAG: "Error",
                            _0: undefined
                          });
              }));
}

export {
  Req ,
  exec ,
}
/* Csrf Not a pure module */
