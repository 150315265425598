// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Validator from "Utils/validator";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function empty(param) {
  return {
          firstName: "",
          lastName: "",
          email: "",
          companyName: "",
          phoneNumber: "",
          dialCountryCode: "",
          country: "",
          password: "",
          passwordConfirm: "",
          isAgreeWithTerms: false,
          isReceiveNews: false
        };
}

function toJson(input, form) {
  var tmp;
  tmp = form === "Individual" ? "user" : "provider";
  return Json_Encode$JsonCombinators.object([
              [
                "first_name",
                input.firstName
              ],
              [
                "last_name",
                input.lastName
              ],
              [
                "email",
                input.email
              ],
              [
                "phone",
                Js_string.replace(input.dialCountryCode, "", input.phoneNumber).trim()
              ],
              [
                "provider_name_requested",
                input.companyName
              ],
              [
                "password",
                input.password
              ],
              [
                "password_confirmation",
                input.passwordConfirm
              ],
              [
                "dial_code",
                input.dialCountryCode
              ],
              [
                "dial_code_country",
                input.country
              ],
              [
                "newsletter_subscription",
                input.isReceiveNews
              ],
              [
                "role",
                tmp
              ]
            ]);
}

var Input = {
  empty: empty,
  toJson: toJson
};

function toJs(input) {
  return {
          firstName: input.firstName,
          lastName: input.lastName,
          email: input.email,
          phoneNumber: input.phoneNumber,
          companyName: input.companyName,
          password: input.password,
          passwordConfirm: input.passwordConfirm,
          isAgreeWithTerms: input.isAgreeWithTerms
        };
}

var ValidationInput = {
  toJs: toJs
};

function fromJs(js) {
  return {
          firstName: Caml_option.nullable_to_opt(js.firstName),
          lastName: Caml_option.nullable_to_opt(js.lastName),
          email: Caml_option.nullable_to_opt(js.email),
          phoneNumber: Caml_option.nullable_to_opt(js.phoneNumber),
          companyName: Caml_option.nullable_to_opt(js.companyName),
          password: Caml_option.nullable_to_opt(js.password),
          passwordConfirm: Caml_option.nullable_to_opt(js.passwordConfirm),
          isAgreeWithTerms: Caml_option.nullable_to_opt(js.isAgreeWithTerms)
        };
}

var ExternalValidationResult = {
  fromJs: fromJs
};

function convertResult(field) {
  if (field !== undefined) {
    return {
            TAG: "Error",
            _0: Caml_option.valFromOption(field)
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function fromExternalResult(results) {
  return {
          firstName: convertResult(results.firstName),
          lastName: convertResult(results.lastName),
          email: convertResult(results.email),
          phoneNumber: convertResult(results.phoneNumber),
          companyName: convertResult(results.companyName),
          password: convertResult(results.password),
          passwordConfirm: convertResult(results.passwordConfirm),
          isAgreeWithTerms: convertResult(results.isAgreeWithTerms)
        };
}

var ValidationResult = {
  convertResult: convertResult,
  fromExternalResult: fromExternalResult
};

var validationFields = [
  {
    id: "firstName",
    validation: [
      {
        TAG: "BasicRule",
        _0: {
          rule: "required",
          message: "First name is required."
        }
      },
      {
        TAG: "BasicRule",
        _0: {
          rule: "empty",
          message: "Enter valid first name."
        }
      }
    ]
  },
  {
    id: "lastName",
    validation: [
      {
        TAG: "BasicRule",
        _0: {
          rule: "required",
          message: "Last name is required."
        }
      },
      {
        TAG: "BasicRule",
        _0: {
          rule: "empty",
          message: "Enter valid last name."
        }
      }
    ]
  },
  {
    id: "email",
    validation: [
      {
        TAG: "BasicRule",
        _0: {
          rule: "required",
          message: "Email address is required."
        }
      },
      {
        TAG: "BasicRule",
        _0: {
          rule: "email",
          message: "Email address is not valid."
        }
      },
      {
        TAG: "BasicRule",
        _0: {
          rule: "empty",
          message: "Enter valid email address."
        }
      }
    ]
  },
  {
    id: "phoneNumber",
    validation: [{
        TAG: "BasicRule",
        _0: {
          rule: "required",
          message: "Phone Number is required."
        }
      }]
  },
  {
    id: "password",
    validation: [
      {
        TAG: "BasicRule",
        _0: {
          rule: "required",
          message: "Password is required."
        }
      },
      {
        TAG: "LengthRule",
        _0: {
          rule: "minlength",
          message: "Password must be atleast 8 characters in length.",
          length: 8
        }
      }
    ]
  },
  {
    id: "passwordConfirm",
    validation: [
      {
        TAG: "BasicRule",
        _0: {
          rule: "required",
          message: "Password is required."
        }
      },
      {
        TAG: "LengthRule",
        _0: {
          rule: "minlength",
          message: "Password must be atleast 8 characters in length.",
          length: 8
        }
      },
      {
        TAG: "MatchRule",
        _0: {
          rule: "matchPassword",
          matchWithField: "password",
          message: "Confirm password and password must be match."
        }
      }
    ]
  },
  {
    id: "companyName",
    validation: [
      {
        TAG: "BasicRule",
        _0: {
          rule: "required",
          message: "Company name is required."
        }
      },
      {
        TAG: "BasicRule",
        _0: {
          rule: "empty",
          message: "Enter valid company name."
        }
      }
    ]
  },
  {
    id: "isAgreeWithTerms",
    validation: [{
        TAG: "BasicRule",
        _0: {
          rule: "required",
          message: "Agreement is required."
        }
      }]
  }
];

function fieldsToJs(fields) {
  return Belt_Array.map(fields, (function (item) {
                return {
                        id: item.id,
                        validation: Belt_Array.map(item.validation, (function (rule) {
                                return rule._0;
                              }))
                      };
              }));
}

function all(values) {
  return fromExternalResult(fromJs(Validator.formErrorsValidations(toJs(values), fieldsToJs(validationFields))));
}

function valid(result) {
  if (result.firstName.TAG === "Ok" && result.lastName.TAG === "Ok" && result.email.TAG === "Ok" && result.phoneNumber.TAG === "Ok" && result.companyName.TAG === "Ok" && result.password.TAG === "Ok" && result.passwordConfirm.TAG === "Ok" && result.isAgreeWithTerms.TAG === "Ok") {
    return true;
  } else {
    return false;
  }
}

var Validate = {
  validationFields: validationFields,
  fieldsToJs: fieldsToJs,
  all: all,
  valid: valid
};

export {
  Input ,
  ValidationInput ,
  ExternalValidationResult ,
  ValidationResult ,
  Validate ,
}
/* Utils/validator Not a pure module */
