// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";

var include = ID.Int({});

var T = include.T;

var eq = include.eq;

var toString = include.toString;

var encoder = include.encoder;

var toJson = include.toJson;

var decoder = include.decoder;

var fromJson = include.fromJson;

var toInt = include.toInt;

var Comparable = include.Comparable;

var $$Map = include.$$Map;

var $$Set = include.$$Set;

export {
  T ,
  eq ,
  toString ,
  encoder ,
  toJson ,
  decoder ,
  fromJson ,
  toInt ,
  Comparable ,
  $$Map ,
  $$Set ,
}
/* include Not a pure module */
