// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as ProjectProvider from "../../models/ProjectProvider.res.js";
import * as ProjectProviderId from "../../models/ProjectProviderId.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(reviewStatus) {
  return Json_Encode$JsonCombinators.object([[
                "reviewStatus",
                ProjectProvider.Status.toJson(reviewStatus)
              ]]);
}

var Req = {
  toJson: toJson
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              projectProvider: field.required("projectProvider", ProjectProvider.decoder)
            };
    });

var Res = {
  decoder: decoder
};

function exec(projectProviderId, reviewStatus) {
  return Rest.$$fetch("/providers_projects/" + (ProjectProviderId.toString(projectProviderId) + "/status"), {
              NAME: "Patch",
              VAL: toJson(reviewStatus)
            }, {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
