// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../../../../libs/Date.res.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import IsPast from "date-fns/isPast";
import IsToday from "date-fns/isToday";
import * as NonSecure from "nanoid/non-secure";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function validateFieldOfCollection2OnChangeWithValidator(input, index, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || fieldStatus._1 !== "Shown") {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 2;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 1;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        return setStatus({
                    TAG: "Dirty",
                    _0: validator.validate(input, index),
                    _1: "Hidden"
                  });
    case 2 :
        var result = validator.validate(input, index);
        if (result.TAG === "Ok") {
          return setStatus({
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return setStatus({
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
    case 3 :
        return setStatus({
                    TAG: "Dirty",
                    _0: validator.validate(input, index),
                    _1: "Shown"
                  });
    
  }
}

function validateDependentFieldOfCollection2OnChange(input, index, fieldStatus, validator, setStatus) {
  if (typeof fieldStatus !== "object" || fieldStatus._1 !== "Shown") {
    return ;
  } else {
    return Caml_option.some(setStatus({
                    TAG: "Dirty",
                    _0: validator.validate(input, index),
                    _1: "Shown"
                  }));
  }
}

function validateFieldOfCollection2OnBlurWithValidator(input, index, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(setStatus({
                        TAG: "Dirty",
                        _0: validator.validate(input, index),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      return Caml_option.some(setStatus({
                      TAG: "Dirty",
                      _0: validator.validate(input, index),
                      _1: "Shown"
                    }));
    }
    
  }
  
}

var validators = {
  configurations: {
    collection: (function (param) {
        if (param.configurations.length !== 0) {
          return {
                  TAG: "Ok",
                  _0: undefined
                };
        } else {
          return {
                  TAG: "Error",
                  _0: "At least one configuration is required"
                };
        }
      }),
    fields: {
      startDate: {
        strategy: "OnFirstBlur",
        validate: (function (param, at) {
            var date = param.configurations[at].startDate;
            if (date === undefined) {
              return {
                      TAG: "Error",
                      _0: "Service start date is required"
                    };
            }
            var date$1 = Caml_option.valFromOption(date);
            if (IsPast(date$1) || IsToday(date$1)) {
              return {
                      TAG: "Error",
                      _0: "Start date can't be in the past"
                    };
            } else {
              return {
                      TAG: "Ok",
                      _0: date$1
                    };
            }
          })
      },
      contractLength: {
        strategy: "OnFirstBlur",
        validate: (function (param, at) {
            var x = param.configurations[at].contractLength;
            if (x !== undefined) {
              return {
                      TAG: "Ok",
                      _0: x.value
                    };
            } else {
              return {
                      TAG: "Error",
                      _0: "Contract length is required"
                    };
            }
          })
      },
      estimatedServers: {
        strategy: "OnFirstBlur",
        validate: (function (param, at) {
            var x = param.configurations[at].estimatedServers;
            if (x !== undefined) {
              if (x <= 0) {
                return {
                        TAG: "Error",
                        _0: "Estimated servers must be greater than zero"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            } else {
              return {
                      TAG: "Error",
                      _0: "Estimated servers is required"
                    };
            }
          })
      },
      estimatedBudget: {
        strategy: "OnFirstBlur",
        validate: (function (param, at) {
            var x = param.configurations[at].estimatedBudget;
            if (x !== undefined) {
              return {
                      TAG: "Ok",
                      _0: x
                    };
            } else {
              return {
                      TAG: "Error",
                      _0: "Estimated budget is required"
                    };
            }
          })
      },
      regions: {
        strategy: "OnFirstBlur",
        validate: (function (param, at) {
            var configurations = param.configurations;
            var match = Belt_SetString.toArray(configurations[at].regions);
            if (match.length !== 0) {
              return {
                      TAG: "Ok",
                      _0: Belt_SetString.toArray(configurations[at].regions)
                    };
            } else {
              return {
                      TAG: "Error",
                      _0: "At least one region is required"
                    };
            }
          })
      },
      operatingSystems: {
        strategy: "OnFirstBlur",
        validate: (function (param, at) {
            var configurations = param.configurations;
            var match = Belt_SetString.toArray(configurations[at].operatingSystems);
            if (match.length !== 0) {
              return {
                      TAG: "Ok",
                      _0: Belt_SetString.toArray(configurations[at].operatingSystems)
                    };
            } else {
              return {
                      TAG: "Error",
                      _0: "At least one operating system is required"
                    };
            }
          })
      }
    }
  }
};

function initialFieldsStatuses(input) {
  return {
          configurations: Belt_Array.map(input.configurations, (function (_configuration) {
                  return {
                          startDate: "Pristine",
                          contractLength: "Pristine",
                          estimatedServers: "Pristine",
                          estimatedBudget: "Pristine",
                          regions: "Pristine",
                          operatingSystems: "Pristine"
                        };
                }))
        };
}

function initialCollectionsStatuses(_input) {
  return {
          configurations: {
            self: undefined
          }
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          formStatus: "Editing",
          collectionsStatuses: {
            configurations: {
              self: undefined
            }
          },
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = validators.configurations.collection(input);
  var match$1 = Belt_Array.reduceWithIndex(fieldsStatuses.configurations, [
        {
          TAG: "Ok",
          _0: []
        },
        []
      ], (function (param, fieldStatus, configurationIndex) {
          var fieldStatuses = param[1];
          var output = param[0];
          var match = fieldStatus.startDate;
          var tmp;
          tmp = typeof match !== "object" ? validators.configurations.fields.startDate.validate(input, configurationIndex) : match._0;
          var match$1 = fieldStatus.contractLength;
          var tmp$1;
          tmp$1 = typeof match$1 !== "object" ? validators.configurations.fields.contractLength.validate(input, configurationIndex) : match$1._0;
          var match$2 = fieldStatus.estimatedServers;
          var tmp$2;
          tmp$2 = typeof match$2 !== "object" ? validators.configurations.fields.estimatedServers.validate(input, configurationIndex) : match$2._0;
          var match$3 = fieldStatus.estimatedBudget;
          var tmp$3;
          tmp$3 = typeof match$3 !== "object" ? validators.configurations.fields.estimatedBudget.validate(input, configurationIndex) : match$3._0;
          var match$4 = fieldStatus.regions;
          var tmp$4;
          tmp$4 = typeof match$4 !== "object" ? validators.configurations.fields.regions.validate(input, configurationIndex) : match$4._0;
          var match$5 = fieldStatus.operatingSystems;
          var tmp$5;
          tmp$5 = typeof match$5 !== "object" ? validators.configurations.fields.operatingSystems.validate(input, configurationIndex) : match$5._0;
          if (output.TAG === "Ok") {
            var startDateResult = tmp;
            var output$1 = output._0;
            if (startDateResult.TAG === "Ok") {
              var contractLengthResult = tmp$1;
              if (contractLengthResult.TAG === "Ok") {
                var estimatedServersResult = tmp$2;
                if (estimatedServersResult.TAG === "Ok") {
                  var estimatedBudgetResult = tmp$3;
                  if (estimatedBudgetResult.TAG === "Ok") {
                    var regionsResult = tmp$4;
                    if (regionsResult.TAG === "Ok") {
                      var operatingSystemsResult = tmp$5;
                      if (operatingSystemsResult.TAG === "Ok") {
                        output$1.push({
                              startDate: startDateResult._0,
                              contractLength: contractLengthResult._0,
                              estimatedServers: estimatedServersResult._0,
                              estimatedBudget: estimatedBudgetResult._0,
                              regions: regionsResult._0,
                              operatingSystems: operatingSystemsResult._0
                            });
                        fieldStatuses.push({
                              startDate: {
                                TAG: "Dirty",
                                _0: startDateResult,
                                _1: "Shown"
                              },
                              contractLength: {
                                TAG: "Dirty",
                                _0: contractLengthResult,
                                _1: "Shown"
                              },
                              estimatedServers: {
                                TAG: "Dirty",
                                _0: estimatedServersResult,
                                _1: "Shown"
                              },
                              estimatedBudget: {
                                TAG: "Dirty",
                                _0: estimatedBudgetResult,
                                _1: "Shown"
                              },
                              regions: {
                                TAG: "Dirty",
                                _0: regionsResult,
                                _1: "Shown"
                              },
                              operatingSystems: {
                                TAG: "Dirty",
                                _0: operatingSystemsResult,
                                _1: "Shown"
                              }
                            });
                        return [
                                {
                                  TAG: "Ok",
                                  _0: output$1
                                },
                                fieldStatuses
                              ];
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }
            
          }
          fieldStatuses.push({
                startDate: {
                  TAG: "Dirty",
                  _0: tmp,
                  _1: "Shown"
                },
                contractLength: {
                  TAG: "Dirty",
                  _0: tmp$1,
                  _1: "Shown"
                },
                estimatedServers: {
                  TAG: "Dirty",
                  _0: tmp$2,
                  _1: "Shown"
                },
                estimatedBudget: {
                  TAG: "Dirty",
                  _0: tmp$3,
                  _1: "Shown"
                },
                regions: {
                  TAG: "Dirty",
                  _0: tmp$4,
                  _1: "Shown"
                },
                operatingSystems: {
                  TAG: "Dirty",
                  _0: tmp$5,
                  _1: "Shown"
                }
              });
          return [
                  {
                    TAG: "Error",
                    _0: undefined
                  },
                  fieldStatuses
                ];
        }));
  if (match.TAG === "Ok") {
    var configurations = match$1[0];
    if (configurations.TAG === "Ok") {
      return {
              TAG: "Valid",
              output: {
                configurations: configurations._0
              },
              fieldsStatuses: {
                configurations: match$1[1]
              },
              collectionsStatuses: {
                configurations: {
                  self: match
                }
              }
            };
    }
    
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            configurations: match$1[1]
          },
          collectionsStatuses: {
            configurations: {
              self: match
            }
          }
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              formStatus: "Editing",
                              collectionsStatuses: match$1.collectionsStatuses,
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            collectionsStatuses: match$1.collectionsStatuses,
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              formStatus: "Editing",
                              collectionsStatuses: state.collectionsStatuses,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            formStatus: "Editing",
                            collectionsStatuses: state.collectionsStatuses,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateConfigurationStartDateField" :
                  var index = action._1;
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput, index, state.fieldsStatuses.configurations[index].startDate, state.submissionStatus, validators.configurations.fields.startDate, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index) {
                                                      return {
                                                              startDate: status,
                                                              contractLength: item.contractLength,
                                                              estimatedServers: item.estimatedServers,
                                                              estimatedBudget: item.estimatedBudget,
                                                              regions: item.regions,
                                                              operatingSystems: item.operatingSystems
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            formStatus: state.formStatus,
                            collectionsStatuses: state.collectionsStatuses,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationContractLengthField" :
                  var index$1 = action._1;
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$1, index$1, state.fieldsStatuses.configurations[index$1].contractLength, state.submissionStatus, validators.configurations.fields.contractLength, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$1) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: status,
                                                              estimatedServers: item.estimatedServers,
                                                              estimatedBudget: item.estimatedBudget,
                                                              regions: item.regions,
                                                              operatingSystems: item.operatingSystems
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            formStatus: state.formStatus,
                            collectionsStatuses: state.collectionsStatuses,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationEstimatedServersField" :
                  var index$2 = action._1;
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$2, index$2, state.fieldsStatuses.configurations[index$2].estimatedServers, state.submissionStatus, validators.configurations.fields.estimatedServers, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$2) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              estimatedServers: status,
                                                              estimatedBudget: item.estimatedBudget,
                                                              regions: item.regions,
                                                              operatingSystems: item.operatingSystems
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            formStatus: state.formStatus,
                            collectionsStatuses: state.collectionsStatuses,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationEstimatedBudgetField" :
                  var index$3 = action._1;
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$3, index$3, state.fieldsStatuses.configurations[index$3].estimatedBudget, state.submissionStatus, validators.configurations.fields.estimatedBudget, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$3) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              estimatedServers: item.estimatedServers,
                                                              estimatedBudget: status,
                                                              regions: item.regions,
                                                              operatingSystems: item.operatingSystems
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            formStatus: state.formStatus,
                            collectionsStatuses: state.collectionsStatuses,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationRegionsField" :
                  var index$4 = action._1;
                  var nextInput$4 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$4, index$4, state.fieldsStatuses.configurations[index$4].regions, state.submissionStatus, validators.configurations.fields.regions, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$4) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              estimatedServers: item.estimatedServers,
                                                              estimatedBudget: item.estimatedBudget,
                                                              regions: status,
                                                              operatingSystems: item.operatingSystems
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            formStatus: state.formStatus,
                            collectionsStatuses: state.collectionsStatuses,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationOperatingSystemsField" :
                  var index$5 = action._1;
                  var nextInput$5 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$5, index$5, state.fieldsStatuses.configurations[index$5].operatingSystems, state.submissionStatus, validators.configurations.fields.operatingSystems, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$5) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              estimatedServers: item.estimatedServers,
                                                              estimatedBudget: item.estimatedBudget,
                                                              regions: item.regions,
                                                              operatingSystems: status
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            formStatus: state.formStatus,
                            collectionsStatuses: state.collectionsStatuses,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "BlurConfigurationStartDateField" :
                  var index$6 = action._0;
                  var result = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$6, state.fieldsStatuses.configurations[index$6].startDate, validators.configurations.fields.startDate, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$6) {
                                            return {
                                                    startDate: status,
                                                    contractLength: item.contractLength,
                                                    estimatedServers: item.estimatedServers,
                                                    estimatedBudget: item.estimatedBudget,
                                                    regions: item.regions,
                                                    operatingSystems: item.operatingSystems
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              formStatus: state.formStatus,
                              collectionsStatuses: state.collectionsStatuses,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationContractLengthField" :
                  var index$7 = action._0;
                  var result$1 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$7, state.fieldsStatuses.configurations[index$7].contractLength, validators.configurations.fields.contractLength, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$7) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: status,
                                                    estimatedServers: item.estimatedServers,
                                                    estimatedBudget: item.estimatedBudget,
                                                    regions: item.regions,
                                                    operatingSystems: item.operatingSystems
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              formStatus: state.formStatus,
                              collectionsStatuses: state.collectionsStatuses,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationEstimatedServersField" :
                  var index$8 = action._0;
                  var result$2 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$8, state.fieldsStatuses.configurations[index$8].estimatedServers, validators.configurations.fields.estimatedServers, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$8) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    estimatedServers: status,
                                                    estimatedBudget: item.estimatedBudget,
                                                    regions: item.regions,
                                                    operatingSystems: item.operatingSystems
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              formStatus: state.formStatus,
                              collectionsStatuses: state.collectionsStatuses,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationEstimatedBudgetField" :
                  var index$9 = action._0;
                  var result$3 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$9, state.fieldsStatuses.configurations[index$9].estimatedBudget, validators.configurations.fields.estimatedBudget, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$9) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    estimatedServers: item.estimatedServers,
                                                    estimatedBudget: status,
                                                    regions: item.regions,
                                                    operatingSystems: item.operatingSystems
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              formStatus: state.formStatus,
                              collectionsStatuses: state.collectionsStatuses,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationRegionsField" :
                  var index$10 = action._0;
                  var result$4 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$10, state.fieldsStatuses.configurations[index$10].regions, validators.configurations.fields.regions, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$10) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    estimatedServers: item.estimatedServers,
                                                    estimatedBudget: item.estimatedBudget,
                                                    regions: status,
                                                    operatingSystems: item.operatingSystems
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              formStatus: state.formStatus,
                              collectionsStatuses: state.collectionsStatuses,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationOperatingSystemsField" :
                  var index$11 = action._0;
                  var result$5 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$11, state.fieldsStatuses.configurations[index$11].operatingSystems, validators.configurations.fields.operatingSystems, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$11) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    estimatedServers: item.estimatedServers,
                                                    estimatedBudget: item.estimatedBudget,
                                                    regions: item.regions,
                                                    operatingSystems: status
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              formStatus: state.formStatus,
                              collectionsStatuses: state.collectionsStatuses,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "AddConfigurationEntry" :
                  var nextInput$6 = {
                    configurations: Belt_Array.concat(state.input.configurations, [action._0])
                  };
                  var nextFieldsStatuses = {
                    configurations: Belt_Array.concat(state.fieldsStatuses.configurations, [{
                            startDate: "Pristine",
                            contractLength: "Pristine",
                            estimatedServers: "Pristine",
                            estimatedBudget: "Pristine",
                            regions: "Pristine",
                            operatingSystems: "Pristine"
                          }])
                  };
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: nextFieldsStatuses,
                            formStatus: state.formStatus,
                            collectionsStatuses: state.collectionsStatuses,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "RemoveConfigurationEntry" :
                  var index$12 = action._0;
                  var nextInput$7 = {
                    configurations: Belt_Array.keepWithIndex(state.input.configurations, (function (param, i) {
                            return i !== index$12;
                          }))
                  };
                  var nextFieldsStatuses$1 = {
                    configurations: Belt_Array.keepWithIndex(state.fieldsStatuses.configurations, (function (param, i) {
                            return i !== index$12;
                          }))
                  };
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: nextFieldsStatuses$1,
                            formStatus: state.formStatus,
                            collectionsStatuses: state.collectionsStatuses,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              formStatus: "Submitted",
                              collectionsStatuses: state.collectionsStatuses,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              formStatus: "Submitted",
                              collectionsStatuses: state.collectionsStatuses,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            collectionsStatuses: state.collectionsStatuses,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateConfigurationStartDate: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationStartDateField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationContractLength: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationContractLengthField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationEstimatedServers: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationEstimatedServersField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationEstimatedBudget: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationEstimatedBudgetField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationRegions: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationRegionsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationOperatingSystems: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationOperatingSystemsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          blurConfigurationStartDate: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationStartDateField",
                    _0: index
                  });
            }),
          blurConfigurationContractLength: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationContractLengthField",
                    _0: index
                  });
            }),
          blurConfigurationEstimatedServers: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationEstimatedServersField",
                    _0: index
                  });
            }),
          blurConfigurationEstimatedBudget: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationEstimatedBudgetField",
                    _0: index
                  });
            }),
          blurConfigurationRegions: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationRegionsField",
                    _0: index
                  });
            }),
          blurConfigurationOperatingSystems: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationOperatingSystemsField",
                    _0: index
                  });
            }),
          configurationStartDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].startDate);
            }),
          configurationContractLengthResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].contractLength);
            }),
          configurationEstimatedServersResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].estimatedServers);
            }),
          configurationEstimatedBudgetResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].estimatedBudget);
            }),
          configurationRegionsResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].regions);
            }),
          configurationOperatingSystemsResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].operatingSystems);
            }),
          addConfiguration: (function (entry) {
              dispatch({
                    TAG: "AddConfigurationEntry",
                    _0: entry
                  });
            }),
          removeConfiguration: (function (index) {
              dispatch({
                    TAG: "RemoveConfigurationEntry",
                    _0: index
                  });
            }),
          configurationsResult: state.collectionsStatuses.configurations.self,
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              return Belt_Array.every(state.fieldsStatuses.configurations, (function (item) {
                            var tmp = item.startDate;
                            if (typeof tmp === "object") {
                              return true;
                            }
                            var tmp$1 = item.contractLength;
                            if (typeof tmp$1 === "object") {
                              return true;
                            }
                            var tmp$2 = item.estimatedServers;
                            if (typeof tmp$2 === "object") {
                              return true;
                            }
                            var tmp$3 = item.estimatedBudget;
                            if (typeof tmp$3 === "object") {
                              return true;
                            }
                            var tmp$4 = item.regions;
                            if (typeof tmp$4 === "object") {
                              return true;
                            }
                            return true;
                          }));
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

function empty() {
  return {
          key: NonSecure.nanoid(),
          startDate: undefined,
          contractLength: undefined,
          estimatedServers: undefined,
          estimatedBudget: undefined,
          regions: undefined,
          operatingSystems: undefined
        };
}

function inputDecoder(contractLengths) {
  return Json_Decode$JsonCombinators.object(function (field) {
              return {
                      key: NonSecure.nanoid(),
                      startDate: field.required("startDate", Json_Decode$JsonCombinators.option($$Date.Naive.decoder)),
                      contractLength: Belt_Option.flatMap(field.required("contractLength", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)), (function (x) {
                              return Belt_Array.getBy(contractLengths, (function (cl) {
                                            return cl.value === x;
                                          }));
                            })),
                      estimatedServers: field.required("estimatedServers", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
                      estimatedBudget: field.required("estimatedBudget", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
                      regions: Belt_SetString.fromArray(field.required("regions", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))),
                      operatingSystems: Belt_SetString.fromArray(field.required("operatingSystems", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)))
                    };
            });
}

function inputFromJson(json, contractLengths) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, inputDecoder(contractLengths)));
}

function inputToJson(configuration) {
  var x = configuration.contractLength;
  return Json_Encode$JsonCombinators.object([
              [
                "startDate",
                $$Date.Naive.toJsonOpt(configuration.startDate)
              ],
              [
                "contractLength",
                x !== undefined ? x.value : null
              ],
              [
                "estimatedServers",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(configuration.estimatedServers)
              ],
              [
                "estimatedBudget",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(configuration.estimatedBudget)
              ],
              [
                "regions",
                Belt_SetString.toArray(configuration.regions)
              ],
              [
                "operatingSystems",
                Belt_SetString.toArray(configuration.operatingSystems)
              ]
            ]);
}

function outputToJson(configuration) {
  return Json_Encode$JsonCombinators.object([
              [
                "startDate",
                $$Date.Naive.toJson(configuration.startDate)
              ],
              [
                "contractLength",
                configuration.contractLength
              ],
              [
                "estimatedServers",
                configuration.estimatedServers
              ],
              [
                "estimatedBudget",
                configuration.estimatedBudget
              ],
              [
                "regions",
                configuration.regions
              ],
              [
                "operatingSystems",
                configuration.operatingSystems
              ]
            ]);
}

function update(input, index, updater) {
  return {
          configurations: Belt_Array.mapWithIndex(input.configurations, (function (idx, configuration) {
                  if (idx !== index) {
                    return configuration;
                  } else {
                    return updater(configuration);
                  }
                }))
        };
}

function remove(input, index) {
  return {
          configurations: Belt_Array.keepWithIndex(input.configurations, (function (param, idx) {
                  return idx !== index;
                }))
        };
}

var Configuration = {
  empty: empty,
  inputDecoder: inputDecoder,
  inputFromJson: inputFromJson,
  inputToJson: inputToJson,
  outputToJson: outputToJson,
  update: update,
  remove: remove
};

function toJson(input) {
  return Json_Encode$JsonCombinators.object([[
                "bareMetalConfigurations",
                Belt_Array.map(input.configurations, inputToJson)
              ]]);
}

var Input = {
  toJson: toJson
};

function toJson$1(output) {
  return Json_Encode$JsonCombinators.object([[
                "bareMetalConfigurations",
                Belt_Array.map(output.configurations, outputToJson)
              ]]);
}

var Output = {
  toJson: toJson$1
};

export {
  validateFieldOfCollection2OnChangeWithValidator ,
  validateDependentFieldOfCollection2OnChange ,
  validateFieldOfCollection2OnBlurWithValidator ,
  validators ,
  initialFieldsStatuses ,
  initialCollectionsStatuses ,
  initialState ,
  validateForm ,
  useForm ,
  Configuration ,
  Input ,
  Output ,
}
/* Date Not a pure module */
