// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Url from "../../utils/Url.res.js";
import * as Rest from "../Rest.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

function toString$1(x) {
  if (x === "Pending") {
    return "in_progress";
  } else {
    return "active";
  }
}

var FilterByStatus = {
  toString: toString$1
};

var Req = {
  SortDirection: SortDirection,
  SortBy: SortBy,
  FilterByStatus: FilterByStatus
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              created_at: field.required("createdAt", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              status: field.required("status", Json_Decode$JsonCombinators.string)
            };
    });

var User = {
  decoder: decoder
};

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              totalCount: field.required("totalCount", Json_Decode$JsonCombinators.$$float),
              usersList: field.required("usersList", Json_Decode$JsonCombinators.array(decoder))
            };
    });

var Res = {
  decoder: decoder$1
};

function exec(page, filterByStatus, searchTerm, sortBy, forceCsvExport) {
  var searchParams = new URLSearchParams("");
  searchParams.set("page", String(page));
  if (searchTerm !== undefined) {
    searchParams.set("search", encodeURIComponent(searchTerm));
  }
  if (sortBy !== undefined) {
    if (sortBy.NAME === "Name") {
      searchParams.set("sort_by", "name");
      searchParams.set("sort_direction", toString(sortBy.VAL));
    } else {
      searchParams.set("sort_by", "created");
      searchParams.set("sort_direction", toString(sortBy.VAL));
    }
  }
  if (filterByStatus !== undefined) {
    searchParams.set("filter_by_status", toString$1(filterByStatus));
  }
  if (forceCsvExport === true) {
    Url.visitBlank("/api/v1/control-plane-users-list.csv?" + searchParams.toString());
  }
  return Rest.$$fetch("/control-plane-users-list.json?" + searchParams.toString(), "Get", {
              NAME: "Json",
              VAL: decoder$1
            }, undefined, undefined);
}

export {
  Req ,
  User ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
