// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../libs/Date.res.js";
import * as Project from "./Project.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as UserContact from "./UserContact.res.js";
import * as ProjectProposal from "./ProjectProposal.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      var x = field.required("title", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string));
      var tmp;
      tmp = x !== undefined && x !== "" ? x : undefined;
      var x$1 = field.required("description", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string));
      var tmp$1;
      tmp$1 = x$1 !== undefined && x$1 !== "" ? x$1 : undefined;
      return {
              title: tmp,
              description: tmp$1,
              startDate: field.required("startDate", $$Date.Naive.decoder),
              endDate: field.required("endDate", Json_Decode$JsonCombinators.option($$Date.Naive.decoder)),
              contact: field.required("contact", Json_Decode$JsonCombinators.option(UserContact.decoder)),
              status: field.required("status", Project.Status.decoder),
              providerLogo: field.required("providerLogo", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              totalProviders: field.required("totalProviders", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              redirectUrl: field.required("redirectUrl", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              category: field.required("category", Project.ProjectType.decoder)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var DraftData = {
  decoder: decoder,
  fromJson: fromJson
};

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              title: field.required("title", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              startDate: field.required("startDate", $$Date.Naive.decoder),
              endDate: field.required("endDate", $$Date.Naive.decoder),
              contact: field.required("contact", Json_Decode$JsonCombinators.option(UserContact.decoder)),
              businessName: field.required("businessName", Json_Decode$JsonCombinators.string),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              totalProviders: field.required("totalProviders", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              totalProposals: field.required("totalProposals", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              canSendProposals: field.required("canSendProposals", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.bool)),
              status: field.required("status", Project.Status.decoder),
              proposalsBreakdown: field.required("proposalsBreakdown", Json_Decode$JsonCombinators.option(ProjectProposal.Breakdown.decoder)),
              providerLogo: field.required("providerLogo", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              proposalDetails: field.required("proposalDetails", Json_Decode$JsonCombinators.option(ProjectProposal.decoder)),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              category: field.required("category", Project.ProjectType.decoder)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var ProjectData = {
  decoder: decoder$1,
  fromJson: fromJson$1
};

var decoder$2 = Json_Decode$JsonCombinators.custom(function (json) {
      return Belt_Result.getExn(Json_Decode$JsonCombinators.decode(json, Json_Decode$JsonCombinators.object(function (field) {
                          var id = field.required("id", Project.Id.decoder);
                          var status = field.required("status", Project.Status.decoder);
                          var category = Project.Category.fromString(field.required("category", Json_Decode$JsonCombinators.string), field.required("subcategory", Json_Decode$JsonCombinators.string));
                          if (status === "Draft") {
                            return {
                                    TAG: "Draft",
                                    id: id,
                                    category: category,
                                    data: Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder))
                                  };
                          } else {
                            return {
                                    TAG: "Project",
                                    id: id,
                                    category: category,
                                    data: Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1))
                                  };
                          }
                        })));
    });

function eq(x1, x2) {
  if (x1.TAG === "Draft") {
    if (x2.TAG === "Draft") {
      return Project.Id.$eq(x1.id, x2.id);
    } else {
      return false;
    }
  } else if (x2.TAG === "Draft") {
    return false;
  } else {
    return Project.Id.$eq(x1.id, x2.id);
  }
}

export {
  DraftData ,
  ProjectData ,
  decoder$2 as decoder,
  eq ,
}
/* decoder Not a pure module */
