// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as Rest from "../Rest.res.js";

function exec(resourceId, providerId) {
  return Rest.$$fetch("/resource_providers/deselect?resource_id=" + (ID.toString(resourceId) + "&provider_id=" + ID.toString(providerId)), {
              NAME: "Post",
              VAL: null
            }, "Empty", undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
