// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";
import * as CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration from "../../bundles/user-dashboard/projects/create-colocation-rfp-project-configuration/CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration.res.js";

function toJson(input) {
  return Json_Encode$JsonCombinators.object([[
                "colocationConfigurations",
                Belt_Array.map(input, CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration.Input.toJson)
              ]]);
}

var Req = {
  toJson: toJson
};

function exec(data) {
  return Rest.$$fetch("/projects/save_colocation_configurations_draft", {
              NAME: "Post",
              VAL: toJson(data)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* Rest Not a pure module */
