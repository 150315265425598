// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Csrf from "../Csrf.res.js";
import * as $$Date from "../../libs/Date.res.js";
import * as Http from "../Http.res.js";
import * as Project from "../../models/Project.res.js";
import * as $$Promise from "../../bindings/Promise.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../loggers/SentryLogger.res.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";

function exec(projectId, data) {
  var formData = new FormData();
  Belt_Array.forEach(data.configurations, (function (configuration) {
          formData.append("[network_private_line_configurations][]start_date", Belt_Option.map(configuration.startDate, $$Date.Naive.toJson));
          formData.append("[network_private_line_configurations][]contract_length", Belt_Option.mapWithDefault(configuration.contractLength, "", (function (cl) {
                      return String(cl.value);
                    })));
          formData.append("[network_private_line_configurations][]street_address", configuration.streetAddress);
          formData.append("[network_private_line_configurations][]building_or_floor", configuration.buildingOrFloor);
          formData.append("[network_private_line_configurations][]suite", configuration.suite);
          formData.append("[network_private_line_configurations][]city_or_town", configuration.cityOrTown);
          formData.append("[network_private_line_configurations][]sub_locality", configuration.subLocality);
          formData.append("[network_private_line_configurations][]state_or_province", configuration.stateOrProvince);
          formData.append("[network_private_line_configurations][]country", configuration.country);
          formData.append("[network_private_line_configurations][]postal_code", configuration.postalCode);
          formData.append("[network_private_line_configurations][]prefix", configuration.prefix);
          formData.append("[network_private_line_configurations][]additional_location_details", configuration.additionalLocationDetails);
          Belt_SetString.forEach(configuration.privateLineFeatures, (function (service) {
                  formData.append("[network_private_line_configurations][][private_line_features][]", service);
                }));
          formData.append("[network_private_line_configurations][]access_type", configuration.accessType);
          formData.append("[network_private_line_configurations][]port_speed", configuration.portSpeed);
          Belt_Array.forEach(configuration.locations, (function ($$location) {
                  formData.append("[network_private_line_configurations][][location_zs][]street_address", $$location.streetAddress);
                  formData.append("[network_private_line_configurations][][location_zs][]building_or_floor", $$location.buildingOrFloor);
                  formData.append("[network_private_line_configurations][][location_zs][]suite", $$location.suite);
                  formData.append("[network_private_line_configurations][][location_zs][]city_or_town", $$location.cityOrTown);
                  formData.append("[network_private_line_configurations][][location_zs][]sub_locality", $$location.subLocality);
                  formData.append("[network_private_line_configurations][][location_zs][]state_or_province", $$location.stateOrProvince);
                  formData.append("[network_private_line_configurations][][location_zs][]country", $$location.country);
                  formData.append("[network_private_line_configurations][][location_zs][]postal_code", $$location.postalCode);
                  formData.append("[network_private_line_configurations][][location_zs][]prefix", $$location.prefix);
                  formData.append("[network_private_line_configurations][][location_zs][]additional_location_details", $$location.additionalLocationDetails);
                  Belt_SetString.forEach($$location.privateLineFeatures, (function (service) {
                          formData.append("[network_private_line_configurations][][location_zs][][private_line_features][]", service);
                        }));
                  formData.append("[network_private_line_configurations][][location_zs][]access_type", $$location.accessType);
                  formData.append("[network_private_line_configurations][][location_zs][]port_speed", $$location.portSpeed);
                }));
        }));
  Belt_Array.forEach(data.documents, (function ($$document) {
          formData.append("[documents][]", $$document);
        }));
  Belt_Array.forEach(data.deletedDocumentIds, (function (id) {
          formData.append("[deleted_document_ids][]", Project.DocumentId.toString(id));
        }));
  formData.append("[geographical_region]", data.geographicalRegion);
  return $$Promise.andThen(fetch("/api/v1/projects/" + (projectId + "/save_network_private_line_configurations"), {
                  method: Http.Method.toFetch({
                        NAME: "Put",
                        VAL: undefined
                      }),
                  body: Caml_option.some(formData),
                  headers: Caml_option.some(new Headers([[
                              "X-CSRF-Token",
                              Belt_Option.getExn(Csrf.token())
                            ]])),
                  credentials: "same-origin"
                }), (function (response) {
                if (response.ok) {
                  return $$Promise.resolve({
                              TAG: "Ok",
                              _0: undefined
                            });
                }
                var match = Http.ContentType.fromHeaders(response.headers);
                if (match !== undefined && typeof match !== "object") {
                  switch (match) {
                    case "Text" :
                        return $$Promise.map(response.text(), (function (text) {
                                      SentryLogger.error4({
                                            rootModule: "UpdateNetworkPrivateLineDraftProjectAndContinueLaterRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "UpdateNetworkPrivateLineDraftProjectAndContinueLaterRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "text"
                                          ], [
                                            "responseBody",
                                            text
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: "Opaque"
                                            };
                                    }));
                    case "Json" :
                        return $$Promise.map(response.json(), (function (json) {
                                      SentryLogger.error4({
                                            rootModule: "UpdateNetworkPrivateLineDraftProjectAndContinueLaterRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "UpdateNetworkPrivateLineDraftProjectAndContinueLaterRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "json"
                                          ], [
                                            "responseBody",
                                            json
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: "Opaque"
                                            };
                                    }));
                    case "Blob" :
                        return $$Promise.map(response.blob(), (function (blob) {
                                      SentryLogger.error4({
                                            rootModule: "UpdateNetworkPrivateLineDraftProjectAndContinueLaterRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "UpdateNetworkPrivateLineDraftProjectAndContinueLaterRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "blob"
                                          ], [
                                            "responseBody",
                                            blob
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: "Opaque"
                                            };
                                    }));
                    
                  }
                }
                SentryLogger.error3({
                      rootModule: "UpdateNetworkPrivateLineDraftProjectAndContinueLaterRequest",
                      subModulePath: /* [] */0,
                      value: "exec",
                      fullPath: "UpdateNetworkPrivateLineDraftProjectAndContinueLaterRequest.exec"
                    }, "Fetch::RequestError", [
                      "code",
                      response.status
                    ], [
                      "status",
                      response.statusText
                    ], [
                      "contentType",
                      "N/A"
                    ]);
                return $$Promise.resolve({
                            TAG: "Error",
                            _0: "Opaque"
                          });
              }));
}

export {
  exec ,
}
/* Csrf Not a pure module */
