// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Qs from "qs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProjectProposal from "../../../../models/ProjectProposal.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

function fromJs(value) {
  if (value === "desc") {
    return "Desc";
  } else {
    return "Asc";
  }
}

var SortDirection = {
  toJson: toJson,
  fromJs: fromJs
};

function toJson$1(x) {
  var variant = x.NAME;
  if (variant === "Alphabetical") {
    return "provider_name";
  } else if (variant === "CreatedDate") {
    return "created_at";
  } else {
    return "expiration_date";
  }
}

var SortBy = {
  toJson: toJson$1
};

function fromJs$1(js) {
  var match = js.sortBy;
  var tmp;
  if (match == null) {
    tmp = undefined;
  } else {
    switch (match) {
      case "created_at" :
          var match$1 = js.sortDirection;
          tmp = (match$1 == null) || match$1 !== "desc" ? ({
                NAME: "CreatedDate",
                VAL: "Asc"
              }) : ({
                NAME: "CreatedDate",
                VAL: "Desc"
              });
          break;
      case "expiration_date" :
          var match$2 = js.sortDirection;
          tmp = (match$2 == null) || match$2 !== "desc" ? ({
                NAME: "ExpiresDate",
                VAL: "Asc"
              }) : ({
                NAME: "ExpiresDate",
                VAL: "Desc"
              });
          break;
      case "provider_name" :
          var match$3 = js.sortDirection;
          tmp = (match$3 == null) || match$3 !== "desc" ? ({
                NAME: "Alphabetical",
                VAL: "Asc"
              }) : ({
                NAME: "Alphabetical",
                VAL: "Desc"
              });
          break;
      default:
        tmp = undefined;
    }
  }
  return {
          providerName: Caml_option.nullable_to_opt(js.providerName),
          statuses: Belt_Option.map(Caml_option.nullable_to_opt(js.statuses), (function (array) {
                  return Belt_Array.map(array, ProjectProposal.Status.fromJs);
                })),
          sortBy: tmp
        };
}

function toJson$2(input) {
  var x = input.sortBy;
  return Json_Encode$JsonCombinators.object([
              [
                "providerName",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.providerName)
              ],
              [
                "status",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(Belt_Option.map(input.statuses, (function (__x) {
                            return Belt_Array.map(__x, ProjectProposal.Status.toJson);
                          })))
              ],
              [
                "sortBy",
                x !== undefined ? toJson$1(x) : null
              ],
              [
                "sortDirection",
                Belt_Option.getWithDefault(Belt_Option.map(input.sortBy, (function (value) {
                            return toJson(value.VAL);
                          })), null)
              ]
            ]);
}

function toQueryString(input) {
  return Qs.stringify(toJson$2(input), {
              arrayFormat: "brackets"
            });
}

function empty() {
  return {
          providerName: "",
          statuses: [],
          sortBy: undefined
        };
}

var Input = {
  fromJs: fromJs$1,
  toJson: toJson$2,
  toQueryString: toQueryString,
  empty: empty
};

export {
  SortDirection ,
  SortBy ,
  Input ,
}
/* qs Not a pure module */
