// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as $$Date from "../../../libs/Date.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import AddDays from "date-fns/addDays";
import IsAfter from "date-fns/isAfter";
import * as ColocationProduct from "../../../models/ColocationProduct.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";
import * as DashboardProductFeatureEditor from "./DashboardProductFeatureEditor.res.js";

function empty(categories, providerId, param) {
  return {
          name: "",
          description: "",
          providerId: providerId,
          category: Belt_Option.map(Belt_Array.get(categories, 0), (function (x) {
                  return x.name;
                })),
          subcategory: undefined,
          startDate: Caml_option.some(new Date()),
          endDate: Caml_option.some(AddDays(new Date(), 90)),
          isExclusiveOffer: false,
          pricePerMonth: undefined,
          setupFee: 0.0,
          term: "",
          powerCircuit: "",
          bandwidth: "",
          ipAllocation: "",
          totalPowerKw: 0,
          features: [DashboardProductFeatureEditor.Input.empty]
        };
}

function fromProduct(product) {
  return {
          name: product.name,
          description: product.description,
          providerId: Caml_option.some(product.providerId),
          category: product.category,
          subcategory: product.subcategory,
          startDate: Caml_option.some(product.startDate),
          endDate: Caml_option.some(product.endDate),
          isExclusiveOffer: product.isExclusiveOffer,
          pricePerMonth: product.pricePerMonth,
          setupFee: product.setupFee,
          term: product.term,
          powerCircuit: product.powerCircuit,
          bandwidth: product.bandwidth,
          ipAllocation: product.ipAllocation,
          totalPowerKw: product.totalPowerKw,
          features: Belt_Array.map(product.features, (function (feature) {
                  return DashboardProductFeatureEditor.Input.fromFeature(feature);
                }))
        };
}

function fromProductDraft(product) {
  return {
          name: product.name,
          description: product.description,
          providerId: Caml_option.some(product.providerId),
          category: product.category,
          subcategory: product.subcategory,
          startDate: Caml_option.some(product.startDate),
          endDate: Caml_option.some(product.endDate),
          isExclusiveOffer: product.isExclusiveOffer,
          pricePerMonth: product.pricePerMonth,
          setupFee: product.setupFee,
          term: product.term,
          powerCircuit: product.powerCircuit,
          bandwidth: product.bandwidth,
          ipAllocation: product.ipAllocation,
          totalPowerKw: product.totalPowerKw,
          features: Belt_Array.map(product.features, (function (feature) {
                  return DashboardProductFeatureEditor.Input.fromFeature(feature);
                }))
        };
}

function toJson(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "name",
                input.name
              ],
              [
                "description",
                input.description
              ],
              [
                "providerId",
                ID.toJsonOpt(input.providerId)
              ],
              [
                "category",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.category)
              ],
              [
                "subcategory",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.subcategory)
              ],
              [
                "startDate",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(Belt_Option.map(input.startDate, $$Date.Naive.toString))
              ],
              [
                "endDate",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(Belt_Option.map(input.endDate, $$Date.Naive.toString))
              ],
              [
                "isExclusiveOffer",
                input.isExclusiveOffer
              ],
              [
                "pricePerMonth",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.pricePerMonth)
              ],
              [
                "setupFee",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.setupFee)
              ],
              [
                "term",
                input.term
              ],
              [
                "powerCircuit",
                input.powerCircuit
              ],
              [
                "bandwidth",
                input.bandwidth
              ],
              [
                "ipAllocation",
                input.ipAllocation
              ],
              [
                "totalPowerKw",
                input.totalPowerKw
              ],
              [
                "features",
                Belt_Array.map(input.features, DashboardProductFeatureEditor.Input.toJson)
              ]
            ]);
}

var Input = {
  empty: empty,
  fromProduct: fromProduct,
  fromProductDraft: fromProductDraft,
  toJson: toJson
};

var ValidationResult = {};

function name(param) {
  var name$1 = param.name;
  if (name$1 === "") {
    return {
            TAG: "Error",
            _0: "Name is required"
          };
  } else if (name$1.length > ColocationProduct.nameMaxLength) {
    return {
            TAG: "Error",
            _0: "Name must be " + (String(ColocationProduct.nameMaxLength) + " characters or less")
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function description(param) {
  var description$1 = param.description;
  if (description$1 === "") {
    return {
            TAG: "Error",
            _0: "Description is required"
          };
  } else if (description$1.length > ColocationProduct.descriptionMaxLength) {
    return {
            TAG: "Error",
            _0: "Description must be " + (String(ColocationProduct.descriptionMaxLength) + " characters or less")
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function providerId(param) {
  if (param.providerId !== undefined) {
    return {
            TAG: "Ok",
            _0: undefined
          };
  } else {
    return {
            TAG: "Error",
            _0: "Provider is required"
          };
  }
}

function category(param) {
  var category$1 = param.category;
  if (category$1 !== undefined && category$1 !== "") {
    return {
            TAG: "Ok",
            _0: undefined
          };
  } else {
    return {
            TAG: "Error",
            _0: "Category is required"
          };
  }
}

function subcategory(param) {
  var subcategory$1 = param.subcategory;
  if (subcategory$1 !== undefined && subcategory$1 !== "") {
    return {
            TAG: "Ok",
            _0: undefined
          };
  } else {
    return {
            TAG: "Error",
            _0: "Space is required"
          };
  }
}

function startDate(param) {
  var endDate = param.endDate;
  var startDate$1 = param.startDate;
  if (startDate$1 !== undefined) {
    if (endDate !== undefined && IsAfter(Caml_option.valFromOption(startDate$1), Caml_option.valFromOption(endDate))) {
      return {
              TAG: "Error",
              _0: "Start date can't be after End date"
            };
    } else {
      return {
              TAG: "Ok",
              _0: undefined
            };
    }
  } else {
    return {
            TAG: "Error",
            _0: "Start date is required"
          };
  }
}

function endDate(param) {
  var endDate$1 = param.endDate;
  var startDate = param.startDate;
  if (endDate$1 !== undefined) {
    if (startDate !== undefined && IsAfter(Caml_option.valFromOption(startDate), Caml_option.valFromOption(endDate$1))) {
      return {
              TAG: "Error",
              _0: "End date can't be before Start date"
            };
    } else {
      return {
              TAG: "Ok",
              _0: undefined
            };
    }
  } else {
    return {
            TAG: "Error",
            _0: "End date is required"
          };
  }
}

function pricePerMonth(param) {
  var pricePerMonth$1 = param.pricePerMonth;
  if (pricePerMonth$1 !== undefined) {
    if (pricePerMonth$1 < 0) {
      return {
              TAG: "Error",
              _0: "Price can't be negative"
            };
    } else {
      return {
              TAG: "Ok",
              _0: undefined
            };
    }
  } else {
    return {
            TAG: "Error",
            _0: "Price is required"
          };
  }
}

function setupFee(param) {
  var setupFee$1 = param.setupFee;
  if (setupFee$1 !== undefined) {
    if (setupFee$1 < 0) {
      return {
              TAG: "Error",
              _0: "Setup fee can't be negative"
            };
    } else {
      return {
              TAG: "Ok",
              _0: undefined
            };
    }
  } else {
    return {
            TAG: "Error",
            _0: "Setup fee is required"
          };
  }
}

function term(param) {
  if (param.term === "") {
    return {
            TAG: "Error",
            _0: "Term is required"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function powerCircuit(param) {
  if (param.powerCircuit === "") {
    return {
            TAG: "Error",
            _0: "Power circuit is required"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function bandwidth(param) {
  if (param.bandwidth === "") {
    return {
            TAG: "Error",
            _0: "Bandwidth is required"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function ipAllocation(param) {
  if (param.ipAllocation === "") {
    return {
            TAG: "Error",
            _0: "IP allocation is required"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function totalPowerKw(param) {
  if (param.totalPowerKw < 0) {
    return {
            TAG: "Error",
            _0: "Total Power can't be negative"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function all(state) {
  return {
          name: name(state),
          description: description(state),
          providerId: providerId(state),
          category: category(state),
          subcategory: subcategory(state),
          startDate: startDate(state),
          endDate: endDate(state),
          pricePerMonth: pricePerMonth(state),
          setupFee: setupFee(state),
          term: term(state),
          powerCircuit: powerCircuit(state),
          bandwidth: bandwidth(state),
          ipAllocation: ipAllocation(state),
          totalPowerKw: totalPowerKw(state),
          features: Belt_Array.map(state.features, (function (feature) {
                  return {
                          name: DashboardProductFeatureEditor.Validate.name(feature),
                          description: DashboardProductFeatureEditor.Validate.description(feature)
                        };
                }))
        };
}

function valid(result) {
  var exit = 0;
  if (result.name.TAG === "Ok" && result.description.TAG === "Ok" && result.providerId.TAG === "Ok" && result.category.TAG === "Ok" && result.subcategory.TAG === "Ok" && result.startDate.TAG === "Ok" && result.endDate.TAG === "Ok" && result.pricePerMonth.TAG === "Ok" && result.setupFee.TAG === "Ok" && result.term.TAG === "Ok" && result.powerCircuit.TAG === "Ok" && result.bandwidth.TAG === "Ok" && result.ipAllocation.TAG === "Ok" && result.totalPowerKw.TAG === "Ok") {
    if (Belt_Array.every(result.features, (function (x) {
              if (x.name.TAG === "Ok" && x.description.TAG === "Ok") {
                return true;
              } else {
                return false;
              }
            }))) {
      return true;
    }
    exit = 1;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var exit$1 = 0;
    exit$1 = 2;
    if (exit$1 === 2) {
      var exit$2 = 0;
      exit$2 = 3;
      if (exit$2 === 3) {
        var exit$3 = 0;
        exit$3 = 4;
        if (exit$3 === 4) {
          return false;
        }
        
      }
      
    }
    
  }
  
}

var Validate = {
  name: name,
  description: description,
  providerId: providerId,
  category: category,
  subcategory: subcategory,
  startDate: startDate,
  endDate: endDate,
  pricePerMonth: pricePerMonth,
  setupFee: setupFee,
  term: term,
  powerCircuit: powerCircuit,
  bandwidth: bandwidth,
  ipAllocation: ipAllocation,
  totalPowerKw: totalPowerKw,
  all: all,
  valid: valid
};

export {
  Input ,
  ValidationResult ,
  Validate ,
}
/* ID Not a pure module */
