// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          quantity: Caml_option.nullable_to_opt(js.quantity),
          interconnect: Caml_option.nullable_to_opt(js.interconnect),
          provider: Caml_option.nullable_to_opt(js.provider),
          specialNotesAndRequirements: Caml_option.nullable_to_opt(js.specialNotesAndRequirements)
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              quantity: field.required("quantity", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              interconnect: field.required("interconnect", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              provider: field.required("provider", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              specialNotesAndRequirements: field.required("specialNotesAndRequirements", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string))
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  fromJs ,
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
