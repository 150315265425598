// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as Csrf from "../Csrf.res.js";
import * as Http from "../Http.res.js";
import * as Project from "../../models/Project.res.js";
import * as $$Promise from "../../bindings/Promise.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../loggers/SentryLogger.res.js";
import * as ProjectProposalId from "../../models/ProjectProposalId.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return field.required("id", ProjectProposalId.decoder);
    });

var Res = {
  decoder: decoder
};

function exec(proposalId, providerId, projectId, $$document, expirationDate, description, additionalDocuments, deletedDocumentIds) {
  var formData = new FormData();
  if ($$document !== undefined) {
    formData.append("document", Caml_option.valFromOption($$document));
  }
  formData.append("providerId", ID.toString(providerId));
  formData.append("expirationDate", expirationDate);
  formData.append("description", description);
  Belt_Array.forEach(additionalDocuments, (function ($$document) {
          formData.append("additionalDocuments[]", $$document);
        }));
  Belt_Array.forEach(deletedDocumentIds, (function (id) {
          formData.append("deletedDocumentIds[]", Project.DocumentId.toString(id));
        }));
  return $$Promise.andThen(fetch("/api/v1/projects/" + (projectId + ("/proposals/" + proposalId)), {
                  method: Http.Method.toFetch({
                        NAME: "Patch",
                        VAL: undefined
                      }),
                  body: Caml_option.some(formData),
                  headers: Caml_option.some(new Headers([[
                              "X-CSRF-Token",
                              Belt_Option.getExn(Csrf.token())
                            ]])),
                  credentials: "same-origin"
                }), (function (response) {
                if (response.ok) {
                  return $$Promise.map(response.json(), (function (json) {
                                return {
                                        TAG: "Ok",
                                        _0: Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder))
                                      };
                              }));
                }
                var match = Http.ContentType.fromHeaders(response.headers);
                if (match !== undefined && typeof match !== "object") {
                  switch (match) {
                    case "Text" :
                        return $$Promise.map(response.text(), (function (text) {
                                      SentryLogger.error4({
                                            rootModule: "UpdateProjectProposalRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "UpdateProjectProposalRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "text"
                                          ], [
                                            "responseBody",
                                            text
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: undefined
                                            };
                                    }));
                    case "Json" :
                        return $$Promise.map(response.json(), (function (json) {
                                      SentryLogger.error4({
                                            rootModule: "UpdateProjectProposalRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "UpdateProjectProposalRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "json"
                                          ], [
                                            "responseBody",
                                            json
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: undefined
                                            };
                                    }));
                    case "Blob" :
                        return $$Promise.map(response.blob(), (function (blob) {
                                      SentryLogger.error4({
                                            rootModule: "UpdateProjectProposalRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "UpdateProjectProposalRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "blob"
                                          ], [
                                            "responseBody",
                                            blob
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: undefined
                                            };
                                    }));
                    
                  }
                }
                SentryLogger.error3({
                      rootModule: "UpdateProjectProposalRequest",
                      subModulePath: /* [] */0,
                      value: "exec",
                      fullPath: "UpdateProjectProposalRequest.exec"
                    }, "Fetch::RequestError", [
                      "code",
                      response.status
                    ], [
                      "status",
                      response.statusText
                    ], [
                      "contentType",
                      "N/A"
                    ]);
                return $$Promise.resolve({
                            TAG: "Error",
                            _0: undefined
                          });
              }));
}

var $$FormData$1;

export {
  $$FormData$1 as $$FormData,
  Res ,
  exec ,
}
/* decoder Not a pure module */
