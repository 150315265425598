// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function toString(x) {
  switch (x) {
    case "Active" :
        return "active";
    case "InProgress" :
        return "in_progress";
    case "Inactive" :
        return "inactive";
    
  }
}

function fromString(x) {
  switch (x) {
    case "active" :
        return "Active";
    case "in_progress" :
        return "InProgress";
    case "inactive" :
        return "Inactive";
    default:
      return "Inactive";
  }
}

var decoder = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.string, fromString);

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  toString ,
  fromString ,
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
