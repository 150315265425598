// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as Rest from "../Rest.res.js";

function exec(productId) {
  return Rest.$$fetch("/products/" + (ID.toString(productId) + "/activate"), {
              NAME: "Patch",
              VAL: null
            }, "Empty", undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
