// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as CreateBareMetalProjectConfigurationForm from "../../bundles/user-dashboard/projects/create-bare-metal-project-configuration/CreateBareMetalProjectConfigurationForm.res.js";

var toJson = CreateBareMetalProjectConfigurationForm.Input.toJson;

var Req = {
  toJson: toJson
};

function exec(data) {
  return Rest.$$fetch("/projects/save_bare_metal_configurations_draft", {
              NAME: "Put",
              VAL: toJson(data)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* Rest Not a pure module */
