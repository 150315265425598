// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as Project from "../../models/Project.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(category, subcategory, status) {
  return Json_Encode$JsonCombinators.object([
              [
                "category",
                Project.ProjectType.toJson(category)
              ],
              [
                "subcategory",
                Project.Category.toJson(subcategory)
              ],
              [
                "status",
                Project.Status.toJson(status)
              ]
            ]);
}

var Req = {
  toJson: toJson
};

function exec(category, subcategory, status) {
  return Rest.$$fetch("/projects/save_internet_draft_project", {
              NAME: "Post",
              VAL: toJson(category, subcategory, status)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* Rest Not a pure module */
