// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as Rest from "../Rest.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(resourceId) {
  return Json_Encode$JsonCombinators.object([[
                "resource_id",
                ID.toJson(resourceId)
              ]]);
}

var Req = {
  toJson: toJson
};

function exec(resourceId) {
  return Rest.$$fetch("/resources/" + ID.toString(resourceId), {
              NAME: "Delete",
              VAL: undefined
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
