// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function empty() {
  return {
          name: "",
          company: "",
          email: "",
          phone: "",
          newsletterSubscription: false,
          dialCode: ""
        };
}

function toJson(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "name",
                input.name
              ],
              [
                "company",
                input.company
              ],
              [
                "email",
                input.email
              ],
              [
                "phone",
                Js_string.replace(input.dialCode, "", input.phone).trim()
              ],
              [
                "newsletterSubscription",
                input.newsletterSubscription
              ],
              [
                "dial_code",
                input.dialCode
              ]
            ]);
}

var Input = {
  empty: empty,
  toJson: toJson
};

var ValidationResult = {};

function name(param) {
  if (param.name === "") {
    return {
            TAG: "Error",
            _0: "Name is required"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function company(param) {
  if (param.company === "") {
    return {
            TAG: "Error",
            _0: "Company name is required"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function email(param) {
  if (param.email === "") {
    return {
            TAG: "Error",
            _0: "Email is required"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function phone(param) {
  if (param.phone.length > 7) {
    return {
            TAG: "Ok",
            _0: undefined
          };
  } else {
    return {
            TAG: "Error",
            _0: "Valid phone is required"
          };
  }
}

function all(state) {
  return {
          name: name(state),
          company: company(state),
          email: email(state),
          phone: phone(state)
        };
}

function valid(result) {
  if (result.name.TAG === "Ok" && result.company.TAG === "Ok" && result.email.TAG === "Ok" && result.phone.TAG === "Ok") {
    return true;
  } else {
    return false;
  }
}

var Validate = {
  name: name,
  company: company,
  email: email,
  phone: phone,
  all: all,
  valid: valid
};

export {
  Input ,
  ValidationResult ,
  Validate ,
}
/* No side effect */
