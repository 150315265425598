// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProviderForProject from "../../models/ProviderForProject.res.js";
import * as LocationForProjectsMap from "../../models/LocationForProjectsMap.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as CreateColocationRfpProjectLocations_Data_Filter from "../../bundles/user-dashboard/projects/create-colocation-rfp-project-locations/CreateColocationRfpProjectLocations_Data_Filter.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              locations: field.required("locations", Json_Decode$JsonCombinators.array(LocationForProjectsMap.decoder)),
              availableProviders: field.required("availableProviders", Json_Decode$JsonCombinators.array(ProviderForProject.decoder)),
              unavailableProviders: field.required("unavailableProviders", Json_Decode$JsonCombinators.array(ProviderForProject.decoder))
            };
    });

var Res = {
  decoder: decoder
};

function exec(filters, projectId) {
  var url = "/projects/search_locations?" + ((
      projectId !== undefined ? "id=" + (Caml_option.valFromOption(projectId) + "&") : ""
    ) + CreateColocationRfpProjectLocations_Data_Filter.Input.toQueryString(filters));
  return Rest.$$fetch(url, "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Res ,
  exec ,
}
/* decoder Not a pure module */
