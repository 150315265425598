// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(email) {
  return Json_Encode$JsonCombinators.object([[
                "email",
                email
              ]]);
}

var Req = {
  toJson: toJson
};

function exec(email) {
  return Rest.$$fetch("/invitations/resend_invite_on_sign_up", {
              NAME: "Put",
              VAL: toJson(email)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* Rest Not a pure module */
