// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as Rest from "../Rest.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(invitationId, status) {
  return Json_Encode$JsonCombinators.object([
              [
                "id",
                ID.toJson(invitationId)
              ],
              [
                "status",
                status
              ]
            ]);
}

var Req = {
  toJson: toJson
};

function exec(invitationId, status) {
  return Rest.$$fetch("/invitations/accept_reject_invitation", {
              NAME: "Put",
              VAL: toJson(invitationId, status)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
