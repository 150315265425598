// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as Provider from "../../models/Provider.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              providers: field.required("providers", Json_Decode$JsonCombinators.array(Provider.Simple.decoder))
            };
    });

var Res = {
  decoder: decoder
};

function exec(projectId) {
  var url = "/projects/" + (projectId + "/proposals/allowed_providers");
  return Rest.$$fetch(url, "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Res ,
  exec ,
}
/* decoder Not a pure module */
