// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          quantity: Caml_option.nullable_to_opt(js.quantity),
          colocationSpace: Caml_option.nullable_to_opt(js.colocationSpace),
          powerCircuit: Caml_option.nullable_to_opt(js.powerCircuit),
          powerValue: Caml_option.nullable_to_opt(js.powerValue),
          powerUnits: Caml_option.nullable_to_opt(js.powerUnits),
          additionalOptions: js.additionalOptions
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              quantity: field.required("quantity", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              colocationSpace: field.required("colocationSpace", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              powerCircuit: field.required("powerCircuit", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              powerValue: field.required("powerValue", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              powerUnits: field.required("powerUnits", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              additionalOptions: field.required("additionalOptions", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function rackQuantityAndPowerDetailText(rack) {
  var rackQuantity = String(Belt_Option.getWithDefault(rack.quantity, 0)) + (" x " + Belt_Option.getWithDefault(rack.colocationSpace, ""));
  var match = rack.powerValue;
  var match$1 = rack.powerUnits;
  var powerDetail = match !== undefined && match$1 !== undefined ? String(match) + match$1 : undefined;
  var match$2 = rack.powerCircuit;
  if (match$2 !== undefined) {
    if (powerDetail !== undefined) {
      return rackQuantity + (", " + (match$2 + (", " + powerDetail)));
    } else {
      return rackQuantity + (", " + match$2);
    }
  } else if (powerDetail !== undefined) {
    return rackQuantity + (", " + powerDetail);
  } else {
    return rackQuantity;
  }
}

export {
  fromJs ,
  decoder ,
  fromJson ,
  rackQuantityAndPowerDetailText ,
}
/* decoder Not a pure module */
