// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          title: js.title,
          description: js.description,
          metaTitle: js.metaTitle,
          metaDescription: js.metaDescription,
          navigationName: js.navigationName,
          slug: js.slug,
          resources: js.resources,
          createdAt: $$Date.Naive.fromString(js.createdAt),
          updatedAt: $$Date.Naive.fromString(js.updatedAt)
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string),
              navigationName: field.required("navigationName", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              resources: field.required("resources", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var Dashboard = {
  fromJs: fromJs,
  decoder: decoder,
  fromJson: fromJson
};

function fromJs$1(js) {
  return {
          description: js.description,
          navigationName: js.navigationName,
          title: js.title,
          slug: js.slug,
          itemsCount: js.itemsCount
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              description: field.required("description", Json_Decode$JsonCombinators.string),
              navigationName: field.required("navigationName", Json_Decode$JsonCombinators.string),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              itemsCount: field.required("itemsCount", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

export {
  Dashboard ,
  fromJs$1 as fromJs,
  decoder$1 as decoder,
  fromJson$1 as fromJson,
}
/* decoder Not a pure module */
