// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as ProjectTile from "../../models/ProjectTile.res.js";
import * as ProjectsViewContext from "../../models/ProjectsViewContext.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as UserProjects_List_Filter_Data from "../../bundles/user-dashboard/projects/user-projects/UserProjects_List_Filter_Data.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              projects: field.required("projects", Json_Decode$JsonCombinators.array(ProjectTile.decoder)),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              totalProjects: field.required("totalProjects", Json_Decode$JsonCombinators.$$int)
            };
    });

var Res = {
  decoder: decoder
};

function queryString(pageNum, filters, userViewContext, isConcierge) {
  return "view_context=" + (ProjectsViewContext.toString(userViewContext) + ("&is_concierge=" + ((
                isConcierge ? "true" : "false"
              ) + ("&page=" + (String(pageNum) + ("&" + UserProjects_List_Filter_Data.Input.toQueryString(filters)))))));
}

function exec(pageNum, filters, userViewContext, isConcierge) {
  var url = "/projects?" + queryString(pageNum, filters, userViewContext, isConcierge);
  return Rest.$$fetch(url, "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Res ,
  queryString ,
  exec ,
}
/* decoder Not a pure module */
