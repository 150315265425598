// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function fromJs(js) {
  return {
          city: js.city,
          state: js.state,
          country: js.country
        };
}

function toJson($$location) {
  return Json_Encode$JsonCombinators.object([
              [
                "city",
                $$location.city
              ],
              [
                "state",
                $$location.state
              ],
              [
                "country",
                $$location.country
              ]
            ]);
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              city: field.required("city", Json_Decode$JsonCombinators.string),
              state: field.required("state", Json_Decode$JsonCombinators.string),
              country: field.required("country", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function toLabel($$location) {
  var __x = Belt_Array.keep([
        $$location.city,
        $$location.state,
        $$location.country
      ], (function (item) {
          return item !== "";
        }));
  return Js_array.joinWith(", ", __x);
}

export {
  fromJs ,
  toJson ,
  decoder ,
  fromJson ,
  toLabel ,
}
/* decoder Not a pure module */
