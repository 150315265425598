// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Validator from "Utils/validator";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function empty(param) {
  return {
          email: "",
          password: ""
        };
}

function toJson(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "email",
                input.email
              ],
              [
                "password",
                input.password
              ]
            ]);
}

var Input = {
  empty: empty,
  toJson: toJson
};

function toJs(input) {
  return {
          email: input.email,
          password: input.password
        };
}

var ValidationInput = {
  toJs: toJs
};

function fromJs(js) {
  return {
          email: Caml_option.nullable_to_opt(js.email),
          password: Caml_option.nullable_to_opt(js.password)
        };
}

var ExternalValidationResult = {
  fromJs: fromJs
};

function convertResult(field) {
  if (field !== undefined) {
    return {
            TAG: "Error",
            _0: Caml_option.valFromOption(field)
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function fromExternalResult(results) {
  return {
          email: convertResult(results.email),
          password: convertResult(results.password)
        };
}

var ValidationResult = {
  convertResult: convertResult,
  fromExternalResult: fromExternalResult
};

var validationFields = [
  {
    id: "email",
    validation: [
      {
        rule: "required",
        message: "Email is required."
      },
      {
        rule: "email",
        message: "Email is not valid."
      },
      {
        rule: "empty",
        message: "Enter valid email."
      }
    ]
  },
  {
    id: "password",
    validation: [
      {
        rule: "required",
        message: "Password is required."
      },
      {
        rule: "empty",
        message: "Enter valid password."
      }
    ]
  }
];

function all(values) {
  return fromExternalResult(fromJs(Validator.formErrorsValidations(toJs(values), validationFields)));
}

function valid(result) {
  if (result.email.TAG === "Ok" && result.password.TAG === "Ok") {
    return true;
  } else {
    return false;
  }
}

var Validate = {
  validationFields: validationFields,
  all: all,
  valid: valid
};

export {
  Input ,
  ValidationInput ,
  ExternalValidationResult ,
  ValidationResult ,
  Validate ,
}
/* Utils/validator Not a pure module */
