// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ColocationConfiguration from "../../models/ColocationConfiguration.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";
import * as CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration from "../../bundles/user-dashboard/projects/create-colocation-rfp-project-configuration/CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration.res.js";

function toJson(input) {
  return Json_Encode$JsonCombinators.object([[
                "colocationConfigurations",
                Belt_Array.map(input, CreateColocationRfpProjectConfiguration_Data_ColocationConfiguration.Input.toJson)
              ]]);
}

var Req = {
  toJson: toJson
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              colocationConfigurations: field.required("colocationConfigurations", Json_Decode$JsonCombinators.array(ColocationConfiguration.decoder))
            };
    });

var Res = {
  decoder: decoder
};

function exec(data, projectId) {
  return Rest.$$fetch(projectId !== undefined ? "/projects/" + (Caml_option.valFromOption(projectId) + "/save_colocation_configurations") : "/projects/save_colocation_configurations", {
              NAME: "Put",
              VAL: toJson(data)
            }, {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
