// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "rescript/lib/es6/js_string.js";
import * as EmailValidator from "email-validator";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function empty() {
  return {
          firstName: "",
          lastName: "",
          company: "",
          email: "",
          phoneNumber: "",
          newsletterSubscription: false,
          agreedTermsAndConditions: false,
          dialCode: ""
        };
}

function fromReservationDraft(reservation) {
  return {
          firstName: reservation.firstName,
          lastName: reservation.lastName,
          company: reservation.company,
          email: reservation.email,
          phoneNumber: reservation.phoneNumber,
          newsletterSubscription: reservation.newsletterSubscription,
          agreedTermsAndConditions: reservation.agreedTermsAndConditions,
          dialCode: reservation.dialCode
        };
}

function toJson(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "firstName",
                input.firstName
              ],
              [
                "lastName",
                input.lastName
              ],
              [
                "company",
                input.company
              ],
              [
                "email",
                input.email
              ],
              [
                "phoneNumber",
                Js_string.replace(input.dialCode, "", input.phoneNumber).trim()
              ],
              [
                "newsletterSubscription",
                input.newsletterSubscription
              ],
              [
                "agreedTermsAndConditions",
                input.agreedTermsAndConditions
              ],
              [
                "dial_code",
                input.dialCode
              ]
            ]);
}

var Input = {
  empty: empty,
  fromReservationDraft: fromReservationDraft,
  toJson: toJson
};

var ValidationResult = {};

function firstName(param) {
  if (param.firstName === "") {
    return {
            TAG: "Error",
            _0: "First name is required"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function lastName(param) {
  if (param.lastName === "") {
    return {
            TAG: "Error",
            _0: "Last name is required"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function company(param) {
  if (param.company === "") {
    return {
            TAG: "Error",
            _0: "Company name is required"
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function email(param) {
  var email$1 = param.email;
  if (email$1 === "") {
    return {
            TAG: "Error",
            _0: "Email is required"
          };
  } else {
    var result = EmailValidator.validate(email$1);
    if (result) {
      return {
              TAG: "Ok",
              _0: undefined
            };
    } else {
      return {
              TAG: "Error",
              _0: "Please enter valid email"
            };
    }
  }
}

function phoneNumber(param) {
  if (param.phoneNumber.length > 6) {
    return {
            TAG: "Ok",
            _0: undefined
          };
  } else {
    return {
            TAG: "Error",
            _0: "Phone is required"
          };
  }
}

function agreedTermsAndConditions(param) {
  if (param.agreedTermsAndConditions) {
    return {
            TAG: "Ok",
            _0: undefined
          };
  } else {
    return {
            TAG: "Error",
            _0: "Terms & Conditions are required"
          };
  }
}

function all(state) {
  return {
          firstName: firstName(state),
          lastName: lastName(state),
          company: company(state),
          email: email(state),
          phoneNumber: phoneNumber(state),
          agreedTermsAndConditions: agreedTermsAndConditions(state)
        };
}

function valid(result) {
  if (result.firstName.TAG === "Ok" && result.lastName.TAG === "Ok" && result.company.TAG === "Ok" && result.email.TAG === "Ok" && result.phoneNumber.TAG === "Ok" && result.agreedTermsAndConditions.TAG === "Ok") {
    return true;
  } else {
    return false;
  }
}

var Validate = {
  firstName: firstName,
  lastName: lastName,
  company: company,
  email: email,
  phoneNumber: phoneNumber,
  agreedTermsAndConditions: agreedTermsAndConditions,
  all: all,
  valid: valid
};

export {
  Input ,
  ValidationResult ,
  Validate ,
}
/* email-validator Not a pure module */
