// Generated by ReScript, PLEASE EDIT WITH CARE

import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromString(x) {
  switch (x) {
    case "active" :
        return "Active";
    case "archived" :
        return "Archived";
    case "disabled" :
        return "Disabled";
    case "draft" :
        return "Draft";
    case "expired" :
        return "Expired";
    default:
      return PervasivesU.failwith("Unknown product status");
  }
}

function toString(x) {
  switch (x) {
    case "Active" :
        return "active";
    case "Draft" :
        return "draft";
    case "Disabled" :
        return "disabled";
    case "Expired" :
        return "expired";
    case "Archived" :
        return "archived";
    
  }
}

var decoder = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.string, fromString);

export {
  fromString ,
  toString ,
  decoder ,
}
/* decoder Not a pure module */
