// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as FieldWithCounterScss from "./FieldWithCounter.scss";

var css = FieldWithCounterScss;

function FieldWithCounter(props) {
  var left = props.limit - props.value.length | 0;
  var chars = left === 1 ? " character " : " characters ";
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        props.label,
                        JsxRuntime.jsx("div", {
                              children: left >= 0 ? String(left) + (chars + "left") : String(left),
                              className: Cx.cx([
                                    css.counter,
                                    left <= 0 ? css.error : (
                                        left <= 10 ? css.warning : ""
                                      )
                                  ])
                            })
                      ],
                      className: css.labelWithCounter
                    }),
                props.field
              ]
            });
}

var make = FieldWithCounter;

export {
  css ,
  make ,
}
/* css Not a pure module */
