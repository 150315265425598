// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../libs/ID.res.js";
import * as Qs from "qs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as CityStateCountry from "../../../../models/CityStateCountry.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function fromJs(js) {
  return {
          providerIds: Belt_Array.map(js.providerIds, ID.fromString),
          certificationIds: Belt_Array.map(js.certificationIds, ID.fromString),
          cities: Belt_Array.map(js.cities, CityStateCountry.fromJs),
          radius: js.radius,
          query: js.query
        };
}

function toJson(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "providerIds",
                ID.toJsonArray(input.providerIds)
              ],
              [
                "certificationIds",
                ID.toJsonArray(input.certificationIds)
              ],
              [
                "cities",
                Belt_Array.map(input.cities, CityStateCountry.toJson)
              ],
              [
                "radius",
                input.radius
              ],
              [
                "query",
                input.query
              ]
            ]);
}

function toQueryString(input) {
  return Qs.stringify(toJson(input), {
              arrayFormat: "brackets"
            });
}

function empty() {
  return {
          providerIds: [],
          certificationIds: [],
          cities: [],
          radius: "25",
          query: ""
        };
}

var Input = {
  fromJs: fromJs,
  toJson: toJson,
  toQueryString: toQueryString,
  empty: empty
};

export {
  Input ,
}
/* ID Not a pure module */
