// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

var empty_additionalOptions = [];

var empty = {
  interconnect: undefined,
  bandwidth: undefined,
  provider: undefined,
  additionalOptions: empty_additionalOptions,
  specialNotesAndRequirements: undefined
};

function fromInternetConnectivity(internetConnectivity) {
  return {
          interconnect: internetConnectivity.interconnect,
          bandwidth: internetConnectivity.bandwidth,
          provider: internetConnectivity.provider,
          additionalOptions: internetConnectivity.additionalOptions,
          specialNotesAndRequirements: internetConnectivity.specialNotesAndRequirements
        };
}

function toJson(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "interconnect",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.interconnect)
              ],
              [
                "bandwidth",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.bandwidth)
              ],
              [
                "provider",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.provider)
              ],
              [
                "additionalOptions",
                Json_Encode$JsonCombinators.array(function (prim) {
                        return prim;
                      })(input.additionalOptions)
              ],
              [
                "specialNotesAndRequirements",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.specialNotesAndRequirements)
              ]
            ]);
}

var Input = {
  empty: empty,
  fromInternetConnectivity: fromInternetConnectivity,
  toJson: toJson
};

export {
  Input ,
}
/* No side effect */
