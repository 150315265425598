// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../libs/ID.res.js";
import * as Qs from "qs";
import * as Project from "../../../../models/Project.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

function fromJs(value) {
  if (value === "desc") {
    return "Desc";
  } else {
    return "Asc";
  }
}

var SortDirection = {
  toJson: toJson,
  fromJs: fromJs
};

function toJson$1(x) {
  var variant = x.NAME;
  if (variant === "End") {
    return "end_date";
  } else if (variant === "Start") {
    return "start_date";
  } else if (variant === "Title") {
    return "title";
  } else {
    return "created_at";
  }
}

var SortBy = {
  toJson: toJson$1
};

function fromJs$1(js) {
  var match = js.sortBy;
  var tmp;
  if (match == null) {
    tmp = undefined;
  } else {
    switch (match) {
      case "created_at" :
          var match$1 = js.sortDirection;
          tmp = (match$1 == null) || match$1 !== "asc" ? ({
                NAME: "Created",
                VAL: "Desc"
              }) : ({
                NAME: "Created",
                VAL: "Asc"
              });
          break;
      case "end_date" :
          var match$2 = js.sortDirection;
          tmp = (match$2 == null) || match$2 !== "desc" ? ({
                NAME: "End",
                VAL: "Asc"
              }) : ({
                NAME: "End",
                VAL: "Desc"
              });
          break;
      case "start_date" :
          var match$3 = js.sortDirection;
          tmp = (match$3 == null) || match$3 !== "desc" ? ({
                NAME: "Start",
                VAL: "Asc"
              }) : ({
                NAME: "Start",
                VAL: "Desc"
              });
          break;
      case "title" :
          tmp = {
            NAME: "Title",
            VAL: "Asc"
          };
          break;
      default:
        tmp = undefined;
    }
  }
  return {
          query: Caml_option.nullable_to_opt(js.query),
          providerIds: Belt_Option.map(Caml_option.nullable_to_opt(js.providerIds), (function (array) {
                  return Belt_Array.map(array, (function (prim) {
                                return prim;
                              }));
                })),
          statuses: Belt_Option.map(Caml_option.nullable_to_opt(js.statuses), (function (array) {
                  return Belt_Array.map(array, Project.Status.fromString);
                })),
          sortBy: tmp,
          categories: Belt_Option.map(Caml_option.nullable_to_opt(js.categories), (function (array) {
                  return Belt_Array.map(array, Project.ProjectType.fromJs);
                }))
        };
}

function toJson$2(input) {
  var x = input.sortBy;
  return Json_Encode$JsonCombinators.object([
              [
                "query",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(input.query)
              ],
              [
                "providerIds",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(Belt_Option.map(input.providerIds, (function (__x) {
                            return Belt_Array.map(__x, ID.toJson);
                          })))
              ],
              [
                "statuses",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(Belt_Option.map(input.statuses, (function (__x) {
                            return Belt_Array.map(__x, Project.Status.toJson);
                          })))
              ],
              [
                "sortBy",
                x !== undefined ? toJson$1(x) : null
              ],
              [
                "sortDirection",
                Belt_Option.getWithDefault(Belt_Option.map(input.sortBy, (function (value) {
                            return toJson(value.VAL);
                          })), null)
              ],
              [
                "categories",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(Belt_Option.map(input.categories, (function (__x) {
                            return Belt_Array.map(__x, Project.ProjectType.toJsonString);
                          })))
              ]
            ]);
}

function toQueryString(input) {
  return Qs.stringify(toJson$2(input), {
              arrayFormat: "brackets"
            });
}

function empty() {
  return {
          query: "",
          providerIds: [],
          statuses: [],
          sortBy: undefined,
          categories: []
        };
}

var Input = {
  fromJs: fromJs$1,
  toJson: toJson$2,
  toQueryString: toQueryString,
  empty: empty
};

export {
  SortDirection ,
  SortBy ,
  Input ,
}
/* ID Not a pure module */
