// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Qs from "qs";
import * as Rest from "../Rest.res.js";
import * as ResourceItem from "../../models/ResourceItem.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(req) {
  return Json_Encode$JsonCombinators.object([
              [
                "page",
                req.page
              ],
              [
                "query",
                req.query
              ],
              [
                "category",
                req.category
              ]
            ]);
}

function toQueryString(req) {
  return Qs.stringify(toJson(req), {
              arrayFormat: "brackets"
            });
}

var Req = {
  toJson: toJson,
  toQueryString: toQueryString
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              resources: field.required("resources", Json_Decode$JsonCombinators.array(ResourceItem.decoder)),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int)
            };
    });

var Res = {
  decoder: decoder
};

function exec(pageNum, query, category) {
  var req = {
    page: pageNum,
    query: query,
    category: category
  };
  var url = "/resources?" + toQueryString(req);
  return Rest.$$fetch(url, "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
