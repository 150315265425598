// Generated by ReScript, PLEASE EDIT WITH CARE


function fromReactEventTargetToHtmlElement(target) {
  return target;
}

var Target = {
  fromReactEventTargetToHtmlElement: fromReactEventTargetToHtmlElement
};

function subscribeToClicks(handler) {
  window.addEventListener("click", handler);
  return function () {
    window.removeEventListener("click", handler);
  };
}

function subscribeToKeyDown(handler) {
  window.addEventListener("keydown", handler);
  return function () {
    window.removeEventListener("keydown", handler);
  };
}

function subscribeToMouseMove(handler) {
  window.addEventListener("mousemove", handler);
  return function () {
    window.removeEventListener("mousemove", handler);
  };
}

function subscribeToMouseUp(handler) {
  window.addEventListener("mouseup", handler);
  return function () {
    window.removeEventListener("mouseup", handler);
  };
}

function subscribeToTouchMove(handler) {
  window.addEventListener("touchmove", handler);
  return function () {
    window.removeEventListener("touchmove", handler);
  };
}

function subscribeToTouchEnd(handler) {
  window.addEventListener("touchend", handler);
  return function () {
    window.removeEventListener("touchend", handler);
  };
}

function subscribeToDragEnter(handler) {
  window.addEventListener("dragenter", handler);
  return function () {
    window.removeEventListener("dragenter", handler);
  };
}

function subscribeToDragLeave(handler) {
  window.addEventListener("dragleave", handler);
  return function () {
    window.removeEventListener("dragleave", handler);
  };
}

function subscribeToDragOver(handler) {
  window.addEventListener("dragover", handler);
  return function () {
    window.removeEventListener("dragover", handler);
  };
}

function subscribeToDrop(handler) {
  window.addEventListener("drop", handler);
  return function () {
    window.removeEventListener("drop", handler);
  };
}

function subscribeToResize(handler) {
  window.addEventListener("resize", handler);
  return function () {
    window.removeEventListener("resize", handler);
  };
}

function subscribeToScroll(handler) {
  window.addEventListener("scroll", handler);
  return function () {
    window.removeEventListener("scroll", handler);
  };
}

var Subscriptions = {
  subscribeToClicks: subscribeToClicks,
  subscribeToKeyDown: subscribeToKeyDown,
  subscribeToMouseMove: subscribeToMouseMove,
  subscribeToMouseUp: subscribeToMouseUp,
  subscribeToTouchMove: subscribeToTouchMove,
  subscribeToTouchEnd: subscribeToTouchEnd,
  subscribeToDragEnter: subscribeToDragEnter,
  subscribeToDragLeave: subscribeToDragLeave,
  subscribeToDragOver: subscribeToDragOver,
  subscribeToDrop: subscribeToDrop,
  subscribeToResize: subscribeToResize,
  subscribeToScroll: subscribeToScroll
};

export {
  Target ,
  Subscriptions ,
}
/* No side effect */
