// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as Rest from "../Rest.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(productId, qty) {
  return Json_Encode$JsonCombinators.object([
              [
                "productId",
                ID.toJson(productId)
              ],
              [
                "qty",
                qty
              ]
            ]);
}

var Req = {
  toJson: toJson
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              cartQty: field.required("cartQty", Json_Decode$JsonCombinators.$$int),
              productQty: field.required("productQty", Json_Decode$JsonCombinators.$$int)
            };
    });

var Res = {
  decoder: decoder
};

function exec(qty, productId) {
  return Rest.$$fetch("/add_to_cart", {
              NAME: "Post",
              VAL: toJson(productId, qty)
            }, {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
