// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as Rest from "../Rest.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(locationIds) {
  return Json_Encode$JsonCombinators.object([[
                "location_ids",
                ID.toJsonArray(locationIds)
              ]]);
}

var Req = {
  toJson: toJson
};

function exec(locationSelectedIds, projectId) {
  return Rest.$$fetch(projectId !== undefined ? "/projects/" + (Caml_option.valFromOption(projectId) + "/select_locations") : "/projects/select_locations", {
              NAME: "Put",
              VAL: toJson(locationSelectedIds)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
