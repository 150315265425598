// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as User from "../../models/User.res.js";
import * as SignInData from "../../bundles/client/sign-in/SignInData.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              afterSignInPath: field.required("afterSignInPath", Json_Decode$JsonCombinators.string),
              user: field.required("user", User.decoder)
            };
    });

var UserWithRedirect = {
  decoder: decoder
};

var toJson = SignInData.Input.toJson;

var Req = {
  toJson: toJson
};

var decoder$1 = Json_Decode$JsonCombinators.oneOf([
      Json_Decode$JsonCombinators.map(decoder, (function (data) {
              return {
                      TAG: "UserObjectWithRedirect",
                      _0: data
                    };
            })),
      Json_Decode$JsonCombinators.map(User.decoder, (function (user) {
              return {
                      TAG: "UserObject",
                      _0: user
                    };
            }))
    ]);

var Res = {
  decoder: decoder$1
};

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return field.required("message", Json_Decode$JsonCombinators.string);
    });

var Err = {
  decoder: decoder$2
};

function exec(input) {
  return Rest.$$fetch("/users/sign_in", {
              NAME: "Post",
              VAL: toJson(input)
            }, {
              NAME: "Json",
              VAL: decoder$1
            }, {
              NAME: "Message",
              VAL: decoder$2
            }, undefined);
}

export {
  UserWithRedirect ,
  Req ,
  Res ,
  Err ,
  exec ,
}
/* decoder Not a pure module */
