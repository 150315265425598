// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Validator from "Utils/validator";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function empty() {
  return {
          currentPassword: "",
          password: "",
          confirmPassword: ""
        };
}

function passwordStrength(password) {
  if (password.length >= 8) {
    return "Strong";
  } else if (password.length >= 6) {
    return "Medium";
  } else {
    return "Low";
  }
}

function toJson(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "current_password",
                input.currentPassword
              ],
              [
                "password",
                input.password
              ],
              [
                "confirm_password",
                input.confirmPassword
              ]
            ]);
}

var Input = {
  empty: empty,
  passwordStrength: passwordStrength,
  toJson: toJson
};

function toJs(input) {
  return {
          currentPassword: input.currentPassword,
          password: input.password,
          confirmPassword: input.confirmPassword
        };
}

var ValidationInput = {
  toJs: toJs
};

function fromJs(js) {
  return {
          currentPassword: Caml_option.nullable_to_opt(js.currentPassword),
          password: Caml_option.nullable_to_opt(js.password),
          confirmPassword: Caml_option.nullable_to_opt(js.confirmPassword)
        };
}

var ExternalValidationResult = {
  fromJs: fromJs
};

function convertResult(field) {
  if (field !== undefined) {
    return {
            TAG: "Error",
            _0: Caml_option.valFromOption(field)
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function fromExternalResult(results) {
  return {
          currentPassword: convertResult(results.currentPassword),
          password: convertResult(results.password),
          confirmPassword: convertResult(results.confirmPassword)
        };
}

var ValidationResult = {
  convertResult: convertResult,
  fromExternalResult: fromExternalResult
};

var validationFields = [
  {
    id: "currentPassword",
    validation: [
      {
        TAG: "BasicRule",
        _0: {
          rule: "required",
          message: "Current Password is required."
        }
      },
      {
        TAG: "LengthRule",
        _0: {
          rule: "minlength",
          message: "Password must be atleast 8 characters in length.",
          length: 8
        }
      }
    ]
  },
  {
    id: "password",
    validation: [
      {
        TAG: "BasicRule",
        _0: {
          rule: "required",
          message: "Password is required."
        }
      },
      {
        TAG: "LengthRule",
        _0: {
          rule: "minlength",
          message: "Password must be atleast 8 characters in length.",
          length: 8
        }
      }
    ]
  },
  {
    id: "confirmPassword",
    validation: [
      {
        TAG: "BasicRule",
        _0: {
          rule: "required",
          message: "Confirm Password is required."
        }
      },
      {
        TAG: "LengthRule",
        _0: {
          rule: "minlength",
          message: "Password must be atleast 8 characters in length.",
          length: 8
        }
      },
      {
        TAG: "MatchRule",
        _0: {
          rule: "matchPassword",
          matchWithField: "password",
          message: "Confirm password and password must be match."
        }
      }
    ]
  }
];

function fieldsToJs(fields) {
  return Belt_Array.map(fields, (function (item) {
                return {
                        id: item.id,
                        validation: Belt_Array.map(item.validation, (function (rule) {
                                return rule._0;
                              }))
                      };
              }));
}

function all(values) {
  return fromExternalResult(fromJs(Validator.formErrorsValidations(toJs(values), fieldsToJs(validationFields))));
}

function valid(result) {
  if (result.currentPassword.TAG === "Ok" && result.password.TAG === "Ok" && result.confirmPassword.TAG === "Ok") {
    return true;
  } else {
    return false;
  }
}

var Validate = {
  validationFields: validationFields,
  fieldsToJs: fieldsToJs,
  all: all,
  valid: valid
};

export {
  Input ,
  ValidationInput ,
  ExternalValidationResult ,
  ValidationResult ,
  Validate ,
}
/* Utils/validator Not a pure module */
