// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../../../../libs/Date.res.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import IsPast from "date-fns/isPast";
import IsToday from "date-fns/isToday";
import * as NonSecure from "nanoid/non-secure";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function validateFieldOfCollection2OnChangeWithValidator(input, index, fieldStatus, submissionStatus, validator, setStatus) {
  var match = validator.strategy;
  var exit = 0;
  var exit$1 = 0;
  if (typeof fieldStatus !== "object" || fieldStatus._1 !== "Shown") {
    exit$1 = 4;
  } else {
    exit = 3;
  }
  if (exit$1 === 4) {
    if (submissionStatus === "NeverSubmitted") {
      switch (match) {
        case "OnFirstChange" :
            exit = 3;
            break;
        case "OnFirstSuccess" :
        case "OnFirstSuccessOrFirstBlur" :
            exit = 2;
            break;
        case "OnFirstBlur" :
        case "OnSubmit" :
            exit = 1;
            break;
        
      }
    } else {
      exit = 3;
    }
  }
  switch (exit) {
    case 1 :
        return setStatus({
                    TAG: "Dirty",
                    _0: validator.validate(input, index),
                    _1: "Hidden"
                  });
    case 2 :
        var result = validator.validate(input, index);
        if (result.TAG === "Ok") {
          return setStatus({
                      TAG: "Dirty",
                      _0: result,
                      _1: "Shown"
                    });
        } else {
          return setStatus({
                      TAG: "Dirty",
                      _0: result,
                      _1: "Hidden"
                    });
        }
    case 3 :
        return setStatus({
                    TAG: "Dirty",
                    _0: validator.validate(input, index),
                    _1: "Shown"
                  });
    
  }
}

function validateDependentFieldOfCollection2OnChange(input, index, fieldStatus, validator, setStatus) {
  if (typeof fieldStatus !== "object" || fieldStatus._1 !== "Shown") {
    return ;
  } else {
    return Caml_option.some(setStatus({
                    TAG: "Dirty",
                    _0: validator.validate(input, index),
                    _1: "Shown"
                  }));
  }
}

function validateFieldOfCollection2OnBlurWithValidator(input, index, fieldStatus, validator, setStatus) {
  var exit = 0;
  if (typeof fieldStatus !== "object") {
    exit = 1;
  } else {
    if (fieldStatus._1 === "Shown") {
      return ;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match = validator.strategy;
    var exit$1 = 0;
    switch (match) {
      case "OnFirstBlur" :
      case "OnFirstSuccessOrFirstBlur" :
          exit$1 = 2;
          break;
      default:
        return Caml_option.some(setStatus({
                        TAG: "Dirty",
                        _0: validator.validate(input, index),
                        _1: "Hidden"
                      }));
    }
    if (exit$1 === 2) {
      return Caml_option.some(setStatus({
                      TAG: "Dirty",
                      _0: validator.validate(input, index),
                      _1: "Shown"
                    }));
    }
    
  }
  
}

var validators = {
  configurations: {
    collection: (function (param) {
        if (param.configurations.length !== 0) {
          return {
                  TAG: "Ok",
                  _0: undefined
                };
        } else {
          return {
                  TAG: "Error",
                  _0: "At least one configuration is required"
                };
        }
      }),
    fields: {
      startDate: {
        strategy: "OnFirstBlur",
        validate: (function (param, at) {
            var date = param.configurations[at].startDate;
            if (date === undefined) {
              return {
                      TAG: "Error",
                      _0: "Service start date is required"
                    };
            }
            var date$1 = Caml_option.valFromOption(date);
            if (IsPast(date$1) || IsToday(date$1)) {
              return {
                      TAG: "Error",
                      _0: "Start date can't be in the past"
                    };
            } else {
              return {
                      TAG: "Ok",
                      _0: date$1
                    };
            }
          })
      },
      contractLength: {
        strategy: "OnFirstBlur",
        validate: (function (param, at) {
            var x = param.configurations[at].contractLength;
            if (x !== undefined) {
              return {
                      TAG: "Ok",
                      _0: x.value
                    };
            } else {
              return {
                      TAG: "Error",
                      _0: "Contract length is required"
                    };
            }
          })
      },
      serverInstance: {
        availability: {
          strategy: "OnFirstBlur",
          validate: (function (param, at) {
              var x = param.configurations[at].serverInstance.availability;
              if (x === "") {
                return {
                        TAG: "Error",
                        _0: "Availability is required"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        cpu: {
          strategy: "OnFirstBlur",
          validate: (function (param, at) {
              var x = param.configurations[at].serverInstance.cpu;
              if (x !== undefined) {
                if (x <= 0) {
                  return {
                          TAG: "Error",
                          _0: "Number of CPUs must be greater than zero"
                        };
                } else {
                  return {
                          TAG: "Ok",
                          _0: x
                        };
                }
              } else {
                return {
                        TAG: "Error",
                        _0: "Number of CPUs is required"
                      };
              }
            })
        },
        database: {
          strategy: "OnFirstBlur",
          validate: (function (param, at) {
              var x = param.configurations[at].serverInstance.database;
              if (x === "") {
                return {
                        TAG: "Ok",
                        _0: undefined
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        description: {
          strategy: "OnFirstBlur",
          validate: (function (param, at) {
              var x = param.configurations[at].serverInstance.description;
              if (x === "") {
                return {
                        TAG: "Ok",
                        _0: undefined
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        hypervisor: {
          strategy: "OnFirstBlur",
          validate: (function (param, at) {
              var x = param.configurations[at].serverInstance.hypervisor;
              if (x === "") {
                return {
                        TAG: "Ok",
                        _0: undefined
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        image: {
          strategy: "OnFirstBlur",
          validate: (function (param, at) {
              var x = param.configurations[at].serverInstance.image;
              if (x === "") {
                return {
                        TAG: "Ok",
                        _0: undefined
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        operatingSystem: {
          strategy: "OnFirstBlur",
          validate: (function (param, at) {
              var x = param.configurations[at].serverInstance.operatingSystem;
              if (x === "") {
                return {
                        TAG: "Error",
                        _0: "Operating system is required"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        ram: {
          strategy: "OnFirstBlur",
          validate: (function (param, at) {
              var x = param.configurations[at].serverInstance.ram;
              if (x === "") {
                return {
                        TAG: "Error",
                        _0: "RAM is required"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        region: {
          strategy: "OnFirstBlur",
          validate: (function (param, at) {
              var x = param.configurations[at].serverInstance.region;
              if (x === "") {
                return {
                        TAG: "Ok",
                        _0: undefined
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        type_: {
          strategy: "OnFirstBlur",
          validate: (function (param, at) {
              var x = param.configurations[at].serverInstance.type_;
              if (x === "") {
                return {
                        TAG: "Ok",
                        _0: undefined
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        visibility: {
          strategy: "OnFirstBlur",
          validate: (function (param, at) {
              var x = param.configurations[at].serverInstance.visibility;
              if (x === "") {
                return {
                        TAG: "Error",
                        _0: "Visibility is required"
                      };
              } else {
                return {
                        TAG: "Ok",
                        _0: x
                      };
              }
            })
        },
        vmNumber: {
          strategy: "OnFirstBlur",
          validate: (function (param, at) {
              var x = param.configurations[at].serverInstance.vmNumber;
              if (x !== undefined) {
                if (x <= 0) {
                  return {
                          TAG: "Error",
                          _0: "Number of VMs must be greater than zero"
                        };
                } else {
                  return {
                          TAG: "Ok",
                          _0: x
                        };
                }
              } else {
                return {
                        TAG: "Error",
                        _0: "Number of VMs is required"
                      };
              }
            })
        }
      },
      billingTypes: {
        strategy: "OnFirstChange",
        validate: (function (param, at) {
            return {
                    TAG: "Ok",
                    _0: Belt_SetString.toArray(param.configurations[at].billingTypes)
                  };
          })
      },
      certifications: {
        strategy: "OnFirstChange",
        validate: (function (param, at) {
            return {
                    TAG: "Ok",
                    _0: Belt_SetString.toArray(param.configurations[at].certifications)
                  };
          })
      },
      features: {
        strategy: "OnFirstChange",
        validate: (function (param, at) {
            return {
                    TAG: "Ok",
                    _0: Belt_SetString.toArray(param.configurations[at].features)
                  };
          })
      },
      additionalRequirements: {
        strategy: "OnFirstBlur",
        validate: (function (param, at) {
            var x = param.configurations[at].additionalRequirements;
            if (x === "") {
              return {
                      TAG: "Ok",
                      _0: undefined
                    };
            } else {
              return {
                      TAG: "Ok",
                      _0: x
                    };
            }
          })
      },
      volumes: {
        collection: (function (param, at) {
            var match = param.configurations[at].volumes;
            if (match.length !== 0) {
              return {
                      TAG: "Ok",
                      _0: undefined
                    };
            } else {
              return {
                      TAG: "Error",
                      _0: "At least one volume is required"
                    };
            }
          }),
        fields: {
          diskType: {
            strategy: "OnFirstBlur",
            validate: (function (param, param$1) {
                var x = param.configurations[param$1[0]].volumes[param$1[1]].diskType;
                if (x === "") {
                  return {
                          TAG: "Error",
                          _0: "Disk type is required"
                        };
                } else {
                  return {
                          TAG: "Ok",
                          _0: x
                        };
                }
              })
          },
          diskSize: {
            strategy: "OnFirstBlur",
            validate: (function (param, param$1) {
                var x = param.configurations[param$1[0]].volumes[param$1[1]].diskSize;
                if (x === "") {
                  return {
                          TAG: "Error",
                          _0: "Disk size is required"
                        };
                } else {
                  return {
                          TAG: "Ok",
                          _0: x
                        };
                }
              })
          },
          maxIops: {
            strategy: "OnFirstBlur",
            validate: (function (param, param$1) {
                var x = param.configurations[param$1[0]].volumes[param$1[1]].maxIops;
                if (x !== undefined && x < 0) {
                  return {
                          TAG: "Error",
                          _0: "Max IOPS must be zero or greater"
                        };
                } else {
                  return {
                          TAG: "Ok",
                          _0: x
                        };
                }
              })
          },
          throughput: {
            strategy: "OnFirstBlur",
            validate: (function (param, param$1) {
                var volume = param.configurations[param$1[0]].volumes[param$1[1]];
                var match = volume.throughput;
                var match$1 = volume.throughputUnit;
                if (match !== undefined) {
                  if (match < 0) {
                    return {
                            TAG: "Error",
                            _0: "Throughput must be zero or greater"
                          };
                  } else {
                    return {
                            TAG: "Ok",
                            _0: match
                          };
                  }
                } else if (match$1 === "") {
                  return {
                          TAG: "Ok",
                          _0: undefined
                        };
                } else {
                  return {
                          TAG: "Error",
                          _0: "You provided throughput unit but forgot to specify its value"
                        };
                }
              })
          },
          throughputUnit: {
            strategy: "OnFirstBlur",
            validate: (function (param, param$1) {
                var volume = param.configurations[param$1[0]].volumes[param$1[1]];
                var match = volume.throughput;
                var match$1 = volume.throughputUnit;
                if (match !== undefined) {
                  if (match$1 === "") {
                    return {
                            TAG: "Error",
                            _0: "You provided throughput value but forgot to specify its unit"
                          };
                  } else {
                    return {
                            TAG: "Ok",
                            _0: match$1
                          };
                  }
                } else if (match$1 === "") {
                  return {
                          TAG: "Ok",
                          _0: undefined
                        };
                } else {
                  return {
                          TAG: "Ok",
                          _0: match$1
                        };
                }
              })
          },
          description: {
            strategy: "OnFirstBlur",
            validate: (function (param, param$1) {
                var x = param.configurations[param$1[0]].volumes[param$1[1]].description;
                if (x === "") {
                  return {
                          TAG: "Ok",
                          _0: undefined
                        };
                } else {
                  return {
                          TAG: "Ok",
                          _0: x
                        };
                }
              })
          }
        }
      }
    }
  }
};

function initialFieldsStatuses(input) {
  return {
          configurations: Belt_Array.map(input.configurations, (function (configuration) {
                  return {
                          startDate: "Pristine",
                          contractLength: "Pristine",
                          serverInstance: {
                            availability: "Pristine",
                            cpu: "Pristine",
                            database: "Pristine",
                            description: "Pristine",
                            hypervisor: "Pristine",
                            image: "Pristine",
                            operatingSystem: "Pristine",
                            ram: "Pristine",
                            region: "Pristine",
                            type_: "Pristine",
                            visibility: "Pristine",
                            vmNumber: "Pristine"
                          },
                          billingTypes: "Pristine",
                          certifications: "Pristine",
                          features: "Pristine",
                          additionalRequirements: "Pristine",
                          volumes: Belt_Array.map(configuration.volumes, (function (_volume) {
                                  return {
                                          diskType: "Pristine",
                                          diskSize: "Pristine",
                                          maxIops: "Pristine",
                                          throughput: "Pristine",
                                          throughputUnit: "Pristine",
                                          description: "Pristine"
                                        };
                                }))
                        };
                }))
        };
}

function initialCollectionsStatuses(input) {
  return {
          configurations: {
            self: undefined,
            volumes: Belt_Array.map(input.configurations, (function (param) {
                    
                  }))
          }
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: initialCollectionsStatuses(input),
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = validators.configurations.collection(input);
  var match$1 = Belt_Array.reduceWithIndex(fieldsStatuses.configurations, [
        {
          TAG: "Ok",
          _0: []
        },
        [],
        []
      ], (function (param, fieldStatus, configurationIndex) {
          var configurationsVolumeCollectionsStatuses = param[2];
          var fieldStatuses = param[1];
          var output = param[0];
          var match = fieldStatus.startDate;
          var tmp;
          tmp = typeof match !== "object" ? validators.configurations.fields.startDate.validate(input, configurationIndex) : match._0;
          var match$1 = fieldStatus.contractLength;
          var tmp$1;
          tmp$1 = typeof match$1 !== "object" ? validators.configurations.fields.contractLength.validate(input, configurationIndex) : match$1._0;
          var match$2 = fieldStatus.serverInstance.availability;
          var tmp$2;
          tmp$2 = typeof match$2 !== "object" ? validators.configurations.fields.serverInstance.availability.validate(input, configurationIndex) : match$2._0;
          var match$3 = fieldStatus.serverInstance.cpu;
          var tmp$3;
          tmp$3 = typeof match$3 !== "object" ? validators.configurations.fields.serverInstance.cpu.validate(input, configurationIndex) : match$3._0;
          var match$4 = fieldStatus.serverInstance.database;
          var tmp$4;
          tmp$4 = typeof match$4 !== "object" ? validators.configurations.fields.serverInstance.database.validate(input, configurationIndex) : match$4._0;
          var match$5 = fieldStatus.serverInstance.description;
          var tmp$5;
          tmp$5 = typeof match$5 !== "object" ? validators.configurations.fields.serverInstance.description.validate(input, configurationIndex) : match$5._0;
          var match$6 = fieldStatus.serverInstance.hypervisor;
          var tmp$6;
          tmp$6 = typeof match$6 !== "object" ? validators.configurations.fields.serverInstance.hypervisor.validate(input, configurationIndex) : match$6._0;
          var match$7 = fieldStatus.serverInstance.image;
          var tmp$7;
          tmp$7 = typeof match$7 !== "object" ? validators.configurations.fields.serverInstance.image.validate(input, configurationIndex) : match$7._0;
          var match$8 = fieldStatus.serverInstance.operatingSystem;
          var tmp$8;
          tmp$8 = typeof match$8 !== "object" ? validators.configurations.fields.serverInstance.operatingSystem.validate(input, configurationIndex) : match$8._0;
          var match$9 = fieldStatus.serverInstance.ram;
          var tmp$9;
          tmp$9 = typeof match$9 !== "object" ? validators.configurations.fields.serverInstance.ram.validate(input, configurationIndex) : match$9._0;
          var match$10 = fieldStatus.serverInstance.region;
          var tmp$10;
          tmp$10 = typeof match$10 !== "object" ? validators.configurations.fields.serverInstance.region.validate(input, configurationIndex) : match$10._0;
          var match$11 = fieldStatus.serverInstance.type_;
          var tmp$11;
          tmp$11 = typeof match$11 !== "object" ? validators.configurations.fields.serverInstance.type_.validate(input, configurationIndex) : match$11._0;
          var match$12 = fieldStatus.serverInstance.visibility;
          var tmp$12;
          tmp$12 = typeof match$12 !== "object" ? validators.configurations.fields.serverInstance.visibility.validate(input, configurationIndex) : match$12._0;
          var match$13 = fieldStatus.serverInstance.vmNumber;
          var tmp$13;
          tmp$13 = typeof match$13 !== "object" ? validators.configurations.fields.serverInstance.vmNumber.validate(input, configurationIndex) : match$13._0;
          var match$14 = fieldStatus.billingTypes;
          var tmp$14;
          tmp$14 = typeof match$14 !== "object" ? validators.configurations.fields.billingTypes.validate(input, configurationIndex) : match$14._0;
          var match$15 = fieldStatus.certifications;
          var tmp$15;
          tmp$15 = typeof match$15 !== "object" ? validators.configurations.fields.certifications.validate(input, configurationIndex) : match$15._0;
          var match$16 = fieldStatus.features;
          var tmp$16;
          tmp$16 = typeof match$16 !== "object" ? validators.configurations.fields.features.validate(input, configurationIndex) : match$16._0;
          var match$17 = fieldStatus.additionalRequirements;
          var tmp$17;
          tmp$17 = typeof match$17 !== "object" ? validators.configurations.fields.additionalRequirements.validate(input, configurationIndex) : match$17._0;
          var match_0 = validators.configurations.fields.volumes.collection(input, configurationIndex);
          var match_1 = Belt_Array.reduceWithIndex(fieldStatus.volumes, [
                {
                  TAG: "Ok",
                  _0: []
                },
                []
              ], (function (param, fieldStatus, volumeIndex) {
                  var statuses = param[1];
                  var output = param[0];
                  var match = fieldStatus.diskType;
                  var tmp;
                  tmp = typeof match !== "object" ? validators.configurations.fields.volumes.fields.diskType.validate(input, [
                          configurationIndex,
                          volumeIndex
                        ]) : match._0;
                  var match$1 = fieldStatus.diskSize;
                  var tmp$1;
                  tmp$1 = typeof match$1 !== "object" ? validators.configurations.fields.volumes.fields.diskSize.validate(input, [
                          configurationIndex,
                          volumeIndex
                        ]) : match$1._0;
                  var match$2 = fieldStatus.maxIops;
                  var tmp$2;
                  tmp$2 = typeof match$2 !== "object" ? validators.configurations.fields.volumes.fields.maxIops.validate(input, [
                          configurationIndex,
                          volumeIndex
                        ]) : match$2._0;
                  var match$3 = fieldStatus.throughput;
                  var tmp$3;
                  tmp$3 = typeof match$3 !== "object" ? validators.configurations.fields.volumes.fields.throughput.validate(input, [
                          configurationIndex,
                          volumeIndex
                        ]) : match$3._0;
                  var match$4 = fieldStatus.throughputUnit;
                  var tmp$4;
                  tmp$4 = typeof match$4 !== "object" ? validators.configurations.fields.volumes.fields.throughputUnit.validate(input, [
                          configurationIndex,
                          volumeIndex
                        ]) : match$4._0;
                  var match$5 = fieldStatus.description;
                  var tmp$5;
                  tmp$5 = typeof match$5 !== "object" ? validators.configurations.fields.volumes.fields.description.validate(input, [
                          configurationIndex,
                          volumeIndex
                        ]) : match$5._0;
                  if (output.TAG === "Ok") {
                    var diskTypeResult = tmp;
                    var output$1 = output._0;
                    if (diskTypeResult.TAG === "Ok") {
                      var diskSizeResult = tmp$1;
                      if (diskSizeResult.TAG === "Ok") {
                        var maxIopsResult = tmp$2;
                        if (maxIopsResult.TAG === "Ok") {
                          var throughputResult = tmp$3;
                          if (throughputResult.TAG === "Ok") {
                            var throughputUnitResult = tmp$4;
                            if (throughputUnitResult.TAG === "Ok") {
                              var descriptionResult = tmp$5;
                              if (descriptionResult.TAG === "Ok") {
                                output$1.push({
                                      diskType: diskTypeResult._0,
                                      diskSize: diskSizeResult._0,
                                      maxIops: maxIopsResult._0,
                                      throughput: throughputResult._0,
                                      throughputUnit: throughputUnitResult._0,
                                      description: descriptionResult._0
                                    });
                                statuses.push({
                                      diskType: {
                                        TAG: "Dirty",
                                        _0: diskTypeResult,
                                        _1: "Shown"
                                      },
                                      diskSize: {
                                        TAG: "Dirty",
                                        _0: diskSizeResult,
                                        _1: "Shown"
                                      },
                                      maxIops: {
                                        TAG: "Dirty",
                                        _0: maxIopsResult,
                                        _1: "Shown"
                                      },
                                      throughput: {
                                        TAG: "Dirty",
                                        _0: throughputResult,
                                        _1: "Shown"
                                      },
                                      throughputUnit: {
                                        TAG: "Dirty",
                                        _0: throughputUnitResult,
                                        _1: "Shown"
                                      },
                                      description: {
                                        TAG: "Dirty",
                                        _0: descriptionResult,
                                        _1: "Shown"
                                      }
                                    });
                                return [
                                        {
                                          TAG: "Ok",
                                          _0: output$1
                                        },
                                        statuses
                                      ];
                              }
                              
                            }
                            
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  statuses.push({
                        diskType: {
                          TAG: "Dirty",
                          _0: tmp,
                          _1: "Shown"
                        },
                        diskSize: {
                          TAG: "Dirty",
                          _0: tmp$1,
                          _1: "Shown"
                        },
                        maxIops: {
                          TAG: "Dirty",
                          _0: tmp$2,
                          _1: "Shown"
                        },
                        throughput: {
                          TAG: "Dirty",
                          _0: tmp$3,
                          _1: "Shown"
                        },
                        throughputUnit: {
                          TAG: "Dirty",
                          _0: tmp$4,
                          _1: "Shown"
                        },
                        description: {
                          TAG: "Dirty",
                          _0: tmp$5,
                          _1: "Shown"
                        }
                      });
                  return [
                          {
                            TAG: "Error",
                            _0: undefined
                          },
                          statuses
                        ];
                }));
          if (output.TAG === "Ok") {
            var startDateResult = tmp;
            var output$1 = output._0;
            if (startDateResult.TAG === "Ok") {
              var contractLengthResult = tmp$1;
              if (contractLengthResult.TAG === "Ok") {
                var availabilityResult = tmp$2;
                if (availabilityResult.TAG === "Ok") {
                  var cpuResult = tmp$3;
                  if (cpuResult.TAG === "Ok") {
                    var databaseResult = tmp$4;
                    if (databaseResult.TAG === "Ok") {
                      var descriptionResult = tmp$5;
                      if (descriptionResult.TAG === "Ok") {
                        var hypervisorResult = tmp$6;
                        if (hypervisorResult.TAG === "Ok") {
                          var imageResult = tmp$7;
                          if (imageResult.TAG === "Ok") {
                            var operatingSystemResult = tmp$8;
                            if (operatingSystemResult.TAG === "Ok") {
                              var ramResult = tmp$9;
                              if (ramResult.TAG === "Ok") {
                                var regionResult = tmp$10;
                                if (regionResult.TAG === "Ok") {
                                  var type_Result = tmp$11;
                                  if (type_Result.TAG === "Ok") {
                                    var visibilityResult = tmp$12;
                                    if (visibilityResult.TAG === "Ok") {
                                      var vmNumberResult = tmp$13;
                                      if (vmNumberResult.TAG === "Ok") {
                                        var billingTypesResult = tmp$14;
                                        if (billingTypesResult.TAG === "Ok") {
                                          var certificationsResult = tmp$15;
                                          if (certificationsResult.TAG === "Ok") {
                                            var featuresResult = tmp$16;
                                            if (featuresResult.TAG === "Ok") {
                                              var additionalRequirementsResult = tmp$17;
                                              if (additionalRequirementsResult.TAG === "Ok") {
                                                var configurationVolumesCollectionResult = match_0;
                                                if (configurationVolumesCollectionResult.TAG === "Ok") {
                                                  var match$18 = match_1;
                                                  var volumes = match$18[0];
                                                  if (volumes.TAG === "Ok") {
                                                    output$1.push({
                                                          startDate: startDateResult._0,
                                                          contractLength: contractLengthResult._0,
                                                          serverInstance: {
                                                            availability: availabilityResult._0,
                                                            cpu: cpuResult._0,
                                                            database: databaseResult._0,
                                                            description: descriptionResult._0,
                                                            hypervisor: hypervisorResult._0,
                                                            image: imageResult._0,
                                                            operatingSystem: operatingSystemResult._0,
                                                            ram: ramResult._0,
                                                            region: regionResult._0,
                                                            type_: type_Result._0,
                                                            visibility: visibilityResult._0,
                                                            vmNumber: vmNumberResult._0
                                                          },
                                                          volumes: volumes._0,
                                                          billingTypes: billingTypesResult._0,
                                                          certifications: certificationsResult._0,
                                                          features: featuresResult._0,
                                                          additionalRequirements: additionalRequirementsResult._0
                                                        });
                                                    fieldStatuses.push({
                                                          startDate: {
                                                            TAG: "Dirty",
                                                            _0: startDateResult,
                                                            _1: "Shown"
                                                          },
                                                          contractLength: {
                                                            TAG: "Dirty",
                                                            _0: contractLengthResult,
                                                            _1: "Shown"
                                                          },
                                                          serverInstance: {
                                                            availability: {
                                                              TAG: "Dirty",
                                                              _0: availabilityResult,
                                                              _1: "Shown"
                                                            },
                                                            cpu: {
                                                              TAG: "Dirty",
                                                              _0: cpuResult,
                                                              _1: "Shown"
                                                            },
                                                            database: {
                                                              TAG: "Dirty",
                                                              _0: databaseResult,
                                                              _1: "Shown"
                                                            },
                                                            description: {
                                                              TAG: "Dirty",
                                                              _0: descriptionResult,
                                                              _1: "Shown"
                                                            },
                                                            hypervisor: {
                                                              TAG: "Dirty",
                                                              _0: hypervisorResult,
                                                              _1: "Shown"
                                                            },
                                                            image: {
                                                              TAG: "Dirty",
                                                              _0: imageResult,
                                                              _1: "Shown"
                                                            },
                                                            operatingSystem: {
                                                              TAG: "Dirty",
                                                              _0: operatingSystemResult,
                                                              _1: "Shown"
                                                            },
                                                            ram: {
                                                              TAG: "Dirty",
                                                              _0: ramResult,
                                                              _1: "Shown"
                                                            },
                                                            region: {
                                                              TAG: "Dirty",
                                                              _0: regionResult,
                                                              _1: "Shown"
                                                            },
                                                            type_: {
                                                              TAG: "Dirty",
                                                              _0: type_Result,
                                                              _1: "Shown"
                                                            },
                                                            visibility: {
                                                              TAG: "Dirty",
                                                              _0: visibilityResult,
                                                              _1: "Shown"
                                                            },
                                                            vmNumber: {
                                                              TAG: "Dirty",
                                                              _0: vmNumberResult,
                                                              _1: "Shown"
                                                            }
                                                          },
                                                          billingTypes: {
                                                            TAG: "Dirty",
                                                            _0: billingTypesResult,
                                                            _1: "Shown"
                                                          },
                                                          certifications: {
                                                            TAG: "Dirty",
                                                            _0: certificationsResult,
                                                            _1: "Shown"
                                                          },
                                                          features: {
                                                            TAG: "Dirty",
                                                            _0: featuresResult,
                                                            _1: "Shown"
                                                          },
                                                          additionalRequirements: {
                                                            TAG: "Dirty",
                                                            _0: additionalRequirementsResult,
                                                            _1: "Shown"
                                                          },
                                                          volumes: match$18[1]
                                                        });
                                                    configurationsVolumeCollectionsStatuses.push(configurationVolumesCollectionResult);
                                                    return [
                                                            {
                                                              TAG: "Ok",
                                                              _0: output$1
                                                            },
                                                            fieldStatuses,
                                                            configurationsVolumeCollectionsStatuses
                                                          ];
                                                  }
                                                  
                                                }
                                                
                                              }
                                              
                                            }
                                            
                                          }
                                          
                                        }
                                        
                                      }
                                      
                                    }
                                    
                                  }
                                  
                                }
                                
                              }
                              
                            }
                            
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }
            
          }
          fieldStatuses.push({
                startDate: {
                  TAG: "Dirty",
                  _0: tmp,
                  _1: "Shown"
                },
                contractLength: {
                  TAG: "Dirty",
                  _0: tmp$1,
                  _1: "Shown"
                },
                serverInstance: {
                  availability: {
                    TAG: "Dirty",
                    _0: tmp$2,
                    _1: "Shown"
                  },
                  cpu: {
                    TAG: "Dirty",
                    _0: tmp$3,
                    _1: "Shown"
                  },
                  database: {
                    TAG: "Dirty",
                    _0: tmp$4,
                    _1: "Shown"
                  },
                  description: {
                    TAG: "Dirty",
                    _0: tmp$5,
                    _1: "Shown"
                  },
                  hypervisor: {
                    TAG: "Dirty",
                    _0: tmp$6,
                    _1: "Shown"
                  },
                  image: {
                    TAG: "Dirty",
                    _0: tmp$7,
                    _1: "Shown"
                  },
                  operatingSystem: {
                    TAG: "Dirty",
                    _0: tmp$8,
                    _1: "Shown"
                  },
                  ram: {
                    TAG: "Dirty",
                    _0: tmp$9,
                    _1: "Shown"
                  },
                  region: {
                    TAG: "Dirty",
                    _0: tmp$10,
                    _1: "Shown"
                  },
                  type_: {
                    TAG: "Dirty",
                    _0: tmp$11,
                    _1: "Shown"
                  },
                  visibility: {
                    TAG: "Dirty",
                    _0: tmp$12,
                    _1: "Shown"
                  },
                  vmNumber: {
                    TAG: "Dirty",
                    _0: tmp$13,
                    _1: "Shown"
                  }
                },
                billingTypes: {
                  TAG: "Dirty",
                  _0: tmp$14,
                  _1: "Shown"
                },
                certifications: {
                  TAG: "Dirty",
                  _0: tmp$15,
                  _1: "Shown"
                },
                features: {
                  TAG: "Dirty",
                  _0: tmp$16,
                  _1: "Shown"
                },
                additionalRequirements: {
                  TAG: "Dirty",
                  _0: tmp$17,
                  _1: "Shown"
                },
                volumes: match_1[1]
              });
          configurationsVolumeCollectionsStatuses.push(match_0);
          return [
                  {
                    TAG: "Error",
                    _0: undefined
                  },
                  fieldStatuses,
                  configurationsVolumeCollectionsStatuses
                ];
        }));
  if (match.TAG === "Ok") {
    var configurations = match$1[0];
    if (configurations.TAG === "Ok") {
      return {
              TAG: "Valid",
              output: {
                configurations: configurations._0
              },
              fieldsStatuses: {
                configurations: match$1[1]
              },
              collectionsStatuses: {
                configurations: {
                  self: match,
                  volumes: match$1[2]
                }
              }
            };
    }
    
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            configurations: match$1[1]
          },
          collectionsStatuses: {
            configurations: {
              self: match,
              volumes: match$1[2]
            }
          }
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateConfigurationStartDateField" :
                  var index = action._1;
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput, index, state.fieldsStatuses.configurations[index].startDate, state.submissionStatus, validators.configurations.fields.startDate, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index) {
                                                      return {
                                                              startDate: status,
                                                              contractLength: item.contractLength,
                                                              serverInstance: item.serverInstance,
                                                              billingTypes: item.billingTypes,
                                                              certifications: item.certifications,
                                                              features: item.features,
                                                              additionalRequirements: item.additionalRequirements,
                                                              volumes: item.volumes
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationContractLengthField" :
                  var index$1 = action._1;
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$1, index$1, state.fieldsStatuses.configurations[index$1].contractLength, state.submissionStatus, validators.configurations.fields.contractLength, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$1) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: status,
                                                              serverInstance: item.serverInstance,
                                                              billingTypes: item.billingTypes,
                                                              certifications: item.certifications,
                                                              features: item.features,
                                                              additionalRequirements: item.additionalRequirements,
                                                              volumes: item.volumes
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationServerInstanceAvailabilityField" :
                  var index$2 = action._1;
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$2, index$2, state.fieldsStatuses.configurations[index$2].serverInstance.availability, state.submissionStatus, validators.configurations.fields.serverInstance.availability, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$2) {
                                                      return item;
                                                    }
                                                    var init = item.serverInstance;
                                                    return {
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            serverInstance: {
                                                              availability: status,
                                                              cpu: init.cpu,
                                                              database: init.database,
                                                              description: init.description,
                                                              hypervisor: init.hypervisor,
                                                              image: init.image,
                                                              operatingSystem: init.operatingSystem,
                                                              ram: init.ram,
                                                              region: init.region,
                                                              type_: init.type_,
                                                              visibility: init.visibility,
                                                              vmNumber: init.vmNumber
                                                            },
                                                            billingTypes: item.billingTypes,
                                                            certifications: item.certifications,
                                                            features: item.features,
                                                            additionalRequirements: item.additionalRequirements,
                                                            volumes: item.volumes
                                                          };
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationServerInstanceCpuField" :
                  var index$3 = action._1;
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$3, index$3, state.fieldsStatuses.configurations[index$3].serverInstance.cpu, state.submissionStatus, validators.configurations.fields.serverInstance.cpu, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$3) {
                                                      return item;
                                                    }
                                                    var init = item.serverInstance;
                                                    return {
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            serverInstance: {
                                                              availability: init.availability,
                                                              cpu: status,
                                                              database: init.database,
                                                              description: init.description,
                                                              hypervisor: init.hypervisor,
                                                              image: init.image,
                                                              operatingSystem: init.operatingSystem,
                                                              ram: init.ram,
                                                              region: init.region,
                                                              type_: init.type_,
                                                              visibility: init.visibility,
                                                              vmNumber: init.vmNumber
                                                            },
                                                            billingTypes: item.billingTypes,
                                                            certifications: item.certifications,
                                                            features: item.features,
                                                            additionalRequirements: item.additionalRequirements,
                                                            volumes: item.volumes
                                                          };
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationServerInstanceDatabaseField" :
                  var index$4 = action._1;
                  var nextInput$4 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$4, index$4, state.fieldsStatuses.configurations[index$4].serverInstance.database, state.submissionStatus, validators.configurations.fields.serverInstance.database, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$4) {
                                                      return item;
                                                    }
                                                    var init = item.serverInstance;
                                                    return {
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            serverInstance: {
                                                              availability: init.availability,
                                                              cpu: init.cpu,
                                                              database: status,
                                                              description: init.description,
                                                              hypervisor: init.hypervisor,
                                                              image: init.image,
                                                              operatingSystem: init.operatingSystem,
                                                              ram: init.ram,
                                                              region: init.region,
                                                              type_: init.type_,
                                                              visibility: init.visibility,
                                                              vmNumber: init.vmNumber
                                                            },
                                                            billingTypes: item.billingTypes,
                                                            certifications: item.certifications,
                                                            features: item.features,
                                                            additionalRequirements: item.additionalRequirements,
                                                            volumes: item.volumes
                                                          };
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationServerInstanceDescriptionField" :
                  var index$5 = action._1;
                  var nextInput$5 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$5, index$5, state.fieldsStatuses.configurations[index$5].serverInstance.description, state.submissionStatus, validators.configurations.fields.serverInstance.description, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$5) {
                                                      return item;
                                                    }
                                                    var init = item.serverInstance;
                                                    return {
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            serverInstance: {
                                                              availability: init.availability,
                                                              cpu: init.cpu,
                                                              database: init.database,
                                                              description: status,
                                                              hypervisor: init.hypervisor,
                                                              image: init.image,
                                                              operatingSystem: init.operatingSystem,
                                                              ram: init.ram,
                                                              region: init.region,
                                                              type_: init.type_,
                                                              visibility: init.visibility,
                                                              vmNumber: init.vmNumber
                                                            },
                                                            billingTypes: item.billingTypes,
                                                            certifications: item.certifications,
                                                            features: item.features,
                                                            additionalRequirements: item.additionalRequirements,
                                                            volumes: item.volumes
                                                          };
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationServerInstanceHypervisorField" :
                  var index$6 = action._1;
                  var nextInput$6 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$6, index$6, state.fieldsStatuses.configurations[index$6].serverInstance.hypervisor, state.submissionStatus, validators.configurations.fields.serverInstance.hypervisor, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$6) {
                                                      return item;
                                                    }
                                                    var init = item.serverInstance;
                                                    return {
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            serverInstance: {
                                                              availability: init.availability,
                                                              cpu: init.cpu,
                                                              database: init.database,
                                                              description: init.description,
                                                              hypervisor: status,
                                                              image: init.image,
                                                              operatingSystem: init.operatingSystem,
                                                              ram: init.ram,
                                                              region: init.region,
                                                              type_: init.type_,
                                                              visibility: init.visibility,
                                                              vmNumber: init.vmNumber
                                                            },
                                                            billingTypes: item.billingTypes,
                                                            certifications: item.certifications,
                                                            features: item.features,
                                                            additionalRequirements: item.additionalRequirements,
                                                            volumes: item.volumes
                                                          };
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationServerInstanceImageField" :
                  var index$7 = action._1;
                  var nextInput$7 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$7, index$7, state.fieldsStatuses.configurations[index$7].serverInstance.image, state.submissionStatus, validators.configurations.fields.serverInstance.image, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$7) {
                                                      return item;
                                                    }
                                                    var init = item.serverInstance;
                                                    return {
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            serverInstance: {
                                                              availability: init.availability,
                                                              cpu: init.cpu,
                                                              database: init.database,
                                                              description: init.description,
                                                              hypervisor: init.hypervisor,
                                                              image: status,
                                                              operatingSystem: init.operatingSystem,
                                                              ram: init.ram,
                                                              region: init.region,
                                                              type_: init.type_,
                                                              visibility: init.visibility,
                                                              vmNumber: init.vmNumber
                                                            },
                                                            billingTypes: item.billingTypes,
                                                            certifications: item.certifications,
                                                            features: item.features,
                                                            additionalRequirements: item.additionalRequirements,
                                                            volumes: item.volumes
                                                          };
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationServerInstanceOperatingSystemField" :
                  var index$8 = action._1;
                  var nextInput$8 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$8, index$8, state.fieldsStatuses.configurations[index$8].serverInstance.operatingSystem, state.submissionStatus, validators.configurations.fields.serverInstance.operatingSystem, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$8) {
                                                      return item;
                                                    }
                                                    var init = item.serverInstance;
                                                    return {
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            serverInstance: {
                                                              availability: init.availability,
                                                              cpu: init.cpu,
                                                              database: init.database,
                                                              description: init.description,
                                                              hypervisor: init.hypervisor,
                                                              image: init.image,
                                                              operatingSystem: status,
                                                              ram: init.ram,
                                                              region: init.region,
                                                              type_: init.type_,
                                                              visibility: init.visibility,
                                                              vmNumber: init.vmNumber
                                                            },
                                                            billingTypes: item.billingTypes,
                                                            certifications: item.certifications,
                                                            features: item.features,
                                                            additionalRequirements: item.additionalRequirements,
                                                            volumes: item.volumes
                                                          };
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationServerInstanceRamField" :
                  var index$9 = action._1;
                  var nextInput$9 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$9, index$9, state.fieldsStatuses.configurations[index$9].serverInstance.ram, state.submissionStatus, validators.configurations.fields.serverInstance.ram, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$9) {
                                                      return item;
                                                    }
                                                    var init = item.serverInstance;
                                                    return {
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            serverInstance: {
                                                              availability: init.availability,
                                                              cpu: init.cpu,
                                                              database: init.database,
                                                              description: init.description,
                                                              hypervisor: init.hypervisor,
                                                              image: init.image,
                                                              operatingSystem: init.operatingSystem,
                                                              ram: status,
                                                              region: init.region,
                                                              type_: init.type_,
                                                              visibility: init.visibility,
                                                              vmNumber: init.vmNumber
                                                            },
                                                            billingTypes: item.billingTypes,
                                                            certifications: item.certifications,
                                                            features: item.features,
                                                            additionalRequirements: item.additionalRequirements,
                                                            volumes: item.volumes
                                                          };
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationServerInstanceRegionField" :
                  var index$10 = action._1;
                  var nextInput$10 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$10, index$10, state.fieldsStatuses.configurations[index$10].serverInstance.region, state.submissionStatus, validators.configurations.fields.serverInstance.region, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$10) {
                                                      return item;
                                                    }
                                                    var init = item.serverInstance;
                                                    return {
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            serverInstance: {
                                                              availability: init.availability,
                                                              cpu: init.cpu,
                                                              database: init.database,
                                                              description: init.description,
                                                              hypervisor: init.hypervisor,
                                                              image: init.image,
                                                              operatingSystem: init.operatingSystem,
                                                              ram: init.ram,
                                                              region: status,
                                                              type_: init.type_,
                                                              visibility: init.visibility,
                                                              vmNumber: init.vmNumber
                                                            },
                                                            billingTypes: item.billingTypes,
                                                            certifications: item.certifications,
                                                            features: item.features,
                                                            additionalRequirements: item.additionalRequirements,
                                                            volumes: item.volumes
                                                          };
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationServerInstanceTypeField" :
                  var index$11 = action._1;
                  var nextInput$11 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$11, index$11, state.fieldsStatuses.configurations[index$11].serverInstance.type_, state.submissionStatus, validators.configurations.fields.serverInstance.type_, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$11) {
                                                      return item;
                                                    }
                                                    var init = item.serverInstance;
                                                    return {
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            serverInstance: {
                                                              availability: init.availability,
                                                              cpu: init.cpu,
                                                              database: init.database,
                                                              description: init.description,
                                                              hypervisor: init.hypervisor,
                                                              image: init.image,
                                                              operatingSystem: init.operatingSystem,
                                                              ram: init.ram,
                                                              region: init.region,
                                                              type_: status,
                                                              visibility: init.visibility,
                                                              vmNumber: init.vmNumber
                                                            },
                                                            billingTypes: item.billingTypes,
                                                            certifications: item.certifications,
                                                            features: item.features,
                                                            additionalRequirements: item.additionalRequirements,
                                                            volumes: item.volumes
                                                          };
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationServerInstanceVisibilityField" :
                  var index$12 = action._1;
                  var nextInput$12 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$12, index$12, state.fieldsStatuses.configurations[index$12].serverInstance.visibility, state.submissionStatus, validators.configurations.fields.serverInstance.visibility, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$12) {
                                                      return item;
                                                    }
                                                    var init = item.serverInstance;
                                                    return {
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            serverInstance: {
                                                              availability: init.availability,
                                                              cpu: init.cpu,
                                                              database: init.database,
                                                              description: init.description,
                                                              hypervisor: init.hypervisor,
                                                              image: init.image,
                                                              operatingSystem: init.operatingSystem,
                                                              ram: init.ram,
                                                              region: init.region,
                                                              type_: init.type_,
                                                              visibility: status,
                                                              vmNumber: init.vmNumber
                                                            },
                                                            billingTypes: item.billingTypes,
                                                            certifications: item.certifications,
                                                            features: item.features,
                                                            additionalRequirements: item.additionalRequirements,
                                                            volumes: item.volumes
                                                          };
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationServerInstanceVmNumberField" :
                  var index$13 = action._1;
                  var nextInput$13 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$13, index$13, state.fieldsStatuses.configurations[index$13].serverInstance.vmNumber, state.submissionStatus, validators.configurations.fields.serverInstance.vmNumber, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$13) {
                                                      return item;
                                                    }
                                                    var init = item.serverInstance;
                                                    return {
                                                            startDate: item.startDate,
                                                            contractLength: item.contractLength,
                                                            serverInstance: {
                                                              availability: init.availability,
                                                              cpu: init.cpu,
                                                              database: init.database,
                                                              description: init.description,
                                                              hypervisor: init.hypervisor,
                                                              image: init.image,
                                                              operatingSystem: init.operatingSystem,
                                                              ram: init.ram,
                                                              region: init.region,
                                                              type_: init.type_,
                                                              visibility: init.visibility,
                                                              vmNumber: status
                                                            },
                                                            billingTypes: item.billingTypes,
                                                            certifications: item.certifications,
                                                            features: item.features,
                                                            additionalRequirements: item.additionalRequirements,
                                                            volumes: item.volumes
                                                          };
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationVolumeDiskTypeField" :
                  var index$14 = action._1;
                  var volumeIndex = index$14[1];
                  var configurationIndex = index$14[0];
                  var nextInput$14 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$14, index$14, state.fieldsStatuses.configurations[configurationIndex].volumes[volumeIndex].diskType, state.submissionStatus, validators.configurations.fields.volumes.fields.diskType, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              serverInstance: item.serverInstance,
                                                              billingTypes: item.billingTypes,
                                                              certifications: item.certifications,
                                                              features: item.features,
                                                              additionalRequirements: item.additionalRequirements,
                                                              volumes: Belt_Array.mapWithIndex(item.volumes, (function (index$p, item) {
                                                                      if (index$p === volumeIndex) {
                                                                        return {
                                                                                diskType: status,
                                                                                diskSize: item.diskSize,
                                                                                maxIops: item.maxIops,
                                                                                throughput: item.throughput,
                                                                                throughputUnit: item.throughputUnit,
                                                                                description: item.description
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationVolumeDiskSizeField" :
                  var index$15 = action._1;
                  var volumeIndex$1 = index$15[1];
                  var configurationIndex$1 = index$15[0];
                  var nextInput$15 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$15, index$15, state.fieldsStatuses.configurations[configurationIndex$1].volumes[volumeIndex$1].diskSize, state.submissionStatus, validators.configurations.fields.volumes.fields.diskSize, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex$1) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              serverInstance: item.serverInstance,
                                                              billingTypes: item.billingTypes,
                                                              certifications: item.certifications,
                                                              features: item.features,
                                                              additionalRequirements: item.additionalRequirements,
                                                              volumes: Belt_Array.mapWithIndex(item.volumes, (function (index$p, item) {
                                                                      if (index$p === volumeIndex$1) {
                                                                        return {
                                                                                diskType: item.diskType,
                                                                                diskSize: status,
                                                                                maxIops: item.maxIops,
                                                                                throughput: item.throughput,
                                                                                throughputUnit: item.throughputUnit,
                                                                                description: item.description
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationVolumeMaxIopsField" :
                  var index$16 = action._1;
                  var volumeIndex$2 = index$16[1];
                  var configurationIndex$2 = index$16[0];
                  var nextInput$16 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$16,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$16, index$16, state.fieldsStatuses.configurations[configurationIndex$2].volumes[volumeIndex$2].maxIops, state.submissionStatus, validators.configurations.fields.volumes.fields.maxIops, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex$2) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              serverInstance: item.serverInstance,
                                                              billingTypes: item.billingTypes,
                                                              certifications: item.certifications,
                                                              features: item.features,
                                                              additionalRequirements: item.additionalRequirements,
                                                              volumes: Belt_Array.mapWithIndex(item.volumes, (function (index$p, item) {
                                                                      if (index$p === volumeIndex$2) {
                                                                        return {
                                                                                diskType: item.diskType,
                                                                                diskSize: item.diskSize,
                                                                                maxIops: status,
                                                                                throughput: item.throughput,
                                                                                throughputUnit: item.throughputUnit,
                                                                                description: item.description
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationVolumeThroughputField" :
                  var index$17 = action._1;
                  var volumeIndex$3 = index$17[1];
                  var configurationIndex$3 = index$17[0];
                  var nextInput$17 = action._0(state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses.contents.configurations, (function (configurationIndex$p, configuration) {
                          Belt_Array.forEachWithIndex(configuration.volumes, (function (volumeIndex$p, volume) {
                                  var result = validateDependentFieldOfCollection2OnChange(nextInput$17, [
                                        configurationIndex$p,
                                        volumeIndex$p
                                      ], volume.throughputUnit, validators.configurations.fields.volumes.fields.throughputUnit, (function (status) {
                                          return {
                                                  configurations: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.configurations, (function (configurationIndex_, configuration) {
                                                          if (configurationIndex_ === configurationIndex$p) {
                                                            return {
                                                                    startDate: configuration.startDate,
                                                                    contractLength: configuration.contractLength,
                                                                    serverInstance: configuration.serverInstance,
                                                                    billingTypes: configuration.billingTypes,
                                                                    certifications: configuration.certifications,
                                                                    features: configuration.features,
                                                                    additionalRequirements: configuration.additionalRequirements,
                                                                    volumes: Belt_Array.mapWithIndex(configuration.volumes, (function (volumeIndex_, volume) {
                                                                            if (volumeIndex_ === volumeIndex$p) {
                                                                              return {
                                                                                      diskType: volume.diskType,
                                                                                      diskSize: volume.diskSize,
                                                                                      maxIops: volume.maxIops,
                                                                                      throughput: volume.throughput,
                                                                                      throughputUnit: status,
                                                                                      description: volume.description
                                                                                    };
                                                                            } else {
                                                                              return volume;
                                                                            }
                                                                          }))
                                                                  };
                                                          } else {
                                                            return configuration;
                                                          }
                                                        }))
                                                };
                                        }));
                                  if (result !== undefined) {
                                    nextFieldsStatuses.contents = result;
                                    return ;
                                  }
                                  
                                }));
                        }));
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$17,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$17, index$17, nextFieldsStatuses.contents.configurations[configurationIndex$3].volumes[volumeIndex$3].throughput, state.submissionStatus, validators.configurations.fields.volumes.fields.throughput, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex$3) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              serverInstance: item.serverInstance,
                                                              billingTypes: item.billingTypes,
                                                              certifications: item.certifications,
                                                              features: item.features,
                                                              additionalRequirements: item.additionalRequirements,
                                                              volumes: Belt_Array.mapWithIndex(item.volumes, (function (index$p, item) {
                                                                      if (index$p === volumeIndex$3) {
                                                                        return {
                                                                                diskType: item.diskType,
                                                                                diskSize: item.diskSize,
                                                                                maxIops: item.maxIops,
                                                                                throughput: status,
                                                                                throughputUnit: item.throughputUnit,
                                                                                description: item.description
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationVolumeThroughputUnitField" :
                  var index$18 = action._1;
                  var volumeIndex$4 = index$18[1];
                  var configurationIndex$4 = index$18[0];
                  var nextInput$18 = action._0(state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$1.contents.configurations, (function (configurationIndex$p, configuration) {
                          Belt_Array.forEachWithIndex(configuration.volumes, (function (volumeIndex$p, volume) {
                                  var result = validateDependentFieldOfCollection2OnChange(nextInput$18, [
                                        configurationIndex$p,
                                        volumeIndex$p
                                      ], volume.throughput, validators.configurations.fields.volumes.fields.throughput, (function (status) {
                                          return {
                                                  configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$1.contents.configurations, (function (configurationIndex_, configuration) {
                                                          if (configurationIndex_ === configurationIndex$p) {
                                                            return {
                                                                    startDate: configuration.startDate,
                                                                    contractLength: configuration.contractLength,
                                                                    serverInstance: configuration.serverInstance,
                                                                    billingTypes: configuration.billingTypes,
                                                                    certifications: configuration.certifications,
                                                                    features: configuration.features,
                                                                    additionalRequirements: configuration.additionalRequirements,
                                                                    volumes: Belt_Array.mapWithIndex(configuration.volumes, (function (volumeIndex_, volume) {
                                                                            if (volumeIndex_ === volumeIndex$p) {
                                                                              return {
                                                                                      diskType: volume.diskType,
                                                                                      diskSize: volume.diskSize,
                                                                                      maxIops: volume.maxIops,
                                                                                      throughput: status,
                                                                                      throughputUnit: volume.throughputUnit,
                                                                                      description: volume.description
                                                                                    };
                                                                            } else {
                                                                              return volume;
                                                                            }
                                                                          }))
                                                                  };
                                                          } else {
                                                            return configuration;
                                                          }
                                                        }))
                                                };
                                        }));
                                  if (result !== undefined) {
                                    nextFieldsStatuses$1.contents = result;
                                    return ;
                                  }
                                  
                                }));
                        }));
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$18,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$18, index$18, nextFieldsStatuses$1.contents.configurations[configurationIndex$4].volumes[volumeIndex$4].throughputUnit, state.submissionStatus, validators.configurations.fields.volumes.fields.throughputUnit, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(nextFieldsStatuses$1.contents.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex$4) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              serverInstance: item.serverInstance,
                                                              billingTypes: item.billingTypes,
                                                              certifications: item.certifications,
                                                              features: item.features,
                                                              additionalRequirements: item.additionalRequirements,
                                                              volumes: Belt_Array.mapWithIndex(item.volumes, (function (index$p, item) {
                                                                      if (index$p === volumeIndex$4) {
                                                                        return {
                                                                                diskType: item.diskType,
                                                                                diskSize: item.diskSize,
                                                                                maxIops: item.maxIops,
                                                                                throughput: item.throughput,
                                                                                throughputUnit: status,
                                                                                description: item.description
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationVolumeDescriptionField" :
                  var index$19 = action._1;
                  var volumeIndex$5 = index$19[1];
                  var configurationIndex$5 = index$19[0];
                  var nextInput$19 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$19,
                            fieldsStatuses: validateFieldOfCollection2OnChangeWithValidator(nextInput$19, index$19, state.fieldsStatuses.configurations[configurationIndex$5].volumes[volumeIndex$5].description, state.submissionStatus, validators.configurations.fields.volumes.fields.description, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === configurationIndex$5) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              serverInstance: item.serverInstance,
                                                              billingTypes: item.billingTypes,
                                                              certifications: item.certifications,
                                                              features: item.features,
                                                              additionalRequirements: item.additionalRequirements,
                                                              volumes: Belt_Array.mapWithIndex(item.volumes, (function (index$p, item) {
                                                                      if (index$p === volumeIndex$5) {
                                                                        return {
                                                                                diskType: item.diskType,
                                                                                diskSize: item.diskSize,
                                                                                maxIops: item.maxIops,
                                                                                throughput: item.throughput,
                                                                                throughputUnit: item.throughputUnit,
                                                                                description: status
                                                                              };
                                                                      } else {
                                                                        return item;
                                                                      }
                                                                    }))
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationBillingTypesField" :
                  var index$20 = action._1;
                  var nextInput$20 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$20,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$20, index$20, state.fieldsStatuses.configurations[index$20].billingTypes, state.submissionStatus, validators.configurations.fields.billingTypes, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$20) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              serverInstance: item.serverInstance,
                                                              billingTypes: status,
                                                              certifications: item.certifications,
                                                              features: item.features,
                                                              additionalRequirements: item.additionalRequirements,
                                                              volumes: item.volumes
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationCertificationsField" :
                  var index$21 = action._1;
                  var nextInput$21 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$21,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$21, index$21, state.fieldsStatuses.configurations[index$21].certifications, state.submissionStatus, validators.configurations.fields.certifications, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$21) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              serverInstance: item.serverInstance,
                                                              billingTypes: item.billingTypes,
                                                              certifications: status,
                                                              features: item.features,
                                                              additionalRequirements: item.additionalRequirements,
                                                              volumes: item.volumes
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationFeaturesField" :
                  var index$22 = action._1;
                  var nextInput$22 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$22,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$22, index$22, state.fieldsStatuses.configurations[index$22].features, state.submissionStatus, validators.configurations.fields.features, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$22) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              serverInstance: item.serverInstance,
                                                              billingTypes: item.billingTypes,
                                                              certifications: item.certifications,
                                                              features: status,
                                                              additionalRequirements: item.additionalRequirements,
                                                              volumes: item.volumes
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationAdditionalRequirementsField" :
                  var index$23 = action._1;
                  var nextInput$23 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$23,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$23, index$23, state.fieldsStatuses.configurations[index$23].additionalRequirements, state.submissionStatus, validators.configurations.fields.additionalRequirements, (function (status) {
                                    return {
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p === index$23) {
                                                      return {
                                                              startDate: item.startDate,
                                                              contractLength: item.contractLength,
                                                              serverInstance: item.serverInstance,
                                                              billingTypes: item.billingTypes,
                                                              certifications: item.certifications,
                                                              features: item.features,
                                                              additionalRequirements: status,
                                                              volumes: item.volumes
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "BlurConfigurationStartDateField" :
                  var index$24 = action._0;
                  var result = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$24, state.fieldsStatuses.configurations[index$24].startDate, validators.configurations.fields.startDate, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$24) {
                                            return {
                                                    startDate: status,
                                                    contractLength: item.contractLength,
                                                    serverInstance: item.serverInstance,
                                                    billingTypes: item.billingTypes,
                                                    certifications: item.certifications,
                                                    features: item.features,
                                                    additionalRequirements: item.additionalRequirements,
                                                    volumes: item.volumes
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationContractLengthField" :
                  var index$25 = action._0;
                  var result$1 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$25, state.fieldsStatuses.configurations[index$25].contractLength, validators.configurations.fields.contractLength, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$25) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: status,
                                                    serverInstance: item.serverInstance,
                                                    billingTypes: item.billingTypes,
                                                    certifications: item.certifications,
                                                    features: item.features,
                                                    additionalRequirements: item.additionalRequirements,
                                                    volumes: item.volumes
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationServerInstanceAvailabilityField" :
                  var index$26 = action._0;
                  var result$2 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$26, state.fieldsStatuses.configurations[index$26].serverInstance.availability, validators.configurations.fields.serverInstance.availability, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$26) {
                                            return item;
                                          }
                                          var init = item.serverInstance;
                                          return {
                                                  startDate: item.startDate,
                                                  contractLength: item.contractLength,
                                                  serverInstance: {
                                                    availability: status,
                                                    cpu: init.cpu,
                                                    database: init.database,
                                                    description: init.description,
                                                    hypervisor: init.hypervisor,
                                                    image: init.image,
                                                    operatingSystem: init.operatingSystem,
                                                    ram: init.ram,
                                                    region: init.region,
                                                    type_: init.type_,
                                                    visibility: init.visibility,
                                                    vmNumber: init.vmNumber
                                                  },
                                                  billingTypes: item.billingTypes,
                                                  certifications: item.certifications,
                                                  features: item.features,
                                                  additionalRequirements: item.additionalRequirements,
                                                  volumes: item.volumes
                                                };
                                        }))
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationServerInstanceCpuField" :
                  var index$27 = action._0;
                  var result$3 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$27, state.fieldsStatuses.configurations[index$27].serverInstance.cpu, validators.configurations.fields.serverInstance.cpu, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$27) {
                                            return item;
                                          }
                                          var init = item.serverInstance;
                                          return {
                                                  startDate: item.startDate,
                                                  contractLength: item.contractLength,
                                                  serverInstance: {
                                                    availability: init.availability,
                                                    cpu: status,
                                                    database: init.database,
                                                    description: init.description,
                                                    hypervisor: init.hypervisor,
                                                    image: init.image,
                                                    operatingSystem: init.operatingSystem,
                                                    ram: init.ram,
                                                    region: init.region,
                                                    type_: init.type_,
                                                    visibility: init.visibility,
                                                    vmNumber: init.vmNumber
                                                  },
                                                  billingTypes: item.billingTypes,
                                                  certifications: item.certifications,
                                                  features: item.features,
                                                  additionalRequirements: item.additionalRequirements,
                                                  volumes: item.volumes
                                                };
                                        }))
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationServerInstanceDatabaseField" :
                  var index$28 = action._0;
                  var result$4 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$28, state.fieldsStatuses.configurations[index$28].serverInstance.database, validators.configurations.fields.serverInstance.database, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$28) {
                                            return item;
                                          }
                                          var init = item.serverInstance;
                                          return {
                                                  startDate: item.startDate,
                                                  contractLength: item.contractLength,
                                                  serverInstance: {
                                                    availability: init.availability,
                                                    cpu: init.cpu,
                                                    database: status,
                                                    description: init.description,
                                                    hypervisor: init.hypervisor,
                                                    image: init.image,
                                                    operatingSystem: init.operatingSystem,
                                                    ram: init.ram,
                                                    region: init.region,
                                                    type_: init.type_,
                                                    visibility: init.visibility,
                                                    vmNumber: init.vmNumber
                                                  },
                                                  billingTypes: item.billingTypes,
                                                  certifications: item.certifications,
                                                  features: item.features,
                                                  additionalRequirements: item.additionalRequirements,
                                                  volumes: item.volumes
                                                };
                                        }))
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationServerInstanceDescriptionField" :
                  var index$29 = action._0;
                  var result$5 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$29, state.fieldsStatuses.configurations[index$29].serverInstance.description, validators.configurations.fields.serverInstance.description, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$29) {
                                            return item;
                                          }
                                          var init = item.serverInstance;
                                          return {
                                                  startDate: item.startDate,
                                                  contractLength: item.contractLength,
                                                  serverInstance: {
                                                    availability: init.availability,
                                                    cpu: init.cpu,
                                                    database: init.database,
                                                    description: status,
                                                    hypervisor: init.hypervisor,
                                                    image: init.image,
                                                    operatingSystem: init.operatingSystem,
                                                    ram: init.ram,
                                                    region: init.region,
                                                    type_: init.type_,
                                                    visibility: init.visibility,
                                                    vmNumber: init.vmNumber
                                                  },
                                                  billingTypes: item.billingTypes,
                                                  certifications: item.certifications,
                                                  features: item.features,
                                                  additionalRequirements: item.additionalRequirements,
                                                  volumes: item.volumes
                                                };
                                        }))
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationServerInstanceHypervisorField" :
                  var index$30 = action._0;
                  var result$6 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$30, state.fieldsStatuses.configurations[index$30].serverInstance.hypervisor, validators.configurations.fields.serverInstance.hypervisor, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$30) {
                                            return item;
                                          }
                                          var init = item.serverInstance;
                                          return {
                                                  startDate: item.startDate,
                                                  contractLength: item.contractLength,
                                                  serverInstance: {
                                                    availability: init.availability,
                                                    cpu: init.cpu,
                                                    database: init.database,
                                                    description: init.description,
                                                    hypervisor: status,
                                                    image: init.image,
                                                    operatingSystem: init.operatingSystem,
                                                    ram: init.ram,
                                                    region: init.region,
                                                    type_: init.type_,
                                                    visibility: init.visibility,
                                                    vmNumber: init.vmNumber
                                                  },
                                                  billingTypes: item.billingTypes,
                                                  certifications: item.certifications,
                                                  features: item.features,
                                                  additionalRequirements: item.additionalRequirements,
                                                  volumes: item.volumes
                                                };
                                        }))
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationServerInstanceImageField" :
                  var index$31 = action._0;
                  var result$7 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$31, state.fieldsStatuses.configurations[index$31].serverInstance.image, validators.configurations.fields.serverInstance.image, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$31) {
                                            return item;
                                          }
                                          var init = item.serverInstance;
                                          return {
                                                  startDate: item.startDate,
                                                  contractLength: item.contractLength,
                                                  serverInstance: {
                                                    availability: init.availability,
                                                    cpu: init.cpu,
                                                    database: init.database,
                                                    description: init.description,
                                                    hypervisor: init.hypervisor,
                                                    image: status,
                                                    operatingSystem: init.operatingSystem,
                                                    ram: init.ram,
                                                    region: init.region,
                                                    type_: init.type_,
                                                    visibility: init.visibility,
                                                    vmNumber: init.vmNumber
                                                  },
                                                  billingTypes: item.billingTypes,
                                                  certifications: item.certifications,
                                                  features: item.features,
                                                  additionalRequirements: item.additionalRequirements,
                                                  volumes: item.volumes
                                                };
                                        }))
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationServerInstanceOperatingSystemField" :
                  var index$32 = action._0;
                  var result$8 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$32, state.fieldsStatuses.configurations[index$32].serverInstance.operatingSystem, validators.configurations.fields.serverInstance.operatingSystem, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$32) {
                                            return item;
                                          }
                                          var init = item.serverInstance;
                                          return {
                                                  startDate: item.startDate,
                                                  contractLength: item.contractLength,
                                                  serverInstance: {
                                                    availability: init.availability,
                                                    cpu: init.cpu,
                                                    database: init.database,
                                                    description: init.description,
                                                    hypervisor: init.hypervisor,
                                                    image: init.image,
                                                    operatingSystem: status,
                                                    ram: init.ram,
                                                    region: init.region,
                                                    type_: init.type_,
                                                    visibility: init.visibility,
                                                    vmNumber: init.vmNumber
                                                  },
                                                  billingTypes: item.billingTypes,
                                                  certifications: item.certifications,
                                                  features: item.features,
                                                  additionalRequirements: item.additionalRequirements,
                                                  volumes: item.volumes
                                                };
                                        }))
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationServerInstanceRamField" :
                  var index$33 = action._0;
                  var result$9 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$33, state.fieldsStatuses.configurations[index$33].serverInstance.ram, validators.configurations.fields.serverInstance.ram, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$33) {
                                            return item;
                                          }
                                          var init = item.serverInstance;
                                          return {
                                                  startDate: item.startDate,
                                                  contractLength: item.contractLength,
                                                  serverInstance: {
                                                    availability: init.availability,
                                                    cpu: init.cpu,
                                                    database: init.database,
                                                    description: init.description,
                                                    hypervisor: init.hypervisor,
                                                    image: init.image,
                                                    operatingSystem: init.operatingSystem,
                                                    ram: status,
                                                    region: init.region,
                                                    type_: init.type_,
                                                    visibility: init.visibility,
                                                    vmNumber: init.vmNumber
                                                  },
                                                  billingTypes: item.billingTypes,
                                                  certifications: item.certifications,
                                                  features: item.features,
                                                  additionalRequirements: item.additionalRequirements,
                                                  volumes: item.volumes
                                                };
                                        }))
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationServerInstanceRegionField" :
                  var index$34 = action._0;
                  var result$10 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$34, state.fieldsStatuses.configurations[index$34].serverInstance.region, validators.configurations.fields.serverInstance.region, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$34) {
                                            return item;
                                          }
                                          var init = item.serverInstance;
                                          return {
                                                  startDate: item.startDate,
                                                  contractLength: item.contractLength,
                                                  serverInstance: {
                                                    availability: init.availability,
                                                    cpu: init.cpu,
                                                    database: init.database,
                                                    description: init.description,
                                                    hypervisor: init.hypervisor,
                                                    image: init.image,
                                                    operatingSystem: init.operatingSystem,
                                                    ram: init.ram,
                                                    region: status,
                                                    type_: init.type_,
                                                    visibility: init.visibility,
                                                    vmNumber: init.vmNumber
                                                  },
                                                  billingTypes: item.billingTypes,
                                                  certifications: item.certifications,
                                                  features: item.features,
                                                  additionalRequirements: item.additionalRequirements,
                                                  volumes: item.volumes
                                                };
                                        }))
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationServerInstanceTypeField" :
                  var index$35 = action._0;
                  var result$11 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$35, state.fieldsStatuses.configurations[index$35].serverInstance.type_, validators.configurations.fields.serverInstance.type_, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$35) {
                                            return item;
                                          }
                                          var init = item.serverInstance;
                                          return {
                                                  startDate: item.startDate,
                                                  contractLength: item.contractLength,
                                                  serverInstance: {
                                                    availability: init.availability,
                                                    cpu: init.cpu,
                                                    database: init.database,
                                                    description: init.description,
                                                    hypervisor: init.hypervisor,
                                                    image: init.image,
                                                    operatingSystem: init.operatingSystem,
                                                    ram: init.ram,
                                                    region: init.region,
                                                    type_: status,
                                                    visibility: init.visibility,
                                                    vmNumber: init.vmNumber
                                                  },
                                                  billingTypes: item.billingTypes,
                                                  certifications: item.certifications,
                                                  features: item.features,
                                                  additionalRequirements: item.additionalRequirements,
                                                  volumes: item.volumes
                                                };
                                        }))
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationServerInstanceVisibilityField" :
                  var index$36 = action._0;
                  var result$12 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$36, state.fieldsStatuses.configurations[index$36].serverInstance.visibility, validators.configurations.fields.serverInstance.visibility, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$36) {
                                            return item;
                                          }
                                          var init = item.serverInstance;
                                          return {
                                                  startDate: item.startDate,
                                                  contractLength: item.contractLength,
                                                  serverInstance: {
                                                    availability: init.availability,
                                                    cpu: init.cpu,
                                                    database: init.database,
                                                    description: init.description,
                                                    hypervisor: init.hypervisor,
                                                    image: init.image,
                                                    operatingSystem: init.operatingSystem,
                                                    ram: init.ram,
                                                    region: init.region,
                                                    type_: init.type_,
                                                    visibility: status,
                                                    vmNumber: init.vmNumber
                                                  },
                                                  billingTypes: item.billingTypes,
                                                  certifications: item.certifications,
                                                  features: item.features,
                                                  additionalRequirements: item.additionalRequirements,
                                                  volumes: item.volumes
                                                };
                                        }))
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationServerInstanceVmNumberField" :
                  var index$37 = action._0;
                  var result$13 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$37, state.fieldsStatuses.configurations[index$37].serverInstance.vmNumber, validators.configurations.fields.serverInstance.vmNumber, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$37) {
                                            return item;
                                          }
                                          var init = item.serverInstance;
                                          return {
                                                  startDate: item.startDate,
                                                  contractLength: item.contractLength,
                                                  serverInstance: {
                                                    availability: init.availability,
                                                    cpu: init.cpu,
                                                    database: init.database,
                                                    description: init.description,
                                                    hypervisor: init.hypervisor,
                                                    image: init.image,
                                                    operatingSystem: init.operatingSystem,
                                                    ram: init.ram,
                                                    region: init.region,
                                                    type_: init.type_,
                                                    visibility: init.visibility,
                                                    vmNumber: status
                                                  },
                                                  billingTypes: item.billingTypes,
                                                  certifications: item.certifications,
                                                  features: item.features,
                                                  additionalRequirements: item.additionalRequirements,
                                                  volumes: item.volumes
                                                };
                                        }))
                                };
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationVolumeDiskTypeField" :
                  var index$38 = action._0;
                  var volumeIndex$6 = index$38[1];
                  var configurationIndex$6 = index$38[0];
                  var result$14 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$38, state.fieldsStatuses.configurations[configurationIndex$6].volumes[volumeIndex$6].diskType, validators.configurations.fields.volumes.fields.diskType, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$6) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    serverInstance: item.serverInstance,
                                                    billingTypes: item.billingTypes,
                                                    certifications: item.certifications,
                                                    features: item.features,
                                                    additionalRequirements: item.additionalRequirements,
                                                    volumes: Belt_Array.mapWithIndex(item.volumes, (function (index$p, item) {
                                                            if (index$p === volumeIndex$6) {
                                                              return {
                                                                      diskType: status,
                                                                      diskSize: item.diskSize,
                                                                      maxIops: item.maxIops,
                                                                      throughput: item.throughput,
                                                                      throughputUnit: item.throughputUnit,
                                                                      description: item.description
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationVolumeDiskSizeField" :
                  var index$39 = action._0;
                  var volumeIndex$7 = index$39[1];
                  var configurationIndex$7 = index$39[0];
                  var result$15 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$39, state.fieldsStatuses.configurations[configurationIndex$7].volumes[volumeIndex$7].diskSize, validators.configurations.fields.volumes.fields.diskSize, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$7) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    serverInstance: item.serverInstance,
                                                    billingTypes: item.billingTypes,
                                                    certifications: item.certifications,
                                                    features: item.features,
                                                    additionalRequirements: item.additionalRequirements,
                                                    volumes: Belt_Array.mapWithIndex(item.volumes, (function (index$p, item) {
                                                            if (index$p === volumeIndex$7) {
                                                              return {
                                                                      diskType: item.diskType,
                                                                      diskSize: status,
                                                                      maxIops: item.maxIops,
                                                                      throughput: item.throughput,
                                                                      throughputUnit: item.throughputUnit,
                                                                      description: item.description
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$15 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationVolumeMaxIopsField" :
                  var index$40 = action._0;
                  var volumeIndex$8 = index$40[1];
                  var configurationIndex$8 = index$40[0];
                  var result$16 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$40, state.fieldsStatuses.configurations[configurationIndex$8].volumes[volumeIndex$8].maxIops, validators.configurations.fields.volumes.fields.maxIops, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$8) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    serverInstance: item.serverInstance,
                                                    billingTypes: item.billingTypes,
                                                    certifications: item.certifications,
                                                    features: item.features,
                                                    additionalRequirements: item.additionalRequirements,
                                                    volumes: Belt_Array.mapWithIndex(item.volumes, (function (index$p, item) {
                                                            if (index$p === volumeIndex$8) {
                                                              return {
                                                                      diskType: item.diskType,
                                                                      diskSize: item.diskSize,
                                                                      maxIops: status,
                                                                      throughput: item.throughput,
                                                                      throughputUnit: item.throughputUnit,
                                                                      description: item.description
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$16 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$16,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationVolumeThroughputField" :
                  var index$41 = action._0;
                  var volumeIndex$9 = index$41[1];
                  var configurationIndex$9 = index$41[0];
                  var result$17 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$41, state.fieldsStatuses.configurations[configurationIndex$9].volumes[volumeIndex$9].throughput, validators.configurations.fields.volumes.fields.throughput, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$9) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    serverInstance: item.serverInstance,
                                                    billingTypes: item.billingTypes,
                                                    certifications: item.certifications,
                                                    features: item.features,
                                                    additionalRequirements: item.additionalRequirements,
                                                    volumes: Belt_Array.mapWithIndex(item.volumes, (function (index$p, item) {
                                                            if (index$p === volumeIndex$9) {
                                                              return {
                                                                      diskType: item.diskType,
                                                                      diskSize: item.diskSize,
                                                                      maxIops: item.maxIops,
                                                                      throughput: status,
                                                                      throughputUnit: item.throughputUnit,
                                                                      description: item.description
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$17 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$17,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationVolumeThroughputUnitField" :
                  var index$42 = action._0;
                  var volumeIndex$10 = index$42[1];
                  var configurationIndex$10 = index$42[0];
                  var result$18 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$42, state.fieldsStatuses.configurations[configurationIndex$10].volumes[volumeIndex$10].throughputUnit, validators.configurations.fields.volumes.fields.throughputUnit, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$10) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    serverInstance: item.serverInstance,
                                                    billingTypes: item.billingTypes,
                                                    certifications: item.certifications,
                                                    features: item.features,
                                                    additionalRequirements: item.additionalRequirements,
                                                    volumes: Belt_Array.mapWithIndex(item.volumes, (function (index$p, item) {
                                                            if (index$p === volumeIndex$10) {
                                                              return {
                                                                      diskType: item.diskType,
                                                                      diskSize: item.diskSize,
                                                                      maxIops: item.maxIops,
                                                                      throughput: item.throughput,
                                                                      throughputUnit: status,
                                                                      description: item.description
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$18 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$18,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationVolumeDescriptionField" :
                  var index$43 = action._0;
                  var volumeIndex$11 = index$43[1];
                  var configurationIndex$11 = index$43[0];
                  var result$19 = validateFieldOfCollection2OnBlurWithValidator(state.input, index$43, state.fieldsStatuses.configurations[configurationIndex$11].volumes[volumeIndex$11].description, validators.configurations.fields.volumes.fields.description, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === configurationIndex$11) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    serverInstance: item.serverInstance,
                                                    billingTypes: item.billingTypes,
                                                    certifications: item.certifications,
                                                    features: item.features,
                                                    additionalRequirements: item.additionalRequirements,
                                                    volumes: Belt_Array.mapWithIndex(item.volumes, (function (index$p, item) {
                                                            if (index$p === volumeIndex$11) {
                                                              return {
                                                                      diskType: item.diskType,
                                                                      diskSize: item.diskSize,
                                                                      maxIops: item.maxIops,
                                                                      throughput: item.throughput,
                                                                      throughputUnit: item.throughputUnit,
                                                                      description: status
                                                                    };
                                                            } else {
                                                              return item;
                                                            }
                                                          }))
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$19 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$19,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationBillingTypesField" :
                  var index$44 = action._0;
                  var result$20 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$44, state.fieldsStatuses.configurations[index$44].billingTypes, validators.configurations.fields.billingTypes, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$44) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    serverInstance: item.serverInstance,
                                                    billingTypes: status,
                                                    certifications: item.certifications,
                                                    features: item.features,
                                                    additionalRequirements: item.additionalRequirements,
                                                    volumes: item.volumes
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$20 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$20,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationCertificationsField" :
                  var index$45 = action._0;
                  var result$21 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$45, state.fieldsStatuses.configurations[index$45].certifications, validators.configurations.fields.certifications, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$45) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    serverInstance: item.serverInstance,
                                                    billingTypes: item.billingTypes,
                                                    certifications: status,
                                                    features: item.features,
                                                    additionalRequirements: item.additionalRequirements,
                                                    volumes: item.volumes
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$21 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$21,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationFeaturesField" :
                  var index$46 = action._0;
                  var result$22 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$46, state.fieldsStatuses.configurations[index$46].features, validators.configurations.fields.features, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$46) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    serverInstance: item.serverInstance,
                                                    billingTypes: item.billingTypes,
                                                    certifications: item.certifications,
                                                    features: status,
                                                    additionalRequirements: item.additionalRequirements,
                                                    volumes: item.volumes
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$22 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$22,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationAdditionalRequirementsField" :
                  var index$47 = action._0;
                  var result$23 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$47, state.fieldsStatuses.configurations[index$47].additionalRequirements, validators.configurations.fields.additionalRequirements, (function (status) {
                          return {
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p === index$47) {
                                            return {
                                                    startDate: item.startDate,
                                                    contractLength: item.contractLength,
                                                    serverInstance: item.serverInstance,
                                                    billingTypes: item.billingTypes,
                                                    certifications: item.certifications,
                                                    features: item.features,
                                                    additionalRequirements: status,
                                                    volumes: item.volumes
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$23 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$23,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "AddConfigurationEntry" :
                  var entry = action._0;
                  var nextInput$24 = {
                    configurations: Belt_Array.concat(state.input.configurations, [entry])
                  };
                  var nextFieldsStatuses$2 = {
                    configurations: Belt_Array.concat(state.fieldsStatuses.configurations, [{
                            startDate: "Pristine",
                            contractLength: "Pristine",
                            serverInstance: {
                              availability: "Pristine",
                              cpu: "Pristine",
                              database: "Pristine",
                              description: "Pristine",
                              hypervisor: "Pristine",
                              image: "Pristine",
                              operatingSystem: "Pristine",
                              ram: "Pristine",
                              region: "Pristine",
                              type_: "Pristine",
                              visibility: "Pristine",
                              vmNumber: "Pristine"
                            },
                            billingTypes: "Pristine",
                            certifications: "Pristine",
                            features: "Pristine",
                            additionalRequirements: "Pristine",
                            volumes: Belt_Array.map(entry.volumes, (function (param) {
                                    return {
                                            diskType: "Pristine",
                                            diskSize: "Pristine",
                                            maxIops: "Pristine",
                                            throughput: "Pristine",
                                            throughputUnit: "Pristine",
                                            description: "Pristine"
                                          };
                                  }))
                          }])
                  };
                  var nextCollectionsStatuses = {
                    configurations: {
                      self: validators.configurations.collection(nextInput$24),
                      volumes: Belt_Array.concat(state.collectionsStatuses.configurations.volumes, [validators.configurations.fields.volumes.collection(nextInput$24, state.input.configurations.length)])
                    }
                  };
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$24,
                            fieldsStatuses: nextFieldsStatuses$2,
                            collectionsStatuses: nextCollectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "RemoveConfigurationEntry" :
                  var index$48 = action._0;
                  var nextInput$25 = {
                    configurations: Belt_Array.keepWithIndex(state.input.configurations, (function (param, i) {
                            return i !== index$48;
                          }))
                  };
                  var nextFieldsStatuses$3 = {
                    configurations: Belt_Array.keepWithIndex(state.fieldsStatuses.configurations, (function (param, i) {
                            return i !== index$48;
                          }))
                  };
                  var nextCollectionsStatuses$1 = {
                    configurations: {
                      self: validators.configurations.collection(nextInput$25),
                      volumes: Belt_Array.keepWithIndex(state.collectionsStatuses.configurations.volumes, (function (param, i) {
                              return i !== (state.collectionsStatuses.configurations.volumes.length - 1 | 0);
                            }))
                    }
                  };
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$25,
                            fieldsStatuses: nextFieldsStatuses$3,
                            collectionsStatuses: nextCollectionsStatuses$1,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "AddConfigurationVolumeEntry" :
                  var at = action._1;
                  var entry$1 = action._0;
                  var nextInput$26 = {
                    configurations: Belt_Array.mapWithIndex(state.input.configurations, (function (index, configuration) {
                            if (index === at) {
                              return {
                                      key: configuration.key,
                                      startDate: configuration.startDate,
                                      contractLength: configuration.contractLength,
                                      serverInstance: configuration.serverInstance,
                                      volumes: Belt_Array.concat(configuration.volumes, [entry$1]),
                                      billingTypes: configuration.billingTypes,
                                      certifications: configuration.certifications,
                                      features: configuration.features,
                                      additionalRequirements: configuration.additionalRequirements
                                    };
                            } else {
                              return configuration;
                            }
                          }))
                  };
                  var nextFieldsStatuses$4 = {
                    configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index, configuration) {
                            if (index === at) {
                              return {
                                      startDate: configuration.startDate,
                                      contractLength: configuration.contractLength,
                                      serverInstance: configuration.serverInstance,
                                      billingTypes: configuration.billingTypes,
                                      certifications: configuration.certifications,
                                      features: configuration.features,
                                      additionalRequirements: configuration.additionalRequirements,
                                      volumes: Belt_Array.concat(configuration.volumes, [{
                                              diskType: "Pristine",
                                              diskSize: "Pristine",
                                              maxIops: "Pristine",
                                              throughput: "Pristine",
                                              throughputUnit: "Pristine",
                                              description: "Pristine"
                                            }])
                                    };
                            } else {
                              return configuration;
                            }
                          }))
                  };
                  var nextCollectionsStatuses$2 = {
                    configurations: {
                      self: validators.configurations.collection(nextInput$26),
                      volumes: Belt_Array.concat(state.collectionsStatuses.configurations.volumes, [validators.configurations.fields.volumes.collection(nextInput$26, at)])
                    }
                  };
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$26,
                            fieldsStatuses: nextFieldsStatuses$4,
                            collectionsStatuses: nextCollectionsStatuses$2,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "RemoveConfigurationVolumeEntry" :
                  var match$4 = action._0;
                  var volumeIndex$12 = match$4[1];
                  var configurationIndex$12 = match$4[0];
                  var nextInput$27 = {
                    configurations: Belt_Array.mapWithIndex(state.input.configurations, (function (index, configuration) {
                            if (index === configurationIndex$12) {
                              return {
                                      key: configuration.key,
                                      startDate: configuration.startDate,
                                      contractLength: configuration.contractLength,
                                      serverInstance: configuration.serverInstance,
                                      volumes: Belt_Array.keepWithIndex(configuration.volumes, (function (param, index) {
                                              return index !== volumeIndex$12;
                                            })),
                                      billingTypes: configuration.billingTypes,
                                      certifications: configuration.certifications,
                                      features: configuration.features,
                                      additionalRequirements: configuration.additionalRequirements
                                    };
                            } else {
                              return configuration;
                            }
                          }))
                  };
                  var nextFieldsStatuses$5 = {
                    configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index, configuration) {
                            if (index === configurationIndex$12) {
                              return {
                                      startDate: configuration.startDate,
                                      contractLength: configuration.contractLength,
                                      serverInstance: configuration.serverInstance,
                                      billingTypes: configuration.billingTypes,
                                      certifications: configuration.certifications,
                                      features: configuration.features,
                                      additionalRequirements: configuration.additionalRequirements,
                                      volumes: Belt_Array.keepWithIndex(configuration.volumes, (function (param, index) {
                                              return index !== volumeIndex$12;
                                            }))
                                    };
                            } else {
                              return configuration;
                            }
                          }))
                  };
                  var nextCollectionsStatuses$3 = {
                    configurations: {
                      self: validators.configurations.collection(nextInput$27),
                      volumes: Belt_Array.mapWithIndex(state.collectionsStatuses.configurations.volumes, (function (index, item) {
                              if (index === configurationIndex$12) {
                                return validators.configurations.fields.volumes.collection(nextInput$27, configurationIndex$12);
                              } else {
                                return item;
                              }
                            }))
                    }
                  };
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$27,
                            fieldsStatuses: nextFieldsStatuses$5,
                            collectionsStatuses: nextCollectionsStatuses$3,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateConfigurationStartDate: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationStartDateField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationContractLength: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationContractLengthField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationServerInstanceAvailability: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationServerInstanceAvailabilityField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationServerInstanceCpu: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationServerInstanceCpuField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationServerInstanceDatabase: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationServerInstanceDatabaseField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationServerInstanceDescription: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationServerInstanceDescriptionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationServerInstanceHypervisor: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationServerInstanceHypervisorField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationServerInstanceImage: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationServerInstanceImageField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationServerInstanceOperatingSystem: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationServerInstanceOperatingSystemField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationServerInstanceRam: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationServerInstanceRamField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationServerInstanceRegion: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationServerInstanceRegionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationServerInstanceType: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationServerInstanceTypeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationServerInstanceVisibility: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationServerInstanceVisibilityField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationServerInstanceVmNumber: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationServerInstanceVmNumberField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationVolumeDiskType: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationVolumeDiskTypeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationVolumeDiskSize: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationVolumeDiskSizeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationVolumeMaxIops: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationVolumeMaxIopsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationVolumeThroughput: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationVolumeThroughputField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationVolumeThroughputUnit: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationVolumeThroughputUnitField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationVolumeDescription: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationVolumeDescriptionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationBillingTypes: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationBillingTypesField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationCertifications: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationCertificationsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationFeatures: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationFeaturesField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationAdditionalRequirements: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationAdditionalRequirementsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          blurConfigurationStartDate: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationStartDateField",
                    _0: index
                  });
            }),
          blurConfigurationContractLength: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationContractLengthField",
                    _0: index
                  });
            }),
          blurConfigurationServerInstanceAvailability: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationServerInstanceAvailabilityField",
                    _0: index
                  });
            }),
          blurConfigurationServerInstanceCpu: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationServerInstanceCpuField",
                    _0: index
                  });
            }),
          blurConfigurationServerInstanceDatabase: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationServerInstanceDatabaseField",
                    _0: index
                  });
            }),
          blurConfigurationServerInstanceDescription: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationServerInstanceDescriptionField",
                    _0: index
                  });
            }),
          blurConfigurationServerInstanceHypervisor: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationServerInstanceHypervisorField",
                    _0: index
                  });
            }),
          blurConfigurationServerInstanceImage: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationServerInstanceImageField",
                    _0: index
                  });
            }),
          blurConfigurationServerInstanceOperatingSystem: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationServerInstanceOperatingSystemField",
                    _0: index
                  });
            }),
          blurConfigurationServerInstanceRam: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationServerInstanceRamField",
                    _0: index
                  });
            }),
          blurConfigurationServerInstanceRegion: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationServerInstanceRegionField",
                    _0: index
                  });
            }),
          blurConfigurationServerInstanceType: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationServerInstanceTypeField",
                    _0: index
                  });
            }),
          blurConfigurationServerInstanceVisibility: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationServerInstanceVisibilityField",
                    _0: index
                  });
            }),
          blurConfigurationServerInstanceVmNumber: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationServerInstanceVmNumberField",
                    _0: index
                  });
            }),
          blurConfigurationVolumeDiskType: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationVolumeDiskTypeField",
                    _0: index
                  });
            }),
          blurConfigurationVolumeDiskSize: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationVolumeDiskSizeField",
                    _0: index
                  });
            }),
          blurConfigurationVolumeMaxIops: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationVolumeMaxIopsField",
                    _0: index
                  });
            }),
          blurConfigurationVolumeThroughput: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationVolumeThroughputField",
                    _0: index
                  });
            }),
          blurConfigurationVolumeThroughputUnit: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationVolumeThroughputUnitField",
                    _0: index
                  });
            }),
          blurConfigurationVolumeDescription: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationVolumeDescriptionField",
                    _0: index
                  });
            }),
          blurConfigurationBillingTypes: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationBillingTypesField",
                    _0: index
                  });
            }),
          blurConfigurationCertifications: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationCertificationsField",
                    _0: index
                  });
            }),
          blurConfigurationFeatures: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationFeaturesField",
                    _0: index
                  });
            }),
          blurConfigurationAdditionalRequirements: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationAdditionalRequirementsField",
                    _0: index
                  });
            }),
          configurationStartDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].startDate);
            }),
          configurationContractLengthResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].contractLength);
            }),
          configurationServerInstanceAvailabilityResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].serverInstance.availability);
            }),
          configurationServerInstanceCpuResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].serverInstance.cpu);
            }),
          configurationServerInstanceDatabaseResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].serverInstance.database);
            }),
          configurationServerInstanceDescriptionResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].serverInstance.description);
            }),
          configurationServerInstanceHypervisorResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].serverInstance.hypervisor);
            }),
          configurationServerInstanceImageResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].serverInstance.image);
            }),
          configurationServerInstanceOperatingSystemResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].serverInstance.operatingSystem);
            }),
          configurationServerInstanceRamResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].serverInstance.ram);
            }),
          configurationServerInstanceRegionResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].serverInstance.region);
            }),
          configurationServerInstanceTypeResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].serverInstance.type_);
            }),
          configurationServerInstanceVisibilityResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].serverInstance.visibility);
            }),
          configurationServerInstanceVmNumberResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].serverInstance.vmNumber);
            }),
          configurationVolumeDiskTypeResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].volumes[param[1]].diskType);
            }),
          configurationVolumeDiskSizeResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].volumes[param[1]].diskSize);
            }),
          configurationVolumeMaxIopsResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].volumes[param[1]].maxIops);
            }),
          configurationVolumeThroughputResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].volumes[param[1]].throughput);
            }),
          configurationVolumeThroughputUnitResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].volumes[param[1]].throughputUnit);
            }),
          configurationVolumeDescriptionResult: (function (param) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[param[0]].volumes[param[1]].description);
            }),
          configurationBillingTypesResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].billingTypes);
            }),
          configurationCertificationsResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].certifications);
            }),
          configurationFeaturesResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].features);
            }),
          configurationAdditionalRequirementsResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].additionalRequirements);
            }),
          addConfiguration: (function (entry) {
              dispatch({
                    TAG: "AddConfigurationEntry",
                    _0: entry
                  });
            }),
          removeConfiguration: (function (index) {
              dispatch({
                    TAG: "RemoveConfigurationEntry",
                    _0: index
                  });
            }),
          addConfigurationVolume: (function (entry, index) {
              dispatch({
                    TAG: "AddConfigurationVolumeEntry",
                    _0: entry,
                    _1: index
                  });
            }),
          removeConfigurationVolume: (function (index) {
              dispatch({
                    TAG: "RemoveConfigurationVolumeEntry",
                    _0: index
                  });
            }),
          configurationsResult: state.collectionsStatuses.configurations.self,
          configurationVolumesResult: (function (index) {
              return state.collectionsStatuses.configurations.volumes[index];
            }),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              return Belt_Array.every(state.fieldsStatuses.configurations, (function (item) {
                            var exit = 0;
                            var tmp = item.startDate;
                            if (typeof tmp !== "object") {
                              var tmp$1 = item.contractLength;
                              if (typeof tmp$1 !== "object") {
                                var match = item.serverInstance;
                                var tmp$2 = match.availability;
                                if (typeof tmp$2 !== "object") {
                                  var tmp$3 = match.cpu;
                                  if (typeof tmp$3 !== "object") {
                                    var tmp$4 = match.database;
                                    if (typeof tmp$4 !== "object") {
                                      var tmp$5 = match.description;
                                      if (typeof tmp$5 !== "object") {
                                        var tmp$6 = match.hypervisor;
                                        if (typeof tmp$6 !== "object") {
                                          var tmp$7 = match.image;
                                          if (typeof tmp$7 !== "object") {
                                            var tmp$8 = match.operatingSystem;
                                            if (typeof tmp$8 !== "object") {
                                              var tmp$9 = match.ram;
                                              if (typeof tmp$9 !== "object") {
                                                var tmp$10 = match.region;
                                                if (typeof tmp$10 !== "object") {
                                                  var tmp$11 = match.type_;
                                                  if (typeof tmp$11 !== "object") {
                                                    var tmp$12 = match.visibility;
                                                    if (typeof tmp$12 !== "object") {
                                                      var tmp$13 = match.vmNumber;
                                                      if (typeof tmp$13 !== "object") {
                                                        var tmp$14 = item.billingTypes;
                                                        if (typeof tmp$14 !== "object") {
                                                          var tmp$15 = item.certifications;
                                                          if (typeof tmp$15 !== "object") {
                                                            var tmp$16 = item.features;
                                                            if (typeof tmp$16 !== "object") {
                                                              var tmp$17 = item.additionalRequirements;
                                                              if (typeof tmp$17 !== "object") {
                                                                if (Belt_Array.every(item.volumes, (function (item) {
                                                                          var tmp = item.diskType;
                                                                          if (typeof tmp === "object") {
                                                                            return true;
                                                                          }
                                                                          var tmp$1 = item.diskSize;
                                                                          if (typeof tmp$1 === "object") {
                                                                            return true;
                                                                          }
                                                                          var tmp$2 = item.maxIops;
                                                                          if (typeof tmp$2 === "object") {
                                                                            return true;
                                                                          }
                                                                          var tmp$3 = item.throughput;
                                                                          if (typeof tmp$3 === "object") {
                                                                            return true;
                                                                          }
                                                                          var tmp$4 = item.throughputUnit;
                                                                          if (typeof tmp$4 === "object") {
                                                                            return true;
                                                                          }
                                                                          var tmp$5 = item.description;
                                                                          if (typeof tmp$5 !== "object") {
                                                                            return false;
                                                                          } else {
                                                                            return true;
                                                                          }
                                                                        }))) {
                                                                  return false;
                                                                }
                                                                exit = 1;
                                                              } else {
                                                                exit = 1;
                                                              }
                                                            } else {
                                                              exit = 1;
                                                            }
                                                          } else {
                                                            exit = 1;
                                                          }
                                                        } else {
                                                          exit = 1;
                                                        }
                                                      } else {
                                                        exit = 1;
                                                      }
                                                    } else {
                                                      exit = 1;
                                                    }
                                                  } else {
                                                    exit = 1;
                                                  }
                                                } else {
                                                  exit = 1;
                                                }
                                              } else {
                                                exit = 1;
                                              }
                                            } else {
                                              exit = 1;
                                            }
                                          } else {
                                            exit = 1;
                                          }
                                        } else {
                                          exit = 1;
                                        }
                                      } else {
                                        exit = 1;
                                      }
                                    } else {
                                      exit = 1;
                                    }
                                  } else {
                                    exit = 1;
                                  }
                                } else {
                                  exit = 1;
                                }
                              } else {
                                exit = 1;
                              }
                            } else {
                              exit = 1;
                            }
                            if (exit === 1) {
                              var exit$1 = 0;
                              exit$1 = 2;
                              if (exit$1 === 2) {
                                var exit$2 = 0;
                                exit$2 = 3;
                                if (exit$2 === 3) {
                                  var exit$3 = 0;
                                  exit$3 = 4;
                                  if (exit$3 === 4) {
                                    var exit$4 = 0;
                                    exit$4 = 5;
                                    if (exit$4 === 5) {
                                      var exit$5 = 0;
                                      exit$5 = 6;
                                      if (exit$5 === 6) {
                                        var exit$6 = 0;
                                        exit$6 = 7;
                                        if (exit$6 === 7) {
                                          var exit$7 = 0;
                                          exit$7 = 8;
                                          if (exit$7 === 8) {
                                            return true;
                                          }
                                          
                                        }
                                        
                                      }
                                      
                                    }
                                    
                                  }
                                  
                                }
                                
                              }
                              
                            }
                            
                          }));
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

function empty(serverInstanceAvailabilities, serverInstanceVisibilities) {
  return {
          availability: serverInstanceAvailabilities[0],
          cpu: undefined,
          database: "",
          description: "",
          hypervisor: "",
          image: "",
          operatingSystem: "",
          ram: "",
          region: "",
          type_: "",
          visibility: serverInstanceVisibilities[0],
          vmNumber: undefined
        };
}

var inputDecoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              availability: Belt_Option.getWithDefault(field.required("availability", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              cpu: field.required("cpu", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              database: Belt_Option.getWithDefault(field.required("database", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              description: Belt_Option.getWithDefault(field.required("description", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              hypervisor: Belt_Option.getWithDefault(field.required("hypervisor", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              image: Belt_Option.getWithDefault(field.required("image", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              operatingSystem: Belt_Option.getWithDefault(field.required("operatingSystem", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              ram: Belt_Option.getWithDefault(field.required("ram", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              region: Belt_Option.getWithDefault(field.required("region", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              type_: Belt_Option.getWithDefault(field.required("type", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              visibility: Belt_Option.getWithDefault(field.required("visibility", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              vmNumber: field.required("vmNumber", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int))
            };
    });

function inputToJson(serverInstance) {
  var x = serverInstance.database;
  var tmp = x === "" ? null : x;
  var x$1 = serverInstance.description;
  var tmp$1 = x$1 === "" ? null : x$1;
  var x$2 = serverInstance.hypervisor;
  var tmp$2 = x$2 === "" ? null : x$2;
  var x$3 = serverInstance.image;
  var tmp$3 = x$3 === "" ? null : x$3;
  var x$4 = serverInstance.operatingSystem;
  var tmp$4 = x$4 === "" ? null : x$4;
  var x$5 = serverInstance.ram;
  var tmp$5 = x$5 === "" ? null : x$5;
  var x$6 = serverInstance.region;
  var tmp$6 = x$6 === "" ? null : x$6;
  var x$7 = serverInstance.type_;
  var tmp$7 = x$7 === "" ? null : x$7;
  return Json_Encode$JsonCombinators.object([
              [
                "availability",
                serverInstance.availability
              ],
              [
                "visibility",
                serverInstance.visibility
              ],
              [
                "cpu",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(serverInstance.cpu)
              ],
              [
                "database",
                tmp
              ],
              [
                "description",
                tmp$1
              ],
              [
                "hypervisor",
                tmp$2
              ],
              [
                "image",
                tmp$3
              ],
              [
                "operatingSystem",
                tmp$4
              ],
              [
                "ram",
                tmp$5
              ],
              [
                "region",
                tmp$6
              ],
              [
                "type",
                tmp$7
              ],
              [
                "vmNumber",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(serverInstance.vmNumber)
              ]
            ]);
}

function outputToJson(serverInstance) {
  return Json_Encode$JsonCombinators.object([
              [
                "availability",
                serverInstance.availability
              ],
              [
                "visibility",
                serverInstance.visibility
              ],
              [
                "cpu",
                serverInstance.cpu
              ],
              [
                "ram",
                serverInstance.ram
              ],
              [
                "vmNumber",
                serverInstance.vmNumber
              ],
              [
                "database",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(serverInstance.database)
              ],
              [
                "description",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(serverInstance.description)
              ],
              [
                "hypervisor",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(serverInstance.hypervisor)
              ],
              [
                "image",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(serverInstance.image)
              ],
              [
                "operatingSystem",
                serverInstance.operatingSystem
              ],
              [
                "region",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(serverInstance.region)
              ],
              [
                "type",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(serverInstance.type_)
              ]
            ]);
}

var ServerInstance = {
  empty: empty,
  inputDecoder: inputDecoder,
  inputToJson: inputToJson,
  outputToJson: outputToJson
};

function empty$1() {
  return {
          key: NonSecure.nanoid(),
          diskType: "",
          diskSize: "",
          maxIops: undefined,
          throughput: undefined,
          throughputUnit: "",
          description: ""
        };
}

var inputDecoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              key: NonSecure.nanoid(),
              diskType: Belt_Option.getWithDefault(field.required("diskType", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              diskSize: Belt_Option.getWithDefault(field.required("diskSize", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              maxIops: field.required("maxIops", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              throughput: field.required("throughput", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              throughputUnit: Belt_Option.getWithDefault(field.required("throughputUnit", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
              description: Belt_Option.getWithDefault(field.required("description", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), "")
            };
    });

function inputToJson$1(volume) {
  var x = volume.diskType;
  var tmp = x === "" ? null : x;
  var x$1 = volume.diskSize;
  var tmp$1 = x$1 === "" ? null : x$1;
  var x$2 = volume.throughputUnit;
  var tmp$2 = x$2 === "" ? null : x$2;
  var x$3 = volume.description;
  var tmp$3 = x$3 === "" ? null : x$3;
  return Json_Encode$JsonCombinators.object([
              [
                "diskType",
                tmp
              ],
              [
                "diskSize",
                tmp$1
              ],
              [
                "maxIops",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(volume.maxIops)
              ],
              [
                "throughput",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(volume.throughput)
              ],
              [
                "throughputUnit",
                tmp$2
              ],
              [
                "description",
                tmp$3
              ]
            ]);
}

function outputToJson$1(volume) {
  return Json_Encode$JsonCombinators.object([
              [
                "diskType",
                volume.diskType
              ],
              [
                "diskSize",
                volume.diskSize
              ],
              [
                "maxIops",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(volume.maxIops)
              ],
              [
                "throughput",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(volume.throughput)
              ],
              [
                "throughputUnit",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(volume.throughputUnit)
              ],
              [
                "description",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(volume.description)
              ]
            ]);
}

function update(input, param, updater) {
  var volumeIndex = param[1];
  var configurationIndex = param[0];
  return {
          configurations: Belt_Array.mapWithIndex(input.configurations, (function (idx, configuration) {
                  if (idx !== configurationIndex) {
                    return configuration;
                  } else {
                    return {
                            key: configuration.key,
                            startDate: configuration.startDate,
                            contractLength: configuration.contractLength,
                            serverInstance: configuration.serverInstance,
                            volumes: Belt_Array.mapWithIndex(configuration.volumes, (function (idx, volume) {
                                    if (idx !== volumeIndex) {
                                      return volume;
                                    } else {
                                      return updater(volume);
                                    }
                                  })),
                            billingTypes: configuration.billingTypes,
                            certifications: configuration.certifications,
                            features: configuration.features,
                            additionalRequirements: configuration.additionalRequirements
                          };
                  }
                }))
        };
}

function remove(input, param) {
  var volumeIndex = param[1];
  var configurationIndex = param[0];
  return {
          configurations: Belt_Array.mapWithIndex(input.configurations, (function (idx, configuration) {
                  if (idx !== configurationIndex) {
                    return configuration;
                  } else {
                    return {
                            key: configuration.key,
                            startDate: configuration.startDate,
                            contractLength: configuration.contractLength,
                            serverInstance: configuration.serverInstance,
                            volumes: Belt_Array.keepWithIndex(configuration.volumes, (function (param, idx) {
                                    return idx !== volumeIndex;
                                  })),
                            billingTypes: configuration.billingTypes,
                            certifications: configuration.certifications,
                            features: configuration.features,
                            additionalRequirements: configuration.additionalRequirements
                          };
                  }
                }))
        };
}

var Volume = {
  empty: empty$1,
  inputDecoder: inputDecoder$1,
  inputToJson: inputToJson$1,
  outputToJson: outputToJson$1,
  update: update,
  remove: remove
};

function empty$2(serverInstanceVisibilities, serverInstanceAvailabilities) {
  return {
          key: NonSecure.nanoid(),
          startDate: undefined,
          contractLength: undefined,
          serverInstance: empty(serverInstanceAvailabilities, serverInstanceVisibilities),
          volumes: [empty$1()],
          billingTypes: undefined,
          certifications: undefined,
          features: undefined,
          additionalRequirements: ""
        };
}

function inputDecoder$2(contractLengths) {
  return Json_Decode$JsonCombinators.object(function (field) {
              return {
                      key: NonSecure.nanoid(),
                      startDate: field.required("startDate", Json_Decode$JsonCombinators.option($$Date.Naive.decoder)),
                      contractLength: Belt_Option.flatMap(field.required("contractLength", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)), (function (x) {
                              return Belt_Array.getBy(contractLengths, (function (cl) {
                                            return cl.value === x;
                                          }));
                            })),
                      serverInstance: field.required("serverInstances", inputDecoder),
                      volumes: field.required("volumes", Json_Decode$JsonCombinators.array(inputDecoder$1)),
                      billingTypes: Belt_SetString.fromArray(field.required("billingTypes", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))),
                      certifications: Belt_SetString.fromArray(field.required("certifications", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))),
                      features: Belt_SetString.fromArray(field.required("features", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))),
                      additionalRequirements: Belt_Option.getWithDefault(field.required("additionalRequirements", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), "")
                    };
            });
}

function inputFromJson(json, contractLengths) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, inputDecoder$2(contractLengths)));
}

function inputToJson$2(configuration) {
  var x = configuration.contractLength;
  var x$1 = configuration.additionalRequirements;
  var tmp = x$1 === "" ? null : x$1;
  return Json_Encode$JsonCombinators.object([
              [
                "startDate",
                $$Date.Naive.toJsonOpt(configuration.startDate)
              ],
              [
                "contractLength",
                x !== undefined ? x.value : null
              ],
              [
                "serverInstances",
                inputToJson(configuration.serverInstance)
              ],
              [
                "volumes",
                Belt_Array.map(configuration.volumes, inputToJson$1)
              ],
              [
                "billingTypes",
                Belt_SetString.toArray(configuration.billingTypes)
              ],
              [
                "certifications",
                Belt_SetString.toArray(configuration.certifications)
              ],
              [
                "features",
                Belt_SetString.toArray(configuration.features)
              ],
              [
                "additionalRequirements",
                tmp
              ]
            ]);
}

function outputToJson$2(configuration) {
  return Json_Encode$JsonCombinators.object([
              [
                "startDate",
                $$Date.Naive.toJson(configuration.startDate)
              ],
              [
                "contractLength",
                configuration.contractLength
              ],
              [
                "serverInstances",
                outputToJson(configuration.serverInstance)
              ],
              [
                "volumes",
                Belt_Array.map(configuration.volumes, outputToJson$1)
              ],
              [
                "billingTypes",
                configuration.billingTypes
              ],
              [
                "certifications",
                configuration.certifications
              ],
              [
                "features",
                configuration.features
              ],
              [
                "additionalRequirements",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(configuration.additionalRequirements)
              ]
            ]);
}

function update$1(input, index, updater) {
  return {
          configurations: Belt_Array.mapWithIndex(input.configurations, (function (idx, configuration) {
                  if (idx !== index) {
                    return configuration;
                  } else {
                    return updater(configuration);
                  }
                }))
        };
}

function remove$1(input, index) {
  return {
          configurations: Belt_Array.keepWithIndex(input.configurations, (function (param, idx) {
                  return idx !== index;
                }))
        };
}

var Configuration = {
  empty: empty$2,
  inputDecoder: inputDecoder$2,
  inputFromJson: inputFromJson,
  inputToJson: inputToJson$2,
  outputToJson: outputToJson$2,
  update: update$1,
  remove: remove$1
};

function toJson(input) {
  return Json_Encode$JsonCombinators.object([[
                "cloudServerConfigurations",
                Belt_Array.map(input.configurations, inputToJson$2)
              ]]);
}

var Input = {
  toJson: toJson
};

function toJson$1(output) {
  return Json_Encode$JsonCombinators.object([[
                "cloudServerConfigurations",
                Belt_Array.map(output.configurations, outputToJson$2)
              ]]);
}

var Output = {
  toJson: toJson$1
};

export {
  validateFieldOfCollection2OnChangeWithValidator ,
  validateDependentFieldOfCollection2OnChange ,
  validateFieldOfCollection2OnBlurWithValidator ,
  validators ,
  initialFieldsStatuses ,
  initialCollectionsStatuses ,
  initialState ,
  validateForm ,
  useForm ,
  ServerInstance ,
  Volume ,
  Configuration ,
  Input ,
  Output ,
}
/* inputDecoder Not a pure module */
