// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../libs/Date.res.js";
import * as Project from "./Project.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProjectProposalId from "./ProjectProposalId.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(status) {
  if (status === "Expired") {
    return "Expired";
  } else if (status === "Canceled") {
    return "Canceled";
  } else if (status === "NoBid") {
    return "No Bid";
  } else if (status === "Preparing") {
    return "Preparing";
  } else if (status === "Finalized") {
    return "Finalized";
  } else {
    return "In Progress";
  }
}

function toString(value) {
  if (value === "Expired") {
    return "Expired";
  } else if (value === "Canceled") {
    return "Canceled";
  } else if (value === "NoBid") {
    return "No Bid";
  } else if (value === "Preparing") {
    return "Preparing";
  } else if (value === "Finalized") {
    return "Finalized";
  } else {
    return "In Progress";
  }
}

function fromApiString(value) {
  switch (value) {
    case "Canceled" :
        return "Canceled";
    case "Expired" :
        return "Expired";
    case "Finalized" :
        return "Finalized";
    case "In Progress" :
        return "InProgress";
    case "No Bid" :
        return "NoBid";
    case "Preparing" :
        return "Preparing";
    default:
      return "InProgress";
  }
}

function toApiString(value) {
  if (value === "Expired") {
    return "expire";
  } else if (value === "Canceled") {
    return "cancel";
  } else if (value === "NoBid") {
    return "nobid";
  } else if (value === "Preparing") {
    return "preparing";
  } else if (value === "Finalized") {
    return "finalize";
  } else {
    return "inprogress";
  }
}

function fromJs(status) {
  switch (status) {
    case "Canceled" :
        return "Canceled";
    case "Expired" :
        return "Expired";
    case "Finalized" :
        return "Finalized";
    case "In Progress" :
        return "InProgress";
    case "No Bid" :
        return "NoBid";
    case "Preparing" :
        return "Preparing";
    default:
      return "InProgress";
  }
}

var decoder = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.string, fromApiString);

function statusList(viewContext) {
  switch (viewContext) {
    case "Personal" :
    case "Admin" :
        return [
                "Finalized",
                "Canceled"
              ];
    case "Visitor" :
        return [];
    
  }
}

var Status = {
  toJson: toJson,
  toString: toString,
  fromApiString: fromApiString,
  toApiString: toApiString,
  fromJs: fromJs,
  decoder: decoder,
  statusList: statusList
};

function fromJs$1(js) {
  return {
          totalSent: js.totalSent,
          totalNoResponse: js.totalNoResponse
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              totalSent: field.required("totalSent", Json_Decode$JsonCombinators.$$int),
              totalNoResponse: field.required("totalNoResponse", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var Breakdown = {
  fromJs: fromJs$1,
  decoder: decoder$1,
  fromJson: fromJson
};

function fromJs$2(js) {
  return {
          id: js.id,
          description: js.description,
          providerLogo: js.providerLogo,
          providerName: js.providerName,
          updatedAt: $$Date.Naive.fromString(js.updatedAt),
          lastViewedAt: Belt_Option.map(Caml_option.nullable_to_opt(js.lastViewedAt), $$Date.Naive.fromString),
          sentAt: $$Date.Naive.fromString(js.sentAt),
          expiresAt: $$Date.Naive.fromString(js.expiresAt),
          status: fromJs(js.status),
          almostExpired: js.almostExpired,
          statusMessage: js.statusMessage,
          tourDate: Belt_Option.map(Caml_option.nullable_to_opt(js.tourDate), $$Date.Naive.fromString),
          tourRequested: js.tourRequested,
          projectCategory: js.projectCategory,
          projectSubcategory: js.projectSubcategory,
          additionalDocuments: js.additionalDocuments
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ProjectProposalId.decoder),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              providerLogo: field.required("providerLogo", Json_Decode$JsonCombinators.string),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.string),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder),
              lastViewedAt: field.required("lastViewedAt", Json_Decode$JsonCombinators.option($$Date.Naive.decoder)),
              sentAt: field.required("sentAt", $$Date.Naive.decoder),
              expiresAt: field.required("expiresAt", $$Date.Naive.decoder),
              status: field.required("status", decoder),
              almostExpired: field.required("almostExpired", Json_Decode$JsonCombinators.bool),
              statusMessage: field.required("statusMessage", Json_Decode$JsonCombinators.string),
              tourDate: field.required("tourDate", Json_Decode$JsonCombinators.option($$Date.Naive.decoder)),
              tourRequested: field.required("tourRequested", Json_Decode$JsonCombinators.bool),
              projectCategory: field.required("projectCategory", Json_Decode$JsonCombinators.string),
              projectSubcategory: field.required("projectSubcategory", Json_Decode$JsonCombinators.string),
              additionalDocuments: field.required("additionalDocuments", Json_Decode$JsonCombinators.array(Project.$$Document.decoder))
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ProjectProposalId.decoder),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              expirationDate: field.required("expirationDate", $$Date.Naive.decoder),
              filename: field.required("filename", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

var ForForm = {
  decoder: decoder$3,
  fromJson: fromJson$2
};

function empty() {
  return {
          message: "",
          tourDate: undefined
        };
}

function toJson$1(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "message",
                input.message
              ],
              [
                "tourDate",
                $$Date.Naive.toJsonOpt(input.tourDate)
              ]
            ]);
}

var TourRequestInput = {
  toJson: toJson$1
};

function toJson$2(input) {
  return Json_Encode$JsonCombinators.object([
              [
                "message",
                input.message
              ],
              [
                "tourDate",
                $$Date.Naive.toJsonOpt(input.tourDate)
              ]
            ]);
}

var TourRequest = {
  empty: empty,
  TourRequestInput: TourRequestInput,
  toJson: toJson$2
};

export {
  Status ,
  Breakdown ,
  fromJs$2 as fromJs,
  decoder$2 as decoder,
  fromJson$1 as fromJson,
  ForForm ,
  TourRequest ,
}
/* decoder Not a pure module */
