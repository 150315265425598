// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as ResourceCategory from "../../models/ResourceCategory.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.array(ResourceCategory.decoder);

var Res = {
  decoder: decoder
};

function exec(param) {
  return Rest.$$fetch("/resources-categories", "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Res ,
  exec ,
}
/* decoder Not a pure module */
