// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as Rest from "../Rest.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(orderIds, newStatus) {
  return Json_Encode$JsonCombinators.object([
              [
                "order_ids",
                ID.toJsonArray(orderIds)
              ],
              [
                "status",
                newStatus
              ]
            ]);
}

var Req = {
  toJson: toJson
};

function exec(orderIds, newStatus) {
  return Rest.$$fetch("/dashboard/orders/update", {
              NAME: "Post",
              VAL: toJson(orderIds, newStatus)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
