// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";

var include = ID.$$String({});

var T = include.T;

var eq = include.eq;

var toJson = include.toJson;

var decoder = include.decoder;

var fromJson = include.fromJson;

var Comparable = include.Comparable;

var $$Map = include.$$Map;

var $$Set = include.$$Set;

export {
  T ,
  eq ,
  toJson ,
  decoder ,
  fromJson ,
  Comparable ,
  $$Map ,
  $$Set ,
}
/* include Not a pure module */
