// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as Rest from "../Rest.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(selectedProvidersIds) {
  return Json_Encode$JsonCombinators.object([[
                "providerIds",
                ID.toJsonArray(selectedProvidersIds)
              ]]);
}

var Req = {
  toJson: toJson
};

function exec(selectedProvidersIds) {
  return Rest.$$fetch("/projects/save_internet_providers_draft_project/", {
              NAME: "Post",
              VAL: toJson(selectedProvidersIds)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
