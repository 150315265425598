// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as Project from "../../models/Project.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(generalInfo) {
  return Json_Encode$JsonCombinators.object([[
                "project",
                Project.GeneralInfo.toJson(generalInfo)
              ]]);
}

var Req = {
  toJson: toJson
};

function exec(generalInfo) {
  return Rest.$$fetch("/projects/save_general_info_draft", {
              NAME: "Post",
              VAL: toJson(generalInfo)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* Rest Not a pure module */
