// Generated by ReScript, PLEASE EDIT WITH CARE

import * as PervasivesU from "rescript/lib/es6/pervasivesU.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromString(x) {
  switch (x) {
    case "accepted" :
        return "Accepted";
    case "pending" :
        return "Pending";
    case "rejected" :
        return "Rejected";
    default:
      return PervasivesU.failwith("Unknown product status");
  }
}

function toString(x) {
  switch (x) {
    case "Pending" :
        return "pending";
    case "Accepted" :
        return "accepted";
    case "Rejected" :
        return "rejected";
    
  }
}

var decoder = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.string, fromString);

export {
  fromString ,
  toString ,
  decoder ,
}
/* decoder Not a pure module */
