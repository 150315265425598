// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as ControlPlaneRedirectModalData from "../../bundles/services/multicloud/components/redirect-modal/components/data/ControlPlaneRedirectModalData.res.js";

function toString(x) {
  if (x === "Success") {
    return "SUCCESS";
  } else {
    return "ERROR";
  }
}

function fromString(x) {
  switch (x) {
    case "ERROR" :
        return "Error";
    case "SUCCESS" :
        return "Success";
    default:
      return "Error";
  }
}

var decoder = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.string, fromString);

var ResMessage = {
  toString: toString,
  fromString: fromString,
  decoder: decoder
};

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              message: field.required("message", decoder),
              redirectUri: field.required("redirectUri", Json_Decode$JsonCombinators.string)
            };
    });

var Res = {
  decoder: decoder$1
};

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return field.required("message", Json_Decode$JsonCombinators.string);
    });

var Err = {
  decoder: decoder$2
};

function exec(body) {
  return Rest.$$fetch("/control_plane_registration", {
              NAME: "Post",
              VAL: ControlPlaneRedirectModalData.Input.toJson(body)
            }, {
              NAME: "Json",
              VAL: decoder$1
            }, {
              NAME: "Message",
              VAL: decoder$2
            }, undefined);
}

export {
  ResMessage ,
  Res ,
  Err ,
  exec ,
}
/* decoder Not a pure module */
