// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as $$Image from "./Image.res.js";
import * as Video from "./Video.res.js";
import * as $$Location from "./Location.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProductStatus from "./ProductStatus.res.js";
import * as ProductFeature from "./ProductFeature.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          name: js.name,
          description: js.description,
          category: js.category,
          subcategory: js.subcategory,
          startDate: $$Date.Naive.fromString(js.startDate),
          endDate: $$Date.Naive.fromString(js.endDate),
          isExclusiveOffer: js.isExclusiveOffer,
          pricePerMonth: Belt_Option.getExn(Belt_Float.fromString(js.pricePerMonth)),
          setupFee: Belt_Option.getExn(Belt_Float.fromString(js.setupFee)),
          status: ProductStatus.fromString(js.status),
          term: js.term,
          powerCircuit: js.powerCircuit,
          bandwidth: js.bandwidth,
          ipAllocation: js.ipAllocation,
          totalPowerKw: js.totalPowerKw,
          providerId: js.providerId,
          providerLogo: js.providerLogo,
          location: $$Location.fromJs(js.location),
          url: js.url,
          features: Belt_Array.map(js.features, ProductFeature.fromJs),
          images: Belt_Array.map(js.images, $$Image.fromJs),
          videos: Belt_Array.map(js.videos, Video.fromJs),
          providerName: js.providerName,
          providerUrl: js.providerUrl,
          seoCategory: js.seoCategory
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              category: field.required("category", Json_Decode$JsonCombinators.string),
              subcategory: field.required("subcategory", Json_Decode$JsonCombinators.string),
              startDate: field.required("startDate", $$Date.Naive.decoder),
              endDate: field.required("endDate", $$Date.Naive.decoder),
              isExclusiveOffer: field.required("isExclusiveOffer", Json_Decode$JsonCombinators.bool),
              pricePerMonth: field.required("pricePerMonth", Json_Decode$JsonCombinators.$$float),
              setupFee: field.required("setupFee", Json_Decode$JsonCombinators.$$float),
              status: field.required("status", ProductStatus.decoder),
              term: field.required("term", Json_Decode$JsonCombinators.string),
              powerCircuit: field.required("powerCircuit", Json_Decode$JsonCombinators.string),
              bandwidth: field.required("bandwidth", Json_Decode$JsonCombinators.string),
              ipAllocation: field.required("ipAllocation", Json_Decode$JsonCombinators.string),
              totalPowerKw: field.required("totalPowerKw", Json_Decode$JsonCombinators.$$int),
              providerId: field.required("providerId", ID.decoder),
              providerLogo: field.required("providerLogo", Json_Decode$JsonCombinators.string),
              location: field.required("location", $$Location.decoder),
              url: field.required("url", Json_Decode$JsonCombinators.string),
              features: field.required("features", Json_Decode$JsonCombinators.array(ProductFeature.decoder)),
              images: field.required("images", Json_Decode$JsonCombinators.array($$Image.decoder)),
              videos: field.required("videos", Json_Decode$JsonCombinators.array(Video.decoder)),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.string),
              providerUrl: field.required("providerUrl", Json_Decode$JsonCombinators.string),
              seoCategory: field.required("seoCategory", Json_Decode$JsonCombinators.string)
            };
    });

function fromJs$1(js) {
  return {
          id: js.id,
          name: js.name,
          description: js.description,
          category: js.category,
          subcategory: js.subcategory,
          startDate: $$Date.Naive.fromString(js.startDate),
          endDate: $$Date.Naive.fromString(js.endDate),
          isExclusiveOffer: js.isExclusiveOffer,
          pricePerMonth: Belt_Option.getExn(Belt_Float.fromString(js.pricePerMonth)),
          setupFee: Belt_Option.getExn(Belt_Float.fromString(js.setupFee)),
          status: ProductStatus.fromString(js.status),
          term: js.term,
          powerCircuit: js.powerCircuit,
          bandwidth: js.bandwidth,
          ipAllocation: js.ipAllocation,
          totalPowerKw: js.totalPowerKw,
          providerId: js.providerId,
          providerLogo: js.providerLogo,
          location: Belt_Option.map(Caml_option.nullable_to_opt(js.location), $$Location.fromJs),
          url: js.url,
          features: Belt_Array.map(js.features, ProductFeature.fromJs),
          images: Belt_Array.map(js.images, $$Image.fromJs),
          videos: Belt_Array.map(js.videos, Video.fromJs),
          providerName: js.providerName,
          seoCategory: js.seoCategory,
          slug: js.slug
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              category: field.required("category", Json_Decode$JsonCombinators.string),
              subcategory: field.required("subcategory", Json_Decode$JsonCombinators.string),
              startDate: field.required("startDate", $$Date.Naive.decoder),
              endDate: field.required("endDate", $$Date.Naive.decoder),
              isExclusiveOffer: field.required("isExclusiveOffer", Json_Decode$JsonCombinators.bool),
              pricePerMonth: field.required("pricePerMonth", Json_Decode$JsonCombinators.$$float),
              setupFee: field.required("setupFee", Json_Decode$JsonCombinators.$$float),
              status: field.required("status", ProductStatus.decoder),
              term: field.required("term", Json_Decode$JsonCombinators.string),
              powerCircuit: field.required("powerCircuit", Json_Decode$JsonCombinators.string),
              bandwidth: field.required("bandwidth", Json_Decode$JsonCombinators.string),
              ipAllocation: field.required("ipAllocation", Json_Decode$JsonCombinators.string),
              totalPowerKw: field.required("totalPowerKw", Json_Decode$JsonCombinators.$$int),
              providerId: field.required("providerId", ID.decoder),
              providerLogo: field.required("providerLogo", Json_Decode$JsonCombinators.string),
              location: field.required("location", Json_Decode$JsonCombinators.option($$Location.decoder)),
              url: field.required("url", Json_Decode$JsonCombinators.string),
              features: field.required("features", Json_Decode$JsonCombinators.array(ProductFeature.decoder)),
              images: field.required("images", Json_Decode$JsonCombinators.array($$Image.decoder)),
              videos: field.required("videos", Json_Decode$JsonCombinators.array(Video.decoder)),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.string),
              seoCategory: field.required("seoCategory", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string)
            };
    });

var Draft = {
  fromJs: fromJs$1,
  decoder: decoder$1
};

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function fromJs$2(js) {
  return {
          id: js.id,
          name: js.name,
          description: js.description,
          startDate: js.startDate,
          endDate: js.endDate,
          isExclusiveOffer: js.isExclusiveOffer,
          pricePerMonth: js.pricePerMonth,
          term: js.term,
          powerCircuit: js.powerCircuit,
          bandwidth: js.bandwidth,
          ipAllocation: js.ipAllocation,
          totalPowerKw: js.totalPowerKw,
          providerLogo: js.providerLogo,
          providerName: js.providerName,
          location: $$Location.fromJs(js.location),
          productCategory: js.productCategory,
          url: js.url
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              startDate: field.required("startDate", $$Date.Naive.decoder),
              endDate: field.required("endDate", $$Date.Naive.decoder),
              isExclusiveOffer: field.required("isExclusiveOffer", Json_Decode$JsonCombinators.bool),
              pricePerMonth: field.required("pricePerMonth", Json_Decode$JsonCombinators.$$float),
              term: field.required("term", Json_Decode$JsonCombinators.string),
              powerCircuit: field.required("powerCircuit", Json_Decode$JsonCombinators.string),
              bandwidth: field.required("bandwidth", Json_Decode$JsonCombinators.string),
              ipAllocation: field.required("ipAllocation", Json_Decode$JsonCombinators.string),
              totalPowerKw: field.required("totalPowerKw", Json_Decode$JsonCombinators.$$int),
              providerLogo: field.required("providerLogo", Json_Decode$JsonCombinators.string),
              providerName: field.required("providerName", Json_Decode$JsonCombinators.string),
              location: field.required("location", $$Location.decoder),
              productCategory: field.required("prroductCategory", Json_Decode$JsonCombinators.string),
              url: field.required("url", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

var Simple = {
  fromJs: fromJs$2,
  decoder: decoder$2,
  fromJson: fromJson$1
};

var maxFeatures = 4;

var nameMaxLength = 60;

var descriptionMaxLength = 800;

export {
  maxFeatures ,
  nameMaxLength ,
  descriptionMaxLength ,
  fromJs ,
  decoder ,
  Draft ,
  fromJson ,
  Simple ,
}
/* decoder Not a pure module */
