// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Qs from "qs";
import * as Svg from "../../../styleguide/components/Svg/Svg.res.js";
import * as React from "react";
import * as Events from "../../../libs/Events.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as SortingPopoverScss from "./SortingPopover.scss";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

var css = SortingPopoverScss;

function toJson(sortBy) {
  var tmp;
  switch (sortBy) {
    case "PriceLowToHigh" :
        tmp = [
          [
            "sort_by",
            "price_per_month"
          ],
          [
            "sort_direction",
            "asc"
          ]
        ];
        break;
    case "PriceHighToLow" :
        tmp = [
          [
            "sort_by",
            "price_per_month"
          ],
          [
            "sort_direction",
            "desc"
          ]
        ];
        break;
    case "TitleAToZ" :
        tmp = [
          [
            "sort_by",
            "name"
          ],
          [
            "sort_direction",
            "asc"
          ]
        ];
        break;
    case "TitleZToA" :
        tmp = [
          [
            "sort_by",
            "name"
          ],
          [
            "sort_direction",
            "desc"
          ]
        ];
        break;
    case "ProviderAToZ" :
        tmp = [
          [
            "sort_by",
            "provider_name"
          ],
          [
            "sort_direction",
            "asc"
          ]
        ];
        break;
    case "ProviderZToA" :
        tmp = [
          [
            "sort_by",
            "provider_name"
          ],
          [
            "sort_direction",
            "desc"
          ]
        ];
        break;
    
  }
  return Json_Encode$JsonCombinators.object(tmp);
}

function toQueryString(sortBy) {
  return Qs.stringify(toJson(sortBy), {
              arrayFormat: "brackets"
            });
}

var SortBy = {
  toJson: toJson,
  toQueryString: toQueryString
};

var initialState = {
  visible: false
};

function reducer(state, action) {
  return {
          visible: !state.visible
        };
}

function SortingPopover(props) {
  var onClick = props.onClick;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var popupContainer = React.useRef(null);
  React.useEffect((function () {
          return Events.Subscriptions.subscribeToClicks(function ($$event) {
                      var container = popupContainer.current;
                      if (!state.visible) {
                        return ;
                      }
                      if (container == null) {
                        return ;
                      }
                      var target = $$event.target;
                      if (!container.contains(target)) {
                        return dispatch("Toggle");
                      }
                      
                    });
        }), [state.visible]);
  var tmp;
  switch (props.sortBy) {
    case "PriceLowToHigh" :
        tmp = "Low price first";
        break;
    case "PriceHighToLow" :
        tmp = "High price first";
        break;
    case "TitleAToZ" :
        tmp = "Title A to Z";
        break;
    case "TitleZToA" :
        tmp = "Title Z to A";
        break;
    case "ProviderAToZ" :
        tmp = "Provider A to Z";
        break;
    case "ProviderZToA" :
        tmp = "Provider Z to A";
        break;
    
  }
  var tmp$1;
  if (state.visible) {
    var tmp$2;
    tmp$2 = props.userLoginStatus === "LoggedIn" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsx("div", {
                    children: "Price, Low to High",
                    className: css.sortSelection,
                    onClick: (function (_event) {
                        onClick("PriceLowToHigh");
                      })
                  }),
              JsxRuntime.jsx("div", {
                    children: null,
                    className: css.divider
                  }),
              JsxRuntime.jsx("div", {
                    children: "Price, High to Low",
                    className: css.sortSelection,
                    onClick: (function (_event) {
                        onClick("PriceHighToLow");
                      })
                  }),
              JsxRuntime.jsx("div", {
                    children: null,
                    className: css.divider
                  })
            ]
          }) : null;
    tmp$1 = JsxRuntime.jsx("div", {
          children: JsxRuntime.jsxs("div", {
                children: [
                  tmp$2,
                  JsxRuntime.jsx("div", {
                        children: "Title, A-Z",
                        className: css.sortSelection,
                        onClick: (function (_event) {
                            onClick("TitleAToZ");
                          })
                      }),
                  JsxRuntime.jsx("div", {
                        children: null,
                        className: css.divider
                      }),
                  JsxRuntime.jsx("div", {
                        children: "Title, Z-A",
                        className: css.sortSelection,
                        onClick: (function (_event) {
                            onClick("TitleZToA");
                          })
                      }),
                  JsxRuntime.jsx("div", {
                        children: null,
                        className: css.divider
                      }),
                  JsxRuntime.jsx("div", {
                        children: "Provider, A-Z",
                        className: css.sortSelection,
                        onClick: (function (_event) {
                            onClick("ProviderAToZ");
                          })
                      }),
                  JsxRuntime.jsx("div", {
                        children: null,
                        className: css.divider
                      }),
                  JsxRuntime.jsx("div", {
                        children: "Provider, Z-A",
                        className: css.sortSelection,
                        onClick: (function (_event) {
                            onClick("ProviderZToA");
                          })
                      })
                ],
                className: css.popup
              }),
          className: Cx.cx([
                css.container,
                css.positionBottom
              ])
        });
  } else {
    tmp$1 = JsxRuntime.jsx("div", {
          children: null
        });
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              JsxRuntime.jsx("span", {
                                    children: tmp
                                  }),
                              JsxRuntime.jsx(Svg.make, {
                                    title: "Arrow down",
                                    viewBoxWidth: "6",
                                    viewBoxHeight: "4",
                                    children: JsxRuntime.jsx("path", {
                                          d: "M.75 0L3 2.4 5.25 0 6 .8 3 4 0 .8z",
                                          fill: "#5C667A",
                                          fillRule: "nonzero"
                                        })
                                  })
                            ]
                          }),
                      tmp$1
                    ],
                    className: css.trigger,
                    onClick: (function (_event) {
                        dispatch("Toggle");
                      })
                  }),
              ref: Caml_option.some(popupContainer),
              className: css.sortingPopover
            });
}

var make = SortingPopover;

export {
  css ,
  SortBy ,
  initialState ,
  reducer ,
  make ,
}
/* css Not a pure module */
