// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as InviteSignUpData from "../../bundles/client/invite-sign-up/InviteSignUpData.res.js";

var toJson = InviteSignUpData.Input.toJson;

var Req = {
  toJson: toJson
};

function exec(input, form) {
  return Rest.$$fetch("/invitations/accept_devise_invitation", {
              NAME: "Put",
              VAL: toJson(input, form)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* Rest Not a pure module */
