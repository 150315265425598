// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Qs from "qs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as CityStateCountry from "../../../models/CityStateCountry.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function fromJs(js) {
  return {
          locations: Belt_Array.map(js.locations, CityStateCountry.fromJs),
          providerIds: Belt_Array.map(js.providerIds, (function (prim) {
                  return prim;
                })),
          totalPowerKw: js.totalPowerKw,
          powerCircuits: js.powerCircuits,
          subcategories: js.subcategories,
          category: js.category
        };
}

function empty(totalPowerKwRangeOpt, categoryOpt, locationsOpt, param) {
  var totalPowerKwRange = totalPowerKwRangeOpt !== undefined ? totalPowerKwRangeOpt : [
      0,
      0
    ];
  var category = categoryOpt !== undefined ? categoryOpt : "colocation-pricing";
  var locations = locationsOpt !== undefined ? locationsOpt : [];
  return {
          locations: locations,
          providerIds: [],
          totalPowerKw: totalPowerKwRange,
          powerCircuits: [],
          subcategories: [],
          category: category
        };
}

function toJson(input) {
  var range = input.totalPowerKw;
  var tmp;
  var exit = 0;
  if (range[0] !== 0 || range[1] !== 0) {
    exit = 1;
  } else {
    tmp = [];
  }
  if (exit === 1) {
    tmp = Json_Encode$JsonCombinators.tuple2((function (prim) {
              return prim;
            }), (function (prim) {
              return prim;
            }))(range);
  }
  return Json_Encode$JsonCombinators.object([
              [
                "locations",
                Belt_Array.map(input.locations, CityStateCountry.toJson)
              ],
              [
                "providerIds",
                ID.toJsonArray(input.providerIds)
              ],
              [
                "totalPowerKw",
                tmp
              ],
              [
                "powerCircuits",
                input.powerCircuits
              ],
              [
                "subcategories",
                input.subcategories
              ]
            ]);
}

function toQueryString(input) {
  return Qs.stringify(toJson(input), {
              arrayFormat: "brackets"
            });
}

var Input = {
  fromJs: fromJs,
  empty: empty,
  toJson: toJson,
  toQueryString: toQueryString
};

export {
  Input ,
}
/* ID Not a pure module */
