// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as CreateCloudProjectIaasCloudServersConfigurationForm from "../../bundles/user-dashboard/projects/create-cloud-project-iaas-cloud-servers-configuration/CreateCloudProjectIaasCloudServersConfigurationForm.res.js";

var toJson = CreateCloudProjectIaasCloudServersConfigurationForm.Input.toJson;

var Req = {
  toJson: toJson
};

function exec(projectId, data) {
  return Rest.$$fetch("/projects/" + (projectId + "/save_cloud_servers_configurations"), {
              NAME: "Put",
              VAL: toJson(data)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* Rest Not a pure module */
