// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as Project from "../../models/Project.res.js";
import * as ProjectTile from "../../models/ProjectTile.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(status) {
  return Json_Encode$JsonCombinators.object([[
                "status",
                Project.Status.toJson(status)
              ]]);
}

var Req = {
  toJson: toJson
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              project: field.required("project", ProjectTile.decoder)
            };
    });

var Res = {
  decoder: decoder
};

function exec(projectId, status) {
  return Rest.$$fetch("/projects/" + (projectId + "/status"), {
              NAME: "Patch",
              VAL: toJson(status)
            }, {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
