// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../../../../libs/Date.res.js";
import * as React from "react";
import * as Project from "../../../../models/Project.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import IsPast from "date-fns/isPast";
import IsToday from "date-fns/isToday";
import * as NonSecure from "nanoid/non-secure";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

var validators_validateRegion = {
  strategy: "OnFirstChange",
  validate: (function (param) {
      return {
              TAG: "Ok",
              _0: param.validateRegion
            };
    })
};

var validators_geographicalRegion = {
  strategy: "OnFirstBlur",
  validate: (function (param) {
      var geographicalRegion = param.geographicalRegion;
      var documents = param.documents;
      if (param.validateRegion) {
        if (documents.length === 0 && geographicalRegion === "") {
          return {
                  TAG: "Ok",
                  _0: geographicalRegion
                };
        }
        if (geographicalRegion === "") {
          return {
                  TAG: "Error",
                  _0: "Geographical Region is required"
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: geographicalRegion
                };
        }
      }
      if (documents.length === 0 && geographicalRegion === "") {
        return {
                TAG: "Error",
                _0: "Geographical Region is required"
              };
      }
      if (geographicalRegion === "") {
        return {
                TAG: "Error",
                _0: "Geographical Region is required"
              };
      } else {
        return {
                TAG: "Ok",
                _0: geographicalRegion
              };
      }
    })
};

var validators_deletedDocumentIds = {
  strategy: "OnFirstBlur",
  validate: (function (param) {
      return {
              TAG: "Ok",
              _0: param.deletedDocumentIds
            };
    })
};

var validators_documents = {
  strategy: "OnFirstBlur",
  validate: (function (param) {
      return {
              TAG: "Ok",
              _0: param.documents
            };
    })
};

var validators_configurations = {
  collection: (function (param) {
      if (param.configurations.length !== 0) {
        return {
                TAG: "Ok",
                _0: undefined
              };
      } else {
        return {
                TAG: "Error",
                _0: "At least one configuration is required"
              };
      }
    }),
  fields: {
    key: undefined,
    startDate: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var date = param.configurations[at].startDate;
          if (date === undefined) {
            return {
                    TAG: "Error",
                    _0: "Service Start Date is required"
                  };
          }
          var date$1 = Caml_option.valFromOption(date);
          if (IsPast(date$1) || IsToday(date$1)) {
            return {
                    TAG: "Error",
                    _0: "Service Start Date can't be in the past"
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: date$1
                  };
          }
        })
    },
    contractLength: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var cl = param.configurations[at].contractLength;
          if (cl !== undefined) {
            return {
                    TAG: "Ok",
                    _0: cl.value
                  };
          } else {
            return {
                    TAG: "Error",
                    _0: "Contract length is required"
                  };
          }
        })
    },
    streetAddress: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].streetAddress;
          if (x === "") {
            return {
                    TAG: "Error",
                    _0: "Street Address is required"
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    buildingOrFloor: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].buildingOrFloor;
          if (x === "") {
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    suite: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].suite;
          if (x === "") {
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    cityOrTown: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].cityOrTown;
          if (x === "") {
            return {
                    TAG: "Error",
                    _0: "City/Town is required"
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    subLocality: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].subLocality;
          if (x === "") {
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    stateOrProvince: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].stateOrProvince;
          if (x === "") {
            return {
                    TAG: "Error",
                    _0: "State/Province is required"
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    country: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].country;
          if (x === "") {
            return {
                    TAG: "Error",
                    _0: "Country is required"
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    postalCode: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].postalCode;
          if (x === "") {
            return {
                    TAG: "Error",
                    _0: "Postal Code is required"
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    prefix: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].prefix;
          if (x === "") {
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    additionalLocationDetails: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].additionalLocationDetails;
          if (x === "") {
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    accessType: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].accessType;
          if (x === "") {
            return {
                    TAG: "Error",
                    _0: "Access Type is required"
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    committedDataRateValue: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].committedDataRateValue;
          if (x !== undefined) {
            if (x <= 0) {
              return {
                      TAG: "Error",
                      _0: "Committed Data Rate must be greater than zero"
                    };
            } else {
              return {
                      TAG: "Ok",
                      _0: x
                    };
            }
          } else {
            return {
                    TAG: "Error",
                    _0: "Committed Data Rate is required"
                  };
          }
        })
    },
    committedDataRateUnit: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].committedDataRateUnit;
          if (x === "") {
            return {
                    TAG: "Error",
                    _0: "Committed Data Rate is required"
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    burstable: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].burstable;
          if (x === "") {
            return {
                    TAG: "Error",
                    _0: "Burstable is required"
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    portSpeed: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].portSpeed;
          if (x === "") {
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    ipv4Addresses: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].ipv4Addresses;
          if (x === "") {
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    ipv6Addresses: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].ipv6Addresses;
          if (x === "") {
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    },
    internetServices: {
      strategy: "OnFirstChange",
      validate: (function (param, at) {
          return {
                  TAG: "Ok",
                  _0: Belt_SetString.toArray(param.configurations[at].internetServices)
                };
        })
    },
    additionalRequirements: {
      strategy: "OnFirstBlur",
      validate: (function (param, at) {
          var x = param.configurations[at].additionalRequirements;
          if (x === "") {
            return {
                    TAG: "Ok",
                    _0: undefined
                  };
          } else {
            return {
                    TAG: "Ok",
                    _0: x
                  };
          }
        })
    }
  }
};

var validators = {
  validateRegion: validators_validateRegion,
  geographicalRegion: validators_geographicalRegion,
  deletedDocumentIds: validators_deletedDocumentIds,
  documents: validators_documents,
  configurations: validators_configurations
};

function initialFieldsStatuses(input) {
  return {
          validateRegion: "Pristine",
          geographicalRegion: "Pristine",
          deletedDocumentIds: "Pristine",
          documents: "Pristine",
          configurations: Belt_Array.make(input.configurations.length, {
                key: "Pristine",
                startDate: "Pristine",
                contractLength: "Pristine",
                streetAddress: "Pristine",
                buildingOrFloor: "Pristine",
                suite: "Pristine",
                cityOrTown: "Pristine",
                subLocality: "Pristine",
                stateOrProvince: "Pristine",
                country: "Pristine",
                postalCode: "Pristine",
                prefix: "Pristine",
                additionalLocationDetails: "Pristine",
                accessType: "Pristine",
                committedDataRateValue: "Pristine",
                committedDataRateUnit: "Pristine",
                burstable: "Pristine",
                portSpeed: "Pristine",
                ipv4Addresses: "Pristine",
                ipv6Addresses: "Pristine",
                internetServices: "Pristine",
                additionalRequirements: "Pristine"
              })
        };
}

var initialCollectionsStatuses = {
  configurations: undefined
};

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: initialCollectionsStatuses,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.validateRegion;
  var tmp;
  tmp = typeof match !== "object" ? validators.validateRegion.validate(input) : match._0;
  var match$1 = fieldsStatuses.geographicalRegion;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.geographicalRegion.validate(input) : match$1._0;
  var match$2 = fieldsStatuses.deletedDocumentIds;
  var tmp$2;
  tmp$2 = typeof match$2 !== "object" ? validators.deletedDocumentIds.validate(input) : match$2._0;
  var match$3 = fieldsStatuses.documents;
  var tmp$3;
  tmp$3 = typeof match$3 !== "object" ? validators.documents.validate(input) : match$3._0;
  var match_0 = validators.configurations.collection(input);
  var match_1 = Belt_Array.reduceWithIndex(fieldsStatuses.configurations, [
        {
          TAG: "Ok",
          _0: []
        },
        []
      ], (function (param, fieldStatus, index) {
          var statuses = param[1];
          var output = param[0];
          var match_0 = {
            TAG: "Ok",
            _0: input.configurations[index].key
          };
          var match = fieldStatus.startDate;
          var tmp;
          tmp = typeof match !== "object" ? validators.configurations.fields.startDate.validate(input, index) : match._0;
          var match$1 = fieldStatus.contractLength;
          var tmp$1;
          tmp$1 = typeof match$1 !== "object" ? validators.configurations.fields.contractLength.validate(input, index) : match$1._0;
          var match$2 = fieldStatus.streetAddress;
          var tmp$2;
          tmp$2 = typeof match$2 !== "object" ? validators.configurations.fields.streetAddress.validate(input, index) : match$2._0;
          var match$3 = fieldStatus.buildingOrFloor;
          var tmp$3;
          tmp$3 = typeof match$3 !== "object" ? validators.configurations.fields.buildingOrFloor.validate(input, index) : match$3._0;
          var match$4 = fieldStatus.suite;
          var tmp$4;
          tmp$4 = typeof match$4 !== "object" ? validators.configurations.fields.suite.validate(input, index) : match$4._0;
          var match$5 = fieldStatus.cityOrTown;
          var tmp$5;
          tmp$5 = typeof match$5 !== "object" ? validators.configurations.fields.cityOrTown.validate(input, index) : match$5._0;
          var match$6 = fieldStatus.subLocality;
          var tmp$6;
          tmp$6 = typeof match$6 !== "object" ? validators.configurations.fields.subLocality.validate(input, index) : match$6._0;
          var match$7 = fieldStatus.stateOrProvince;
          var tmp$7;
          tmp$7 = typeof match$7 !== "object" ? validators.configurations.fields.stateOrProvince.validate(input, index) : match$7._0;
          var match$8 = fieldStatus.country;
          var tmp$8;
          tmp$8 = typeof match$8 !== "object" ? validators.configurations.fields.country.validate(input, index) : match$8._0;
          var match$9 = fieldStatus.postalCode;
          var tmp$9;
          tmp$9 = typeof match$9 !== "object" ? validators.configurations.fields.postalCode.validate(input, index) : match$9._0;
          var match$10 = fieldStatus.prefix;
          var tmp$10;
          tmp$10 = typeof match$10 !== "object" ? validators.configurations.fields.prefix.validate(input, index) : match$10._0;
          var match$11 = fieldStatus.additionalLocationDetails;
          var tmp$11;
          tmp$11 = typeof match$11 !== "object" ? validators.configurations.fields.additionalLocationDetails.validate(input, index) : match$11._0;
          var match$12 = fieldStatus.accessType;
          var tmp$12;
          tmp$12 = typeof match$12 !== "object" ? validators.configurations.fields.accessType.validate(input, index) : match$12._0;
          var match$13 = fieldStatus.committedDataRateValue;
          var tmp$13;
          tmp$13 = typeof match$13 !== "object" ? validators.configurations.fields.committedDataRateValue.validate(input, index) : match$13._0;
          var match$14 = fieldStatus.committedDataRateUnit;
          var tmp$14;
          tmp$14 = typeof match$14 !== "object" ? validators.configurations.fields.committedDataRateUnit.validate(input, index) : match$14._0;
          var match$15 = fieldStatus.burstable;
          var tmp$15;
          tmp$15 = typeof match$15 !== "object" ? validators.configurations.fields.burstable.validate(input, index) : match$15._0;
          var match$16 = fieldStatus.portSpeed;
          var tmp$16;
          tmp$16 = typeof match$16 !== "object" ? validators.configurations.fields.portSpeed.validate(input, index) : match$16._0;
          var match$17 = fieldStatus.ipv4Addresses;
          var tmp$17;
          tmp$17 = typeof match$17 !== "object" ? validators.configurations.fields.ipv4Addresses.validate(input, index) : match$17._0;
          var match$18 = fieldStatus.ipv6Addresses;
          var tmp$18;
          tmp$18 = typeof match$18 !== "object" ? validators.configurations.fields.ipv6Addresses.validate(input, index) : match$18._0;
          var match$19 = fieldStatus.internetServices;
          var tmp$19;
          tmp$19 = typeof match$19 !== "object" ? validators.configurations.fields.internetServices.validate(input, index) : match$19._0;
          var match$20 = fieldStatus.additionalRequirements;
          var tmp$20;
          tmp$20 = typeof match$20 !== "object" ? validators.configurations.fields.additionalRequirements.validate(input, index) : match$20._0;
          if (output.TAG === "Ok") {
            var keyResult = match_0;
            var output$1 = output._0;
            if (keyResult.TAG === "Ok") {
              var startDateResult = tmp;
              if (startDateResult.TAG === "Ok") {
                var contractLengthResult = tmp$1;
                if (contractLengthResult.TAG === "Ok") {
                  var streetAddressResult = tmp$2;
                  if (streetAddressResult.TAG === "Ok") {
                    var buildingOrFloorResult = tmp$3;
                    if (buildingOrFloorResult.TAG === "Ok") {
                      var suiteResult = tmp$4;
                      if (suiteResult.TAG === "Ok") {
                        var cityOrTownResult = tmp$5;
                        if (cityOrTownResult.TAG === "Ok") {
                          var subLocalityResult = tmp$6;
                          if (subLocalityResult.TAG === "Ok") {
                            var stateOrProvinceResult = tmp$7;
                            if (stateOrProvinceResult.TAG === "Ok") {
                              var countryResult = tmp$8;
                              if (countryResult.TAG === "Ok") {
                                var postalCodeResult = tmp$9;
                                if (postalCodeResult.TAG === "Ok") {
                                  var prefixResult = tmp$10;
                                  if (prefixResult.TAG === "Ok") {
                                    var additionalLocationDetailsResult = tmp$11;
                                    if (additionalLocationDetailsResult.TAG === "Ok") {
                                      var accessTypeResult = tmp$12;
                                      if (accessTypeResult.TAG === "Ok") {
                                        var committedDataRateValueResult = tmp$13;
                                        if (committedDataRateValueResult.TAG === "Ok") {
                                          var committedDataRateUnitResult = tmp$14;
                                          if (committedDataRateUnitResult.TAG === "Ok") {
                                            var burstableResult = tmp$15;
                                            if (burstableResult.TAG === "Ok") {
                                              var portSpeedResult = tmp$16;
                                              if (portSpeedResult.TAG === "Ok") {
                                                var ipv4AddressesResult = tmp$17;
                                                if (ipv4AddressesResult.TAG === "Ok") {
                                                  var ipv6AddressesResult = tmp$18;
                                                  if (ipv6AddressesResult.TAG === "Ok") {
                                                    var internetServicesResult = tmp$19;
                                                    if (internetServicesResult.TAG === "Ok") {
                                                      var additionalRequirementsResult = tmp$20;
                                                      if (additionalRequirementsResult.TAG === "Ok") {
                                                        output$1.push({
                                                              key: keyResult._0,
                                                              startDate: startDateResult._0,
                                                              contractLength: contractLengthResult._0,
                                                              streetAddress: streetAddressResult._0,
                                                              buildingOrFloor: buildingOrFloorResult._0,
                                                              suite: suiteResult._0,
                                                              cityOrTown: cityOrTownResult._0,
                                                              subLocality: subLocalityResult._0,
                                                              stateOrProvince: stateOrProvinceResult._0,
                                                              country: countryResult._0,
                                                              postalCode: postalCodeResult._0,
                                                              prefix: prefixResult._0,
                                                              additionalLocationDetails: additionalLocationDetailsResult._0,
                                                              accessType: accessTypeResult._0,
                                                              committedDataRateValue: committedDataRateValueResult._0,
                                                              committedDataRateUnit: committedDataRateUnitResult._0,
                                                              burstable: burstableResult._0,
                                                              portSpeed: portSpeedResult._0,
                                                              ipv4Addresses: ipv4AddressesResult._0,
                                                              ipv6Addresses: ipv6AddressesResult._0,
                                                              internetServices: internetServicesResult._0,
                                                              additionalRequirements: additionalRequirementsResult._0
                                                            });
                                                        statuses.push({
                                                              key: {
                                                                TAG: "Dirty",
                                                                _0: keyResult,
                                                                _1: "Hidden"
                                                              },
                                                              startDate: {
                                                                TAG: "Dirty",
                                                                _0: startDateResult,
                                                                _1: "Shown"
                                                              },
                                                              contractLength: {
                                                                TAG: "Dirty",
                                                                _0: contractLengthResult,
                                                                _1: "Shown"
                                                              },
                                                              streetAddress: {
                                                                TAG: "Dirty",
                                                                _0: streetAddressResult,
                                                                _1: "Shown"
                                                              },
                                                              buildingOrFloor: {
                                                                TAG: "Dirty",
                                                                _0: buildingOrFloorResult,
                                                                _1: "Shown"
                                                              },
                                                              suite: {
                                                                TAG: "Dirty",
                                                                _0: suiteResult,
                                                                _1: "Shown"
                                                              },
                                                              cityOrTown: {
                                                                TAG: "Dirty",
                                                                _0: cityOrTownResult,
                                                                _1: "Shown"
                                                              },
                                                              subLocality: {
                                                                TAG: "Dirty",
                                                                _0: subLocalityResult,
                                                                _1: "Shown"
                                                              },
                                                              stateOrProvince: {
                                                                TAG: "Dirty",
                                                                _0: stateOrProvinceResult,
                                                                _1: "Shown"
                                                              },
                                                              country: {
                                                                TAG: "Dirty",
                                                                _0: countryResult,
                                                                _1: "Shown"
                                                              },
                                                              postalCode: {
                                                                TAG: "Dirty",
                                                                _0: postalCodeResult,
                                                                _1: "Shown"
                                                              },
                                                              prefix: {
                                                                TAG: "Dirty",
                                                                _0: prefixResult,
                                                                _1: "Shown"
                                                              },
                                                              additionalLocationDetails: {
                                                                TAG: "Dirty",
                                                                _0: additionalLocationDetailsResult,
                                                                _1: "Shown"
                                                              },
                                                              accessType: {
                                                                TAG: "Dirty",
                                                                _0: accessTypeResult,
                                                                _1: "Shown"
                                                              },
                                                              committedDataRateValue: {
                                                                TAG: "Dirty",
                                                                _0: committedDataRateValueResult,
                                                                _1: "Shown"
                                                              },
                                                              committedDataRateUnit: {
                                                                TAG: "Dirty",
                                                                _0: committedDataRateUnitResult,
                                                                _1: "Shown"
                                                              },
                                                              burstable: {
                                                                TAG: "Dirty",
                                                                _0: burstableResult,
                                                                _1: "Shown"
                                                              },
                                                              portSpeed: {
                                                                TAG: "Dirty",
                                                                _0: portSpeedResult,
                                                                _1: "Shown"
                                                              },
                                                              ipv4Addresses: {
                                                                TAG: "Dirty",
                                                                _0: ipv4AddressesResult,
                                                                _1: "Shown"
                                                              },
                                                              ipv6Addresses: {
                                                                TAG: "Dirty",
                                                                _0: ipv6AddressesResult,
                                                                _1: "Shown"
                                                              },
                                                              internetServices: {
                                                                TAG: "Dirty",
                                                                _0: internetServicesResult,
                                                                _1: "Shown"
                                                              },
                                                              additionalRequirements: {
                                                                TAG: "Dirty",
                                                                _0: additionalRequirementsResult,
                                                                _1: "Shown"
                                                              }
                                                            });
                                                        return [
                                                                {
                                                                  TAG: "Ok",
                                                                  _0: output$1
                                                                },
                                                                statuses
                                                              ];
                                                      }
                                                      
                                                    }
                                                    
                                                  }
                                                  
                                                }
                                                
                                              }
                                              
                                            }
                                            
                                          }
                                          
                                        }
                                        
                                      }
                                      
                                    }
                                    
                                  }
                                  
                                }
                                
                              }
                              
                            }
                            
                          }
                          
                        }
                        
                      }
                      
                    }
                    
                  }
                  
                }
                
              }
              
            }
            
          }
          statuses.push({
                key: {
                  TAG: "Dirty",
                  _0: match_0,
                  _1: "Hidden"
                },
                startDate: {
                  TAG: "Dirty",
                  _0: tmp,
                  _1: "Shown"
                },
                contractLength: {
                  TAG: "Dirty",
                  _0: tmp$1,
                  _1: "Shown"
                },
                streetAddress: {
                  TAG: "Dirty",
                  _0: tmp$2,
                  _1: "Shown"
                },
                buildingOrFloor: {
                  TAG: "Dirty",
                  _0: tmp$3,
                  _1: "Shown"
                },
                suite: {
                  TAG: "Dirty",
                  _0: tmp$4,
                  _1: "Shown"
                },
                cityOrTown: {
                  TAG: "Dirty",
                  _0: tmp$5,
                  _1: "Shown"
                },
                subLocality: {
                  TAG: "Dirty",
                  _0: tmp$6,
                  _1: "Shown"
                },
                stateOrProvince: {
                  TAG: "Dirty",
                  _0: tmp$7,
                  _1: "Shown"
                },
                country: {
                  TAG: "Dirty",
                  _0: tmp$8,
                  _1: "Shown"
                },
                postalCode: {
                  TAG: "Dirty",
                  _0: tmp$9,
                  _1: "Shown"
                },
                prefix: {
                  TAG: "Dirty",
                  _0: tmp$10,
                  _1: "Shown"
                },
                additionalLocationDetails: {
                  TAG: "Dirty",
                  _0: tmp$11,
                  _1: "Shown"
                },
                accessType: {
                  TAG: "Dirty",
                  _0: tmp$12,
                  _1: "Shown"
                },
                committedDataRateValue: {
                  TAG: "Dirty",
                  _0: tmp$13,
                  _1: "Shown"
                },
                committedDataRateUnit: {
                  TAG: "Dirty",
                  _0: tmp$14,
                  _1: "Shown"
                },
                burstable: {
                  TAG: "Dirty",
                  _0: tmp$15,
                  _1: "Shown"
                },
                portSpeed: {
                  TAG: "Dirty",
                  _0: tmp$16,
                  _1: "Shown"
                },
                ipv4Addresses: {
                  TAG: "Dirty",
                  _0: tmp$17,
                  _1: "Shown"
                },
                ipv6Addresses: {
                  TAG: "Dirty",
                  _0: tmp$18,
                  _1: "Shown"
                },
                internetServices: {
                  TAG: "Dirty",
                  _0: tmp$19,
                  _1: "Shown"
                },
                additionalRequirements: {
                  TAG: "Dirty",
                  _0: tmp$20,
                  _1: "Shown"
                }
              });
          return [
                  {
                    TAG: "Error",
                    _0: undefined
                  },
                  statuses
                ];
        }));
  var validateRegionResult = tmp;
  var validateRegionResult$1;
  if (validateRegionResult.TAG === "Ok") {
    var geographicalRegionResult = tmp$1;
    if (geographicalRegionResult.TAG === "Ok") {
      var deletedDocumentIdsResult = tmp$2;
      if (deletedDocumentIdsResult.TAG === "Ok") {
        var documentsResult = tmp$3;
        if (documentsResult.TAG === "Ok") {
          var configurationsCollectionResult = match_0;
          if (configurationsCollectionResult.TAG === "Ok") {
            var match$4 = match_1;
            var configurations = match$4[0];
            if (configurations.TAG === "Ok") {
              return {
                      TAG: "Valid",
                      output: {
                        configurations: configurations._0,
                        documents: documentsResult._0,
                        deletedDocumentIds: deletedDocumentIdsResult._0,
                        geographicalRegion: geographicalRegionResult._0,
                        validateRegion: validateRegionResult._0
                      },
                      fieldsStatuses: {
                        validateRegion: {
                          TAG: "Dirty",
                          _0: validateRegionResult,
                          _1: "Shown"
                        },
                        geographicalRegion: {
                          TAG: "Dirty",
                          _0: geographicalRegionResult,
                          _1: "Shown"
                        },
                        deletedDocumentIds: {
                          TAG: "Dirty",
                          _0: deletedDocumentIdsResult,
                          _1: "Shown"
                        },
                        documents: {
                          TAG: "Dirty",
                          _0: documentsResult,
                          _1: "Shown"
                        },
                        configurations: match$4[1]
                      },
                      collectionsStatuses: {
                        configurations: configurationsCollectionResult
                      }
                    };
            }
            validateRegionResult$1 = validateRegionResult;
          } else {
            validateRegionResult$1 = validateRegionResult;
          }
        } else {
          validateRegionResult$1 = validateRegionResult;
        }
      } else {
        validateRegionResult$1 = validateRegionResult;
      }
    } else {
      validateRegionResult$1 = validateRegionResult;
    }
  } else {
    validateRegionResult$1 = validateRegionResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            validateRegion: {
              TAG: "Dirty",
              _0: validateRegionResult$1,
              _1: "Shown"
            },
            geographicalRegion: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            },
            deletedDocumentIds: {
              TAG: "Dirty",
              _0: tmp$2,
              _1: "Shown"
            },
            documents: {
              TAG: "Dirty",
              _0: tmp$3,
              _1: "Shown"
            },
            configurations: match_1[1]
          },
          collectionsStatuses: {
            configurations: match_0
          }
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurValidateRegionField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.validateRegion, validators_validateRegion, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: status,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: init.configurations
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurGeographicalRegionField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.geographicalRegion, validators_geographicalRegion, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: status,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: init.configurations
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurDeletedDocumentIdsField" :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.deletedDocumentIds, validators_deletedDocumentIds, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: status,
                                  documents: init.documents,
                                  configurations: init.configurations
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurDocumentsField" :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.documents, validators_documents, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: status,
                                  configurations: init.configurations
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateValidateRegionField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.validateRegion, state.submissionStatus, validators_validateRegion, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: status,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: init.configurations
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateGeographicalRegionField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.geographicalRegion, state.submissionStatus, validators_geographicalRegion, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: status,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: init.configurations
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateDeletedDocumentIdsField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.deletedDocumentIds, state.submissionStatus, validators_deletedDocumentIds, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: status,
                                            documents: init.documents,
                                            configurations: init.configurations
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateDocumentsField" :
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.documents, state.submissionStatus, validators_documents, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: status,
                                            configurations: init.configurations
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationKeyField" :
                  var index = action._1;
                  var nextInput$4 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$4.configurations[index].key, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.key = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationStartDateField" :
                  var index$1 = action._1;
                  var nextInput$5 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$5, index$1, state.fieldsStatuses.configurations[index$1].startDate, state.submissionStatus, validators_configurations.fields.startDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$1) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.startDate = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationContractLengthField" :
                  var index$2 = action._1;
                  var nextInput$6 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$6, index$2, state.fieldsStatuses.configurations[index$2].contractLength, state.submissionStatus, validators_configurations.fields.contractLength, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$2) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.contractLength = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationStreetAddressField" :
                  var index$3 = action._1;
                  var nextInput$7 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$7, index$3, state.fieldsStatuses.configurations[index$3].streetAddress, state.submissionStatus, validators_configurations.fields.streetAddress, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$3) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.streetAddress = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationBuildingOrFloorField" :
                  var index$4 = action._1;
                  var nextInput$8 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$8, index$4, state.fieldsStatuses.configurations[index$4].buildingOrFloor, state.submissionStatus, validators_configurations.fields.buildingOrFloor, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$4) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.buildingOrFloor = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationSuiteField" :
                  var index$5 = action._1;
                  var nextInput$9 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$9, index$5, state.fieldsStatuses.configurations[index$5].suite, state.submissionStatus, validators_configurations.fields.suite, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$5) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.suite = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationCityOrTownField" :
                  var index$6 = action._1;
                  var nextInput$10 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$10, index$6, state.fieldsStatuses.configurations[index$6].cityOrTown, state.submissionStatus, validators_configurations.fields.cityOrTown, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$6) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.cityOrTown = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationSubLocalityField" :
                  var index$7 = action._1;
                  var nextInput$11 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$11, index$7, state.fieldsStatuses.configurations[index$7].subLocality, state.submissionStatus, validators_configurations.fields.subLocality, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$7) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.subLocality = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationStateOrProvinceField" :
                  var index$8 = action._1;
                  var nextInput$12 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$12, index$8, state.fieldsStatuses.configurations[index$8].stateOrProvince, state.submissionStatus, validators_configurations.fields.stateOrProvince, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$8) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.stateOrProvince = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationCountryField" :
                  var index$9 = action._1;
                  var nextInput$13 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$13, index$9, state.fieldsStatuses.configurations[index$9].country, state.submissionStatus, validators_configurations.fields.country, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$9) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.country = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationPostalCodeField" :
                  var index$10 = action._1;
                  var nextInput$14 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$14, index$10, state.fieldsStatuses.configurations[index$10].postalCode, state.submissionStatus, validators_configurations.fields.postalCode, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$10) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.postalCode = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationPrefixField" :
                  var index$11 = action._1;
                  var nextInput$15 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$15,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$15, index$11, state.fieldsStatuses.configurations[index$11].prefix, state.submissionStatus, validators_configurations.fields.prefix, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$11) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.prefix = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationAdditionalLocationDetailsField" :
                  var index$12 = action._1;
                  var nextInput$16 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$16,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$16, index$12, state.fieldsStatuses.configurations[index$12].additionalLocationDetails, state.submissionStatus, validators_configurations.fields.additionalLocationDetails, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$12) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.additionalLocationDetails = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationAccessTypeField" :
                  var index$13 = action._1;
                  var nextInput$17 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$17,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$17, index$13, state.fieldsStatuses.configurations[index$13].accessType, state.submissionStatus, validators_configurations.fields.accessType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$13) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.accessType = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationCommittedDataRateValueField" :
                  var index$14 = action._1;
                  var nextInput$18 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$18,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$18, index$14, state.fieldsStatuses.configurations[index$14].committedDataRateValue, state.submissionStatus, validators_configurations.fields.committedDataRateValue, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$14) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.committedDataRateValue = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationCommittedDataRateUnitField" :
                  var index$15 = action._1;
                  var nextInput$19 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$19,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$19, index$15, state.fieldsStatuses.configurations[index$15].committedDataRateUnit, state.submissionStatus, validators_configurations.fields.committedDataRateUnit, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$15) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.committedDataRateUnit = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationBurstableField" :
                  var index$16 = action._1;
                  var nextInput$20 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$20,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$20, index$16, state.fieldsStatuses.configurations[index$16].burstable, state.submissionStatus, validators_configurations.fields.burstable, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$16) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.burstable = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationPortSpeedField" :
                  var index$17 = action._1;
                  var nextInput$21 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$21,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$21, index$17, state.fieldsStatuses.configurations[index$17].portSpeed, state.submissionStatus, validators_configurations.fields.portSpeed, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$17) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.portSpeed = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationIpv4AddressesField" :
                  var index$18 = action._1;
                  var nextInput$22 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$22,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$22, index$18, state.fieldsStatuses.configurations[index$18].ipv4Addresses, state.submissionStatus, validators_configurations.fields.ipv4Addresses, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$18) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.ipv4Addresses = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationIpv6AddressesField" :
                  var index$19 = action._1;
                  var nextInput$23 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$23,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$23, index$19, state.fieldsStatuses.configurations[index$19].ipv6Addresses, state.submissionStatus, validators_configurations.fields.ipv6Addresses, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$19) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.ipv6Addresses = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationInternetServicesField" :
                  var index$20 = action._1;
                  var nextInput$24 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$24,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$24, index$20, state.fieldsStatuses.configurations[index$20].internetServices, state.submissionStatus, validators_configurations.fields.internetServices, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$20) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.internetServices = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateConfigurationAdditionalRequirementsField" :
                  var index$21 = action._1;
                  var nextInput$25 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$25,
                            fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput$25, index$21, state.fieldsStatuses.configurations[index$21].additionalRequirements, state.submissionStatus, validators_configurations.fields.additionalRequirements, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            validateRegion: init.validateRegion,
                                            geographicalRegion: init.geographicalRegion,
                                            deletedDocumentIds: init.deletedDocumentIds,
                                            documents: init.documents,
                                            configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                                    if (index$p !== index$21) {
                                                      return item;
                                                    }
                                                    var newrecord = Caml_obj.obj_dup(item);
                                                    newrecord.additionalRequirements = status;
                                                    return newrecord;
                                                  }))
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "BlurConfigurationKeyField" :
                  var index$22 = action._0;
                  var result$4 = Formality.validateFieldOnBlurWithoutValidator(state.input.configurations[index$22].key, state.fieldsStatuses.configurations[index$22].key, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$22) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.key = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationStartDateField" :
                  var index$23 = action._0;
                  var result$5 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$23, state.fieldsStatuses.configurations[index$23].startDate, validators_configurations.fields.startDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$23) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.startDate = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationContractLengthField" :
                  var index$24 = action._0;
                  var result$6 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$24, state.fieldsStatuses.configurations[index$24].contractLength, validators_configurations.fields.contractLength, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$24) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.contractLength = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationStreetAddressField" :
                  var index$25 = action._0;
                  var result$7 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$25, state.fieldsStatuses.configurations[index$25].streetAddress, validators_configurations.fields.streetAddress, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$25) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.streetAddress = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationBuildingOrFloorField" :
                  var index$26 = action._0;
                  var result$8 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$26, state.fieldsStatuses.configurations[index$26].buildingOrFloor, validators_configurations.fields.buildingOrFloor, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$26) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.buildingOrFloor = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationSuiteField" :
                  var index$27 = action._0;
                  var result$9 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$27, state.fieldsStatuses.configurations[index$27].suite, validators_configurations.fields.suite, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$27) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.suite = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationCityOrTownField" :
                  var index$28 = action._0;
                  var result$10 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$28, state.fieldsStatuses.configurations[index$28].cityOrTown, validators_configurations.fields.cityOrTown, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$28) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.cityOrTown = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationSubLocalityField" :
                  var index$29 = action._0;
                  var result$11 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$29, state.fieldsStatuses.configurations[index$29].subLocality, validators_configurations.fields.subLocality, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$29) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.subLocality = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationStateOrProvinceField" :
                  var index$30 = action._0;
                  var result$12 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$30, state.fieldsStatuses.configurations[index$30].stateOrProvince, validators_configurations.fields.stateOrProvince, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$30) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.stateOrProvince = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationCountryField" :
                  var index$31 = action._0;
                  var result$13 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$31, state.fieldsStatuses.configurations[index$31].country, validators_configurations.fields.country, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$31) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.country = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationPostalCodeField" :
                  var index$32 = action._0;
                  var result$14 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$32, state.fieldsStatuses.configurations[index$32].postalCode, validators_configurations.fields.postalCode, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$32) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.postalCode = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationPrefixField" :
                  var index$33 = action._0;
                  var result$15 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$33, state.fieldsStatuses.configurations[index$33].prefix, validators_configurations.fields.prefix, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$33) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.prefix = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$15 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$15,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationAdditionalLocationDetailsField" :
                  var index$34 = action._0;
                  var result$16 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$34, state.fieldsStatuses.configurations[index$34].additionalLocationDetails, validators_configurations.fields.additionalLocationDetails, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$34) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.additionalLocationDetails = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$16 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$16,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationAccessTypeField" :
                  var index$35 = action._0;
                  var result$17 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$35, state.fieldsStatuses.configurations[index$35].accessType, validators_configurations.fields.accessType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$35) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.accessType = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$17 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$17,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationCommittedDataRateValueField" :
                  var index$36 = action._0;
                  var result$18 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$36, state.fieldsStatuses.configurations[index$36].committedDataRateValue, validators_configurations.fields.committedDataRateValue, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$36) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.committedDataRateValue = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$18 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$18,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationCommittedDataRateUnitField" :
                  var index$37 = action._0;
                  var result$19 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$37, state.fieldsStatuses.configurations[index$37].committedDataRateUnit, validators_configurations.fields.committedDataRateUnit, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$37) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.committedDataRateUnit = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$19 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$19,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationBurstableField" :
                  var index$38 = action._0;
                  var result$20 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$38, state.fieldsStatuses.configurations[index$38].burstable, validators_configurations.fields.burstable, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$38) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.burstable = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$20 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$20,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationPortSpeedField" :
                  var index$39 = action._0;
                  var result$21 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$39, state.fieldsStatuses.configurations[index$39].portSpeed, validators_configurations.fields.portSpeed, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$39) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.portSpeed = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$21 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$21,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationIpv4AddressesField" :
                  var index$40 = action._0;
                  var result$22 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$40, state.fieldsStatuses.configurations[index$40].ipv4Addresses, validators_configurations.fields.ipv4Addresses, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$40) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.ipv4Addresses = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$22 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$22,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationIpv6AddressesField" :
                  var index$41 = action._0;
                  var result$23 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$41, state.fieldsStatuses.configurations[index$41].ipv6Addresses, validators_configurations.fields.ipv6Addresses, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$41) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.ipv6Addresses = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$23 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$23,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationInternetServicesField" :
                  var index$42 = action._0;
                  var result$24 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$42, state.fieldsStatuses.configurations[index$42].internetServices, validators_configurations.fields.internetServices, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$42) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.internetServices = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$24 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$24,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurConfigurationAdditionalRequirementsField" :
                  var index$43 = action._0;
                  var result$25 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$43, state.fieldsStatuses.configurations[index$43].additionalRequirements, validators_configurations.fields.additionalRequirements, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  validateRegion: init.validateRegion,
                                  geographicalRegion: init.geographicalRegion,
                                  deletedDocumentIds: init.deletedDocumentIds,
                                  documents: init.documents,
                                  configurations: Belt_Array.mapWithIndex(state.fieldsStatuses.configurations, (function (index$p, item) {
                                          if (index$p !== index$43) {
                                            return item;
                                          }
                                          var newrecord = Caml_obj.obj_dup(item);
                                          newrecord.additionalRequirements = status;
                                          return newrecord;
                                        }))
                                };
                        }));
                  if (result$25 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$25,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "AddConfigurationEntry" :
                  var init = state.input;
                  var nextInput_configurations = Belt_Array.concat(state.input.configurations, [action._0]);
                  var nextInput_documents = init.documents;
                  var nextInput_deletedDocumentIds = init.deletedDocumentIds;
                  var nextInput_geographicalRegion = init.geographicalRegion;
                  var nextInput_validateRegion = init.validateRegion;
                  var nextInput$26 = {
                    configurations: nextInput_configurations,
                    documents: nextInput_documents,
                    deletedDocumentIds: nextInput_deletedDocumentIds,
                    geographicalRegion: nextInput_geographicalRegion,
                    validateRegion: nextInput_validateRegion
                  };
                  var init$1 = state.fieldsStatuses;
                  var nextFieldsStatuses_validateRegion = init$1.validateRegion;
                  var nextFieldsStatuses_geographicalRegion = init$1.geographicalRegion;
                  var nextFieldsStatuses_deletedDocumentIds = init$1.deletedDocumentIds;
                  var nextFieldsStatuses_documents = init$1.documents;
                  var nextFieldsStatuses_configurations = Belt_Array.concat(state.fieldsStatuses.configurations, [{
                          key: "Pristine",
                          startDate: "Pristine",
                          contractLength: "Pristine",
                          streetAddress: "Pristine",
                          buildingOrFloor: "Pristine",
                          suite: "Pristine",
                          cityOrTown: "Pristine",
                          subLocality: "Pristine",
                          stateOrProvince: "Pristine",
                          country: "Pristine",
                          postalCode: "Pristine",
                          prefix: "Pristine",
                          additionalLocationDetails: "Pristine",
                          accessType: "Pristine",
                          committedDataRateValue: "Pristine",
                          committedDataRateUnit: "Pristine",
                          burstable: "Pristine",
                          portSpeed: "Pristine",
                          ipv4Addresses: "Pristine",
                          ipv6Addresses: "Pristine",
                          internetServices: "Pristine",
                          additionalRequirements: "Pristine"
                        }]);
                  var nextFieldsStatuses = {
                    validateRegion: nextFieldsStatuses_validateRegion,
                    geographicalRegion: nextFieldsStatuses_geographicalRegion,
                    deletedDocumentIds: nextFieldsStatuses_deletedDocumentIds,
                    documents: nextFieldsStatuses_documents,
                    configurations: nextFieldsStatuses_configurations
                  };
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$26,
                            fieldsStatuses: nextFieldsStatuses,
                            collectionsStatuses: {
                              configurations: validators_configurations.collection(nextInput$26)
                            },
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "RemoveConfigurationEntry" :
                  var index$44 = action._0;
                  var init$2 = state.input;
                  var nextInput_configurations$1 = Belt_Array.keepWithIndex(state.input.configurations, (function (param, i) {
                          return i !== index$44;
                        }));
                  var nextInput_documents$1 = init$2.documents;
                  var nextInput_deletedDocumentIds$1 = init$2.deletedDocumentIds;
                  var nextInput_geographicalRegion$1 = init$2.geographicalRegion;
                  var nextInput_validateRegion$1 = init$2.validateRegion;
                  var nextInput$27 = {
                    configurations: nextInput_configurations$1,
                    documents: nextInput_documents$1,
                    deletedDocumentIds: nextInput_deletedDocumentIds$1,
                    geographicalRegion: nextInput_geographicalRegion$1,
                    validateRegion: nextInput_validateRegion$1
                  };
                  var init$3 = state.fieldsStatuses;
                  var nextFieldsStatuses_validateRegion$1 = init$3.validateRegion;
                  var nextFieldsStatuses_geographicalRegion$1 = init$3.geographicalRegion;
                  var nextFieldsStatuses_deletedDocumentIds$1 = init$3.deletedDocumentIds;
                  var nextFieldsStatuses_documents$1 = init$3.documents;
                  var nextFieldsStatuses_configurations$1 = Belt_Array.keepWithIndex(state.fieldsStatuses.configurations, (function (param, i) {
                          return i !== index$44;
                        }));
                  var nextFieldsStatuses$1 = {
                    validateRegion: nextFieldsStatuses_validateRegion$1,
                    geographicalRegion: nextFieldsStatuses_geographicalRegion$1,
                    deletedDocumentIds: nextFieldsStatuses_deletedDocumentIds$1,
                    documents: nextFieldsStatuses_documents$1,
                    configurations: nextFieldsStatuses_configurations$1
                  };
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$27,
                            fieldsStatuses: nextFieldsStatuses$1,
                            collectionsStatuses: {
                              configurations: validators_configurations.collection(nextInput$27)
                            },
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateValidateRegion: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateValidateRegionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateGeographicalRegion: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateGeographicalRegionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateDeletedDocumentIds: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateDeletedDocumentIdsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateDocuments: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateDocumentsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateConfigurationKey: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationKeyField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationStartDate: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationStartDateField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationContractLength: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationContractLengthField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationStreetAddress: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationStreetAddressField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationBuildingOrFloor: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationBuildingOrFloorField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationSuite: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationSuiteField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationCityOrTown: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationCityOrTownField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationSubLocality: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationSubLocalityField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationStateOrProvince: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationStateOrProvinceField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationCountry: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationCountryField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationPostalCode: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationPostalCodeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationPrefix: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationPrefixField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationAdditionalLocationDetails: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationAdditionalLocationDetailsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationAccessType: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationAccessTypeField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationCommittedDataRateValue: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationCommittedDataRateValueField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationCommittedDataRateUnit: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationCommittedDataRateUnitField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationBurstable: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationBurstableField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationPortSpeed: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationPortSpeedField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationIpv4Addresses: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationIpv4AddressesField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationIpv6Addresses: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationIpv6AddressesField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationInternetServices: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationInternetServicesField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          updateConfigurationAdditionalRequirements: (function (index, nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateConfigurationAdditionalRequirementsField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      }),
                    _1: index
                  });
            }),
          blurValidateRegion: (function () {
              dispatch("BlurValidateRegionField");
            }),
          blurGeographicalRegion: (function () {
              dispatch("BlurGeographicalRegionField");
            }),
          blurDeletedDocumentIds: (function () {
              dispatch("BlurDeletedDocumentIdsField");
            }),
          blurDocuments: (function () {
              dispatch("BlurDocumentsField");
            }),
          blurConfigurationKey: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationKeyField",
                    _0: index
                  });
            }),
          blurConfigurationStartDate: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationStartDateField",
                    _0: index
                  });
            }),
          blurConfigurationContractLength: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationContractLengthField",
                    _0: index
                  });
            }),
          blurConfigurationStreetAddress: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationStreetAddressField",
                    _0: index
                  });
            }),
          blurConfigurationBuildingOrFloor: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationBuildingOrFloorField",
                    _0: index
                  });
            }),
          blurConfigurationSuite: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationSuiteField",
                    _0: index
                  });
            }),
          blurConfigurationCityOrTown: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationCityOrTownField",
                    _0: index
                  });
            }),
          blurConfigurationSubLocality: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationSubLocalityField",
                    _0: index
                  });
            }),
          blurConfigurationStateOrProvince: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationStateOrProvinceField",
                    _0: index
                  });
            }),
          blurConfigurationCountry: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationCountryField",
                    _0: index
                  });
            }),
          blurConfigurationPostalCode: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationPostalCodeField",
                    _0: index
                  });
            }),
          blurConfigurationPrefix: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationPrefixField",
                    _0: index
                  });
            }),
          blurConfigurationAdditionalLocationDetails: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationAdditionalLocationDetailsField",
                    _0: index
                  });
            }),
          blurConfigurationAccessType: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationAccessTypeField",
                    _0: index
                  });
            }),
          blurConfigurationCommittedDataRateValue: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationCommittedDataRateValueField",
                    _0: index
                  });
            }),
          blurConfigurationCommittedDataRateUnit: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationCommittedDataRateUnitField",
                    _0: index
                  });
            }),
          blurConfigurationBurstable: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationBurstableField",
                    _0: index
                  });
            }),
          blurConfigurationPortSpeed: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationPortSpeedField",
                    _0: index
                  });
            }),
          blurConfigurationIpv4Addresses: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationIpv4AddressesField",
                    _0: index
                  });
            }),
          blurConfigurationIpv6Addresses: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationIpv6AddressesField",
                    _0: index
                  });
            }),
          blurConfigurationInternetServices: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationInternetServicesField",
                    _0: index
                  });
            }),
          blurConfigurationAdditionalRequirements: (function (index) {
              dispatch({
                    TAG: "BlurConfigurationAdditionalRequirementsField",
                    _0: index
                  });
            }),
          validateRegionResult: Formality.exposeFieldResult(state.fieldsStatuses.validateRegion),
          geographicalRegionResult: Formality.exposeFieldResult(state.fieldsStatuses.geographicalRegion),
          deletedDocumentIdsResult: Formality.exposeFieldResult(state.fieldsStatuses.deletedDocumentIds),
          documentsResult: Formality.exposeFieldResult(state.fieldsStatuses.documents),
          configurationKeyResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].key);
            }),
          configurationStartDateResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].startDate);
            }),
          configurationContractLengthResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].contractLength);
            }),
          configurationStreetAddressResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].streetAddress);
            }),
          configurationBuildingOrFloorResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].buildingOrFloor);
            }),
          configurationSuiteResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].suite);
            }),
          configurationCityOrTownResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].cityOrTown);
            }),
          configurationSubLocalityResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].subLocality);
            }),
          configurationStateOrProvinceResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].stateOrProvince);
            }),
          configurationCountryResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].country);
            }),
          configurationPostalCodeResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].postalCode);
            }),
          configurationPrefixResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].prefix);
            }),
          configurationAdditionalLocationDetailsResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].additionalLocationDetails);
            }),
          configurationAccessTypeResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].accessType);
            }),
          configurationCommittedDataRateValueResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].committedDataRateValue);
            }),
          configurationCommittedDataRateUnitResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].committedDataRateUnit);
            }),
          configurationBurstableResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].burstable);
            }),
          configurationPortSpeedResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].portSpeed);
            }),
          configurationIpv4AddressesResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].ipv4Addresses);
            }),
          configurationIpv6AddressesResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].ipv6Addresses);
            }),
          configurationInternetServicesResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].internetServices);
            }),
          configurationAdditionalRequirementsResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.configurations[index].additionalRequirements);
            }),
          addConfiguration: (function (entry) {
              dispatch({
                    TAG: "AddConfigurationEntry",
                    _0: entry
                  });
            }),
          removeConfiguration: (function (index) {
              dispatch({
                    TAG: "RemoveConfigurationEntry",
                    _0: index
                  });
            }),
          configurationsResult: state.collectionsStatuses.configurations,
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              if (Belt_Array.every(state.fieldsStatuses.configurations, (function (item) {
                        var exit = 0;
                        var tmp = item.key;
                        if (typeof tmp !== "object") {
                          var tmp$1 = item.startDate;
                          if (typeof tmp$1 !== "object") {
                            var tmp$2 = item.contractLength;
                            if (typeof tmp$2 !== "object") {
                              var tmp$3 = item.streetAddress;
                              if (typeof tmp$3 !== "object") {
                                var tmp$4 = item.buildingOrFloor;
                                if (typeof tmp$4 !== "object") {
                                  var tmp$5 = item.suite;
                                  if (typeof tmp$5 !== "object") {
                                    var tmp$6 = item.cityOrTown;
                                    if (typeof tmp$6 !== "object") {
                                      var tmp$7 = item.subLocality;
                                      if (typeof tmp$7 !== "object") {
                                        var tmp$8 = item.stateOrProvince;
                                        if (typeof tmp$8 !== "object") {
                                          var tmp$9 = item.country;
                                          if (typeof tmp$9 !== "object") {
                                            var tmp$10 = item.postalCode;
                                            if (typeof tmp$10 !== "object") {
                                              var tmp$11 = item.prefix;
                                              if (typeof tmp$11 !== "object") {
                                                var tmp$12 = item.additionalLocationDetails;
                                                if (typeof tmp$12 !== "object") {
                                                  var tmp$13 = item.accessType;
                                                  if (typeof tmp$13 !== "object") {
                                                    var tmp$14 = item.committedDataRateValue;
                                                    if (typeof tmp$14 !== "object") {
                                                      var tmp$15 = item.committedDataRateUnit;
                                                      if (typeof tmp$15 !== "object") {
                                                        var tmp$16 = item.burstable;
                                                        if (typeof tmp$16 !== "object") {
                                                          var tmp$17 = item.portSpeed;
                                                          if (typeof tmp$17 !== "object") {
                                                            var tmp$18 = item.ipv4Addresses;
                                                            if (typeof tmp$18 !== "object") {
                                                              var tmp$19 = item.ipv6Addresses;
                                                              if (typeof tmp$19 !== "object") {
                                                                var tmp$20 = item.internetServices;
                                                                if (typeof tmp$20 !== "object") {
                                                                  var tmp$21 = item.additionalRequirements;
                                                                  if (typeof tmp$21 !== "object") {
                                                                    return false;
                                                                  }
                                                                  exit = 1;
                                                                } else {
                                                                  exit = 1;
                                                                }
                                                              } else {
                                                                exit = 1;
                                                              }
                                                            } else {
                                                              exit = 1;
                                                            }
                                                          } else {
                                                            exit = 1;
                                                          }
                                                        } else {
                                                          exit = 1;
                                                        }
                                                      } else {
                                                        exit = 1;
                                                      }
                                                    } else {
                                                      exit = 1;
                                                    }
                                                  } else {
                                                    exit = 1;
                                                  }
                                                } else {
                                                  exit = 1;
                                                }
                                              } else {
                                                exit = 1;
                                              }
                                            } else {
                                              exit = 1;
                                            }
                                          } else {
                                            exit = 1;
                                          }
                                        } else {
                                          exit = 1;
                                        }
                                      } else {
                                        exit = 1;
                                      }
                                    } else {
                                      exit = 1;
                                    }
                                  } else {
                                    exit = 1;
                                  }
                                } else {
                                  exit = 1;
                                }
                              } else {
                                exit = 1;
                              }
                            } else {
                              exit = 1;
                            }
                          } else {
                            exit = 1;
                          }
                        } else {
                          exit = 1;
                        }
                        if (exit === 1) {
                          var exit$1 = 0;
                          exit$1 = 2;
                          if (exit$1 === 2) {
                            var exit$2 = 0;
                            exit$2 = 3;
                            if (exit$2 === 3) {
                              var exit$3 = 0;
                              exit$3 = 4;
                              if (exit$3 === 4) {
                                var exit$4 = 0;
                                exit$4 = 5;
                                if (exit$4 === 5) {
                                  var exit$5 = 0;
                                  exit$5 = 6;
                                  if (exit$5 === 6) {
                                    var exit$6 = 0;
                                    exit$6 = 7;
                                    if (exit$6 === 7) {
                                      var exit$7 = 0;
                                      exit$7 = 8;
                                      if (exit$7 === 8) {
                                        var exit$8 = 0;
                                        exit$8 = 9;
                                        if (exit$8 === 9) {
                                          var exit$9 = 0;
                                          exit$9 = 10;
                                          if (exit$9 === 10) {
                                            var exit$10 = 0;
                                            exit$10 = 11;
                                            if (exit$10 === 11) {
                                              var exit$11 = 0;
                                              exit$11 = 12;
                                              if (exit$11 === 12) {
                                                return true;
                                              }
                                              
                                            }
                                            
                                          }
                                          
                                        }
                                        
                                      }
                                      
                                    }
                                    
                                  }
                                  
                                }
                                
                              }
                              
                            }
                            
                          }
                          
                        }
                        
                      }))) {
                return true;
              }
              var match = state.fieldsStatuses;
              var tmp = match.validateRegion;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.geographicalRegion;
              if (typeof tmp$1 === "object") {
                return true;
              }
              var tmp$2 = match.deletedDocumentIds;
              if (typeof tmp$2 === "object") {
                return true;
              }
              var tmp$3 = match.documents;
              if (typeof tmp$3 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

function empty() {
  return {
          key: NonSecure.nanoid(),
          startDate: undefined,
          contractLength: undefined,
          streetAddress: "",
          buildingOrFloor: "",
          suite: "",
          cityOrTown: "",
          subLocality: "",
          stateOrProvince: "",
          country: "",
          postalCode: "",
          prefix: "",
          additionalLocationDetails: "",
          accessType: "",
          committedDataRateValue: undefined,
          committedDataRateUnit: "",
          burstable: "",
          portSpeed: "",
          ipv4Addresses: "",
          ipv6Addresses: "",
          internetServices: undefined,
          additionalRequirements: ""
        };
}

function inputDecoder(contractLengths) {
  return Json_Decode$JsonCombinators.object(function (field) {
              return {
                      key: NonSecure.nanoid(),
                      startDate: field.required("startDate", Json_Decode$JsonCombinators.option($$Date.Naive.decoder)),
                      contractLength: Belt_Option.flatMap(field.required("contractLength", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)), (function (x) {
                              return Belt_Array.getBy(contractLengths, (function (cl) {
                                            return cl.value === x;
                                          }));
                            })),
                      streetAddress: Belt_Option.getWithDefault(field.required("streetAddress", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      buildingOrFloor: Belt_Option.getWithDefault(field.required("buildingOrFloor", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      suite: Belt_Option.getWithDefault(field.required("suite", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      cityOrTown: Belt_Option.getWithDefault(field.required("cityOrTown", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      subLocality: Belt_Option.getWithDefault(field.required("subLocality", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      stateOrProvince: Belt_Option.getWithDefault(field.required("stateOrProvince", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      country: Belt_Option.getWithDefault(field.required("country", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      postalCode: Belt_Option.getWithDefault(field.required("postalCode", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      prefix: Belt_Option.getWithDefault(field.required("prefix", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      additionalLocationDetails: Belt_Option.getWithDefault(field.required("additionalLocationDetails", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      accessType: Belt_Option.getWithDefault(field.required("accessType", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      committedDataRateValue: field.required("committedDataRateValue", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
                      committedDataRateUnit: Belt_Option.getWithDefault(field.required("committedDataRateUnit", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      burstable: Belt_Option.getWithDefault(field.required("burstable", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      portSpeed: Belt_Option.getWithDefault(field.required("portSpeed", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      ipv4Addresses: Belt_Option.getWithDefault(field.required("ipv4Addresses", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      ipv6Addresses: Belt_Option.getWithDefault(field.required("ipv6Addresses", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), ""),
                      internetServices: Belt_SetString.fromArray(field.required("internetServices", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))),
                      additionalRequirements: Belt_Option.getWithDefault(field.required("additionalRequirements", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), "")
                    };
            });
}

function inputFromJson(json, contractLengths) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, inputDecoder(contractLengths)));
}

function inputToJson(configuration) {
  var x = configuration.contractLength;
  var x$1 = configuration.streetAddress;
  var tmp = x$1 === "" ? null : x$1;
  var x$2 = configuration.buildingOrFloor;
  var tmp$1 = x$2 === "" ? null : x$2;
  var x$3 = configuration.suite;
  var tmp$2 = x$3 === "" ? null : x$3;
  var x$4 = configuration.cityOrTown;
  var tmp$3 = x$4 === "" ? null : x$4;
  var x$5 = configuration.subLocality;
  var tmp$4 = x$5 === "" ? null : x$5;
  var x$6 = configuration.stateOrProvince;
  var tmp$5 = x$6 === "" ? null : x$6;
  var x$7 = configuration.country;
  var tmp$6 = x$7 === "" ? null : x$7;
  var x$8 = configuration.postalCode;
  var tmp$7 = x$8 === "" ? null : x$8;
  var x$9 = configuration.prefix;
  var tmp$8 = x$9 === "" ? null : x$9;
  var x$10 = configuration.additionalLocationDetails;
  var tmp$9 = x$10 === "" ? null : x$10;
  var x$11 = configuration.accessType;
  var tmp$10 = x$11 === "" ? null : x$11;
  var x$12 = configuration.committedDataRateUnit;
  var tmp$11 = x$12 === "" ? null : x$12;
  var x$13 = configuration.burstable;
  var tmp$12 = x$13 === "" ? null : x$13;
  var x$14 = configuration.portSpeed;
  var tmp$13 = x$14 === "" ? null : x$14;
  var x$15 = configuration.ipv4Addresses;
  var tmp$14 = x$15 === "" ? null : x$15;
  var x$16 = configuration.ipv6Addresses;
  var tmp$15 = x$16 === "" ? null : x$16;
  var x$17 = configuration.additionalRequirements;
  var tmp$16 = x$17 === "" ? null : x$17;
  return Json_Encode$JsonCombinators.object([
              [
                "startDate",
                $$Date.Naive.toJsonOpt(configuration.startDate)
              ],
              [
                "contractLength",
                x !== undefined ? x.value : null
              ],
              [
                "streetAddress",
                tmp
              ],
              [
                "buildingOrFloor",
                tmp$1
              ],
              [
                "suite",
                tmp$2
              ],
              [
                "cityOrTown",
                tmp$3
              ],
              [
                "subLocality",
                tmp$4
              ],
              [
                "stateOrProvince",
                tmp$5
              ],
              [
                "country",
                tmp$6
              ],
              [
                "postalCode",
                tmp$7
              ],
              [
                "prefix",
                tmp$8
              ],
              [
                "additionalLocationDetails",
                tmp$9
              ],
              [
                "accessType",
                tmp$10
              ],
              [
                "committedDataRateValue",
                Json_Encode$JsonCombinators.option(function (prim) {
                        return prim;
                      })(configuration.committedDataRateValue)
              ],
              [
                "committedDataRateUnit",
                tmp$11
              ],
              [
                "burstable",
                tmp$12
              ],
              [
                "portSpeed",
                tmp$13
              ],
              [
                "ipv4Addresses",
                tmp$14
              ],
              [
                "ipv6Addresses",
                tmp$15
              ],
              [
                "internetServices",
                Json_Encode$JsonCombinators.array(function (prim) {
                        return prim;
                      })(Belt_SetString.toArray(configuration.internetServices))
              ],
              [
                "additionalRequirements",
                tmp$16
              ]
            ]);
}

function outputToFormData(data) {
  var formData = new FormData();
  Belt_Array.forEach(data.configurations, (function (configuration) {
          formData.append("internet[internet_rfp_configurations][]start_date", $$Date.Naive.toJson(configuration.startDate));
          formData.append("internet[internet_rfp_configurations][]contract_length", configuration.contractLength);
          formData.append("internet[internet_rfp_configurations][]street_address", configuration.streetAddress);
          formData.append("internet[internet_rfp_configurations][]building_or_floor", Belt_Option.getWithDefault(configuration.buildingOrFloor, ""));
          formData.append("internet[internet_rfp_configurations][]suite", Belt_Option.getWithDefault(configuration.suite, ""));
          formData.append("internet[internet_rfp_configurations][]city_or_town", configuration.cityOrTown);
          formData.append("internet[internet_rfp_configurations][]sub_locality", Belt_Option.getWithDefault(configuration.subLocality, ""));
          formData.append("internet[internet_rfp_configurations][]state_or_province", configuration.stateOrProvince);
          formData.append("internet[internet_rfp_configurations][]country", configuration.country);
          formData.append("internet[internet_rfp_configurations][]postal_code", configuration.postalCode);
          formData.append("internet[internet_rfp_configurations][]prefix", Belt_Option.getWithDefault(configuration.prefix, ""));
          formData.append("internet[internet_rfp_configurations][]additional_location_details", Belt_Option.getWithDefault(configuration.additionalLocationDetails, ""));
          formData.append("internet[internet_rfp_configurations][]access_type", configuration.accessType);
          formData.append("internet[internet_rfp_configurations][]committed_data_rate_value", configuration.committedDataRateValue);
          formData.append("internet[internet_rfp_configurations][]committed_data_rate_unit", configuration.committedDataRateUnit);
          formData.append("internet[internet_rfp_configurations][]burstable", configuration.burstable);
          formData.append("internet[internet_rfp_configurations][]port_speed", Belt_Option.getWithDefault(configuration.portSpeed, ""));
          formData.append("internet[internet_rfp_configurations][]ipv4_addresses", Belt_Option.getWithDefault(configuration.ipv4Addresses, ""));
          formData.append("internet[internet_rfp_configurations][]ipv6_addresses", Belt_Option.getWithDefault(configuration.ipv6Addresses, ""));
          if (configuration.internetServices.length === 0) {
            formData.append("internet[internet_rfp_configurations][]internet_services", "");
          }
          Belt_Array.forEach(configuration.internetServices, (function (service) {
                  formData.append("internet[internet_rfp_configurations][][internet_services][]", service);
                }));
          formData.append("internet[internet_rfp_configurations][]additional_requirements", Belt_Option.getWithDefault(configuration.additionalRequirements, ""));
        }));
  Belt_Array.forEach(data.documents, (function ($$document) {
          formData.append("internet[documents][]", $$document);
        }));
  Belt_Array.forEach(data.deletedDocumentIds, (function (id) {
          formData.append("internet[deleted_document_ids][]", Project.DocumentId.toString(id));
        }));
  formData.append("internet[geographical_region]", Belt_Option.getWithDefault(data.geographicalRegion, ""));
  return formData;
}

function update(input, index, updater) {
  return {
          configurations: Belt_Array.mapWithIndex(input.configurations, (function (idx, configuration) {
                  if (idx !== index) {
                    return configuration;
                  } else {
                    return updater(configuration);
                  }
                })),
          documents: input.documents,
          deletedDocumentIds: input.deletedDocumentIds,
          geographicalRegion: input.geographicalRegion,
          validateRegion: input.validateRegion
        };
}

function remove(input, index) {
  return {
          configurations: Belt_Array.keepWithIndex(input.configurations, (function (param, idx) {
                  return idx !== index;
                })),
          documents: input.documents,
          deletedDocumentIds: input.deletedDocumentIds,
          geographicalRegion: input.geographicalRegion,
          validateRegion: input.validateRegion
        };
}

var Configuration = {
  empty: empty,
  inputDecoder: inputDecoder,
  inputFromJson: inputFromJson,
  inputToJson: inputToJson,
  outputToFormData: outputToFormData,
  update: update,
  remove: remove
};

function toJson(input) {
  return Json_Encode$JsonCombinators.object([[
                "internetRfpConfigurations",
                Belt_Array.map(input.configurations, inputToJson)
              ]]);
}

var Input = {
  toJson: toJson
};

function toFormData(data) {
  return outputToFormData(data);
}

var Output = {
  toFormData: toFormData
};

var $$FormData$1;

export {
  $$FormData$1 as $$FormData,
  validators ,
  initialFieldsStatuses ,
  initialCollectionsStatuses ,
  initialState ,
  validateForm ,
  useForm ,
  Configuration ,
  Input ,
  Output ,
}
/* Date Not a pure module */
