// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as CreateNetworkProjectSdWanNetworkConfigurationForm from "../../bundles/user-dashboard/projects/create-network-project-sdwan-network-configuration/CreateNetworkProjectSdWanNetworkConfigurationForm.res.js";

var toJson = CreateNetworkProjectSdWanNetworkConfigurationForm.Input.toJson;

var Req = {
  toJson: toJson
};

function exec(data) {
  return Rest.$$fetch("/projects/save_network_sd_wan_configurations_draft", {
              NAME: "Put",
              VAL: toJson(data)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* Rest Not a pure module */
