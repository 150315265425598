// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";

function exec() {
  return Rest.$$fetch("/users/sign_out", {
              NAME: "Delete",
              VAL: undefined
            }, "Empty", undefined, undefined);
}

export {
  exec ,
}
/* Rest Not a pure module */
