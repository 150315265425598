// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          firstName: js.firstName,
          lastName: js.lastName,
          email: js.email,
          company: js.company,
          phoneNumber: js.phoneNumber,
          image: Caml_option.nullable_to_opt(js.image),
          totalOrders: js.totalOrders
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              firstName: field.required("firstName", Json_Decode$JsonCombinators.string),
              lastName: field.required("lastName", Json_Decode$JsonCombinators.string),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              company: field.required("company", Json_Decode$JsonCombinators.string),
              phoneNumber: field.required("phoneNumber", Json_Decode$JsonCombinators.string),
              image: field.required("image", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)),
              totalOrders: field.required("totalOrders", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

export {
  fromJs ,
  decoder ,
  fromJson ,
}
/* decoder Not a pure module */
