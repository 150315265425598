// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";

function exec() {
  return Rest.$$fetch("/projects/cancel", {
              NAME: "Patch",
              VAL: null
            }, "Empty", undefined, undefined);
}

export {
  exec ,
}
/* Rest Not a pure module */
