// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";
import * as Rest from "../Rest.res.js";
import * as Project from "../../models/Project.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(role, providerId) {
  return Json_Encode$JsonCombinators.object([
              [
                "role",
                Project.Role.toJson(role)
              ],
              [
                "providerId",
                Belt_Option.getWithDefault(Belt_Option.map(providerId, (function (x) {
                            return ID.toString(x);
                          })), "")
              ]
            ]);
}

var Req = {
  toJson: toJson
};

function exec(role, isAdmin, projectId, providerId, param) {
  return Rest.$$fetch("/projects/" + (projectId + (
                isAdmin ? "/switch_admin_project_role?" : "/switch_project_role?"
              )), {
              NAME: "Put",
              VAL: toJson(role, providerId)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
